import { KibanaFilterType, ElasticFilterType } from '../filter-interfaces';
import IsFilterParser from './is-filter-parser';

/**
 * Used for Backward compatibility, similar to Kibana 'IS' filter parser
 */
export default class MatchFilterParser extends IsFilterParser {
  constructor() {
    super(KibanaFilterType.Phrase, ElasticFilterType.Match);
  }
}
