import { EventStatus } from '@logz-pkg/enums';
import { EventModel } from '@logz-pkg/models';
import { ICrudApiService } from '../utilities/types';
import { CrudApiService } from '../utilities/crud.api.service';

export class EventNextStatusApiService implements ICrudApiService<EventStatus[]> {
  constructor(public eventId: EventModel['id']) {}

  crudApiService = new CrudApiService<EventStatus[]>();

  get urls() {
    return {
      nextPermittedStatus: `/event-manager/events/${this.eventId}/next-statuses`,
    };
  }

  getNextStatus = (): Promise<EventStatus[]> => this.crudApiService.get(this.urls.nextPermittedStatus);
}
export class CorrelatedEventNextStatusApiService implements ICrudApiService<EventStatus[]> {
  constructor(public currentStatus: EventModel['status']) {}

  crudApiService = new CrudApiService<EventStatus[]>();

  get urls() {
    return {
      nextPermittedStatus: `/v2/event-manager/siem/events/${this.currentStatus}/next-statuses`,
    };
  }

  getNextStatus = (): Promise<EventStatus[]> => this.crudApiService.get(this.urls.nextPermittedStatus);
}
