/** @deprecated We should eventually eliminate non-flex owner accounts */
export class NonFlexAccountRequestModel {
  accountName: string;
  sharingAccountIds?: number[];
  docSizeSetting?: boolean;
  utilizationSettings?: { utilizationEnabled: boolean; frequencyMinutes?: number };
}

export class LogsAccountRequestModel {
  accountName: string;
  accessible: boolean;
  searchable: boolean;
  maxDailyGB: number;
  softLimitGB?: number;
  snapSearchRetentionDays?: number;
  retentionDays: number;
  docSizeSetting?: boolean;
  utilizationSettings?: { utilizationEnabled: boolean; frequencyMinutes?: number };
  sharingObjectsAccounts?: number[];
  reservedDailyGB?: number;
  email?: string;
}

export class LogsAccountResponseModel {
  accountId: number;
  accountToken: string;
}
