import { plainToClass } from 'class-transformer';
import { LookupElementModel, LookupListModel, LookupUploadResponseModel } from '@logz-pkg/models';
import { ICrudApiService } from '../utilities/types';
import { CrudApiService } from '../utilities/crud.api.service';

export class LookupElementsApiService implements ICrudApiService<LookupElementModel> {
  constructor(public lookupId: LookupListModel['id']) {}
  crudApiService = new CrudApiService<LookupElementModel>({ modelDefinition: LookupElementModel });

  get urls() {
    return {
      search: `/lookup-lists/${this.lookupId}/elements/search`,
      collection: `/lookup-lists/${this.lookupId}/elements`,
      resource: (lookupElementId: LookupElementModel['id']) =>
        `/lookup-lists/${this.lookupId}/elements/${lookupElementId}`,
      upload: `/lookup-lists/${this.lookupId}/elements/upload`,
    };
  }

  search = async requestObject => {
    if (this.lookupId) return this.crudApiService.search(this.urls.search, requestObject);

    return null;
  };

  get = (lookupElementId: LookupElementModel['id']) => this.crudApiService.get(this.urls.resource(lookupElementId));

  getAll = () => this.crudApiService.getAll(this.urls.collection);

  create = (lookupElement: LookupElementModel) => this.crudApiService.create(this.urls.collection, lookupElement);

  update = (lookupElement: LookupElementModel) =>
    this.crudApiService.update(this.urls.resource(lookupElement.id), lookupElement);

  delete = (lookupElementId: LookupElementModel['id']) =>
    this.crudApiService.delete(this.urls.resource(lookupElementId));

  upload = async ({ file, defaultTTL }: { file: File; defaultTTL: number }) => {
    const payload = new FormData();

    payload.append('file', file);

    if (typeof defaultTTL !== 'undefined' && defaultTTL !== null) payload.append('defaultTTL', `${defaultTTL}`);

    const response: LookupUploadResponseModel = await this.crudApiService.do(this.urls.upload, {
      payload,
    });

    return plainToClass(LookupUploadResponseModel, response);
  };

  getCount = async (): Promise<number> => {
    const searchResult = await this.search({});

    return searchResult.total;
  };
}
