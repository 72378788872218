const interceptors = [];

interface IXHRResponse {
  args: Array<any>;
  url: string;
  response: string;
  xhr: XMLHttpRequest;
}

interface IRequestInterceptor {
  request?: (requestData: { url: string | object }) => { url: string | object; headers?: any };
  response?: (data: IXHRResponse) => IXHRResponse;
}

export const XhrInterceptor = (env: Record<string, any>): Record<string, any> => {
  const { open, send } = env.XMLHttpRequest.prototype;

  env.XMLHttpRequest.prototype.open = function openProxy(method, url) {
    this.openParams = { url };

    return open.apply(this, arguments);
  };

  env.XMLHttpRequest.prototype.send = function sendProxy(...args) {
    // Subscribe and invoke all request interceptors and attach the request url to our object
    const modifiedConfig = interceptors.reduce(
      (aggregatedConfig, { request }) => {
        if (!request) return aggregatedConfig;

        const reqConfig = request({ args, url: this.openParams.url, xhr: this, send: request });

        if (Object.keys(reqConfig?.headers)?.length) {
          aggregatedConfig.headers = { ...aggregatedConfig.headers, ...reqConfig.headers };
        }

        return aggregatedConfig;
      },
      { headers: {} },
    );

    // Aggregate all headers and apply them onto the requests
    Object.entries(modifiedConfig.headers).forEach(([headerKey, headerVal]) =>
      this.setRequestHeader(headerKey, headerVal),
    );

    // Subscribe and invoke all response interceptors
    this.addEventListener(
      'readystatechange',
      () => {
        // Invoke only when a request is marked as DONE
        if (this.readyState === 4) {
          interceptors.forEach(({ response }) => {
            response && response({ args, url: this.openParams.url, xhr: this, response: this.response });
          });
        }
      },
      false,
    );

    return send.apply(this, args);
  };

  return {
    register(interceptor: IRequestInterceptor) {
      interceptors.push(interceptor);

      return () => {
        const index = interceptors.indexOf(interceptor);

        if (index >= 0) {
          interceptors.splice(index, 1);
        }
      };
    },
  };
};
