import {
  ServiceMapNodesEdgesResponse,
  TracesModel,
  ServiceSpmRecord,
  IGetSpmMetricsRequest,
  SpmAccount,
  LeanSpmRecord,
  MapServiceEnvAccount,
} from '@logz-pkg/models';
import { ICollectionResponse, ISearchRequestObject } from '@logz-build/typescript';
import { cacheProvider } from '../../core/cache/cache.provider';
import { ICrudApiService } from '@logz-pkg/frontend-services/src/dal/utilities/types';
import { CrudApiService } from '@logz-pkg/frontend-services/src/dal/utilities/crud.api.service';

class ObservabilityApiService implements ICrudApiService<ServiceMapNodesEdgesResponse & ServiceSpmRecord> {
  cacheOptions = { cache: true, secondsToLive: 60 * 60 * 6 };
  crudApiService = new CrudApiService<ServiceMapNodesEdgesResponse & ServiceSpmRecord & LeanSpmRecord>();
  crudOperationsApiService = new CrudApiService<string>();
  crudHasMetricsApiService = new CrudApiService<boolean>();

  urls = {
    searchDependencies: '/observability-apps/services/dependencies/search',
    searchTraces: '/observability-apps/services/traces/search',
    searchServices: '/observability-apps/services/search',
    searchCompactServices: '/observability-apps/services/compact/search',
    searchServiceOperations: '/observability-apps/services/operation/search',
    getAllOperations: '/observability-apps/services/operations/all',
    getAllEnvironments: '/observability-apps/services/environments/all',
    activeSpmAccounts: '/observability-apps/services/spm/accounts',
  };

  fetchServicesDependencies = async ({
    start,
    end,
  }: {
    start: number;
    end: number;
  }): Promise<ServiceMapNodesEdgesResponse> =>
    this.crudApiService.do(this.urls.searchDependencies, { payload: { filter: { start, end } } });

  fetchServicesSpans = async (values: ISearchRequestObject): Promise<ICollectionResponse<TracesModel>> =>
    this.crudApiService.do(this.urls.searchTraces, {
      payload: values,
    });

  fetchServices = async ({
    start,
    end,
    offset,
    operations,
    serviceType,
    serviceName,
    nodes,
    pods,
    groupByField,
    environments,
    filters,
    accountIds,
  }: IGetSpmMetricsRequest): Promise<ICollectionResponse<ServiceSpmRecord>> =>
    this.crudApiService.search(this.urls.searchServices, {
      filter: {
        start,
        end,
        offset,
        operations,
        serviceType,
        serviceName,
        nodes,
        pods,
        groupByField,
        accountIds,
        filters,
        environments,
      },
    });

  fetchCompactServices = async ({
    start,
    end,
  }: {
    start: string;
    end: string;
  }): Promise<ICollectionResponse<MapServiceEnvAccount>> =>
    this.crudApiService.search(
      this.urls.searchCompactServices,
      {
        filter: {
          start,
          end,
        },
      },
      { cacheOptions: this.cacheOptions },
    );

  fetchServiceOperations = async (values: ISearchRequestObject): Promise<ICollectionResponse<ServiceSpmRecord>> =>
    this.crudApiService.search(this.urls.searchServiceOperations, values);

  fetchAllOperations = async (values: ISearchRequestObject): Promise<ICollectionResponse<string>> =>
    this.crudOperationsApiService.search(this.urls.getAllOperations, values);

  fetchAllEnvironments = async (values: ISearchRequestObject): Promise<ICollectionResponse<string>> =>
    this.crudOperationsApiService.search(this.urls.getAllEnvironments, values);

  getSpmAccounts = async (values: ISearchRequestObject): Promise<SpmAccount[]> =>
    this.crudHasMetricsApiService.do(this.urls.activeSpmAccounts, { payload: values });

  hasSpm = async (values: ISearchRequestObject): Promise<boolean> => {
    const spmAccounts = await cacheProvider.get(
      this.urls.activeSpmAccounts,
      () => this.getSpmAccounts(values),
      60 * 60 * 5,
    );

    return Boolean(spmAccounts?.length > 0);
  };
}

export const observabilityApiService = new ObservabilityApiService();
