import { SortDirection } from '@logz-pkg/enums';
import { Expose, Transform } from 'class-transformer';
import { ElasticsearchFieldModel } from '../elasticsearch/elasticsearch-field';

export class AlertOutputFormatColumnModel {
  fieldName: ElasticsearchFieldModel['id'];
  regex = '';
  sort: SortDirection | null;

  // This is helping us workaround the fact that we don't really get an ID
  @Expose({ toClassOnly: true })
  // Set the fieldName as the ID to help us keep track of changes to the column
  @Transform((_, column) => column.fieldName, { toClassOnly: true })
  id: ElasticsearchFieldModel['id'];
}
