import { Exclude, Type, Transform, Expose } from 'class-transformer';
import { EventModel } from './event';

export class EventDetailsModel {
  @Expose({ name: 'alertEventId' })
  id: string;
  @Type(() => Date)
  @Transform(value => value * 1000, { toClassOnly: true })
  triggeredAt: Date;
  description: string;
  severity: EventModel['severity'];

  @Exclude({ toPlainOnly: true })
  title?: string;

  @Exclude({ toPlainOnly: true })
  alertDefinitionId?: number;

  @Exclude()
  mitreTags: string[] = [];
}
