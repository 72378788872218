import { MetricsAccountPlanModel, MetricsStoreTypeModel } from '@logz-pkg/models';
import { CrudApiService } from '../../../utilities/crud.api.service';
import { ICrudApiService } from '../../../utilities/types';

class MetricsAccountApiService implements ICrudApiService<MetricsAccountPlanModel> {
  crudApiService = new CrudApiService({ modelDefinition: MetricsAccountPlanModel });

  urls = {
    collection: '/account-management/metrics-accounts/accounts',
    search: '/account-management/metrics-accounts/summary/search',
    get: id => `/account-management/metrics-accounts/${id}`,
  };

  getAccountById = (id: number): Promise<MetricsStoreTypeModel> => this.crudApiService.get(this.urls.get(id));
  getAll = (): Promise<MetricsAccountPlanModel[]> => this.crudApiService.getAll(this.urls.collection);
  getAllM3DB = () =>
    this.crudApiService.do<MetricsStoreTypeModel[]>(this.urls.search, { payload: { dataStoreType: 'M3DB' } });
}

export const metricsAccountApiService = new MetricsAccountApiService();
