export const patternsEvents = {
  nextPage: 'next-page',
  fetchError: 'patterns-fetch-error',
  noPatternsFound: 'no-patterns-found',
  totalPatterns: 'total-patterns',
  patternsTabClicked: 'log-patterns-tab-clicked',
  filterIn: 'filter-in-pattern',
  filterOut: 'filter-out-pattern',
  sortBy: 'sort-by',
};
