import { ShortUrlModel, ShortUrlRequestModel } from '@logz-pkg/models';
import { ICrudApiService } from '../utilities/types';
import { CrudApiService } from '../utilities/crud.api.service';

class ShortUrlApiService implements ICrudApiService<ShortUrlModel, ShortUrlRequestModel> {
  crudApiService = new CrudApiService<ShortUrlModel, ShortUrlRequestModel>({ modelDefinition: ShortUrlModel });

  urls = {
    collection: '/observability-apps/services/short-url',
    resource: (id: ShortUrlModel['id']) => `/observability-apps/services/short-url/${id}`,
  };

  create = (model: ShortUrlRequestModel) => this.crudApiService.create(this.urls.collection, model);

  get = (id: ShortUrlModel['id']) => this.crudApiService.get(this.urls.resource(id));
}

export const shortUrlApiService = new ShortUrlApiService();
