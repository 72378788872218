import { Os } from '@logz-pkg/enums';

const getChromeVersion = () => {
  const { userAgent } = window.navigator;
  const raw = userAgent.match(/Chrom(e|ium)\/([0-9]+)\./);

  return raw ? parseInt(raw[2], 10) : false;
};

const getOs = (): Os => {
  const { userAgent } = window.navigator;

  if (userAgent.includes('Windows')) return Os.Windows;

  if (userAgent.includes('Mac')) return Os.Mac;

  if (userAgent.includes('Linux')) return Os.Linux;

  return null;
};

export const browserService = {
  getOs,
  getChromeVersion,
};
