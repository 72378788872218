import { AssumeRoleDetailsModel } from '@logz-pkg/models';
import { ICrudApiService } from '../utilities/types';
import { CrudApiService } from '../utilities/crud.api.service';

class AssumeRoleDetailsApiService implements ICrudApiService<AssumeRoleDetailsModel> {
  crudApiService = new CrudApiService({ modelDefinition: AssumeRoleDetailsModel });
  urls = {
    get: '/log-shipping/s3-buckets/aws-assume-role-details',
  };

  get = () => this.crudApiService.get(this.urls.get);
}

export const assumeRoleDetailsApiService = new AssumeRoleDetailsApiService();
