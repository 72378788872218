import { SavedObjectModel, SavedObjectRequestModel } from '@logz-pkg/models';
import { ISearchRequestObject } from '@logz-build/typescript';
import { ICrudApiService } from '../utilities/types';
import { CrudApiService } from '../utilities/crud.api.service';

class SavedObjectsApiService implements ICrudApiService<SavedObjectModel, SavedObjectRequestModel> {
  crudApiService = new CrudApiService<SavedObjectModel, SavedObjectRequestModel>({ modelDefinition: SavedObjectModel });
  urls = {
    collection: '/observability-apps/services/explore/saved-object',
    search: `/observability-apps/services/explore/saved-object/search`,
    migrate: '/observability-apps/services/explore/saved-object/migrate-search',
    resource: (id: string) => `/observability-apps/services/explore/saved-object/${id}`,
    favorite: (id: string) => `/observability-apps/services/explore/saved-object/${id}/favorite`,
    unfavorite: (id: string) => `/observability-apps/services/explore/saved-object/${id}/unfavorite`,
  };

  create = (values: SavedObjectRequestModel) => this.crudApiService.create(this.urls.collection, values);

  delete = async (id: string) => this.crudApiService.delete(this.urls.resource(id));

  get = async (id: string) => this.crudApiService.get(this.urls.resource(id));

  search = async (values: ISearchRequestObject) => this.crudApiService.search(`${this.urls.search}`, values);

  migrate = async () => this.crudApiService.do(this.urls.migrate);

  update = async (values: SavedObjectRequestModel) => this.crudApiService.update(this.urls.resource(values.id), values);

  favorite = async (id: string) => this.crudApiService.do(this.urls.favorite(id));

  unfavorite = async (id: string) => this.crudApiService.do(this.urls.unfavorite(id));
}

export const savedObjectsApiService = new SavedObjectsApiService();
