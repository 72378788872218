import { EventModel } from '@logz-pkg/models';
import { populate } from '../event-management/populate';
import { EventManagementApiService } from '@logz-pkg/frontend-services';

class CorrelatedEventManagementApiService extends EventManagementApiService {
  constructor() {
    super();
    this.urls = {
      search: '/v2/event-manager/siem/events/search',
      resource: (eventId: EventModel['id']): string => `/v2/event-manager/siem/events/${eventId}`,
      bulkUpdate: '/v2/event-manager/siem/events/bulk/update',
    };
  }
  get = async (id: EventModel['id']): Promise<EventModel> => {
    const event = await this.crudApiService.get(this.urls.resource(id));
    const eventWithTags = (await populate.withMitreTags([event]))[0];

    return eventWithTags;
  };
}

export const correlatedEventManagementApiService = new CorrelatedEventManagementApiService();
