import KibanaFilterMetadata from '../metadatas/osd-filter-metadata';
import { IElasticFilter, ElasticFilterType, KibanaFilterType } from '../filter-interfaces';
import KibanaLookupFilterMetadata from '../metadatas/osd-lookup-filter-metadata';
import LookupFilterModel from '../models/lookup-filter-model';
import BaseFilterParser from './base-filter-parser';

/**
 * Elastic to Kibana 'LOOKUP' filter parser
 */
class LookupFilterParser extends BaseFilterParser {
  constructor() {
    super(KibanaFilterType.Lookup, ElasticFilterType.Lookup);
  }

  getMetadata(filter: IElasticFilter, negate = false, indexPattern): KibanaFilterMetadata {
    let retVal = null;

    if (filter.lookup) {
      const { id, name, field: key, alertEventId } = filter.lookup;

      retVal = new KibanaLookupFilterMetadata(id, name, key, negate, alertEventId, indexPattern);
    }

    return retVal;
  }

  getFilter(metadata: KibanaLookupFilterMetadata): LookupFilterModel {
    if (!this.assertType(metadata)) return null;

    return new LookupFilterModel(metadata);
  }
}

export const lookupFilterParser = new LookupFilterParser();
export default new LookupFilterParser();
