import * as yup from 'yup';
import { Os } from '@logz-pkg/enums';
import { registerUniqueMethod } from '@logz-pkg/utils';
import {
  TelemetryAgentInformationElementType,
  TelemetryAgentIntegrationCapability,
  TelemetryAgentIntegrationType,
  TelemetryAgentParamType,
} from '../enums';
import { keyValueParamManifestSchema, keyValueParamSchema } from './key-value.param.schema';
import { multiSelectParamManifestSchema, multiSelectParamSchema } from './multi-select.param.schema';
import { textParamManifestSchema, textParamSchema } from './text.param.schema';
import { numberParamManifestSchema, numberParamSchema } from './number.param.schema';
import { singleSelectParamManifestSchema, singleSelectParamSchema } from './single-select.param.schema';
import { multilinePatternParamManifestSchema, multilinePatternParamSchema } from './multiline.param.schema';
import { pathListParamManifestSchema, pathListParamSchema } from './path-list.param.schema';
import { stringListParamManifestSchema, stringListParamSchema } from './string-list.param.schema';
import { oneOfEnum, paramBaseSchema, paramMetaDataSchema, telemetryBaseSchema } from './shared.schemas';

registerUniqueMethod(yup);

export const paramManifestSchema = yup.mixed().test('paramManifestSchema', function (param) {
  try {
    const schema = paramTypeToManifestSchema[param.type];

    if (!schema) {
      throw { message: `unsupported param type: ${param.type}` };
    }

    schema.validateSync(param);

    return true;
  } catch (e) {
    return this.createError({
      message: e.message,
    });
  }
});

export const paramSchema = yup.mixed().test('paramSchema', function (param) {
  try {
    const schema = paramTypeToSchema[param.type];

    if (!schema) {
      throw { message: 'unsupported param type' };
    }

    // Boolean param may have nested params, and we want to validate all of them
    // When multiple nested errors are found, and abortEarly is set to false, we get a different validationError structure
    // which is handled below in the catchphrase
    schema.validateSync(param, { abortEarly: param.type !== TelemetryAgentParamType.Boolean });

    return true;
  } catch (e) {
    const validationError = this.createError({
      message: e.message,
    });

    if (e.inner?.length) {
      // Some params (i.e. boolean) may have further nested params to validate
      // This makes sure a nested validation error is registered on the correct path
      // when we receive an inner prop we know that we have nested errors to register on the correct path
      const errors = e.inner.map(inner => ({ ...inner, path: `${validationError.path}.${inner.path}` }));
      const multiError = this.createError({
        message: () => errors,
      });

      return multiError;
    }

    if (e.path !== 'value') {
      // in case our error is on a non primitive value
      validationError.path = `${validationError.path}.${e.path}`;
    }

    return validationError;
  }
});

export const telemetrySchema = telemetryBaseSchema.shape({
  params: yup
    .array()
    .of(paramSchema)
    .unique('param name must be unique', param => param.name)
    .strict()
    .nullable(),
});

export const telemetryManifestSchema = telemetryBaseSchema.shape({
  params: yup
    .array()
    .of(paramManifestSchema)
    .unique('param name must be unique', param => param.name)
    .strict()
    .nullable(),
});

const informationSchema = yup.object({
  title: yup.string(),
  text: yup.string().nullable(),
  variant: oneOfEnum(TelemetryAgentInformationElementType),
});

const datasourceBaseSchema = yup.object().shape({
  name: yup.string().required(),
  label: yup.string().nullable(),
  logo: yup.string().url().nullable(),
  description: yup.string().nullable(),
  hint: yup.string().nullable(),
  params: yup.array().nullable(), // this field's base schema is overridden in its extending schemas
  telemetries: yup.array().nullable(), // this field's base schema is overridden in its extending schemas
  information: yup.array().of(informationSchema).nullable(),
});

const datasourceManifestSchema = datasourceBaseSchema.shape({
  params: yup
    .array()
    .of(paramManifestSchema)
    .unique('param name must be unique', param => param.name)
    .strict()
    .nullable(),
  telemetries: yup
    .array()
    .of(telemetryManifestSchema)
    .strict()
    .max(3)
    .unique('telemetry must be unique', telemetry => telemetry.type),
});

const datasourceSchema = datasourceBaseSchema.shape({
  params: yup
    .array()
    .of(paramSchema)
    .unique('param name must be unique', param => param.name)
    .strict()
    .nullable(),
  telemetries: yup
    .array()
    .of(telemetrySchema)
    .strict()
    .min(1)
    .max(3)
    .required()
    .unique('telemetry must be unique', telemetry => telemetry.type),
});

const booleanParamBaseSchema = paramBaseSchema.shape({
  type: oneOfEnum({ type: TelemetryAgentParamType.Boolean }).required('param type required'),
  value: yup.boolean().nullable(),
  params: yup.array().nullable(), // this field's base schema is overridden in its extending schemas
});

const booleanParamManifestSchema = booleanParamBaseSchema.concat(paramMetaDataSchema).shape({
  params: yup
    .array()
    .of(paramManifestSchema)
    .unique('param name must be unique', param => param.name),
});

const booleanParamSchema = booleanParamBaseSchema.shape({
  value: yup.boolean().nullable(),
  params: yup.array().when(['value'], {
    is: value => value,
    then: yup
      .array()
      .of(paramSchema)
      .unique('param name must be unique', param => param.name)
      .strict()
      .nullable(),
    otherwise: yup.array().nullable(),
  }),
});

const subtypeBaseSchema = yup.object().shape({
  name: yup.string().required(),
  logo: yup.string().url().nullable(),
  description: yup.string().nullable(),
  hint: yup.string().nullable(),
  supportedOs: yup.array().of(oneOfEnum(Os)).strict().unique('supported os must be unique').nullable(),
  capabilities: yup
    .array()
    .of(oneOfEnum(TelemetryAgentIntegrationCapability))
    .strict()
    .unique('supported services must contain unique values')
    .nullable(),
  integrationTemplate: yup.string().nullable(),
  datasources: yup.array().nullable(), // this field's base schema is overridden in its extending schemas
});

const subtypeManifestSchema = subtypeBaseSchema.shape({
  datasources: yup.array().of(datasourceManifestSchema).strict().nullable(),
});

const subtypeSchema = subtypeBaseSchema.shape({
  datasources: yup.array().of(datasourceSchema).strict().min(1).required(),
});

const platformBaseSchema = yup.object().shape({
  name: yup.string().required(),
  label: yup.string().nullable(),
  type: oneOfEnum(TelemetryAgentIntegrationType).nullable(),
  logo: yup.string().url().nullable(),
  description: yup.string().nullable(),
  hint: yup.string().nullable(),
  subtypes: yup.array().nullable(), // this field's base schema is overridden in its extending schemas
});

const platformManifestSchema = platformBaseSchema.shape({
  subtypes: yup.array().of(subtypeManifestSchema).strict().nullable(),
});

const platformSchema = platformBaseSchema.shape({
  subtypes: yup.array().of(subtypeSchema).strict().min(1).max(1).required(),
});

const telemetryAgentManifestSchema = yup.array().of(platformManifestSchema).strict().min(1);

const createTelemetryAgentRequestSchema = yup.object().shape({
  name: yup.string().required('Missing agent name'),
  description: yup.string().nullable(),
  configuration: platformSchema.required('Missing configuration'),
  logsAccountId: yup.number().nullable(),
  metricsAccountId: yup.number().nullable(),
  tracingAccountId: yup.number().nullable(),
});

export const telemetryAgentSchemas = {
  config: {
    platform: platformSchema,
    subtype: subtypeSchema,
    datasource: datasourceSchema,
    telemetry: telemetrySchema,
    param: paramSchema,
    booleanParam: booleanParamSchema,
    multilineParam: multilinePatternParamSchema,
    pathListParam: pathListParamSchema,
    stringListParam: stringListParamSchema,
    textParam: textParamSchema,
    numberParam: numberParamSchema,
    singleSelectParam: singleSelectParamSchema,
    multiSelectParam: multiSelectParamSchema,
    keyValueParam: keyValueParamSchema,
  },
  manifest: {
    schema: telemetryAgentManifestSchema,
    platform: platformManifestSchema,
    subtype: subtypeManifestSchema,
    datasource: datasourceManifestSchema,
    telemetry: telemetryManifestSchema,
    param: paramManifestSchema,
    booleanParam: booleanParamManifestSchema,
    multilineParam: multilinePatternParamManifestSchema,
    pathListParam: pathListParamManifestSchema,
    stringListParam: stringListParamManifestSchema,
    textParam: textParamManifestSchema,
    numberParam: numberParamManifestSchema,
    singleSelectParam: singleSelectParamManifestSchema,
    multiSelectParam: multiSelectParamManifestSchema,
    keyValueParam: keyValueParamManifestSchema,
  },
  createRequest: createTelemetryAgentRequestSchema,
};

const paramTypeToManifestSchema = {
  [TelemetryAgentParamType.Boolean]: booleanParamManifestSchema,
  [TelemetryAgentParamType.MultilinePattern]: multilinePatternParamManifestSchema,
  [TelemetryAgentParamType.PathList]: pathListParamManifestSchema,
  [TelemetryAgentParamType.StringList]: stringListParamManifestSchema,
  [TelemetryAgentParamType.SingleSelect]: singleSelectParamManifestSchema,
  [TelemetryAgentParamType.MultiSelect]: multiSelectParamManifestSchema,
  [TelemetryAgentParamType.Text]: textParamManifestSchema,
  [TelemetryAgentParamType.Number]: numberParamManifestSchema,
  [TelemetryAgentParamType.KeyValue]: keyValueParamManifestSchema,
};

const paramTypeToSchema = {
  [TelemetryAgentParamType.Boolean]: booleanParamSchema,
  [TelemetryAgentParamType.MultilinePattern]: multilinePatternParamSchema,
  [TelemetryAgentParamType.PathList]: pathListParamSchema,
  [TelemetryAgentParamType.StringList]: stringListParamSchema,
  [TelemetryAgentParamType.SingleSelect]: singleSelectParamSchema,
  [TelemetryAgentParamType.MultiSelect]: multiSelectParamSchema,
  [TelemetryAgentParamType.Text]: textParamSchema,
  [TelemetryAgentParamType.Number]: numberParamSchema,
  [TelemetryAgentParamType.KeyValue]: keyValueParamSchema,
};
