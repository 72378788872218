import { has, intersection, omit } from 'lodash';
import { IElasticFilter } from './filter-interfaces';
import { CustomElasticKeys, CustomFilterParsers, KibanaFilterParsers, SupportedElasticKeys } from './osd-filter-parsers';
import KibanaFilterMetadata from './metadatas/osd-filter-metadata';
import KibanaFilterModel from './models/osd-filter-model';
import BaseFilterParser from './parsers/base-filter-parser';

const AllFilterParsers = [...KibanaFilterParsers, ...CustomFilterParsers];

/**
 * Traverse available Elastic to Kibana parsers for valid Kibana Filter metadata,
 *  only one parser should prevail.
 */
function elasticToKibanaMetadata(filter: IElasticFilter, negate = false, indexPattern): KibanaFilterMetadata {
  if (!filter) return;

  for (const parser of AllFilterParsers) {
    const retVal: KibanaFilterMetadata = parser.getMetadata(filter, negate, indexPattern);

    if (retVal) return retVal;
  }

  return null; // in case of unsupported filter
}

/**
 * Traverse available Elastic to Kibana parsers for valid Kibana Filter,
 * only one parser should prevail.
 */
function getKibanaFormattedFilter(metadata: KibanaFilterMetadata): KibanaFilterModel {
  if (!metadata) return;

  for (const parser of AllFilterParsers) {
    const retVal: KibanaFilterModel = (parser as BaseFilterParser).getFilter(metadata);

    if (retVal) return retVal;
  }
}

function elasticToKibanaReduceCallback(accumulator, filter, negate, indexPattern) {
  let metadata = elasticToKibanaMetadata(filter, negate, indexPattern);

  if (filter.meta) {
    metadata = { ...metadata, ...filter.meta };
  }

  if (metadata && metadata.key) {
    return accumulator.concat(getKibanaFormattedFilter(metadata));
  }

  return accumulator;
}

/**
 * Transform Elastic query filter to Kibana filter format.
 */
function elasticToKibanaFilters(query: string | object, indexPattern: string): KibanaFilterModel[] {
  const queryJson = typeof query === 'string' ? JSON.parse(query) : query;

  if (!queryJson.bool) return null;

  let mustEsFilters = [];

  if (queryJson.bool.must) {
    mustEsFilters = queryJson.bool.must.reduce(
      (accumulated, currentValue) => elasticToKibanaReduceCallback(accumulated, currentValue, false, indexPattern),
      [],
    );
  }

  let mustNotEsFilters = [];

  if (queryJson.bool.must_not) {
    mustNotEsFilters = queryJson.bool.must_not.reduce(
      (accumulated, currentValue) => elasticToKibanaReduceCallback(accumulated, currentValue, true, indexPattern),
      [],
    );
  }

  return mustEsFilters.concat(mustNotEsFilters);
}

function isDefaultRangeFilter(filter: IElasticFilter) {
  return has(filter, 'range.@timestamp');
}

/**
 * Remove default date range filter and clean $state.
 */
function clearKibanaProps(filters: IElasticFilter[]): IElasticFilter[] {
  return filters.filter(filter => !isDefaultRangeFilter(filter)).map(filter => omit(filter, ['$state']));
}

/**
 * Parse Elastic filters generated by Kibana-Execute supported by Logzio (ignores time related default filter)
 */
function parseElasticFilters(filters: IElasticFilter[], intersectionKeys = SupportedElasticKeys): IElasticFilter[] {
  // Extract supported filters
  const result = filters.filter(filter => {
    const filterKeys = Object.keys(filter);

    return intersection(intersectionKeys, filterKeys).length > 0;
  });

  return clearKibanaProps(result);
}

/**
 * Parse Custom Elastic filters generated by Kibana-Execute supported by Logzio (ignores time related default filter)
 */
function parseCustomElasticFilters(filters: IElasticFilter[]): IElasticFilter[] {
  return parseElasticFilters(filters, CustomElasticKeys);
}

/**
 * Parse All supported Elastic and Custom filters.
 */
function parseSupportedElasticFilters(filters: IElasticFilter[]): IElasticFilter[] {
  return [...parseElasticFilters(filters), ...parseCustomElasticFilters(filters)];
}

export default {
  parseElasticFilters,
  parseCustomElasticFilters,
  parseSupportedElasticFilters,
  elasticToKibanaFilters,
  elasticToKibanaMetadata,
};

export const kibanaFilters = {
  parseElasticFilters,
  parseCustomElasticFilters,
  parseSupportedElasticFilters,
  elasticToKibanaFilters,
  elasticToKibanaMetadata,
};
