import { TracingSamplingRuleActionTypes } from '@logz-pkg/enums';
import { Transform } from 'class-transformer';
import {
  transformOtelRulesToConfigRules,
  transformConfigRulesToOtelRules,
  getDisabledRulesIndexes,
} from './sampling-rules.manipulator';

type TracingConfigRuleAction = {
  type: TracingSamplingRuleActionTypes;
  value?: number;
};

export type TracingConfigRule = {
  index?: number;
  action: TracingConfigRuleAction;
  services?: string[];
  isDisabled?: boolean;
};

export class TracingSamplingConfigModel<T = TracingConfigRule> {
  readonly accountId: number;
  tracingAccountId: number;

  @Transform((_, { rules }) => getDisabledRulesIndexes(rules), { toPlainOnly: true })
  disabledRules: number[] = [];

  @Transform((_, { rules }) => transformConfigRulesToOtelRules(rules), { toPlainOnly: true })
  @Transform((_, { rules, disabledRules }) => transformOtelRulesToConfigRules(rules, disabledRules), {
    toClassOnly: true,
  })
  rules: T[] = [];

  createdBy?: number;
  createdAt?: Date;
  updatedBy?: number;
  updatedAt?: Date;
}

export type OtelRule = RuleWithSubPolicy | KeepFailedRule | KeepLatencyRule | KeepPercentageRule;

export type RuleWithSubPolicy = {
  name: string;
  type: 'and';
  and: {
    and_sub_policy: [ServicesRule, KeepFailedRule | KeepLatencyRule | KeepPercentageRule];
  };
};

export type ServicesRule = {
  name: string;
  type: 'string_attribute';
  string_attribute: {
    key: 'service.name';
    values: string[];
  };
};

export type KeepFailedRule = {
  name: string;
  type: 'status_code';
  status_code: {
    status_codes: ['ERROR'];
  };
};

export type KeepLatencyRule = {
  name: string;
  type: 'latency';
  latency: {
    threshold_ms: number;
  };
};

export type KeepPercentageRule = {
  name: string;
  type: 'probabilistic';
  probabilistic: {
    hash_salt: 'custom-salt';
    sampling_percentage: number;
  };
};
