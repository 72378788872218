import { isNil } from 'lodash';
import {
  ElasticsearchEnhancedFilterModel,
  ElasticsearchFilterModel,
  IElasticsearchFilterValue,
} from './elasticsearch-filter';

export const getFilterValueFormat = (filter: ElasticsearchEnhancedFilterModel | ElasticsearchFilterModel) => {
  return {
    EXIST: (_?: any) => 'exists',
    IS: (value: IElasticsearchFilterValue['IS']) => value,
    LOOKUP: (value: IElasticsearchFilterValue['LOOKUP']) => `Lookup: ${value?.name}`,
    IS_ONE_OF: (value: IElasticsearchFilterValue['IS_ONE_OF']) => value.join(', '),
    IS_BETWEEN: (value: IElasticsearchFilterValue['IS_BETWEEN']) =>
      `${!isNil(value.gte) ? value.gte : '-∞'} to ${!isNil(value.lt) ? value.lt : '+∞'}`,
    LESS_THAN: (value: IElasticsearchFilterValue['LESS_THAN']) => `-∞ to ${!isNil(value.lt) ? value.lt : '+∞'}`,
    GREATER_THAN: (value: IElasticsearchFilterValue['GREATER_THAN']) => `${!isNil(value.gte) ? value.gte : '-∞'} to +∞`,
    LUCENE: (value: IElasticsearchFilterValue['LUCENE']) => `query: ${value}`,
  }
    [filter.type](filter?.value)
    .toString();
};
