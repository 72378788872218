import { urlParser } from '@logz-pkg/utils';

export const JAEGER_AUTH_TOKEN = 'logz-jaegerToken';
export const LOGZ_SESSION_COOKIE_KEY = 'logz-session';
export const LAST_AUTH_TOKEN_COOKIE = 'logz-lastAuthToken';
export const SIGNED_IN_WITH_SSO_COOKIE_KEY = 'signedWithSso';
export const CURRENT_ACCOUNT_ID_COOKIE = 'logz-current-account-id';
export const ADMIN_ACCOUNT_ID_COOKIE = 'logz-admin-account-id';
export const LAST_USED_ACCOUNT_ID_COOKIE = 'logz-last-used-account-id';
export const CURRENT_ACCOUNT_ID_HEADER_NAME = 'x-current-account-id';
export const ADMIN_ACCOUNT_ID_HEADER_NAME = 'x-admin-account-id';

export const SHARE_TOKEN_HEADER = 'X-SHARE-TOKEN';
export const USER_TOKEN_HEADER = 'X-USER-TOKEN';

type ShareTokenValues = string | string[];

const getShareToken = (): ShareTokenValues => {
  const { parsedQuery, hashQuery } = urlParser(window.document.location.href);

  return parsedQuery.shareToken || hashQuery.shareToken;
};

export const isInShareMode = (): boolean => {
  const shareToken = getShareToken();

  return Boolean(shareToken);
};

export const authTokens = {
  JAEGER_AUTH_TOKEN,
  LOGZ_SESSION_COOKIE_KEY,
  LAST_AUTH_TOKEN_COOKIE,
  SIGNED_IN_WITH_SSO_COOKIE_KEY,
  SHARE_TOKEN_HEADER,
  USER_TOKEN_HEADER,
  CURRENT_ACCOUNT_ID_COOKIE,
  ADMIN_ACCOUNT_ID_COOKIE,
  LAST_USED_ACCOUNT_ID_COOKIE,
  CURRENT_ACCOUNT_ID_HEADER_NAME,
  ADMIN_ACCOUNT_ID_HEADER_NAME,
  getShareToken,
  isInShareMode,
};
