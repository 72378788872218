import {
  CreateTelemetryAgentRequest,
  TelemetryAgentConfigurationModel,
  TelemetryAgentModel,
  TelemetryAgentSearchResponseModel,
} from '@logz-pkg/models';
import { ICollectionResponse, ISearchRequestObject } from '@logz-build/typescript';
import { Os, Product } from '@logz-pkg/enums';
import { ICrudApiService } from '../utilities/types';
import { CrudApiService } from '../utilities/crud.api.service';

export class TelemetryAgentApiService implements ICrudApiService<TelemetryAgentModel, CreateTelemetryAgentRequest> {
  crudApiService = new CrudApiService<TelemetryAgentModel, CreateTelemetryAgentRequest>({
    modelDefinition: TelemetryAgentModel,
  });
  searchApiService = new CrudApiService<TelemetryAgentSearchResponseModel>();
  configurationsApiService = new CrudApiService<TelemetryAgentConfigurationModel>();
  installationApiService = new CrudApiService<Record<Os, string>>();
  dashboardsApiService = new CrudApiService<Record<Product, string[]>>();

  urls = {
    collection: 'telemetry-agent/agents',
    search: 'telemetry-agent/agents/search',
    resource: (id: TelemetryAgentModel['id']) => `telemetry-agent/agents/${id}`,
    manifest: 'telemetry-agent/manifest',
    installationCommands: 'telemetry-agent/install-commands',
    getAvailableDashboards: 'telemetry-agent/available-dashboards',
    enable: (id: TelemetryAgentModel['id']) => `telemetry-agent/agents/${id}/enable`,
    disable: (id: TelemetryAgentModel['id']) => `telemetry-agent/agents/${id}/disable`,
  };

  get = async (id: TelemetryAgentModel['id']): Promise<TelemetryAgentModel> => {
    return this.crudApiService.get(this.urls.resource(id));
  };

  search = async (
    requestObject: ISearchRequestObject,
  ): Promise<ICollectionResponse<TelemetryAgentSearchResponseModel>> => {
    return this.searchApiService.search(this.urls.search, requestObject);
  };

  create = async (model: CreateTelemetryAgentRequest) => {
    return this.crudApiService.create(this.urls.collection, model);
  };

  edit = async (model: TelemetryAgentModel) => {
    return this.crudApiService.update(this.urls.resource(model.id), model);
  };

  delete = async (id: TelemetryAgentModel['id']): Promise<TelemetryAgentModel> => {
    return this.crudApiService.delete(this.urls.resource(id));
  };

  getManifest = async (): Promise<TelemetryAgentConfigurationModel[]> => {
    return this.configurationsApiService.getAll(this.urls.manifest, { cache: true, secondsToLive: 3600 });
  };

  getInstallationCommands = async (id: TelemetryAgentModel['id']): Promise<Record<Os, string>> => {
    return this.installationApiService.do(this.urls.installationCommands, { payload: { id } });
  };

  getAvailableDashboards = async (
    configuration: TelemetryAgentConfigurationModel,
  ): Promise<Record<Product, string[]>> => {
    return this.dashboardsApiService.do(this.urls.getAvailableDashboards, { payload: configuration });
  };

  enable = async (id: TelemetryAgentModel['id']): Promise<void> => {
    return this.crudApiService.do(this.urls.enable(id));
  };

  disable = async (id: TelemetryAgentModel['id']): Promise<void> => {
    return this.crudApiService.do(this.urls.disable(id));
  };
}

export const telemetryAgentApiService = new TelemetryAgentApiService();
