import { ScheduledReportModel } from '@logz-pkg/models';
import { ICollectionResponse } from '@logz-build/typescript';
import { cloneDeep } from 'lodash';
import { plainNotificationEndpointApiService } from '../notification-endpoint/api.service';
import { kibanaDashboardApiService } from '../entities/osd-dashboard/api.service';

const getDashboardsIdsFromResult = (ScheduledReportsSearchResult): string[] =>
  ScheduledReportsSearchResult.results.reduce((acc, cur) => {
    if (cur.reportObject) {
      acc.push(cur.reportObject.id);
    }

    return acc;
  }, []);

const enrichWithEndpoints = async (reports: ScheduledReportModel[]): Promise<ScheduledReportModel[]> => {
  try {
    const endpointIds = reports.reduce((acc, report) => {
      const filteredUniqIds = report.recipients.endpointIds.filter(endpointId => acc.indexOf(endpointId) === -1);

      acc.push(...filteredUniqIds);

      return acc;
    }, []);

    const { results: slackEndpoints } = await plainNotificationEndpointApiService.search({
      filter: { endpointIds },
    });

    return reports.map(report => {
      report.recipients.notificationEndpoints = slackEndpoints.filter(({ id }) =>
        report.recipients.endpointIds.includes(id),
      ) as unknown as any;

      return report;
    });
  } catch (e) {
    return reports;
  }
};

const enrichWithDashboards = (results, kibanaDashboards): ScheduledReportModel[] =>
  results.map(scheduledReport => {
    scheduledReport.dashboard = kibanaDashboards.results.find(
      dashboard => dashboard.id === scheduledReport.reportObject.id,
    );

    return scheduledReport;
  });

export const populateCollection = async (collection: ICollectionResponse<ScheduledReportModel>) => {
  const results = cloneDeep(collection);
  const dashboardIds = getDashboardsIdsFromResult(results);

  if (dashboardIds.length > 0) {
    const kibanaDashboards = await kibanaDashboardApiService.searchByIds(dashboardIds);

    results.results = enrichWithDashboards(results.results, kibanaDashboards);
  }

  results.results = await enrichWithEndpoints(results.results);

  return results;
};

export const populateEntity = async (entity: ScheduledReportModel) => {
  let result = cloneDeep(entity);

  const kibanaDashboard = await kibanaDashboardApiService.searchByIds([result.reportObject.id]);

  result.dashboard = kibanaDashboard.results.find(dashboard => dashboard.id === result.reportObject.id);
  [result] = await enrichWithEndpoints([result]);

  return result;
};

export const populateScheduledReports = {
  entity: populateEntity,
  collection: populateCollection,
};
