import { shortUrlApiService } from '../dal/observability/short-url.api.service';

export const shortUrlService = {
  getShortUrl: async () => {
    const shortUrl = await shortUrlApiService.create({ url: window.location.hash });

    return `${window.location.protocol}//${window.location.hostname}/#/short-url/${shortUrl.id}?switchToAccountId=${shortUrl.accountId}`;
  },

  getFullUrl: async (id: string) => {
    const { url } = await shortUrlApiService.get(id);

    return url;
  },
};
