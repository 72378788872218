import { Expose, Exclude, Type, Transform } from 'class-transformer';
import { ArchiveAndRestoreStorageType, S3AuthenticationTypes } from '@logz-pkg/enums';

class S3Secret {
  accessKey: string;
  secretKey: string;
}

class S3Iam {
  arn: string;
  externalId: string;
}

class S3Storage {
  credentialsType: S3AuthenticationTypes;

  path: string;

  @Type(() => S3Secret)
  @Expose({ name: 's3SecretCredentials' })
  secret: S3Secret;

  @Type(() => S3Iam)
  @Expose({ name: 's3IamCredentials' })
  iam: S3Iam;
}

class BlobStorage {
  tenantId?: string;
  clientId?: string;
  clientSecret?: string;
  accountName?: string;
  containerName?: string;
  // Remove after fix https://logzio.atlassian.net/browse/DEV-26406
  @Transform(value => (value === '' ? null : value), { toPlainOnly: true })
  path?: string;
}

@Exclude()
export class ArchiveSettingsModel {
  // Because of inconsistency in API the id is populated manually in api.server
  id?: number;

  @Expose({ name: 'enabled' })
  isEnabled: boolean;

  @Expose({ name: 'compressed' })
  isCompressed: boolean;

  @Expose({ since: 2 })
  storageType: ArchiveAndRestoreStorageType;

  @Expose({ name: 'amazonS3StorageSettings' })
  @Transform(value => value ?? new S3Storage(), { toClassOnly: true })
  @Type(() => S3Storage)
  s3?: S3Storage = new S3Storage();

  @Expose({ name: 'azureBlobStorageSettings' })
  @Transform(value => value ?? new BlobStorage(), { toClassOnly: true })
  @Type(() => BlobStorage)
  blob?: BlobStorage = new BlobStorage();

  isAwsWithIAMSettings() {
    return (
      this.storageType === ArchiveAndRestoreStorageType.AWS && this.s3?.credentialsType === S3AuthenticationTypes.IAM
    );
  }
}
