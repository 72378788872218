import { Transform } from 'class-transformer';
import { AlertModel, AlertExportFields } from '../alert/alert';
import { AlertOutputFormatColumnModel } from '../alert/alert-output-format-column';
import { AlertOutputSamplesResponseModel } from '../alert/alert-output-samples-response';

export class RuleOutputFormatColumn extends AlertOutputFormatColumnModel {}
export class RuleOutputSamplesResponse extends AlertOutputSamplesResponseModel {}
export class RuleModel extends AlertModel {
  @Transform(value => value.join(','), { groups: ['csv'] })
  mitreTags: string[] = [];
}
export const RuleExportFields: Array<keyof RuleModel> = [...AlertExportFields, 'mitreTags'];
