import * as yup from 'yup';
import { TelemetryAgentParamType } from '../enums';
import { oneOfEnum, paramBaseSchema, paramMetaDataSchema } from './shared.schemas';

const numberParamBaseSchema = paramBaseSchema.shape({
  type: oneOfEnum({ type: TelemetryAgentParamType.Number }).required('param type required'),
  value: yup.number().nullable(),
  min: yup.number().nullable(),
  max: yup.number().nullable(),
});

export const numberParamSchema = yup.lazy(param => {
  let schema = numberParamBaseSchema;
  let valueSchema = yup.number();

  if (param.required) {
    valueSchema = valueSchema.required('required');
  } else {
    valueSchema = valueSchema.nullable();
  }

  if (typeof param.min === 'number') {
    valueSchema = valueSchema.min(param.min);
  }

  if (typeof param.max === 'number') {
    valueSchema = valueSchema.max(param.max);
  }

  schema = schema.shape({
    value: valueSchema,
  });

  return schema;
});

export const numberParamManifestSchema = numberParamBaseSchema.concat(paramMetaDataSchema).shape({
  value: yup.number().nullable(),
});
