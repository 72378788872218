import { ICollectionResponse, ISearchRequestObject } from '@logz-build/typescript';
import { AccountModel } from '@logz-pkg/models';
import { ICrudApiService } from '../../utilities/types';
import { CrudApiService } from '../../utilities/crud.api.service';
import { LoggerService } from '../../../core/logger/logger.service';

class AccountApiService implements ICrudApiService<AccountModel> {
  crudApiService = new CrudApiService({ modelDefinition: AccountModel });
  urls = {
    search: '/user/accounts',
    searchable: '/v1/accounts/searchable',
    subAccounts: '/v1/sub-accounts',
  };

  async search(requestObject: ISearchRequestObject): Promise<ICollectionResponse<AccountModel>> {
    const url = requestObject.filter?.searchable ? this.urls.searchable : this.urls.search;

    const result = await this.crudApiService.search(url, requestObject, {
      httpMethod: 'get',
      appSideSortFn: (a, b) => {
        if (a.name < b.name) return -1;

        if (a.name > b.name) return 1;

        return 0;
      },
      appSideFilterFn: account => {
        if (requestObject.filter?.byIds?.length > 0) {
          if (!requestObject.filter.byIds.includes(account.id)) return false;
        }

        if (requestObject.filter?.searchTerm) {
          if (!account.name.toLowerCase().includes(requestObject.filter.searchTerm.toLowerCase())) return false;
        }

        return true;
      },
    });

    return result;
  }

  async getById({ accountId }): Promise<AccountModel> {
    const allAccounts = await this.crudApiService.getAll(this.urls.search);

    return allAccounts.find(({ id }) => id === accountId);
  }

  async searchByIds(
    ids: AccountModel['id'][],
    filter = { searchable: false },
  ): Promise<ICollectionResponse<AccountModel>> {
    const pageSize = ids.length > 0 ? ids.length : 25;

    return this.search({
      filter: { byIds: ids, ...filter },
      pagination: { pageSize, pageNumber: 1 },
    });
  }

  getAll = async () => {
    const response = await this.search({});

    // DEV-29921 sometimes data resolved as null from unknown reasons
    if (!response) {
      LoggerService.logWarn({
        message: `Got null as data when trying to fetch ${this.urls.search}`,
        extra: response,
      });

      return [];
    }

    return response.results;
  };

  getSubAccounts = async (): Promise<AccountModel[]> => this.crudApiService.getAll(this.urls.subAccounts);
}

export const accountApiService = new AccountApiService();
