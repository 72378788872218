import { browserService } from '../core/browser/browser.service';

interface IFetchResponse extends Response {
  message: string;
}

interface IRequestIntercept {
  request?: (fetchData: { url: string | object; config?: object }) => {
    url: string | object;
    config: object;
    headers?: object;
  };
  requestError?: (error: Error) => any;
  response?: (data: IFetchResponse) => Promise<IFetchResponse> | IFetchResponse;
  responseError?: (error: IFetchResponse) => any;
}

function addHeaders(
  url: string,
  config: Record<string, any>,
  headers: Record<string, any>,
  WindowHeaders: (...args: unknown[]) => void,
) {
  if (!config.headers) {
    config.headers = new WindowHeaders(config.headers);
  } else {
    try {
      // WindowHeaders might not be a constructor, if it isn't, we'll use config.headers is as provided
      // We want to compare the current headers with the windowHeader object to check that they are both a `Headers` instance  [Object Headers]
      if (config.headers.toString() !== new WindowHeaders().toString())
        config.headers = new WindowHeaders(config.headers);
    } catch (e) {}
  }

  Object.entries(headers).forEach(([key, val]) => config.headers.set(key, val));
}

const getFormattedRequestArgs = (args: Record<string, any>[]): Record<string, any> => {
  let requestArgs;
  let isRequestModified;

  if (Array.isArray(args)) {
    const [url, config] = args;

    const isSimpleUrlReq = typeof url === 'string';

    isRequestModified = !isSimpleUrlReq;

    requestArgs = {
      url: isSimpleUrlReq ? url : url.url,
      config: isSimpleUrlReq ? config : url,
    };
  } else {
    requestArgs = args;
  }

  return { requestArgs, isRequestModified };
};

function interceptor(
  id: string,
  fetch: typeof window.fetch,
  windowHeaders: (value: any) => void,
  ...args: Record<string, any>[]
) {
  const { requestArgs, isRequestModified } = getFormattedRequestArgs(args);
  const { subscribers } = mapIdToInterceptor[id];
  // Register request interceptors
  const modifiedFetchArgs = subscribers.reduce((requestArgs, { request, requestError }) => {
    try {
      if (!request) return requestArgs;

      const { headers, url, config = {} } = request(requestArgs);

      if (headers && Object.keys(headers).length) {
        addHeaders(`${url}`, config, headers, windowHeaders);
      }

      return { url, config };
    } catch (error) {
      if (requestError) requestError(error);

      throw error;
    }
    // Fetch api uses a [url, config] return format, we want to keep this for the final original request whilst maintaining out API
  }, requestArgs);

  // Register fetch call
  let fetchPromise;

  // Safari/chrome105+ doesn't seems to support fetch with a Request object having a POST method
  // probably because now when you construct a Request object, the body is wrapped in a ReadableStream
  // https://github.com/GoogleChrome/workbox/issues/1732 -> https://stackoverflow.com/questions/49850705/fetch-in-safari-11-1-request-with-console-log-doesnt-work-readablestream-upl/50952018#50952018
  const isSafari = navigator.userAgent.includes('Safari') && !navigator.userAgent.includes('Chrome');

  const chromeVersion = browserService.getChromeVersion();

  if (
    (isSafari || (chromeVersion && chromeVersion >= 105)) &&
    modifiedFetchArgs.config.body?.constructor?.name === 'ReadableStream'
  ) {
    fetchPromise = modifiedFetchArgs.config
      .clone()
      .text()
      .then(body => {
        const init = { ...modifiedFetchArgs.config, body };

        return fetch(isRequestModified ? modifiedFetchArgs.config : modifiedFetchArgs.url, init);
      });
  } else {
    fetchPromise = fetch(isRequestModified ? modifiedFetchArgs.config : modifiedFetchArgs.url, modifiedFetchArgs.config);
  }

  // Register response interceptors
  subscribers.forEach(({ response, responseError }) => {
    if (response || responseError) {
      fetchPromise = fetchPromise.then(response).catch(responseError);
    }
  });

  return fetchPromise;
}

type MapIdToInterceptor = Record<
  string,
  {
    fetch: typeof window.fetch;
    subscribers: IRequestIntercept[];
  }
>;

export const mapIdToInterceptor: MapIdToInterceptor = {};
export const fetchInterceptor = (
  targetWindow: Record<string, any>,
  id: string = targetWindow.document.baseURI,
): { register: (interceptorDefinition: IRequestIntercept) => () => void } => {
  const currentInterceptor = Object.entries(mapIdToInterceptor).find(([interceptorId, interceptorDefinition]) => {
    if (interceptorId === id) return interceptorDefinition;

    if (interceptorDefinition.fetch === targetWindow.fetch) return interceptorDefinition;

    return false;
  });

  if (!currentInterceptor) mapIdToInterceptor[id] = { fetch: targetWindow.fetch, subscribers: [] };

  if (!targetWindow.fetch.isLogzioInterceptorOverride) {
    targetWindow.fetch = (function (fetch, windowHeaders) {
      return function (...args) {
        return interceptor(id, fetch, windowHeaders, ...args);
      };
    })(targetWindow.fetch, targetWindow.Headers);
  }

  targetWindow.fetch.isLogzioInterceptorOverride = true;

  return {
    register(interceptorDefinition) {
      const { subscribers } = mapIdToInterceptor[id];

      subscribers.push(interceptorDefinition);

      return () => {
        const index = subscribers.indexOf(interceptorDefinition);

        if (index >= 0) {
          subscribers.splice(index, 1);
        }
      };
    },
  };
};
