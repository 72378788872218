import KibanaFilterMetadata from '../metadatas/osd-filter-metadata';
import KibanaFilterModel from './osd-filter-model';

export default class ExistsFilterModel extends KibanaFilterModel {
  public exists: { field: string };

  constructor(public meta: KibanaFilterMetadata) {
    super(meta);
    this.exists = { field: meta.key };
  }
}
