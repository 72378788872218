export enum SearchSource {
  HomeDashboard = 'HomeDashboard',

  LogzPage = 'LogzPage',
  LogzAlert = 'LogzAlert',
  LogzAppRoute = 'LogzAppRoute',
  LogzAction = 'LogzAction',

  OsdVisualization = 'OsdVisualization',
  OsdDashboard = 'OsdDashboard',
  OsdSavedSearch = 'OsdSavedSearch',
  OsdIndex = 'OsdIndex',
  OsdConfig = 'OsdConfig',
  OsdTimelion = 'OsdTimelion',
  OsdAction = 'OsdAction',
  OsdQuery = 'OsdQuery',

  GrafanaDashboard = 'GrafanaDashboard',
  GrafanaFolder = 'GrafanaFolder',
  GrafanaAlert = 'GrafanaAlert',
  GrafanaPlaylist = 'GrafanaPlaylist',
  GrafanaSnapshot = 'GrafanaSnapshot',
  GrafanaAction = 'GrafanaAction',

  LogzDocs = 'LogzDocs',
  LogzReport = 'LogzReport',
  LogzNotificationEndpoint = 'LogzNotificationEndpoint',

  JaegerService = 'JaegerService',
  JaegerAction = 'JaegerAction',

  Explore = 'Explore',
  ExploreSavedSearch = 'ExploreSavedSearch',

  UnifiedDashboard = 'UnifiedDashboard',
}

type ResultState = {
  name: string;
  params?: any;
};

export const mapAppStateToSearchSource = {
  'dashboard.osd': SearchSource.OsdDashboard,
  'dashboard.metrics': SearchSource.GrafanaDashboard,
  'dashboard.view-dashboard': SearchSource.UnifiedDashboard,
} as const;

export class GlobalSearchResultModel {
  title: string;
  subtitle?: string;
  source: SearchSource;
  state?: ResultState;
  link?: string;
  description?: string;
  createdBy?: string;
  createdAt?: string;
  updatedAt?: string;
  updatedBy?: string;
  visitCount?: number;
}
