import { Type } from 'class-transformer';
import { TracingBudgetModel } from './tracing-budget';
import { TracingAccountModel } from './tracing-account';

export class ActivateTrialTracingModel {
  @Type(() => TracingBudgetModel)
  tracingBudget: TracingBudgetModel;
  @Type(() => TracingAccountModel)
  tracingAccounts: TracingAccountModel[];
}
