import { ElasticsearchFieldModel } from '../../../elasticsearch/elasticsearch-field';
import {
  ElasticsearchEnhancedFilterModel,
  IElasticsearchFilterValue,
} from '../../../elasticsearch/elasticsearch-filter';
import KibanaFilterMetadata from '../../../osd/filters/metadatas/osd-filter-metadata';
import { IElasticFilter } from '../../../osd/filters/filter-interfaces';
import LuceneFilterParser from '../../../osd/filters/parsers/lucene-filter-parser';
import { BaseExploreFilterParser } from './base';

/**
 * Elastic to ElasticsearchFilterModel 'LUCENE' filter parser
 */
export class ExploreFilterLuceneFilterParser extends BaseExploreFilterParser {
  constructor() {
    super(new LuceneFilterParser());
  }

  getFilter(elasticFilter: object, negate: boolean): ElasticsearchEnhancedFilterModel {
    const metadata: KibanaFilterMetadata = this.getMetadata(elasticFilter, negate);

    if (!metadata) return null;

    const filter = new ElasticsearchEnhancedFilterModel();

    filter.type = 'LUCENE';
    filter.negate = metadata.negate;
    filter.field = new ElasticsearchFieldModel();
    filter.value = metadata.key;

    return filter;
  }

  getElasticFilter(filter: ElasticsearchEnhancedFilterModel): IElasticFilter {
    if (filter.type !== 'LUCENE') return null;

    const parsedValue = (filter.value as IElasticsearchFilterValue['LUCENE']).replaceAll('\n', ' ');

    return {
      [this.baseFilterParser.elasticKey]: {
        query: parsedValue,
      },
    };
  }
}
