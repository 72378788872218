import moment from 'moment';
import { GeneralSettingsPciStatus } from '@logz-pkg/enums';
import { PciModel } from './pci';

class PciModelManipulator {
  isInit = ({ status }: PciModel): boolean => status === GeneralSettingsPciStatus.Initial;
  isActive = ({ status }: PciModel): boolean => status === GeneralSettingsPciStatus.Activated;
  isPendingActivation = ({ status }: PciModel): boolean => status === GeneralSettingsPciStatus.PendingActivation;
  isDeactivated = ({ status }: PciModel): boolean => status === GeneralSettingsPciStatus.Deactivated;
  isPendingDeactivation = ({ status }: PciModel): boolean => status === GeneralSettingsPciStatus.PendingDeactivation;

  getFormattedUpdatedAt({ updatedAt }: PciModel): string {
    return moment(updatedAt).format('MMM DD, YYYY');
  }
}

export const pciModelManipulator = new PciModelManipulator();
