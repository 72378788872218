import { ISearchRequestObject } from '@logz-build/typescript';
import { type OsdFieldNameSearchResponseModel } from '@logz-pkg/models';
import { CrudApiService } from '../../utilities/crud.api.service';
import { ICrudApiService } from '../../utilities/types';

class KibanaFieldApiService implements ICrudApiService<OsdFieldNameSearchResponseModel> {
  crudApiService = new CrudApiService<OsdFieldNameSearchResponseModel>();

  urls = {
    search: '/api/kibana/fields/name/search',
  };

  search = (requestObject: ISearchRequestObject) => {
    return this.crudApiService.search(this.urls.search, requestObject);
  };
}

export const kibanaFieldApiService = new KibanaFieldApiService();
