import { format } from 'url';
import { ManifestIntegrationItemModel, IntegrationsManifestModel, CollectorModel } from '@logz-pkg/models';
import { ICrudApiService } from '../utilities/types';
import { CrudApiService } from '../utilities/crud.api.service';

class IntegrationsService implements ICrudApiService<ManifestIntegrationItemModel> {
  cacheOptions = { cache: true, secondsToLive: 60 * 60 };
  crudApiService = new CrudApiService({ modelDefinition: CollectorModel });
  manifestApiService = new CrudApiService({ modelDefinition: IntegrationsManifestModel });
  urls = {
    manifest: () =>
      format({
        pathname: '/v2/send-your-data/manifest',
      }),
    resource: (id: string) =>
      format({
        pathname: `/v2/send-your-data/content/${id}`,
      }),
    installDashboards: 'v2/content-distributor/install',
  };

  getManifest = (): Promise<IntegrationsManifestModel> =>
    this.manifestApiService.get(this.urls.manifest(), this.cacheOptions);

  getCollector = (id): Promise<CollectorModel> => {
    return this.crudApiService.get(this.urls.resource(id));
  };

  installDashboards = async ({ bundleIds, metricsAccountId }): Promise<any> => {
    return this.crudApiService.do(this.urls.installDashboards, {
      payload: {
        contentEntryIds: bundleIds,
        ...(metricsAccountId && {
          additionalParams: {
            metricsAccountId,
          },
        }),
      },
    });
  };
}

export const integrationsService = new IntegrationsService();
