import KibanaFilterMetadata from '../metadatas/osd-filter-metadata';
import { KibanaFilterType, ElasticFilterType, IElasticFilter } from '../filter-interfaces';
import IsFilterModel from '../models/is-filter-model';
import BaseFilterParser from './base-filter-parser';

/**
 * Elastic to Kibana 'IS' filter parser
 */
export default class IsFilterParser extends BaseFilterParser {
  constructor(type = KibanaFilterType.Phrase, elasticKey = ElasticFilterType.MatchPhrase) {
    super(type, elasticKey);
  }

  getMetadata(filter: IElasticFilter, negate = false, indexPattern): KibanaFilterMetadata {
    let retVal = null;
    const filterKeyProp = filter[this.elasticKey];

    if (filterKeyProp) {
      const key = this.getFieldName(filterKeyProp);
      // kibana is using a different way to pass the query for a filter {"message" : "msg"} instead of {"message" : {"query" :"msg"}}
      // go to https://www.elastic.co/guide/en/elasticsearch/reference/6.8/query-dsl-match-query-phrase.html for both examples
      const value = filterKeyProp[key]?.query ?? filterKeyProp[key];

      retVal = new KibanaFilterMetadata(key, value, this.type, negate, indexPattern);
    }

    return retVal;
  }

  getFilter(metadata: KibanaFilterMetadata): IsFilterModel {
    if (!this.assertType(metadata)) return null;

    return new IsFilterModel(metadata);
  }
}
