import { Expose } from 'class-transformer';

type SiemRepositoryAccountRelation = {
  siemAccountId: number;
  isUsingRepositoryAccount: boolean;
};

export class SiemRepositoryAccountModel {
  @Expose()
  get id(): number {
    return this.repositoryAccountId;
  }
  relations: SiemRepositoryAccountRelation[];
  repositoryAccountId: number;
}
