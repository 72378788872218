import KibanaFilterMetadata from '../metadatas/osd-filter-metadata';
import { KibanaFilterType, ElasticFilterType, IElasticFilter } from '../filter-interfaces';
import ExistsFilterModel from '../models/exists-filter-model';
import BaseFilterParser from './base-filter-parser';

/**
 * Elastic to Kibana 'EXISTS' filter parser
 */
export default class ExistsFilterParser extends BaseFilterParser {
  constructor() {
    super(KibanaFilterType.Exists, ElasticFilterType.Exists);
  }

  getMetadata(filter: IElasticFilter, negate = false, indexPattern): KibanaFilterMetadata {
    let retVal = null;

    if (filter[this.elasticKey]) {
      const key = filter.exists.field;
      const value = 'exists';

      retVal = new KibanaFilterMetadata(key, value, this.type, negate, indexPattern);
    }

    return retVal;
  }

  getFilter(metadata: KibanaFilterMetadata): ExistsFilterModel {
    if (!this.assertType(metadata)) return null;

    return new ExistsFilterModel(metadata);
  }
}
