import { MetricInfoModel } from '@logz-pkg/models';
import { ISearchRequestObject, ICollectionResponse } from '@logz-build/typescript';
import { ICrudApiService } from '../utilities/types';
import { CrudApiService } from '../utilities/crud.api.service';
import { metricsDropFiltersApiService } from '../metrics-drop-filters/api.service';
import { filterMetricsByDropFilters, getDropFilterPayload } from './populate';

export class MetricsInsightsService implements ICrudApiService<MetricInfoModel> {
  crudApiService = new CrudApiService({ modelDefinition: MetricInfoModel });

  urls = {
    search: '/data-optimization/metrics/usage/search',
  };

  search = async (requestObject: ISearchRequestObject): Promise<ICollectionResponse<MetricInfoModel>> => {
    const [metricsInsights, dropFilters] = await Promise.all([
      this.crudApiService.search(this.urls.search, requestObject),
      metricsDropFiltersApiService.search(getDropFilterPayload(requestObject)),
    ]);

    return filterMetricsByDropFilters(metricsInsights, dropFilters);
  };
}

export const metricsInsightsService = new MetricsInsightsService();
