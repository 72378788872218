import { ColdTierBudgetRequest, ColdTierBudgetResponse } from '@logz-pkg/models';
import { CrudApiService } from '../utilities/crud.api.service';
import { ICrudApiService } from '../utilities/types';

class ColdTierCrudApiService implements ICrudApiService<ColdTierBudgetResponse, ColdTierBudgetRequest> {
  apiService = new CrudApiService<ColdTierBudgetResponse, ColdTierBudgetRequest>({
    modelDefinition: ColdTierBudgetResponse,
  });

  urls = {
    budget: `/v1/cold/budget`,
  };

  get = () => this.apiService.get(this.urls.budget);
}

export const coldTierCrudApiService = new ColdTierCrudApiService();
