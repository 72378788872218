import { GrafanaAnnotationModel } from '@logz-pkg/models';
import { ICrudApiService } from '../../utilities/types';
import { CrudApiService } from '../../utilities/crud.api.service';

class GrafanaAnnotationApiService implements ICrudApiService<GrafanaAnnotationModel> {
  crudApiService = new CrudApiService<GrafanaAnnotationModel>();

  urls = {
    annotations: '/grafana-app/api/annotations',
  };

  /**
   * Getting the data about all alerts states changes (Grafana annotations)
   */
  getAnnotations = async (start: number, end: number): Promise<GrafanaAnnotationModel[]> => {
    return this.crudApiService.getAll(this.urls.annotations, undefined, {
      limit: 200000,
      to: end,
      from: start,
    });
  };
}

export const grafanaAnnotationApiService = new GrafanaAnnotationApiService();
