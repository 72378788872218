import { SearchSnippets, SearchSnippetTypes } from '@logz-pkg/enums';
import { IOpensearchDsl } from '../osd/filters/filter-interfaces';

export class SearchSnippet {
  constructor(props: Partial<SearchSnippet>) {
    Object.assign(this, props);
  }

  snippetId = SearchSnippets.LogzioFilterSearchSnippet;
  name = 'Untitled';
  type: `${SearchSnippetTypes}` = 'logs';
  value: IOpensearchDsl;
}
