import ExistsFilterParser from '../../../osd/filters/parsers/exists-filter-parser';
import { ElasticsearchFieldModel } from '../../../elasticsearch/elasticsearch-field';
import { ElasticsearchEnhancedFilterModel } from '../../../elasticsearch/elasticsearch-filter';
import KibanaFilterMetadata from '../../../osd/filters/metadatas/osd-filter-metadata';
import { IElasticFilter } from '../../../osd/filters/filter-interfaces';
import { BaseExploreFilterParser } from './base';

/**
 * Elastic to ElasticsearchEnhancedFilterModel 'EXISTS' filter parser
 */
export class ExploreFilterExistsFilterParser extends BaseExploreFilterParser {
  constructor() {
    super(new ExistsFilterParser());
  }

  getFilter(elasticFilter: object, negate: boolean): ElasticsearchEnhancedFilterModel {
    const metadata: KibanaFilterMetadata = this.getMetadata(elasticFilter, negate);

    if (!metadata) return null;

    const filter = new ElasticsearchEnhancedFilterModel();

    filter.type = 'EXIST';
    filter.negate = metadata.negate;
    filter.field = new ElasticsearchFieldModel();
    filter.field.name = metadata.key;

    return filter;
  }

  getElasticFilter(filter: ElasticsearchEnhancedFilterModel): IElasticFilter {
    if (filter.type !== 'EXIST') return null;

    return { [this.baseFilterParser.elasticKey]: { field: filter.field.name } };
  }
}
