import { Expose } from 'class-transformer';
import { ElasticsearchFieldType } from '@logz-pkg/enums';

export class ElasticsearchFieldModel {
  type: ElasticsearchFieldType;
  name: string;
  @Expose({ name: 'aggregatable' }) isAggregatable: boolean;
  // This is helping us workaround the fact that we don't really get an ID, and we return the value of fieldName
  // as the ID.
  @Expose({ toClassOnly: true })
  get id(): ElasticsearchFieldModel['name'] {
    return this.name;
  }
}
