import { ICollectionResponse, ISearchRequestObject } from '@logz-build/typescript';
import { ElasticsearchFieldModel } from '@logz-pkg/models';
import { ICrudApiService, SearchOptions } from '../utilities/types';
import { CrudApiService } from '../utilities/crud.api.service';

const cacheOptions = { cache: true, secondsToLive: 60 * 60 };

class ElasticsearchFieldApiService implements ICrudApiService<ElasticsearchFieldModel> {
  crudApiService = new CrudApiService({ modelDefinition: ElasticsearchFieldModel });

  urls = {
    search: 'account/fields/search',
  };

  search = async (
    requestObject: ISearchRequestObject,
    options: Partial<SearchOptions<ElasticsearchFieldModel>> = {},
  ): Promise<ICollectionResponse<ElasticsearchFieldModel>> => {
    const returnObject = await this.crudApiService.search(this.urls.search, requestObject, { cacheOptions, ...options });

    returnObject.results = returnObject.results ?? [];

    return returnObject;
  };

  searchByIds = async (ids: ElasticsearchFieldModel['id'][]) => {
    return this.search({
      filter: { byIds: ids },
    });
  };
}

export const elasticsearchFieldApiService = new ElasticsearchFieldApiService();
