import { filter, map } from 'lodash';
// TODO: Pass this statement to markers.module when `import` is available

import { MultiFilterBaseModel } from './osd-multi-filter-base';

class MultiFilterModel extends MultiFilterBaseModel {
  constructor(options) {
    super(options);

    this.filterType = options.filterType || 'appMultiselectCheckbox';
  }

  /**
   * Return the elements which are selected (their value)
   */
  getSelectedItems() {
    return filter(this.data, { model: true });
  }

  getSelectedCount() {
    return this.getSelectedItems().length;
  }

  isEmpty() {
    return this.getSelectedCount() === 0;
  }

  getSelectedItemNames() {
    return map(this.getSelectedItems(), item => item.label);
  }

  setSelectedItems(itemsToBeSelected = []) {
    itemsToBeSelected = itemsToBeSelected.map(option => option.toUpperCase());
    this.data.forEach(value => {
      if (itemsToBeSelected.includes(value.value.toUpperCase())) {
        value.model = true;
      }
    });
  }

  /**
   * Return a string for a chip, indicating the selected options.
   * If there are more than 2 values, just say '+x more'
   *
   * @return {string}
   */
  getDisplayedName() {
    const currentCount = this.getSelectedCount();

    if (currentCount === 1) {
      return [this.singular, ' : ', this.getSelectedItemNames()].join('');
    } else if (currentCount === 2) {
      return [this.name, ' : ', this.getSelectedItemNames().join(', ')].join('');
    }

    return [this.name, ': ', this.getSelectedItemNames().slice(0, 2).join(', '), ' +', currentCount - 2, ' more'].join(
      '',
    );
  }
}

// A service which returns factory so we could call Marker(arg1, arg2) and it will create it with its dependencies
// https://stackoverflow.com/a/38021246/1467394

export function multiFilter() {
  return function multiFilterFactory(options = {}) {
    // If a service is needed in FilterModel - inject it to the factory and pass it to the instance
    return new MultiFilterModel(options);
  };
}
