export class MultiFilterBaseModel {
  name: string;
  id: string;
  filterType: any;
  data: any;
  width: number;
  singular: string | boolean;

  constructor(options) {
    this.name = options.name;
    this.id = options.id;
    this.filterType = options.filterType;
    this.data = options.data || [];
    this.width = options.width || 600;
    this.singular = options.singular || this.name;
  }

  isEmpty(filter: any): any {}

  getDisplayedName(): any {}
}
