import { LogPatternModel } from '@logz-pkg/models';
import { ICollectionResponse, ISearchRequestObject } from '@logz-build/typescript';
import { CrudApiService } from '../utilities/crud.api.service';
import { ICrudApiService } from '../utilities/types';
import { ILogPatternsSearchFilter } from './types';

export class LogPatternsApiService implements ICrudApiService<LogPatternModel> {
  crudApiService = new CrudApiService<LogPatternModel>();

  urls = {
    search: '/proxy/patterns/search',
  };

  search = async (
    payload: ISearchRequestObject | ILogPatternsSearchFilter,
  ): Promise<ICollectionResponse<LogPatternModel>> => {
    return this.crudApiService.do(this.urls.search, {
      payload,
    });
  };
}

export const logPatternsApiService = new LogPatternsApiService();
