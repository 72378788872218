import { AlertEventDetailsModel } from '@logz-pkg/models';
import { ICrudApiService } from '../utilities/types';
import { CrudApiService } from '../utilities/crud.api.service';

class AlertDetailsApiService implements ICrudApiService<AlertEventDetailsModel> {
  alertEventCrudApiService = new CrudApiService<AlertEventDetailsModel>({ modelDefinition: AlertEventDetailsModel });

  urls = {
    resource: (alertEventId: string) => `/v1/alerts/events/${alertEventId}/query/details`,
  };

  get = async (id: AlertEventDetailsModel['id']) => this.alertEventCrudApiService.get(this.urls.resource(id));
}

export const alertDetailsApiService = new AlertDetailsApiService();
