import { get } from 'lodash';
import { cacheProvider } from '../cache/cache.provider';
import { httpService } from '../http/request.provider';

const CACHE_KEY = 'logz-config';

const setConfig = (config: Record<string, any>): void => cacheProvider.set(CACHE_KEY, config);

const fetchConfig = async (): Promise<Record<string, any>> => {
  const { data: config } = await httpService.get('/config');

  setConfig(config);

  return config;
};

const getConfig = async (): Promise<Record<string, any>> => cacheProvider.get(CACHE_KEY, fetchConfig);

const getValue = async (key: string): Promise<any> => getConfig().then(config => get(config, key));

export const configProvider = {
  setConfig,
  fetchConfig,
  getConfig,
  getValue,
};
