import {
  ActivateTrialTracingModel,
  CreateTracingAccountModel,
  JaegerTagsModel,
  MetricsAccountPlanModel,
  TracingAccountIsInUseResponseModel as IsInUseResponseModel,
  TracingAccountModel,
  TracingActivatedResponseModel,
  TracingUsageResponseModel,
} from '@logz-pkg/models';
import { ICrudApiService } from '../utilities/types';
import { CrudApiService } from '../utilities/crud.api.service';
import { httpService } from '../../core/http/request.provider';

class TracingAccountsApiService implements ICrudApiService<TracingAccountModel> {
  accountsCrudApiService = new CrudApiService({ modelDefinition: TracingAccountModel });
  isInUseCrudApiService = new CrudApiService({ modelDefinition: IsInUseResponseModel });
  usageCrudApiService = new CrudApiService({ modelDefinition: TracingUsageResponseModel });
  isActivatedCrudApiService = new CrudApiService({ modelDefinition: TracingActivatedResponseModel });
  activateTrialCrudApiService = new CrudApiService({ modelDefinition: ActivateTrialTracingModel });
  tagsCrudApiService = new CrudApiService({ modelDefinition: JaegerTagsModel });
  tracingMetricsAccountApiService = new CrudApiService({ modelDefinition: MetricsAccountPlanModel });

  urls = {
    account: (accountId: number) => `/tracing/accounts/${accountId}`,
    accounts: '/tracing/accounts',
    activated: '/tracing/activated',
    activateTrial: '/tracing/activate-trial',
    isInUse: '/tracing/accounts/is-in-use',
    metricsAccount: (tracingAccountId: number) => `/tracing/${tracingAccountId}/metrics-account`,
    tags: (tracingAccountId: number) => `/tracing/accounts/${tracingAccountId}/tags`,
  };

  urlAccountsCachKey = httpService.generateHttpResponseCacheKey(this.urls.accounts);

  async getAccount(accountId: number): Promise<TracingAccountModel> {
    return this.accountsCrudApiService.get(this.urls.account(accountId));
  }

  async getAllTracingAccounts({ fresh } = { fresh: true }): Promise<TracingAccountModel[]> {
    const { activated: isTracingActivated } = await tracingAccountsApiService.isActivated();

    if (!isTracingActivated) return [];

    return this.accountsCrudApiService.getAll(this.urls.accounts, {
      fresh,
      cache: true,
      secondsToLive: 60 * 60,
    });
  }

  async update(tracingAccount: TracingAccountModel): Promise<TracingAccountModel> {
    return this.accountsCrudApiService.update(`${this.urls.accounts}/${tracingAccount.accountId}`, tracingAccount);
  }

  async createAccount(tracingAccount: CreateTracingAccountModel): Promise<TracingAccountModel> {
    return this.accountsCrudApiService.do(this.urls.accounts, {
      payload: tracingAccount,
      cacheInvalidationOptions: {
        urlsToInvalidateCache: [this.urlAccountsCachKey],
      },
    });
  }

  async getAccountsInUse(): Promise<{} | { [key: string]: boolean }> {
    return this.isInUseCrudApiService.get(this.urls.isInUse);
  }

  async getAccountUsage(tracingAccountId): Promise<TracingUsageResponseModel> {
    return this.usageCrudApiService.get(`${this.urls.accounts}/${tracingAccountId}/usage`);
  }

  async isActivated(): Promise<TracingActivatedResponseModel> {
    return this.isActivatedCrudApiService.get(this.urls.activated);
  }

  async activateTrial(): Promise<ActivateTrialTracingModel> {
    return this.activateTrialCrudApiService.create(this.urls.activateTrial);
  }

  async deleteAccount(accountId: number): Promise<{}> {
    return this.accountsCrudApiService.delete(`${this.urls.accounts}/${accountId}`, {
      urlsToInvalidateCache: [this.urlAccountsCachKey],
    });
  }

  async getTags(tracingAccountId): Promise<JaegerTagsModel> {
    return this.tagsCrudApiService.get(this.urls.tags(tracingAccountId));
  }

  async getTracingMetricsAccount(
    tracingAccountId,
    { fresh = false }: { fresh: boolean } = { fresh: false },
  ): Promise<MetricsAccountPlanModel> {
    return this.tracingMetricsAccountApiService.get(this.urls.metricsAccount(tracingAccountId), {
      fresh,
      cache: true,
      secondsToLive: 60 * 60,
    });
  }

  async createTracingMetricsAccount(tracingAccountId): Promise<MetricsAccountPlanModel> {
    return this.tracingMetricsAccountApiService.create(this.urls.metricsAccount(tracingAccountId), null, {
      urlsToInvalidateCache: [this.urlAccountsCachKey],
    });
  }
}

export const tracingAccountsApiService = new TracingAccountsApiService();
