import { LogsAccountRequestModel, LogsAccountResponseModel, NonFlexAccountRequestModel } from '@logz-pkg/models';
import { CrudApiService } from '../../utilities/crud.api.service';
import { ICrudApiService } from '../../utilities/types';

class AccountManagementCrudApiService implements ICrudApiService<LogsAccountResponseModel, LogsAccountRequestModel> {
  nonFlexApiService = new CrudApiService<{}, NonFlexAccountRequestModel>();
  crudApiService = new CrudApiService<LogsAccountResponseModel, LogsAccountRequestModel>({
    modelDefinition: LogsAccountResponseModel,
  });

  urls = {
    nonFlexOwner: `/account/owner-account`,
    collection: `/account-management/time-based-accounts`,
    resource: (id: number) => `/account-management/time-based-accounts/${id}`,
  };

  create = (model: LogsAccountRequestModel) => this.crudApiService.create(this.urls.collection, model);

  update = (id, model: LogsAccountRequestModel) => this.crudApiService.update(this.urls.resource(id), model);

  delete = id => this.crudApiService.delete(this.urls.resource(id));

  /** @deprecated We should eventually eliminate non-flex owner accounts */
  updateNonFlexOwner = (model: NonFlexAccountRequestModel) =>
    this.nonFlexApiService.update(this.urls.nonFlexOwner, model);
}

export const acccountManagementCrudApiService = new AccountManagementCrudApiService();
