import { CrudApiService } from '../utilities/crud.api.service';
import { ICrudApiService } from '../utilities/types';

export class PaymentApiService implements ICrudApiService<boolean> {
  crudApiService = new CrudApiService<boolean>();

  urls = {
    get: '/v2/payment/payment-change-needed',
  };

  get = () => this.crudApiService.get(this.urls.get);
}

export const paymentApiService = new PaymentApiService();
