import { Expose } from 'class-transformer';
import { NotificationEndpointType } from '@logz-pkg/enums';

export class PlainNotificationEndpointModel {
  id: number;
  title: string;

  @Expose({ name: 'endpointType' })
  type: NotificationEndpointType;
}
