import { AuthSettingsModel } from '@logz-pkg/models';
import { ICrudApiService } from '../utilities/types';
import { CrudApiService } from '../utilities/crud.api.service';

class AuthSettingsApiService implements ICrudApiService<AuthSettingsModel> {
  crudApiService = new CrudApiService<AuthSettingsModel>({ modelDefinition: AuthSettingsModel });
  url = `/authentication/settings`;

  get = async () => this.crudApiService.get(this.url);

  update = async (authSettings: AuthSettingsModel) => this.crudApiService.update(this.url, authSettings);

  enableTwoFactorAuth = async (generalAuthSettings: AuthSettingsModel) => {
    const settings: AuthSettingsModel = {
      ...generalAuthSettings,
      authSettings: {
        allowed_ip_prefixes: generalAuthSettings.authSettings.allowed_ip_prefixes,
        multifactor: { provider: 'google-authenticator' },
      },
    };

    return authSettingsApiService.update(settings);
  };
}

export const authSettingsApiService = new AuthSettingsApiService();
