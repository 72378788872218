import { ICollectionResponse } from '@logz-build/typescript';
import { AxiosError } from 'axios';
import { CollectionEntityModel } from './models/collection-entity.model';
import { IErrorResponse } from './types';

interface IHandleDalErrorData {
  (error: Error): unknown;
  (error: AxiosError): unknown;
}

let loggerService = {
  logError: ({} = {}) => {
    return null;
  },
};

export const fromList = <T extends CollectionEntityModel>(results: object[]): ICollectionResponse<T | object> => ({
  total: results.length,
  results,
  pagination: {
    pageNumber: 1,
    pageSize: results.length,
  },
});

// we want to avoid circular dependency
// logger is loading auth service, that's loading dal crud service and that's using logger.
// so let's decouple them by injecting it on demand
export const setDalLogger = logger => {
  loggerService = logger;
};

export const handleDalError: IHandleDalErrorData = (error): unknown => {
  let message;
  const errorData = (error?.data as IErrorResponse) ?? error;

  if (error.config) {
    // response - client received an error response
    // request - client never received a response, or request never left
    error.stack = new Error().stack;

    const suffixMsg = `for ${error?.config?.method} "${error?.config?.url}"`;
    const status = (error?.response ?? error?.request)?.status;

    if (!error.response) {
      loggerService.logError({ error, message: status, isFailedRequest: true });

      throw errorData;
    }

    message = `Got status ${status} ${suffixMsg}`;
  } else {
    // anything else
    ({ message } = error);
  }

  loggerService.logError({ message, error });

  // Rethrow to handle in the UI if wanted.
  throw errorData;
};

export const parseSSEData = (sseData: string) => {
  const lines = sseData.trim().split('\n');
  let data = '';

  lines.forEach(line => {
    data += `${line.substring('data: '.length)}\n`;
  });

  return data;
};

export const sortByLastUsed = <T>(
  data: (T & { lastUsed?: number | undefined })[],
): (T & { lastUsed?: number | undefined })[] =>
  data?.sort((a, b) => {
    const aLastUsed = new Date(a.lastUsed).getTime();
    const bLastUsed = new Date(b.lastUsed).getTime();

    // equal items sort equally
    if (aLastUsed === bLastUsed) {
      return 0;
    }

    // nulls sort aLastUsedfter anything else
    if (!aLastUsed) {
      return 1;
    }

    if (!bLastUsed) {
      return -1;
    }

    return bLastUsed - aLastUsed;
  });

export const markdownToText = markdown => {
  return markdown
    .replace(/`+/g, '')
    .replace(/##\s*/g, '')
    .replace(/\*\*(.*?)\*\*/g, '$1')
    .trim();
};
