import { AccountRetentionDetailsModel, DetailedAccountModel } from '@logz-pkg/models';
import { CrudApiService } from '../../utilities/crud.api.service';
import { ICrudApiService } from '../../utilities/types';

class AccountManagementCollectionApiService implements ICrudApiService<DetailedAccountModel> {
  apiService = new CrudApiService<DetailedAccountModel>({ modelDefinition: DetailedAccountModel });
  retentionDetailsApiService = new CrudApiService<AccountRetentionDetailsModel>({
    modelDefinition: AccountRetentionDetailsModel,
  });

  urls = {
    resource: (accountId: number) => `/account-management/time-based-accounts/detailed/${accountId}`,
    getAll: `/account-management/time-based-accounts/detailed`,
    retentionDetails: `/account-management/time-based-accounts/retention-details`,
  };

  get = id => this.apiService.get(this.urls.resource(id));

  getAll = () => this.apiService.getAll(this.urls.getAll);

  getRetentionDetails = () => this.retentionDetailsApiService.getAll(this.urls.retentionDetails);
}

export const accountManagementCollectionApiService = new AccountManagementCollectionApiService();
