import { Expose, Type } from 'class-transformer';
import { ProductType, UsageResponseStatus } from '@logz-pkg/enums';
import { DailyUsage } from './daily-usage';
import { ProductPlanModel } from './product-plan';

export class ProductUsageModel {
  product: ProductPlanModel['name'];
  @Type(() => DailyUsage)
  usages: DailyUsage[];
  productType: ProductType;
  status: UsageResponseStatus;

  @Expose({ toClassOnly: true })
  getUsageSumForMonth(date: Date) {
    return this.usages
      .filter(({ date: usageDate }) => usageDate.getMonth() === date.getMonth())
      .reduce((total, { value }) => total + value, 0);
  }

  @Expose({ toClassOnly: true })
  getUsagePercentForMonth(date: Date, threshold: number) {
    return (this.getUsageSumForMonth(date) / threshold) * 100;
  }

  @Expose({ toClassOnly: true })
  getDailyUsageValues() {
    return this.usages.map(({ value }) => value) || [];
  }
}
