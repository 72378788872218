import { ISearchRequestObject } from '@logz-build/typescript';
import { FieldMappingModel, FieldMappingLimitsModel } from '@logz-pkg/models';
import { ICrudApiService } from '../utilities/types';
import { CrudApiService } from '../utilities/crud.api.service';

export class FieldMappingsApiService implements ICrudApiService<FieldMappingModel> {
  crudApiService = new CrudApiService({ modelDefinition: FieldMappingModel });
  limitsApiService = new CrudApiService({
    modelDefinition: FieldMappingLimitsModel,
  });
  logTypesApiService = new CrudApiService<string>();

  urls = {
    search: '/field-mappings/search',
    limits: '/field-mappings/limits',
    logTypes: (fieldName: FieldMappingModel['fieldName']) => `/field-mappings/log-types/${fieldName}`,
    resource: (id: FieldMappingModel['id']) => `/field-mappings/${id}`,
    apply: '/field-mappings/apply',
    errors: '/field-mappings/errors',
    resolveError: (id: FieldMappingModel['id']) => `/field-mappings/errors/${id}`,
  };

  search = (requestObject: ISearchRequestObject) => this.crudApiService.search(this.urls.search, requestObject);

  get = (id: FieldMappingModel['id']) => this.crudApiService.get(this.urls.resource(id));

  getLimits = () => this.limitsApiService.get(this.urls.limits);

  getFieldLogTypes = (fieldName: FieldMappingModel['fieldName']) =>
    this.logTypesApiService.getAll(this.urls.logTypes(fieldName));

  applyMapping = (mapping: FieldMappingModel) => this.crudApiService.do(this.urls.apply, { payload: mapping });

  getErrors = () => this.crudApiService.getAll(this.urls.errors);

  resolveError = (id: FieldMappingModel['id']) => this.crudApiService.delete(this.urls.resolveError(id));

  delete = (id: FieldMappingModel['id']) => this.crudApiService.delete(this.urls.resource(id));
}

export const fieldMappingsApiService = new FieldMappingsApiService();
