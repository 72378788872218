import moment, { Moment } from 'moment';
import { Expose, Type, Transform, Exclude } from 'class-transformer';
import { SnapshotObjectType } from '@logz-pkg/enums';
import { NotificationEndpointModel } from '../notification-endpoint/notification-endpoint';
import { KibanaDashboardModel } from '../osd/osd-dashboard';
import { UserModel } from '../user/user';

class Recipients {
  emails: string[] = [];
  endpointIds: NotificationEndpointModel['id'][] = [];

  // This is so we could hold the whole notification endpoints in the object later on.
  @Exclude()
  notificationEndpoints: NotificationEndpointModel[];
}

class ReportObject {
  type: SnapshotObjectType;
  shouldAddObjectLink: boolean;
  shouldUseAccountLogo: boolean;

  @Expose({ name: 'timeRangeMs' })
  timeRange: number;

  @Expose({ name: 'objectId' })
  id: string;
}

export class ScheduledReportModel {
  id: number;
  name: string;
  description: string;
  cronSchedule: string;
  errorMessage: string;

  @Exclude({ toPlainOnly: true })
  deactivatedByServer: boolean;

  @Exclude()
  dashboard: KibanaDashboardModel;

  timeZone: string;

  @Type(() => ReportObject)
  reportObject: ReportObject;

  @Type(() => Recipients)
  recipients: Recipients = new Recipients();

  @Type(() => Date)
  @Transform(value => moment(value * 1000), { toClassOnly: true })
  @Transform(value => value?.valueOf(), { toPlainOnly: true })
  createdAt: Moment;

  @Type(() => Date)
  @Transform(value => moment(value * 1000), { toClassOnly: true })
  @Transform(value => value?.valueOf(), { toPlainOnly: true })
  updatedAt: Moment;

  @Type(() => Date)
  @Transform(value => moment(value * 1000), { toClassOnly: true })
  @Transform(value => value?.valueOf(), { toPlainOnly: true })
  lastSentAt: Moment;

  @Expose({ name: 'activated' })
  isActivated: boolean;

  @Exclude({ toPlainOnly: true })
  userFullName = '';

  @Expose({ name: 'userId' })
  updatedBy: UserModel['id'];
}
