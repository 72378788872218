import { SessionModel } from '@logz-pkg/models';
import { ICrudApiService } from '../utilities/types';
import { CrudApiService } from '../utilities/crud.api.service';
import { TimeConstants } from '@logz-pkg/enums';

export class SessionApiService implements ICrudApiService<SessionModel> {
  crudApiService = new CrudApiService({ modelDefinition: SessionModel });
  urls = {
    resource: '/session-context',
  };

  get = async ({ fresh } = { fresh: false }) =>
    this.crudApiService.get(this.urls.resource, { cache: true, fresh, secondsToLive: TimeConstants.SecondsInHour * 2 });

  clearCache = () => this.crudApiService.httpService.clearCache(this.urls.resource);
}

export const sessionApiService = new SessionApiService();
