import { plainToClass } from 'class-transformer';
import { ElasticsearchValidateQueryModel } from '@logz-pkg/models';
import { ICrudApiService } from '../utilities/types';
import { CrudApiService } from '../utilities/crud.api.service';

class ElasticsearchValidateQueryApiService implements ICrudApiService<ElasticsearchValidateQueryModel> {
  crudApiService = new CrudApiService({ modelDefinition: ElasticsearchValidateQueryModel });

  urls = {
    validate:
      '/osd/elasticsearch/logzioCustomerKibanaIndex/__kibanaQueryValidator/_validate/query?explain=false&ignore_unavailable=true',
  };

  validate = async (query: string): Promise<ElasticsearchValidateQueryModel> => {
    const payload = {
      query: {
        query_string: {
          analyze_wildcard: true,
          query,
        },
      },
    };
    const meta = { dontShowProgressBar: true };
    const data = await this.crudApiService.do<ElasticsearchValidateQueryModel>(this.urls.validate, {
      payload,
      meta,
    });

    return plainToClass(ElasticsearchValidateQueryModel, data);
  };
}

export const elasticsearchValidateQueryApiService = new ElasticsearchValidateQueryApiService();
