import type { opensearchtypes } from '@opensearch-project/opensearch';
import { cloneDeep } from 'lodash';

const baseSearchPayload: { params: opensearchtypes.SearchRequest } = {
  params: {
    index: 'logzioCustomerIndex*',
    body: {
      track_total_hits: true,
      version: true,
      // for filtering specific accounts u can use that array
      // e.g. _source: { excludes: ['logzioAccountId-9', 'logzioAccountId-10'] },
      // will filter search only accounts 9 and 10
      _source: { excludes: [] },
      size: 500,
      sort: [{ '@timestamp': { order: 'desc' } }],
      stored_fields: ['*'],
      docvalue_fields: [{ field: '@timestamp', format: 'date_time' }],
    },
  },
};

export interface IBuildSearchPayload {
  scroll?: string;
  scrollId?: string;
  from?: opensearchtypes.SearchRequest['body']['from'];
  query?: opensearchtypes.SearchRequest['body']['query'];
  size?: number;
  aggs?: opensearchtypes.SearchRequest['body']['aggs'];
  source?: opensearchtypes.SearchRequest['body']['_source'];
  sort?: opensearchtypes.SearchRequest['body']['sort'];
  searchAfter?: opensearchtypes.SearchRequest['body']['search_after'];
  trackTotalHits?: opensearchtypes.SearchRequest['body']['track_total_hits'];
  timeout?: opensearchtypes.SearchRequest['body']['timeout'];
  terminateAfter?: opensearchtypes.SearchRequest['body']['terminate_after'];
  highlight?: opensearchtypes.SearchRequest['body']['highlight'];
  storedFields?: opensearchtypes.SearchRequest['body']['stored_fields'];
}

const buildSearchPayload = ({
  from = 0,
  query = {},
  size = 500,
  aggs = {},
  source = {},
  sort = [{ '@timestamp': { order: 'desc' } }],
  searchAfter,
  timeout,
  terminateAfter,
  trackTotalHits = true,
  highlight,
  storedFields,
}: IBuildSearchPayload): { params: opensearchtypes.SearchRequest } => {
  const payload = cloneDeep(baseSearchPayload);

  payload.params.body.from = from;
  payload.params.body.query = query;
  payload.params.body.size = size;
  payload.params.body.aggs = aggs;
  payload.params.body.sort = sort;
  payload.params.body._source = source;
  payload.params.body.track_total_hits = trackTotalHits;
  payload.params.body.highlight = highlight;
  payload.params.body.stored_fields = storedFields;

  if (timeout) {
    payload.params.body.timeout = timeout;
  }

  if (terminateAfter) {
    payload.params.body.terminate_after = terminateAfter;
  }

  if (searchAfter) {
    payload.params.body.search_after = searchAfter;
  }

  return payload;
};

export const opensearchQueryService = { buildSearchPayload };
