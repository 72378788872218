import { TracingBudgetModel } from '@logz-pkg/models';
import { ICrudApiService } from '../utilities/types';
import { CrudApiService } from '../utilities/crud.api.service';

class TracingBudgetApiService implements ICrudApiService<TracingBudgetModel> {
  crudApiService = new CrudApiService({ modelDefinition: TracingBudgetModel });

  async get(): Promise<TracingBudgetModel> {
    return this.crudApiService.get('/tracing/budget');
  }
}

export const tracingBudgetApiService = new TracingBudgetApiService();
