import { cloneDeep } from 'lodash';
import {
  ElasticsearchFilterOperator,
  ElasticsearchFilterModel,
  RuleModel,
  StaticLookupListModel,
} from '@logz-pkg/models';
import { LookupStrings } from '@logz-pkg/enums';
import { handleDalError } from '../utilities/utilities';
import { lookupListsApiService } from '../lookup/lookup-list.api.service';
import { LoggerService } from '../../core/logger/logger.service';

export const populateLookups = async (rule: RuleModel): Promise<RuleModel> => {
  const ruleCopy = cloneDeep(rule);
  let response;

  const lookupsIds = ruleCopy.subComponents
    .flatMap(subComponent => subComponent.queryDefinition.filters)
    .filter(filter => filter.type === ElasticsearchFilterOperator.Lookup)
    .map(filter => (filter.value as StaticLookupListModel).id);

  if (lookupsIds.length === 0) return ruleCopy;

  try {
    response = await lookupListsApiService.search({ filter: { byIds: lookupsIds } });
  } catch (error) {
    LoggerService.logError({ message: 'Could not get lookup list', error });
    handleDalError(error);
  }

  const lookupsNameById = response.results.reduce(
    (lookupNames, currentLookup) => ({ ...lookupNames, [currentLookup.id]: currentLookup.name }),
    {},
  );

  ruleCopy.subComponents.forEach(subComponent => {
    subComponent.queryDefinition.filters
      .filter(currFilter => currFilter.type === ElasticsearchFilterOperator.Lookup)
      .forEach((filter: ElasticsearchFilterModel) => {
        const foundLookupName = lookupsNameById[(filter.value as StaticLookupListModel).id];

        (filter.value as StaticLookupListModel).name = foundLookupName || LookupStrings.LookupNotFound;

        if (!foundLookupName) {
          filter.invalid = true;
        }
      });
  });

  return ruleCopy;
};
