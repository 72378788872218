import { PlainNotificationEndpointModel } from '@logz-pkg/models';
import { ICollectionResponse, ISearchRequestObject } from '@logz-build/typescript';
import { ICrudApiService } from '../utilities/types';
import { CrudApiService } from '../utilities/crud.api.service';

export class PlainNotificationEndpointApiService implements ICrudApiService<PlainNotificationEndpointModel> {
  crudApiService = new CrudApiService({ modelDefinition: PlainNotificationEndpointModel });
  urls = {
    search: '/endpoints/summary/search',
  };

  async search(requestObject = {}) {
    try {
      const { results } = await this.crudApiService.search(this.urls.search, requestObject);

      return this.searchManipulator(results, requestObject);
    } catch ({ data: error }) {
      throw error;
    }
  }

  async searchByEndpointIds(ids: PlainNotificationEndpointModel['id'][]) {
    return this.search({ filter: { endpointIds: ids } });
  }

  private searchManipulator(
    notificationEndpoints: PlainNotificationEndpointModel[],
    requestObject: ISearchRequestObject,
  ): ICollectionResponse<PlainNotificationEndpointModel> {
    if (!requestObject.filter) {
      requestObject.filter = {};
    }

    const { endpointTypes, searchTerm, maximumEndpoints, endpointIds } = requestObject.filter;

    if (endpointTypes?.length) {
      notificationEndpoints = notificationEndpoints.filter(endpoint => endpointTypes.includes(endpoint.type));
    }

    if (searchTerm) {
      notificationEndpoints = notificationEndpoints.filter(({ title }) => title.toLowerCase().includes(searchTerm));
    }

    if (maximumEndpoints) {
      notificationEndpoints = notificationEndpoints.slice(0, maximumEndpoints);
    }

    if (endpointIds) {
      notificationEndpoints = notificationEndpoints.filter(endpoint => endpointIds.includes(endpoint.id));
    }

    return {
      total: notificationEndpoints.length,
      results: notificationEndpoints.sort((a, b) => b.id - a.id),
      pagination: { pageNumber: 1, pageSize: 25 },
    };
  }
}

export const plainNotificationEndpointApiService = new PlainNotificationEndpointApiService();
