import { GlobalSearchResultModel, SearchSource } from '@logz-pkg/models';
import { httpService } from '@logz-pkg/frontend-services';

const objectTypeToSearchSource = {
  visualization: SearchSource.OsdVisualization,
  dashboard: SearchSource.OsdDashboard,
  search: SearchSource.OsdSavedSearch,
  'index-pattern': SearchSource.OsdIndex,
  config: SearchSource.OsdConfig,
  'timelion-sheet': SearchSource.OsdTimelion,
  query: SearchSource.OsdQuery,
};
const transformSavedObjectToGlobalSearch = osdObject => ({
  source: objectTypeToSearchSource[osdObject.type],
  title: osdObject.attributes?.title,
  description: osdObject.attributes?.description,
  icon: osdObject.attributes?.description,
  link: osdObject.meta?.inAppUrl.path.replace(/\/app\/(.*)#/g, '#/dashboard/osd/$1'),
  createdBy: osdObject.attributes?._createdBy?.username,
  createdAt: osdObject.attributes?._createdAt,
  updatedBy: osdObject.attributes?._updatedBy?.username,
  updatedAt: osdObject.attributes?._updatedAt,
});

class OsdSearchApiService {
  urls = {
    find: ({ includeSavedSearch = true }) =>
      `/osd-2-0/api/opensearch-dashboards/management/saved_objects/_find?type=config&type=query&type=visualization&type=dashboard${
        includeSavedSearch ? '&type=search' : ''
      }`,
    suggest: '/osd-2-0/api/opensearch-dashboards/suggestions/values/logzioCustomerIndex*',
    bulkGet: 'osd-2-0/api/saved_objects/_bulk_get',
  };

  async suggest(query: string, field: string): Promise<string[]> {
    const { data } = await httpService.post(this.urls.suggest, {
      query,
      field,
      boolFilter: [],
    });

    return data;
  }

  async getOsdSavedObjects(search: string, { includeSavedSearch = true } = {}): Promise<GlobalSearchResultModel[]> {
    const { data } = await httpService.get(this.urls.find({ includeSavedSearch }), {
      search: `${search.replace(/\s+/g, '*')}*`,
      fields: 'id',
      sortField: 'type',
    });

    return data.saved_objects.map(transformSavedObjectToGlobalSearch);
  }

  async bulkGet(ids: string[]): Promise<GlobalSearchResultModel[]> {
    const { data } = await httpService.post(
      this.urls.bulkGet,
      ids.map(id => ({
        id,
        type: 'dashboard',
      })),
    );

    return data.saved_objects.map(transformSavedObjectToGlobalSearch);
  }
}

export const osdSearchApiService = new OsdSearchApiService();
