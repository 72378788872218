import { AccountSentLogsModel, AccountSentMetricsModel } from '@logz-pkg/models';
import { ICrudApiService } from '../utilities/types';
import { CrudApiService } from '../utilities/crud.api.service';

export class WelcomeCenterApiService implements ICrudApiService<{}> {
  crudApiService = new CrudApiService();
  sentLogsApiService = new CrudApiService({ modelDefinition: AccountSentLogsModel });
  sentMetricsApiService = new CrudApiService({ modelDefinition: AccountSentMetricsModel });

  urls = {
    resource: () => `/welcome-flow`,
    sentLogs: () => '/v1/account-sent-logs',
    sentMetrics: () => '/v1/account-sent-metrics',
  };

  reportProgress = welcomeFlow => {
    this.crudApiService.do(this.urls.resource(), { payload: welcomeFlow });
  };

  accountSentLogs = (): Promise<AccountSentLogsModel> =>
    this.sentLogsApiService.get(this.urls.sentLogs(), undefined, undefined, { dontShowProgressBar: true });

  accountSentMetrics = (): Promise<AccountSentMetricsModel> =>
    this.sentMetricsApiService.get(this.urls.sentMetrics(), undefined, undefined, { dontShowProgressBar: true });
}

export const welcomeCenterApiService = new WelcomeCenterApiService();
