import { httpService } from '../http/request.provider';
import { cacheProvider } from '../cache/cache.provider';

const sharedObjectIdRegex = /\.account-(\d+)$/;
const endpoint = '/osd-manager/shared-objects';

const getSharingAccounts = () =>
  cacheProvider.get(endpoint, () => httpService.get(endpoint).then(response => response.data), 60 * 5);

const clearCache = () => cacheProvider.clear(endpoint);

const parseAccountId = objId => {
  const matches = sharedObjectIdRegex.exec(objId);

  if (matches && matches.length > 1) {
    return parseInt(matches[1], 10);
  }

  return null;
};

const getAccountByObjectId = async objId => {
  const accountId = parseAccountId(objId);

  if (!accountId) {
    return null;
  }

  return getSharingAccounts().then(accounts => accounts.filter(a => a.accountId === accountId)[0]);
};

const hasAccountId = objId => !!parseAccountId(objId);

export const sharedObjects = {
  getSharingAccounts,
  clearCache,
  getAccountByObjectId,
  parseAccountId,
  hasAccountId,
};
