import { sessionIdService } from '../session-id/session-id.service';
import { cookiesProvider } from '../auth/cookies.provider';
import { authTokens } from '../auth/tokens';
import { cacheProvider } from './cache.provider';

export const clearAllCache = ({ clearTokens = false } = {}): void => {
  const sessionId = sessionIdService.getExistingSessionId();

  window.sessionStorage.clear();

  // DEV-29453 It will allow to track all user events as one sequence (incl logout, password reset, account switch)
  if (sessionId) sessionIdService.setSessionId(sessionId);

  if (clearTokens) {
    cookiesProvider.withEnv.remove(authTokens.LOGZ_SESSION_COOKIE_KEY);
    cookiesProvider.withEnv.remove(authTokens.LAST_AUTH_TOKEN_COOKIE, { currentDomain: true });
  }

  cacheProvider.clearImpersistentLocalStorage();
};
