import { Transform } from 'class-transformer';
import moment from 'moment';
import { TracingSuspensionStates } from '@logz-pkg/enums';
import { TracingPlanModel } from '../plan/tracing-plan';

class AuthorizedAccounts {
  accountId: number;
  accountName: string;
}

export class TracingAccountModel {
  accountId: number;
  accountName: string;
  plan: TracingPlanModel;
  @Transform(value => moment(value * 1000), { toClassOnly: true })
  @Transform(value => value?.valueOf(), { toPlainOnly: true })
  createdAt: number;
  token: string;
  isSearchable: boolean;
  authorizedAccounts: AuthorizedAccounts[];
  suspensionState: TracingSuspensionStates;
  tracingAccountSettings?: { maxDailyGB: number; dailyIndexSizeLimitSuspensionFactor?: number };
}
