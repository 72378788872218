import { classToPlain, plainToClass } from 'class-transformer';
import { cloneDeep } from 'lodash';
import { ISearchRequestObject } from '@logz-build/typescript';
import {
  AlertModel,
  AlertOutputSamplesResponseModel,
  AlertExportFields,
  ContributingUserModel,
  TriggeredAlertModel,
  AlertScheduleValidationModel,
  AlertsSearchFilters,
  AlertsSearchSort,
} from '@logz-pkg/models';
import {
  BulkDeleteActionReq,
  BulkUpdateActionReq,
  ExportToCsvService,
  ICrudApiService,
  SearchOptions,
} from '../utilities/types';
import { CrudApiService } from '../utilities/crud.api.service';
import { handleDalError } from '../utilities/utilities';
import { PopulateAlert } from './populate';

type AlertActionType = 'createdBy' | 'updatedBy';

export class AlertApiService implements ICrudApiService<AlertModel>, ExportToCsvService {
  crudApiService = new CrudApiService({ modelDefinition: AlertModel });
  usersCrudApiService = new CrudApiService({ modelDefinition: ContributingUserModel });
  tagsCrudApiService = new CrudApiService<string>();
  TriggeredAlertCrudApiService = new CrudApiService<TriggeredAlertModel>({ modelDefinition: TriggeredAlertModel });
  scheduledAlertCrudApiService = new CrudApiService<AlertScheduleValidationModel>();

  urls = {
    collection: 'v2/alerts',
    search: 'v2/alerts/search',
    resource: (id: number) => `v2/alerts/${id}`,
    enableRca: (id: number) => `v2/alerts/${id}/rca/enable`,
    disableRca: (id: number) => `v2/alerts/${id}/rca/disable`,
    enable: (id: number) => `v2/alerts/${id}/enable`,
    disable: (id: number) => `v2/alerts/${id}/disable`,
    generateOutputSamples: `v2/alerts/generate-samples`,
    users: (alertActionType: AlertActionType) => `alerts/contributing-users/${alertActionType}`,
    searchTags: '/v2/alerts/tags/search',
    triggeredAlertCollection: 'alerts/triggered-alerts',
    triggeredAlertResource: (triggerId: string) => `alerts/triggered-alerts/${triggerId}`,
    validateCronExp: '/v2/alerts/validate-cron',
    bulkDelete: '/v2/alerts/bulk/delete',
    bulkUpdate: '/v2/alerts/bulk/update',
  };

  static populate = async <T extends AlertModel>(alert: T): Promise<T> => {
    let populatedAlert = cloneDeep(alert);

    populatedAlert = await PopulateAlert.populateAccountsToQueryOn(populatedAlert);
    populatedAlert = await PopulateAlert.populateFields(populatedAlert);
    populatedAlert = await PopulateAlert.populateRecipients(populatedAlert);

    return populatedAlert;
  };

  search = async (
    requestObject: ISearchRequestObject<AlertsSearchFilters, AlertsSearchSort>,
    params?: { invalidDataHandler?: SearchOptions<AlertModel>['invalidDataHandler'] },
  ) => {
    const response = await this.crudApiService.search(this.urls.search, requestObject, {
      invalidDataHandler: params?.invalidDataHandler,
    });

    response.results = await PopulateAlert.populateMultiRecipients(response.results);

    return response;
  };

  create = (alert: AlertModel) => this.crudApiService.create(this.urls.collection, alert);

  update = (alert: AlertModel) => this.crudApiService.update(this.urls.resource(alert.id), alert);

  delete = (id: AlertModel['id']) => this.crudApiService.delete(this.urls.resource(id));

  getAll = () => this.crudApiService.getAll(this.urls.collection);

  get = async (id: AlertModel['id']) => {
    const alert = await this.crudApiService.get(this.urls.resource(id));

    return AlertApiService.populate(alert);
  };

  enableState = async (id: AlertModel['id']) => {
    const response = await this.crudApiService.do<AlertModel>(this.urls.enable(id));

    return plainToClass(AlertModel, response);
  };

  disableState = async (id: AlertModel['id']) => {
    const response = await this.crudApiService.do<AlertModel>(this.urls.disable(id));

    return plainToClass(AlertModel, response);
  };

  enableRca = async (id: AlertModel['id']) => {
    const response = await this.crudApiService.do<AlertModel>(this.urls.enableRca(id));

    return plainToClass(AlertModel, response);
  };

  disableRca = async (id: AlertModel['id']) => {
    const response = await this.crudApiService.do<AlertModel>(this.urls.disableRca(id));

    return plainToClass(AlertModel, response);
  };

  createdByUsers = () => this.usersCrudApiService.get(this.urls.users('createdBy'));

  updatedByUsers = () => this.usersCrudApiService.get(this.urls.users('updatedBy'));

  generateSamples = async (alert: AlertModel) => {
    const response = await this.crudApiService.do<AlertOutputSamplesResponseModel>(this.urls.generateOutputSamples, {
      payload: { alert: classToPlain(alert) },
    });

    try {
      return plainToClass(AlertOutputSamplesResponseModel, response);
    } catch (error) {
      handleDalError(error);
    }
  };

  searchTags = async (requestObject: ISearchRequestObject) =>
    this.tagsCrudApiService.search(this.urls.searchTags, requestObject);

  getTriggeredAlert = async (triggerId: string) =>
    this.TriggeredAlertCrudApiService.get(this.urls.triggeredAlertResource(triggerId));

  validateCron = (cronExpression: string) =>
    this.scheduledAlertCrudApiService.do<AlertScheduleValidationModel>(this.urls.validateCronExp, {
      payload: { cronExpression },
    });

  bulkDelete = (requestObject: BulkDeleteActionReq) =>
    this.crudApiService.bulkDelete(this.urls.bulkDelete, requestObject);

  bulkUpdate = (requestObject: BulkUpdateActionReq) =>
    this.crudApiService.bulkUpdate(this.urls.bulkUpdate, requestObject);

  csvExportFields = AlertExportFields;
}

export const alertApiService = new AlertApiService();
