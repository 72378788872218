import { AccountModel, AlertModel, ElasticsearchFieldModel, ElasticsearchFilterModel } from '@logz-pkg/models';
import { cloneDeep, uniq } from 'lodash';
import { accountApiService } from '../account/user/api.service';
import { plainNotificationEndpointApiService } from '../notification-endpoint/api.service';
import { LoggerService } from '../../core/logger/logger.service';
import { elasticsearchFieldApiService } from '../elasticsearch/field.api.service';

const populateAccountsToQueryOn = async <T extends AlertModel>(alert: T): Promise<T> => {
  const alertCopy = cloneDeep(alert);

  const accountIds = alertCopy.subComponents.flatMap(
    subComponent => subComponent.queryDefinition.accountsToQueryOn,
  ) as unknown as AccountModel['id'][];

  const { results: accounts } = await accountApiService.searchByIds(accountIds, { searchable: true });

  const accountsModelById = accounts.reduce(
    (accountModels, currentAccountModel) => ({ ...accountModels, [currentAccountModel.id]: currentAccountModel }),
    {},
  );

  alertCopy.subComponents.forEach(subComponent => {
    subComponent.queryDefinition.accountsToQueryOn = (
      subComponent.queryDefinition.accountsToQueryOn as unknown as AccountModel['id'][]
    ).map(accountId => accountsModelById[accountId]);
  });

  return alertCopy;
};

const populateRecipients = async <T extends AlertModel>(alert: T): Promise<T> => {
  const alertCopy = cloneDeep(alert);

  const notificationEndpointIds = alertCopy.output.recipients.notificationEndpoints;
  const { results: notificationEndpoints } = await plainNotificationEndpointApiService.searchByEndpointIds(
    notificationEndpointIds as unknown as AlertModel['id'][],
  );

  alertCopy.output.recipients.notificationEndpoints = notificationEndpoints;

  return alertCopy;
};

const populateMultiRecipients = async (alerts: AlertModel[]): Promise<AlertModel[]> => {
  const endpointIds = alerts.reduce((acc, alert) => {
    acc.push(...alert.output.recipients.notificationEndpoints);

    return acc;
  }, []);

  const { results: notificationEndpoints } = await plainNotificationEndpointApiService.searchByEndpointIds(
    uniq(endpointIds),
  );

  return alerts.map(alert => {
    const endpointIds: any = alert.output.recipients.notificationEndpoints;

    alert.output.recipients.notificationEndpoints = notificationEndpoints.filter(({ id }) => {
      return endpointIds.includes(id);
    });

    return alert;
  });
};

const populateFields = async <T extends AlertModel>(alert: T): Promise<T> => {
  const alertCopy = cloneDeep(alert);

  alertCopy.subComponents.forEach(subComponent => {
    const aggregationFieldName = subComponent.queryDefinition.aggregation
      .field as unknown as ElasticsearchFieldModel['id'];
    const groupByFieldNames = subComponent.queryDefinition.groupBy as unknown as ElasticsearchFieldModel[];

    // If we have an aggregation without aggregationField, we should not create ElasticsearchFieldModel with empty field name
    if (aggregationFieldName) {
      const aggregationField = new ElasticsearchFieldModel();

      aggregationField.name = aggregationFieldName;
      subComponent.queryDefinition.aggregation.field = aggregationField;
    }

    subComponent.queryDefinition.groupBy = groupByFieldNames.map(fieldName => {
      const fieldInstance = new ElasticsearchFieldModel();

      fieldInstance.name = fieldName.name;

      return fieldInstance;
    });
  });

  return alertCopy;
};

const getEnrichedFilters = async (filters: ElasticsearchFilterModel[]): Promise<ElasticsearchFilterModel[]> => {
  const filterFieldNames = filters?.map(filter => filter.field?.name);

  if (filterFieldNames.length) {
    try {
      const accountFieldMapping = await elasticsearchFieldApiService.search({
        filter: {
          aggregatableOnly: false,
          byFieldNames: filterFieldNames,
        },
      });

      if (accountFieldMapping) {
        filters?.forEach(filter => {
          const fieldMapping = accountFieldMapping.results.find(field => field.name === filter.field.name);

          if (fieldMapping) {
            filter.field.type = fieldMapping.type;
          }
        });
      }
    } catch (err) {
      LoggerService.logError({
        message: `Unable to enrich filters - ${err?.message}`,
        error: err,
      });
    }
  }

  return filters;
};
export const PopulateAlert = {
  getEnrichedFilters,
  populateAccountsToQueryOn,
  populateRecipients,
  populateFields,
  populateMultiRecipients,
};
