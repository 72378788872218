import { Type, Transform, Exclude } from 'class-transformer';
import moment, { Moment } from 'moment';
import {
  SecurityIOCFeedsFeedTypes as FeedTypes,
  SecurityIOCFeedsSyncStatus as FeedStatuses,
  SecurityIOCFeedsFeedConfidences as Confidences,
  SecurityIOCFeedsIOCType as IOCTypes,
  SecurityIOCFeedsErrorCode as ErrorCodes,
  SecurityIOCFeedsFeedFormat as FeedFormat,
} from '@logz-pkg/enums';

class PrivateFeedSettings {
  method: string;
  headers: string;
  body: string;
}

class ErrorDetails {
  code: ErrorCodes = ErrorCodes.INTERNAL_ERROR;
  message = '';
  suggestedFix = '';
}

export class ThreatsFeedModel {
  id: number;
  name: string;
  description: string;
  sourceUrl: string;
  iocType: IOCTypes;
  iocCount: number;
  format: FeedFormat = FeedFormat.SAWMILL_PIPELINE;

  @Exclude({ toPlainOnly: true })
  feedType: FeedTypes = FeedTypes.LOGZIO;

  @Exclude({ toPlainOnly: true })
  syncStatus: FeedStatuses = FeedStatuses.ACTIVE;

  confidence: Confidences;

  @Exclude({ toPlainOnly: true })
  @Type(() => Date)
  @Transform(value => moment(value * 1000), { toClassOnly: true })
  lastSuccessfulRun: Moment;

  @Type(() => PrivateFeedSettings)
  privateFeedSettings: PrivateFeedSettings = new PrivateFeedSettings();

  @Exclude({ toPlainOnly: true })
  errorDetails: ErrorDetails;
}
