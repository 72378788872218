import { SiemRepositoryAccountModel, SiemAccountV2Model } from '@logz-pkg/models';
import { ICrudApiService } from '../utilities/types';
import { CrudApiService } from '../utilities/crud.api.service';
import { siemAccountsApiServiceV2 } from './account.api.v2.service';

class SiemRepositoryApiService implements ICrudApiService<SiemRepositoryAccountModel> {
  crudApiService = new CrudApiService({ modelDefinition: SiemRepositoryAccountModel });
  urls = {
    resource: '/siem/account-management/repository-accounts',
  };

  update = async (model): Promise<SiemRepositoryAccountModel> => {
    return this.crudApiService.update(this.urls.resource, model);
  };

  getRepository = async (): Promise<SiemAccountV2Model | null> => {
    const dependencies: SiemRepositoryAccountModel = await this.crudApiService.get(this.urls.resource);

    if (dependencies.repositoryAccountId === null) return null;

    return siemAccountsApiServiceV2.get(dependencies.repositoryAccountId);
  };
}

export const siemRepositoryApiService = new SiemRepositoryApiService();
