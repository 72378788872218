import * as yup from 'yup';
import { TelemetryAgentParamType } from '../enums';
import { oneOfEnum, paramBaseSchema, paramMetaDataSchema } from './shared.schemas';

const pathListParamBaseSchema = paramBaseSchema.shape({
  type: oneOfEnum({ type: TelemetryAgentParamType.PathList }).required('param type required'),
  value: yup.array().nullable(), // this field's base schema is overridden in its extending schemas
});

export const pathListParamManifestSchema = pathListParamBaseSchema.concat(paramMetaDataSchema).shape({
  value: yup.array().of(yup.string().nullable()).strict(),
});

const requireableStringArraySchema = yup
  .array()
  .of(yup.string())
  .when(['required'], {
    is: req => req,
    then: yup.array().of(yup.string().required()).strict().min(1, 'at least one item is required'),
  });

export const pathListParamSchema = pathListParamBaseSchema.shape({
  value: yup.lazy(value => {
    if (value?.length > 1) {
      return requireableStringArraySchema.of(yup.string().required()).strict();
    }

    return requireableStringArraySchema;
  }),
});
