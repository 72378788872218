import { IElasticFilter } from '../../../osd/filters/filter-interfaces';
import KibanaFilterMetadata from '../../../osd/filters/metadatas/osd-filter-metadata';
import IsFilterParser from '../../../osd/filters/parsers/is-filter-parser';
import { ElasticsearchFieldModel } from '../../../elasticsearch/elasticsearch-field';
import { ElasticsearchFilterModel, ElasticsearchFilterOperator } from '../../../elasticsearch/elasticsearch-filter';
import { BaseAlertFilterParser } from './base';

/**
 * Elastic to ElasticsearchFilterModel 'IS' filter parser
 */
export class AlertFilterIsFilterParser extends BaseAlertFilterParser {
  constructor() {
    super(new IsFilterParser());
  }

  getFilter(elasticFilter: object, negate: boolean): ElasticsearchFilterModel {
    const metadata: KibanaFilterMetadata = this.getMetadata(elasticFilter, negate);

    if (!metadata) return null;

    const filter = new ElasticsearchFilterModel();

    filter.type = ElasticsearchFilterOperator.Is;
    filter.value = metadata.value;
    filter.negate = metadata.negate;
    filter.field = new ElasticsearchFieldModel();
    filter.field.name = metadata.key;

    return filter;
  }

  getElasticFilter(filter: ElasticsearchFilterModel): IElasticFilter {
    if (filter.type !== ElasticsearchFilterOperator.Is) return null;

    return { [this.baseFilterParser.elasticKey]: { [filter.field.name]: { query: filter.value } } };
  }
}
