import { TelemetryStatus } from '@logz-pkg/models';
import { Observable } from '@logz-pkg/observable';
import { ICrudApiService } from '../utilities/types';
import { CrudApiService } from '../utilities/crud.api.service';
import { cacheProvider } from '../../core/cache/cache.provider';

export class TelemetryDataStatusService implements ICrudApiService<TelemetryStatus> {
  crudApiService = new CrudApiService();
  status = new Observable<TelemetryStatus>({
    spmMetrics: { sent: false },
    k8sMetrics: { sent: false },
    traces: { sent: false },
    logs: { sent: false },
  });

  urls = {
    telemetriesStatus: '/telemetry-agent/telemetries-status',
  };

  constructor() {
    const persistedStatus = cacheProvider.getPersistent<TelemetryStatus>('telemetry-status');

    if (persistedStatus) {
      this.status.set(persistedStatus);
    }
  }
  clear() {
    this.status.set({} as TelemetryStatus);
  }
  async getTelemetriesStatus(): Promise<TelemetryStatus> {
    const meta = { dontShowProgressBar: true };

    const telemetryStatus = await this.crudApiService.do<TelemetryStatus>(this.urls.telemetriesStatus, { meta });

    this.status.set(telemetryStatus);
    cacheProvider.setPersistent('telemetry-status', telemetryStatus);

    return telemetryStatus;
  }
}

export const telemetryDataStatusService = new TelemetryDataStatusService();
