import { plainToClass } from 'class-transformer';
import { AccountLogoModel } from '@logz-pkg/models';
import { ICrudApiService } from '../../utilities/types';
import { CrudApiService } from '../../utilities/crud.api.service';

interface IUploadProps {
  file: File;
}

class AccountLogoApiService implements ICrudApiService<AccountLogoModel> {
  crudApiService = new CrudApiService({ modelDefinition: AccountLogoModel });
  urls = {
    resource: '/accounts/logo',
    upload: '/accounts/logo/upload',
  };

  get = () => this.crudApiService.get(this.urls.resource);
  delete = () => this.crudApiService.delete(this.urls.resource);
  upload = async ({ file }: IUploadProps) => {
    const payload = new FormData();

    payload.append('file', file);

    const response: AccountLogoModel = await this.crudApiService.do(this.urls.upload, { payload });

    return plainToClass(AccountLogoModel, response);
  };
}

export const accountLogoApiService = new AccountLogoApiService();
