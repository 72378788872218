import moment from 'moment';
import { LogShippingTokenModel } from './log-shipping-token';

class LogShippingTokenModelManipulator {
  getFormattedCreatedAt(logShippingToken: LogShippingTokenModel): string {
    return moment.unix(logShippingToken?.createdAt).format('llll');
  }
}

export const logShippingTokenModelManipulator = new LogShippingTokenModelManipulator();
