import { isNil } from 'lodash';
import { authTokens } from '../tokens';
import { cacheProvider } from '../../cache/cache.provider';
import { cookiesProvider } from '../cookies.provider';

export const hasShareToken = (): boolean => !isNil(authTokens.getShareToken());
const hasCurrentAccountId = (): boolean => cacheProvider.hasCached(cacheProvider.CacheKeys.CURRENT_ACCOUNT_ID);
const setCurrentAccountId = (accountId: number | string): void =>
  cacheProvider.set(cacheProvider.CacheKeys.CURRENT_ACCOUNT_ID, `${accountId}`);
const setCurrentAccountIdCookie = (accountId: number | string): void =>
  cookiesProvider.withEnv.set(authTokens.CURRENT_ACCOUNT_ID_COOKIE, `${accountId}`);
const getCurrentAccountId = (): string => cacheProvider.getCached(cacheProvider.CacheKeys.CURRENT_ACCOUNT_ID);
const clearCurrentAccountId = (): void => cacheProvider.clear(cacheProvider.CacheKeys.CURRENT_ACCOUNT_ID);
const setAdminAccountId = (accountId: number | string): void =>
  cacheProvider.set(cacheProvider.CacheKeys.ADMIN_ACCOUNT_ID, `${accountId}`);
const setAdminAccountIdCookie = (accountId: number | string): void =>
  cookiesProvider.withEnv.set(authTokens.ADMIN_ACCOUNT_ID_COOKIE, `${accountId}`);
const getAdminAccountId = (): string => cacheProvider.getCached(cacheProvider.CacheKeys.ADMIN_ACCOUNT_ID);
const hasAdminAccountId = (): boolean => cacheProvider.hasCached(cacheProvider.CacheKeys.ADMIN_ACCOUNT_ID);
const clearAdminAccountId = (): void => cacheProvider.clear(cacheProvider.CacheKeys.ADMIN_ACCOUNT_ID);

export const authTokenService = {
  hasCurrentAccountId,
  /* deprecated */
  hasAuthToken: hasCurrentAccountId,
  hasShareToken,
  setCurrentAccountId,
  getCurrentAccountId,
  setCurrentAccountIdCookie,
  clearCurrentAccountId,
  setAdminAccountId,
  getAdminAccountId,
  hasAdminAccountId,
  clearAdminAccountId,
  setAdminAccountIdCookie,
};
