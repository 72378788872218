import { TracingSamplingConfigModel } from '@logz-pkg/models';
import { ICrudApiService } from '../utilities/types';
import { CrudApiService } from '../utilities/crud.api.service';

class TracingSamplingConfigApiService implements ICrudApiService<TracingSamplingConfigModel> {
  tracingConfigurationPoliciesApiService = new CrudApiService<TracingSamplingConfigModel>({
    modelDefinition: TracingSamplingConfigModel,
  });
  otelConfigApiService = new CrudApiService();

  urls = {
    samplingRulesConfig: (tracingAccountId: number) => `/telemetry-agent/tracing/config/${tracingAccountId}`,
    otelConfig: (tracingAccountId: number) => `telemetry-agent/tracing/otel-config/${tracingAccountId}`,
  };

  getByTracingAccountId = (
    tracingAccountId: TracingSamplingConfigModel['tracingAccountId'],
  ): Promise<TracingSamplingConfigModel> =>
    this.tracingConfigurationPoliciesApiService.get(this.urls.samplingRulesConfig(tracingAccountId));

  update = (samplingRulesConfig: TracingSamplingConfigModel): Promise<TracingSamplingConfigModel> =>
    this.tracingConfigurationPoliciesApiService.update(
      this.urls.samplingRulesConfig(samplingRulesConfig.tracingAccountId),
      samplingRulesConfig,
    );

  getConfig = async (tracingAccountId: number) => this.otelConfigApiService.get(this.urls.otelConfig(tracingAccountId));
}

export const tracingSamplingConfigApiService = new TracingSamplingConfigApiService();
