import { Expose, Transform } from 'class-transformer';
import { GlobalNotificationType } from '@logz-pkg/enums';

export class GlobalNotificationModel {
  id: string;
  @Expose({ name: 'messageType' })
  @Transform(value => (value ? GlobalNotificationType[value] : GlobalNotificationType.GENERAL), { toClassOnly: true })
  type: string;
  message: string;
}
