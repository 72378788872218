type BroadcastChannels = 'explore';

export type BroadcastMessage = { data: { exploreLink: string; type: 'success' | 'error' | 'timeout' } };

class BroadcasterService {
  private readonly broadcaster: Record<BroadcastChannels, BroadcastChannel> | {} = {};

  createChannel(channelName: BroadcastChannels) {
    this.broadcaster[channelName] = new BroadcastChannel(channelName);
  }

  public broadcast(channelName: BroadcastChannels, message: BroadcastMessage['data']): void {
    this.broadcaster[channelName].postMessage(message);
  }

  public setOnMessageHandler(channelName: BroadcastChannels, callback: (message: BroadcastMessage) => void): void {
    if (this.broadcaster[channelName].onmessage === null) {
      this.broadcaster[channelName].onmessage = event => callback(event);
    } else {
      throw new Error('Cannot set more than one onmessage handler');
    }
  }
}

export const broadcasterService = new BroadcasterService();
