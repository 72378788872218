import {
  LogsToMetricsCreateRuleRequestModel,
  LogsToMetricsRuleModel,
  LogsToMetricsRuleSearchResponseModel,
} from '@logz-pkg/models';
import { ICollectionResponse, ISearchRequestObject } from '@logz-build/typescript';
import { ICrudApiService } from '../utilities/types';
import { CrudApiService } from '../utilities/crud.api.service';

export class LogsToMetricsApiService
  implements ICrudApiService<LogsToMetricsRuleModel, LogsToMetricsCreateRuleRequestModel>
{
  crudApiService = new CrudApiService<LogsToMetricsRuleModel, LogsToMetricsCreateRuleRequestModel>({
    modelDefinition: LogsToMetricsRuleModel,
  });

  searchCrudApiService = new CrudApiService<LogsToMetricsRuleSearchResponseModel>({
    modelDefinition: LogsToMetricsRuleSearchResponseModel,
  });

  urls = {
    collection: 'v1/log-metric/rules',
    search: 'v1/log-metric/rules/search',
    resource: (id: LogsToMetricsRuleModel['id']) => `v1/log-metric/rules/${id}`,
    enable: (id: LogsToMetricsRuleModel['id']) => `v1/log-metric/rules/${id}/enable`,
    disable: (id: LogsToMetricsRuleModel['id']) => `v1/log-metric/rules/${id}/disable`,
  };

  get = async (id: LogsToMetricsRuleModel['id']): Promise<LogsToMetricsRuleModel> => {
    return this.crudApiService.get(this.urls.resource(id));
  };

  search = async ({
    pagination,
  }: ISearchRequestObject): Promise<ICollectionResponse<LogsToMetricsRuleSearchResponseModel>> => {
    return this.searchCrudApiService.search(this.urls.search, { pagination });
  };

  create = async (model: LogsToMetricsCreateRuleRequestModel): Promise<LogsToMetricsRuleModel> => {
    return this.crudApiService.create(this.urls.collection, model);
  };

  update = async (model: LogsToMetricsRuleModel): Promise<LogsToMetricsRuleModel> => {
    return this.crudApiService.update(this.urls.resource(model.id), model);
  };

  delete = async (id: LogsToMetricsRuleModel['id']): Promise<LogsToMetricsRuleModel> => {
    return this.crudApiService.delete(this.urls.resource(id));
  };

  enable = async (model: LogsToMetricsRuleModel): Promise<LogsToMetricsRuleModel> => {
    return this.crudApiService.do(this.urls.enable(model.id));
  };

  disable = async (model: LogsToMetricsRuleModel): Promise<LogsToMetricsRuleModel> => {
    return this.crudApiService.do(this.urls.disable(model.id));
  };
}

export const logsToMetricsApiService = new LogsToMetricsApiService();
