import { replaceHost } from '@logz-pkg/utils';
import { UxType } from '@logz-pkg/enums';
import { SystemRegion } from '@logz-pkg/models';
import { cacheProvider } from '../cache/cache.provider';
import { Env } from '../environment';
import { configProvider } from '../config/config.provider';
import { httpService } from '../http/request.provider';
import { LoggerService } from '../logger/logger.service';

export interface RegionOption {
  label: string;
  value: string;
  isDisabled: boolean;
  regionName: string;
}

const getSystemRegions = (): Promise<SystemRegion[]> =>
  cacheProvider.get(
    cacheProvider.CacheKeys.SYSTEM_REGIONS,
    async () => {
      const response = await httpService.get('/__admin__/system-regions');

      return Env.configs.overrideSystemRegions ? updateUrls(response) : response.data;
    },
    60 * 60 * 24,
  );

const getPublicRegions = async () =>
  cacheProvider.get(cacheProvider.CacheKeys.LOGZIO_REGIONS, () => httpService.get('/logzio-regions'), 60 * 60 * 24);

const updateUrls = ({ data }) =>
  data.map(region => ({
    ...region,
    appUrl: replaceHost(region.appUrl, { host: window.location.host }),
    adminConsoleUrl: replaceHost(region.adminConsoleUrl, { host: window.location.host }),
  }));

const toRegionOptionObject = region => ({
  label: `${region.regionName} (${region.cloud})`,
  value: region.appUrl,
  isDisabled: !region.canSignup,
  regionName: region.regionName,
});

const getRegionOptions = async (): Promise<{ regionOptions: RegionOption[]; defaultRegion: RegionOption }> => {
  let defaultRegion: RegionOption, regionOptions: RegionOption[];

  const [defaultRegionResponse, regionsResponse] = await Promise.allSettled([
    configProvider.getValue('registration.defaultRegion'),
    getPublicRegions(),
  ]);

  if (defaultRegionResponse.status !== 'fulfilled') {
    defaultRegion = null;

    const message = 'Error loading default region';

    regionsService.notificationService.danger({ message });
    LoggerService.logError({ message, error: defaultRegionResponse.reason });
  } else {
    defaultRegion = toRegionOptionObject(defaultRegionResponse.value);
  }

  if (regionsResponse.status !== 'fulfilled') {
    regionOptions = [];

    LoggerService.logError({
      message: 'Error loading available logs regions',
      error: regionsResponse.reason,
      uxType: UxType.TOAST,
    });
  } else {
    const { data: regions } = regionsResponse.value;

    regionOptions = regions
      .sort(region => (region.regionName !== defaultRegion?.regionName ? 1 : -1))
      .map(region => toRegionOptionObject(region));
  }

  return { regionOptions, defaultRegion };
};

export const regionsService = {
  // temp facade
  notificationService: {
    danger: ({ message }) => console.error(message),
  },
  init(NotificationService) {
    this.notificationService = NotificationService;
  },
  getSystemRegions,
  getPublicRegions,
  getRegionOptions,
};
