export class OptimizerDefinitionModel {
  id: number | null;
  name: string;
  description: string;
  protected: boolean;
  enabled: boolean;
  lastUpdatedOn: number;
  lastUpdateBy: string;
  createdOn: number;
  createdBy: string;
  tags: string[];
  triggerType: string;
  senderState: string;
  triggerOn: {
    severities: { severity: string; threshold: number }[];
    comparisonOperator: null;
    aggregation: null;
    threshold: null;
    frequency: string;
    type: string;
  };
  search: {
    filter: string;
    groupBy: string[];
    logzioExtension: null;
    metricsAggregations: { aggregationType: string; fieldToAggregateOn: string; valueToAggregateOn: string }[];
    periodInMinutes: number;
    queryString: string;
    source: {
      allSubAccounts: boolean;
      allTimelessIndices: boolean;
      subAccountsIds: number[];
      timelessIndices: number[];
    };
  };
  output: {
    format: { fieldsConfig: null; type: string };
    target: {
      emailNotifications: string;
      notificationEndpoints: string[];
      severity: null;
      suppressNotificationInMinutes: number;
      timelessIndex: { id: number; logType: string; name: string };
    };
  };
}
