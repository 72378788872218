import { ICollectionResponse, ISearchRequestObject } from '@logz-build/typescript';
import { SecurityIOCFeedsFeedTypes as FeedTypes } from '@logz-pkg/enums';
import { plainToClass } from 'class-transformer';
import { ThreatsFeedModel as FeedModel } from '@logz-pkg/models';
import { ICrudApiService } from '../../utilities/types';
import { CrudApiService } from '../../utilities/crud.api.service';

class ThreatsFeedsApiService implements ICrudApiService<FeedModel> {
  crudApiService = new CrudApiService({ modelDefinition: FeedModel });
  maxPrivateFeedsApiService = new CrudApiService<number>();

  urls = {
    collection: 'threats/feeds',
    search: 'threats/feeds/search',
    resource: (id: number) => `threats/feeds/${id}`,
    sync: (id: number) => `threats/feeds/${id}/enable-sync`,
    maxPrivateFeeds: 'threats/feeds/max-private-feeds',
  };

  search = async (requestObject: ISearchRequestObject): Promise<ICollectionResponse<FeedModel>> =>
    this.crudApiService.search(this.urls.search, requestObject);

  get = async (id: FeedModel['id']): Promise<FeedModel> => this.crudApiService.get(this.urls.resource(id));

  create = async (model: FeedModel): Promise<FeedModel> => this.crudApiService.create(this.urls.collection, model);

  update = async (model: FeedModel): Promise<FeedModel> =>
    this.crudApiService.update(this.urls.resource(model.id), model);

  delete = async (id: FeedModel['id']): Promise<FeedModel> => this.crudApiService.delete(this.urls.resource(id));

  sync = async (id: FeedModel['id']): Promise<FeedModel> =>
    this.crudApiService.do(this.urls.sync(id)).then(plainToClass.bind(null, FeedModel));

  getPrivateFeedsCount = async (): Promise<{ current: number; max: number }> => ({
    current: (await this.search({ filter: { feedType: FeedTypes.PRIVATE } })).total,
    max: await this.getMaxPrivateFeeds(),
  });

  getMaxPrivateFeeds = async (): Promise<number> => this.maxPrivateFeedsApiService.get(this.urls.maxPrivateFeeds);
}

export const threatsFeedsApiService = new ThreatsFeedsApiService();
