import { CrudApiService } from '../../utilities/crud.api.service';
import { ICrudApiService } from '../../utilities/types';

class LogMeasurementApiService implements ICrudApiService<string> {
  crudApiService = new CrudApiService<string>();

  urls = {
    logMeasurementType: (accountId: number) => `/__admin__/log-analytics/${accountId}/measurement-type`,
  };

  getMeasurementType = async (accountId: number): Promise<string> => {
    return this.crudApiService.get(`${this.urls.logMeasurementType(accountId)}`);
  };
}

export const logMeasurementApiService = new LogMeasurementApiService();
