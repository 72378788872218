import { CouponModel } from '@logz-pkg/models';
import { ICrudApiService } from '../utilities/types';
import { CrudApiService } from '../utilities/crud.api.service';

export class CouponsApiService implements ICrudApiService<CouponModel> {
  crudApiService = new CrudApiService({ modelDefinition: CouponModel });
  urls = {
    getCoupons: `/v1/coupons/search`,
  };

  getCoupons = async (): Promise<CouponModel[]> => {
    const { results } = await this.crudApiService.search(
      this.urls.getCoupons,
      {},
      { cacheOptions: { cache: true, secondsToLive: 60 * 5 } },
    );

    return results;
  };
}

export const couponsApiService = new CouponsApiService();
