import { Product, ProductType, UsageResponseStatus } from '@logz-pkg/enums';
import { plainToClass } from 'class-transformer';
import { ProductUsageModel } from '@logz-pkg/models';
import { planDetailsApiService } from '../../unique-entities/plan-details/api.service';
import { LoggerService } from '../../../core/logger/logger.service';
import { UsageResponse } from './api.service';
import { getLogManagementDailyUsage } from './utils';

const getLogAnalyticsUsage = async (): Promise<ProductUsageModel> => {
  try {
    const usages = await planDetailsApiService.getUsageBuckets();

    return plainToClass(ProductUsageModel, {
      product: Product.LogAnalytics,
      productType: ProductType.GbRetention,
      status: UsageResponseStatus.Success,
      usages: getLogManagementDailyUsage(usages),
    });
  } catch (error) {
    LoggerService.logError({
      message: 'Unable to get Log analytics usage',
      error,
    });
  }
};

const getUsage = async (): Promise<UsageResponse> => {
  const usage = (await Promise.all([getLogAnalyticsUsage()])).filter(Boolean);

  return { usage };
};

export const usageMock = {
  getUsage,
};
