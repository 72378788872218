import { ICollectionResponse, ISearchRequestObject } from '@logz-build/typescript';
import { MetricInfoModel, MetricsDropFilterModel } from '@logz-pkg/models';

export const getDropFilterPayload = (requestObject: ISearchRequestObject) => {
  const maxAllowedPageSize = 2147483647;
  const payload: ISearchRequestObject = {
    filter: {
      active: true,
    },
    pagination: {
      pageNumber: 1,
      pageSize: maxAllowedPageSize,
    },
    sort: [],
  };

  if (Array.isArray(requestObject.filter?.accountIds)) {
    payload.filter.accountIds = requestObject.filter.accountIds;
  }

  return payload;
};

export const filterMetricsByDropFilters = (
  metricsInsights: ICollectionResponse<MetricInfoModel>,
  dropFilters: ICollectionResponse<MetricsDropFilterModel>,
): ICollectionResponse<MetricInfoModel> => {
  const getFiltersForAccount = (accounts, accountId) => accounts.filter(acc => acc.accountId === accountId).length > 0;

  const getFilterByMetircName = (expressions, metricName) =>
    expressions.find(e => e.name === '__name__' && e.value === metricName) !== undefined;

  metricsInsights.results.forEach(metricsInsight => {
    const metricFilters = dropFilters.results.find(
      filter =>
        getFiltersForAccount(metricsInsight.accounts, filter.accountId) &&
        getFilterByMetircName(filter.filter.expression, metricsInsight.metricName) &&
        filter.active &&
        filter.filter.expression.length === 1,
    );

    metricsInsight.id = metricsInsight.metricName;

    metricsInsight.isDroppingInProgress = metricFilters !== undefined;
  });

  return metricsInsights;
};
