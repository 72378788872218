import { TelemetryAgentStatus } from './enums';
import { TelemetryAgentConfigurationModel } from './configuration';

type AgentStatus = {
  state: TelemetryAgentStatus;
  errorMessage?: string;
};

export class TelemetryAgentModel {
  id: string;
  accountId: number;
  name: string;
  description?: string;
  createdBy: number;
  createdAt: Date;
  updatedBy: number;
  updatedAt: Date;
  configuration: TelemetryAgentConfigurationModel;
  logsAccountId?: number;
  metricsAccountId?: number;
  tracingAccountId?: number;
  lastStatus?: any;
  status?: AgentStatus;
}

export class TelemetryAgentSearchResponseModel extends TelemetryAgentModel {
  createdByName: string;
  updatedByName: string;
}

export class TelemetryAgentHelmConditionTemplate {
  template: string;
  targetElement?: string;
  condition?: string;
  keepAsPlaceholder?: boolean;
  conditionalTemplates?: TelemetryAgentHelmConditionTemplate[];
}
