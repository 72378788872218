import BaseFilterParser from '../../../osd/filters/parsers/base-filter-parser';
import { IElasticFilter } from '../../../osd/filters/filter-interfaces';
import KibanaFilterMetadata from '../../../osd/filters/metadatas/osd-filter-metadata';
import { ElasticsearchFilterModel } from '../../../elasticsearch/elasticsearch-filter';

export class BaseAlertFilterParser {
  constructor(protected baseFilterParser: BaseFilterParser) {}

  getMetadata(filter: object, negate: boolean): KibanaFilterMetadata {
    return this.baseFilterParser.getMetadata(filter, negate, null);
  }

  getFilter(filter: object, negate: boolean): ElasticsearchFilterModel {
    throw new Error('MUST OVERRIDE');
  }

  getElasticFilter(filter: ElasticsearchFilterModel): IElasticFilter {
    throw new Error('MUST OVERRIDE');
  }
}
