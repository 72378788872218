import { BillingCycle, DateFormat, DiscountType } from '@logz-pkg/enums';
import { Expose } from 'class-transformer';
import moment from 'moment';

export class CouponModel {
  billingCycle: BillingCycle;
  description: string;
  discount = 0;
  discountCycles: number;
  discountType: DiscountType;
  expirationDate: number;
  yearlyLimitPrice: number;
  @Expose({ toClassOnly: true })
  get formattedExpirationDate() {
    return moment(this.expirationDate * 1000)
      .utc()
      .format(DateFormat.DashedDateTimeFull);
  }
  @Expose({ toClassOnly: true })
  get isPercentageDiscountType(): boolean {
    return this.discountType === DiscountType.Percentage;
  }
  @Expose({ toClassOnly: true })
  get discountText(): string {
    return this.isPercentageDiscountType ? `${this.discount}%` : `$${this.discount}`;
  }
}
