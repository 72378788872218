import * as yup from 'yup';
import { registerUniqueMethod } from '@logz-pkg/utils';

registerUniqueMethod(yup);

const name = yup.string().required();

const servicesPolicy = yup.object({
  name,
  type: yup.string().oneOf(['string_attribute']).required(),
  string_attribute: yup
    .object({
      key: yup.string().oneOf(['service.name']).required(),
      values: yup.array().of(yup.string()).unique(),
    })
    .required(),
});

const latencyPolicy = yup.object({
  name,
  type: yup.string().oneOf(['latency']).required(),
  latency: yup
    .object({
      threshold_ms: yup.number().min(0).required(),
    })
    .required(),
});

const percentagePolicy = yup.object({
  name,
  type: yup.string().oneOf(['probabilistic']).required(),
  probabilistic: yup
    .object({
      hash_salt: yup.string().oneOf(['custom-salt']).required(),
      sampling_percentage: yup.number().min(0).max(100).required(),
    })
    .required(),
});

const failedPolicy = yup.object({
  name,
  type: yup.string().oneOf(['status_code']).required(),
  status_code: yup
    .object({
      status_codes: yup
        .array()
        .of(yup.string().oneOf(['ERROR']))
        .unique()
        .min(1),
    })
    .required(),
});

const andSubPolicy = yup
  .array()
  .of(yup.object())
  .min(2)
  .max(2)
  .test({
    name: 'anyof',
    test(rules) {
      let includesServicesRule = false;

      const rulesChecks = rules.map(policy => {
        const policyChecks = [
          servicesPolicy.isValidSync(policy),
          latencyPolicy.isValidSync(policy),
          percentagePolicy.isValidSync(policy),
          failedPolicy.isValidSync(policy),
        ];

        includesServicesRule = includesServicesRule || policyChecks[0];

        return policyChecks.some(isPolicyValid => !!isPolicyValid);
      });

      if (!includesServicesRule) {
        return false;
      }

      return rulesChecks.every(policyIsValid => !!policyIsValid);
    },
  });

const withSubPolicies = yup.object({
  name,
  type: yup.string().oneOf(['and']).required(),
  and: yup.object({ and_sub_policy: andSubPolicy }).required(),
});

export const tracingSamplingRulesSchema = yup.object({
  disabledRules: yup.array().of(yup.number()).default([]),
  rules: yup
    .array()
    .oneOfSchemas([latencyPolicy, servicesPolicy, percentagePolicy, withSubPolicies, failedPolicy])
    .default([]),
});
