import {
  GrafanaRulesData,
  GrafanaRulesModel,
  GrafanaDatasource,
  GrafanaRulesFolder,
  GrafanaPrometheusAlerts,
} from '@logz-pkg/models';
import { ICrudApiService } from '../../utilities/types';
import { CrudApiService } from '../../utilities/crud.api.service';

class GrafanaRulesApiService implements ICrudApiService<GrafanaRulesModel> {
  crudApiService = new CrudApiService<GrafanaRulesModel>();
  genericCrudApiService = new CrudApiService();

  urls = {
    rules: '/grafana-app/api/ruler/grafana/api/v1/rules',
    prometheusRules: '/grafana-app/api/prometheus/grafana/api/v1/rules',
    anomalyAlerts: (id: string) => `grafana-app/api/ruler/grafana/api/v1/rules/${id}/${id}?subtype=cortex`,
    anomalyAlertsGrafanaX: (id: string, folder: string) =>
      `grafana-app/api/ruler/grafana/api/v1/rules/${id}/${folder}?subtype=cortex`,
    updateAnomalyAlerts: (id: string) => `grafana-app/api/ruler/grafana/api/v1/rules/${id}?subtype=cortex`,
    folderExists: (folder: string) => `/grafana-app/api/search?query=${folder}&type=dash-folder&permission=View`,
    folders: '/grafana-app/api/folders',
    datasources: `/grafana-app/api/datasources/summary`,
    datasourceByName: name => `/grafana-app/api/datasources/name/${name}/summary`,
    notification: '/grafana-app/api/alertmanager/grafana/config/api/v1/alerts',
  };

  /**
   * Grafana alert rule is a set of evaluation criteria that determines whether an alert will fire.
   */
  getRules = async (): Promise<GrafanaRulesModel> => {
    return this.crudApiService.get(this.urls.rules, undefined, { subtype: 'cortex' });
  };

  getRulesFromPrometheus = async (): Promise<GrafanaPrometheusAlerts> => {
    return this.genericCrudApiService.get(this.urls.prometheusRules);
  };

  getDatasourceByName = async (name: string): Promise<GrafanaDatasource> => {
    return this.genericCrudApiService.get<GrafanaDatasource>(this.urls.datasourceByName(name));
  };

  getAnomalyAlerts = async ({
    folder,
    isGrafanaX = false,
  }: {
    folder: string;
    isGrafanaX: boolean;
  }): Promise<GrafanaRulesData> => {
    if (isGrafanaX) {
      const folderUid = await this.getFolderUID({ folder });

      return this.genericCrudApiService.get<GrafanaRulesData>(this.urls.anomalyAlertsGrafanaX(folderUid, folder));
    }

    return this.genericCrudApiService.get<GrafanaRulesData>(this.urls.anomalyAlerts(folder));
  };

  folderExists = async ({ folder }: { folder: string }): Promise<GrafanaRulesFolder[]> => {
    return this.genericCrudApiService.get<GrafanaRulesFolder[]>(this.urls.folderExists(folder), undefined, {
      subtype: 'cortex',
    });
  };
  getFolderUID = async ({ folder }: { folder: string }) => {
    const folders = await this.genericCrudApiService.get(this.urls.folders);

    return Array.isArray(folders) ? folders.find(f => f.title === folder)?.uid : undefined;
  };

  createFolder = async ({ name }: { name: string }) => {
    return this.genericCrudApiService.do(this.urls.folders, { payload: { title: name } });
  };

  updateFolder = async (rulesFolder: GrafanaRulesData, isGrafanaX = false): Promise<any> =>
    this.genericCrudApiService.do(
      this.urls.updateAnomalyAlerts(
        isGrafanaX ? await this.getFolderUID({ folder: rulesFolder.name }) : rulesFolder.name,
      ),
      {
        payload: rulesFolder,
      },
    );

  getNotifications = async <T>(): Promise<T> => {
    return this.genericCrudApiService.get<T>(this.urls.notification);
  };

  updateNotifications = async (payload: any): Promise<GrafanaRulesModel> => {
    return this.genericCrudApiService.do(this.urls.notification, { payload });
  };
}

export const grafanaRulesApiService = new GrafanaRulesApiService();
