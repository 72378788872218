import { SearchableAccountModel } from '@logz-pkg/models';
import { AccountType } from '@logz-pkg/enums';
import { ICrudApiService } from '../../utilities/types';
import { CrudApiService } from '../../utilities/crud.api.service';

export class SearchableAccountsApiService implements ICrudApiService<SearchableAccountModel> {
  crudApiService = new CrudApiService({ modelDefinition: SearchableAccountModel });

  urls = {
    collection: 'v1/accounts/searchable',
  };

  getAll = async (): Promise<SearchableAccountModel[]> => {
    return this.crudApiService.getAll(this.urls.collection);
  };

  getAccountsByType = async (accountType: AccountType | AccountType[]): Promise<SearchableAccountModel[]> => {
    return this.crudApiService.getAll(this.urls.collection, undefined, { accountTypes: accountType });
  };
}

export const searchableAccountsApiService = new SearchableAccountsApiService();
