import { AlertAggregationType } from '@logz-pkg/enums';
import { Expose, Transform, Type } from 'class-transformer';
import { AccountModel } from '../account/account';
import { ElasticsearchFieldModel } from '../elasticsearch/elasticsearch-field';
import { ElasticsearchFilterModel } from '../elasticsearch/elasticsearch-filter';
import { alertModelManipulator } from './alert.manipulator';

class AlertAggregation {
  @Expose({ name: 'aggregationType' }) type: AlertAggregationType = AlertAggregationType.NumberOfLogs;
  @Expose({ name: 'fieldToAggregateOn' })
  @Transform((value: ElasticsearchFieldModel) => value && value.name, { toPlainOnly: true })
  field: ElasticsearchFieldModel | null = null;
  @Expose({ name: 'valueToAggregateOn' })
  @Transform((value: ElasticsearchFieldModel) => value, { toPlainOnly: true })
  value: ElasticsearchFieldModel = null;
}

export class AlertQueryModel {
  query = '*';
  shouldQueryOnAllAccounts = false;

  @Transform((value: ElasticsearchFieldModel[]) => value.map(field => field.name), { toPlainOnly: true })
  @Transform((value: string[]) => value.map(name => ({ name })), { toClassOnly: true })
  groupBy: ElasticsearchFieldModel[] = [];

  @Type(() => AlertAggregation) aggregation: AlertAggregation = new AlertAggregation();

  @Type(() => AccountModel)
  @Expose({ name: 'accountIdsToQueryOn' })
  @Transform((accounts: AccountModel[]) => accounts.map(account => account.id), { toPlainOnly: true })
  accountsToQueryOn: AccountModel[] = [];

  // We're sending elastic DSL as the filters
  // No type is added due to: https://github.com/typestack/class-transformer/issues/308
  @Transform((_, alertQuery) => alertModelManipulator.convertFiltersToElastic(alertQuery), { toPlainOnly: true })
  @Transform((value: object) => alertModelManipulator.convertElasticToFilters(value), { toClassOnly: true })
  filters: ElasticsearchFilterModel[] = [];
}
