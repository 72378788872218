import { ISearchRequestObject } from '@logz-build/typescript';
import { KibanaDrilldownModel, KibanaDashboardModel } from '@logz-pkg/models';
import { ICrudApiService } from '../utilities/types';
import { CrudApiService } from '../utilities/crud.api.service';

export class DrillDownsApiService implements ICrudApiService<KibanaDrilldownModel> {
  kibanaDrillDownsCrudApiService = new CrudApiService({ modelDefinition: KibanaDrilldownModel });
  kibanaDrillDownsContributionCrudApiService = new CrudApiService({ modelDefinition: KibanaDrilldownModel });
  kibanaDashboardsCrudApiService = new CrudApiService({ modelDefinition: KibanaDashboardModel });

  urls = {
    create: '/api/kibana/drilldowns',
    search: '/api/kibana/drilldowns/search',
    update: fieldName => `/api/kibana/drilldowns/${fieldName}`,
    delete: fieldName => `/api/kibana/drilldowns/${fieldName}`,
  };

  jaegerUrls = {
    search: '/drilldowns',
  };

  getKibanaDrillDowns = async (requestObject: ISearchRequestObject) =>
    this.kibanaDrillDownsCrudApiService.search(this.urls.search, requestObject);

  create = async (drillDownModel: KibanaDrilldownModel) =>
    this.kibanaDrillDownsCrudApiService.create(this.urls.create, drillDownModel);

  update = async (drillDownModel: KibanaDrilldownModel) =>
    this.kibanaDrillDownsCrudApiService.update(this.urls.update(drillDownModel.fieldName), drillDownModel);

  remove = async (drillDownModel: KibanaDrilldownModel) =>
    this.kibanaDrillDownsCrudApiService.delete(this.urls.delete(drillDownModel.fieldName));
}

export const drillDownsApiService = new DrillDownsApiService();
