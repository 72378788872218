import { DynamicLookupListLoadingStatus } from '@logz-pkg/enums/src/lookup.enums';
import { Type, Transform } from 'class-transformer';

export class DynamicLookupListLoadingHistoryModel {
  lookupListId?: string;
  @Type(() => Date)
  @Transform(value => value * 1000, { toClassOnly: true })
  time: Date;
  status: DynamicLookupListLoadingStatus;
  numberOfElements: number;
  maxNumberOfElements: number;
  description: string | null = null;
}
