import { IAuditTrailSearch, IAuditTrailResponse } from '@logz-pkg/models';
import { CrudApiService } from '../utilities/crud.api.service';

class AuditTrailService {
  crudApiService = new CrudApiService();
  urls = {
    search: '/audit-trail',
    adminAudit: (accountId: number) => `/__admin__/account/${accountId}/audit-trail`,
  };

  search = (search: IAuditTrailSearch, accountId?: number): Promise<IAuditTrailResponse> => {
    const url = accountId ? this.urls.adminAudit(accountId) : this.urls.search;

    return this.crudApiService.do(url, { payload: search });
  };
}

export const auditTrailService = new AuditTrailService();
