import { ValidatePlanRequestModel, ValidatePlanResponseModel } from '@logz-pkg/models';
import { ICrudApiService } from '../utilities/types';
import { CrudApiService } from '../utilities/crud.api.service';

class ValidatePlanApiService implements ICrudApiService<ValidatePlanResponseModel, ValidatePlanRequestModel> {
  apiService = new CrudApiService<ValidatePlanResponseModel, ValidatePlanRequestModel>({
    modelDefinition: ValidatePlanResponseModel,
  });

  urls = {
    validate: `/v2/plan/validate`,
  };

  validate = (model: ValidatePlanRequestModel) =>
    this.apiService.do<ValidatePlanResponseModel>(this.urls.validate, { payload: model });
}

export const validatePlanApiService = new ValidatePlanApiService();
