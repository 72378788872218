import { ElasticsearchFilterModel } from '../../elasticsearch/elasticsearch-filter';
import { IElasticFilter } from '../../osd/filters/filter-interfaces';
import { AlertFilterIsFilterParser } from './parsers/is';
import { BaseAlertFilterParser } from './parsers/base';
import { AlertFilterExistsFilterParser } from './parsers/exists';
import { AlertFilterIsOneOfFilterParser } from './parsers/is-one-of';
import { AlertFilterLookupFilterParser } from './parsers/lookup';
import { AlertFilterIsBetweenFilterParser } from './parsers/is-between';

class AlertFilterModelManipulator {
  private alertFilterToElasticFilterParsers: BaseAlertFilterParser[];

  constructor() {
    this.alertFilterToElasticFilterParsers = [
      new AlertFilterIsFilterParser(),
      new AlertFilterIsBetweenFilterParser(),
      new AlertFilterExistsFilterParser(),
      new AlertFilterIsOneOfFilterParser(),
      new AlertFilterLookupFilterParser(),
    ];
  }

  convertElasticToFilter(elasticFilter: object, negate = false): ElasticsearchFilterModel | undefined {
    return (
      this.alertFilterToElasticFilterParsers
        .map(parser => parser.getFilter(elasticFilter, negate))
        // First non-null filter
        .find(filter => Boolean(filter))
    );
  }

  convertToElastic(filter: ElasticsearchFilterModel): IElasticFilter | undefined {
    return this.alertFilterToElasticFilterParsers
      .map(parser => parser.getElasticFilter(filter))
      .find(
        // Return the first non-null filter
        filter => Boolean(filter),
      );
  }

  getHumanReadableValue(filter: ElasticsearchFilterModel): string {
    return String(filter.getFilterValueFormat());
  }
}

export const alertFilterModelManipulator = new AlertFilterModelManipulator();
