import { InvoicePeriod } from '@logz-pkg/enums';
import { ICrudApiService } from '../../utilities/types';
import { CrudApiService } from '../../utilities/crud.api.service';

class SelfPaymentApiService implements ICrudApiService<InvoicePeriod> {
  urlPrefix = 'self-service/payment';

  urls = {
    requestInvoice: `${this.urlPrefix}/request-invoice`,
    requestUpdateDetails: `${this.urlPrefix}/request-update-details`,
  };

  crudApiService = new CrudApiService();

  requestInvoice = (period: InvoicePeriod) =>
    this.crudApiService.do(this.urls.requestInvoice, { payload: { timeFrame: period } });

  requestUpdateDetails = async () => this.crudApiService.do(this.urls.requestUpdateDetails);
}

export const selfServicePaymentApiService = new SelfPaymentApiService();
