import { classToPlain, plainToClass } from 'class-transformer';
import {
  AccountPlanModel,
  CalculatePricesRequestModel,
  CalculatePricesResponseModel,
  CalculateTaxRequestModel,
  CalculateTaxResponseModel,
  CustomRequestModel,
  DowngradeRequestModel,
  ProductPlansArray,
  PurchaseRequestModel,
  UpgradeRequestModel,
  UpdateRequestModel,
} from '@logz-pkg/models';
import { PromotionType } from '@logz-pkg/enums';
import { ICrudApiService } from '../utilities/types';
import { CrudApiService } from '../utilities/crud.api.service';

interface IStripePortal {
  portalUrl: string;
}

class PlanAndBillingApiService implements ICrudApiService<AccountPlanModel> {
  crudApiService = new CrudApiService({ modelDefinition: AccountPlanModel });
  stripePortalCrudApiService = new CrudApiService<IStripePortal>();

  urls = {
    resource: '/v2/plan',
    calculatePrices: '/v2/plan/calculate-prices',
    requestCustom: '/v2/plan/request-custom',
    requestDowngrade: '/v2/plan/request-downgrade',
    purchase: '/v2/plan/purchase',
    upgrade: '/v2/plan/upgrade',
    update: '/v2/plan/update',
    calculateTax: 'self-service/payment/tax', // DEV-22922 Use new URL: '/v2/plan/calculate-tax',
    getStripePortalLink: '/self-service/payment/stripe-portal',
    promotion: '/v2/plan/promotion',
  };

  getPlan = async (): Promise<AccountPlanModel> => {
    const response = await this.crudApiService.get(this.urls.resource);

    const model = plainToClass(AccountPlanModel, response);

    model.current.products = new ProductPlansArray(model.current.products);

    return model;
  };

  // Until the new endpoint arrives we will use the old endpoint DEV-22922
  calculateTax = async ({ shippingAddress }: CalculateTaxRequestModel): Promise<CalculateTaxResponseModel> => {
    const response = await this.crudApiService.do<any>(this.urls.calculateTax, {
      payload: { billingAddress: { ...shippingAddress, name: '', email: '' } }, // DEV-22922 Use new payload
    });

    return { taxPercentage: response?.tax }; // DEV-22922 use new response
  };

  calculatePrices = async (calculatePricesRequest: CalculatePricesRequestModel) => {
    const response = await this.crudApiService.do<CalculatePricesResponseModel>(this.urls.calculatePrices, {
      payload: classToPlain(calculatePricesRequest),
    });

    const pricesResponse = plainToClass(CalculatePricesResponseModel, response);

    pricesResponse.productPlans = new ProductPlansArray(pricesResponse.productPlans);

    return pricesResponse;
  };

  requestCustom = (customRequest: CustomRequestModel) =>
    this.crudApiService.do<void>(this.urls.requestCustom, {
      payload: classToPlain(customRequest),
    });

  purchase = (purchaseRequest: PurchaseRequestModel) =>
    this.crudApiService.do<void>(this.urls.purchase, {
      payload: classToPlain(purchaseRequest),
    });

  upgrade = (upgradeRequest: UpgradeRequestModel) =>
    this.crudApiService.do<void>(this.urls.upgrade, { payload: classToPlain(upgradeRequest) });

  updatePlan = (updateRequest: UpdateRequestModel) =>
    this.crudApiService.do<void>(this.urls.update, { payload: classToPlain(updateRequest) });

  requestDowngrade = (downgradeRequest: DowngradeRequestModel) =>
    this.crudApiService.do<void>(this.urls.requestDowngrade, { payload: classToPlain(downgradeRequest) });

  getStripePortalLink = () => this.stripePortalCrudApiService.get(this.urls.getStripePortalLink);

  sendNotification = (promotionType: PromotionType) =>
    this.crudApiService.do<void>(this.urls.promotion, {
      payload: {
        promotionType,
      },
    });
}

export const planAndBillingApiService = new PlanAndBillingApiService();
