import { AlertOutputType } from '@logz-pkg/enums';
import { AlertOutputFormatColumnModel } from './alert-output-format-column';

class AlertSamples {
  samples: object[] = [];
  columns: AlertOutputFormatColumnModel[] = [];
}

export class AlertOutputSamplesResponseModel {
  type: AlertOutputType = AlertOutputType.Json;
  alertSamples: AlertSamples[] = [new AlertSamples()];
}
