import { plainToClass } from 'class-transformer';
import { LookupListTypes } from '@logz-pkg/enums';
import {
  DynamicLookupListLoadingHistoryModel,
  LookupListModel,
  LookupUploadResponseModel,
  ValidateCronResponseModel,
} from '@logz-pkg/models';
import { ICrudApiService } from '../utilities/types';
import { CrudApiService } from '../utilities/crud.api.service';
import { accountApiService } from '../account/user/api.service';
import { PopulateAlert } from '../alert/populate';

class LookupListsApiService implements ICrudApiService<LookupListModel> {
  crudApiService = new CrudApiService<LookupListModel>({ modelDefinition: LookupListModel });
  dynamicLookupHistoryService = new CrudApiService<DynamicLookupListLoadingHistoryModel>({
    modelDefinition: DynamicLookupListLoadingHistoryModel,
  });
  validateCronApiService = new CrudApiService<ValidateCronResponseModel>({ modelDefinition: ValidateCronResponseModel });
  urls = {
    collection: '/lookup-lists',
    search: '/lookup-lists/search',
    resource: (lookupListId: LookupListModel['id']) => `/lookup-lists/${lookupListId}`,
    upload: '/lookup-lists/upload',
    validateCron: '/lookup-lists/validate-cron',
    lookupHistory: (lookupListId: string) => `/lookup-lists/dynamic-history/${lookupListId}/search`,
  };

  search = requestObject => this.crudApiService.search(this.urls.search, requestObject);

  populate = async (lookup: LookupListModel): Promise<LookupListModel> => {
    if (lookup.type === LookupListTypes.DYNAMIC) {
      const { results } = await accountApiService.searchByIds(
        lookup.searchQuery.accountsToQueryOn as unknown as number[],
        { searchable: true },
      );

      lookup.searchQuery.accountsToQueryOn = results;
      lookup.searchQuery.filters = await PopulateAlert.getEnrichedFilters(lookup.searchQuery.filters);
    }

    return lookup;
  };

  get = async (id: LookupListModel['id']): Promise<LookupListModel> => {
    const loadedLookup = await this.crudApiService.get(this.urls.resource(id));

    return this.populate(loadedLookup);
  };

  getAll = async () => this.crudApiService.getAll(this.urls.collection);

  create = (lookupList: LookupListModel) => this.crudApiService.create(this.urls.collection, lookupList);

  update = (lookupList: LookupListModel) => this.crudApiService.update(this.urls.resource(lookupList.id), lookupList);

  delete = (id: LookupListModel['id']) => this.crudApiService.delete(this.urls.resource(id));

  upload = async ({ file, defaultTTL }: { file: File; defaultTTL: number }) => {
    const payload = new FormData();

    payload.append('file', file);

    if (typeof defaultTTL !== 'undefined' && defaultTTL !== null) payload.append('defaultTTL', `${defaultTTL}`);

    const response: LookupUploadResponseModel = await this.crudApiService.do(this.urls.upload, {
      payload,
    });

    return plainToClass(LookupUploadResponseModel, response);
  };

  getCount = async (): Promise<number> => {
    const searchResult = await this.search({});

    return searchResult.total;
  };

  validateCron = async (cronExpression: string): Promise<ValidateCronResponseModel> =>
    this.validateCronApiService.do(this.urls.validateCron, { payload: { cronExpression } });

  searchHistory = (lookupListId, requestObject) =>
    this.dynamicLookupHistoryService.search(this.urls.lookupHistory(lookupListId), requestObject);
}

export const lookupListsApiService = new LookupListsApiService();
