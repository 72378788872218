export enum GrafanaResultType {
  Folder = 'dash-folder',
  Dashboard = 'dash-db',
}

export class GrafanaAlertResultModel {
  id: number;
  dashboardId: number;
  dashboardUid: string;
  dashboardSlug: string;
  panelId: number;
  name: string;
  state: string;
  newStateDate: string;
  evalDate: string;
  evalData: any;
  executionError: string;
  url: string;
}
