import { IOpensearchDsl } from '../osd/filters/filter-interfaces';
import { ElasticsearchEnhancedFilterModel } from '../elasticsearch/elasticsearch-filter';
import { enhancedFilterModelManipulator } from './filter/model.manipulator';

class ExploreModelManipulator {
  private getElasticFilters = ({
    elasticFilters,
  }: {
    elasticFilters: Record<any, any>;
  }): ElasticsearchEnhancedFilterModel[] => {
    if (!(elasticFilters.bool && elasticFilters.bool.filter)) return [];

    return elasticFilters.bool.filter
      .map(filter => enhancedFilterModelManipulator.convertElasticToFilter({ elasticFilter: filter }))
      .filter(filter => Boolean(filter));
  };

  private getMustElasticFilters = ({
    elasticFilters,
  }: {
    elasticFilters: Record<any, any>;
  }): ElasticsearchEnhancedFilterModel[] => {
    if (!(elasticFilters.bool && elasticFilters.bool.must)) return [];

    return elasticFilters.bool.must
      .map(filter => enhancedFilterModelManipulator.convertElasticToFilter({ elasticFilter: filter }))
      .filter(filter => Boolean(filter));
  };

  private getMustNotElasticFilters = ({
    elasticFilters,
  }: {
    elasticFilters: Record<any, any>;
  }): ElasticsearchEnhancedFilterModel[] => {
    if (!elasticFilters.bool || !(elasticFilters.bool && elasticFilters.bool.must_not)) return [];

    return elasticFilters.bool.must_not
      .map(filter => enhancedFilterModelManipulator.convertElasticToFilter({ elasticFilter: filter, negate: true }))
      .filter(filter => Boolean(filter));
  };

  convertElasticToFilters({ elasticFilters }: { elasticFilters: Record<any, any> }): ElasticsearchEnhancedFilterModel[] {
    if (!elasticFilters) return [];

    return [
      ...this.getElasticFilters({ elasticFilters }),
      ...this.getMustElasticFilters({ elasticFilters }),
      ...this.getMustNotElasticFilters({ elasticFilters }),
    ];
  }

  convertFiltersToElastic({ filters }: { filters: ElasticsearchEnhancedFilterModel[] }): IOpensearchDsl {
    if (filters.length === 0)
      return {
        bool: {
          must: [],
          must_not: [],
          should: [],
          filter: [],
        },
      };

    const dsl: IOpensearchDsl = {
      bool: {
        must: filters
          .filter(filter => !filter.negate)
          .filter(filter => !filter.isDisabled)
          .map(filter => enhancedFilterModelManipulator.convertToElastic({ filter })),
        must_not: filters
          .filter(filter => filter.negate)
          .filter(filter => !filter.isDisabled)
          .map(filter => enhancedFilterModelManipulator.convertToElastic({ filter })),
        should: [],
        filter: [],
      },
    };

    return dsl;
  }
}

export const exploreModelManipulator = new ExploreModelManipulator();
