export const insightsEvents = {
  fetchError: 'exceptions-fetch-error',
  noInsightsFound: 'no-exceptions-found',
  totalInsights: 'total-exceptions',
  exceptionsTabClicked: 'clicked-on-exceptions-tab',
  filterIn: 'filter-in-exception',
  filterOut: 'filter-out-exception',
  filterInField: 'filter-in-exception-field',
  filterOutField: 'filter-out-exception-field',
  mostFrequentSort: 'clicked-on-most-frequent-exception',
  mostRecentSort: 'clicked-on-most-recent-exception',
  toggleOnNewInsights: 'clicked-on-toggle-new-exceptions-only',
  toggleOffNewInsights: 'clicked-on-toggle-off-new-exceptions-only',
  selectAllDeployments: 'clicked-on-select-all-deployments',
  deselectAllDeployments: 'clicked-on-deselect-all-deployments',
  selectDeployment: 'clicked-on-select-deployment',
  deselectDeployment: 'clicked-on-deselect-deployment',
  openDeploymentsFilter: 'click-on-open-deployments-filter',
  expandInsight: 'expand-exception',
  upgradePlan: 'clicked-on-upgrade-plan-exceptions',
  addDeploymentsEmptyState: 'clicked-on-add-deployments-empty-state',
  shareException: 'clicked-on-share-exception',
};
