import { Type, Transform, Exclude, Expose } from 'class-transformer';
import moment, { Moment } from 'moment';
import { ThreatsFeedPipelineModel as PipelineModel } from './threats-feed-pipeline';
import { SecurityIOCFeedsFeedTypes as FeedTypes } from '@logz-pkg/enums';

export class ThreatsFeedManagementModel {
  id: number;
  name: string = null;
  description = '';
  severity: string = null;
  url: string = null;
  sourceUrl = '';
  iocType: string = null;
  enabled = true;
  fromTimeFieldName = '';
  fromTimePattern = '';
  apiKeyFieldName = '';
  apiKeyValue = '';
  compression: string = null;
  accountId: number;
  method: string = null;
  headers: string = null;
  body: string = null;

  @Expose({ name: 'pipelineId' })
  @Transform(value => value.id, { toPlainOnly: true })
  @Transform(id => ({ id }), { toClassOnly: true })
  pipeline: PipelineModel;

  @Type(() => Date)
  @Transform(value => moment(value * 1000), { toClassOnly: true })
  lastUpdatedOn: Moment;

  @Exclude({ toPlainOnly: true })
  @Type(() => Date)
  @Transform(value => moment(value * 1000), { toClassOnly: true })
  lastSuccessfulRun: Moment;

  @Expose({ toClassOnly: true })
  get feedType() {
    return this.accountId === 0 ? FeedTypes.LOGZIO : FeedTypes.PRIVATE;
  }
}
