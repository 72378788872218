import { get, set, remove, CookieAttributes } from 'js-cookie';
import { Env } from '../environment';

interface ICookieOptions extends CookieAttributes {
  currentDomain?: boolean;
}

const IN_LOGZ_DOMAIN = /.*\.logz\.io/.test(window.location.hostname.toLowerCase());
const DEFAULT_SETTINGS = {
  domain: IN_LOGZ_DOMAIN ? 'logz.io' : '',
  path: '/',
  expires: 30, // 30 Days
};

const callWithEnv = (fn: () => any, name: string, ...args: Record<string, any>[]): any => {
  const { cookieSuffix } = Env.configs;
  const cookieName = cookieSuffix ? `${name}-${cookieSuffix}` : name;

  return fn.call(null, cookieName, ...args);
};

const getMergedOptions = (options: ICookieOptions): Record<string, any> => ({
  ...DEFAULT_SETTINGS,
  ...options,
  ...(options.currentDomain ? { domain: location.hostname } : {}),
});

export const getCookie = get;
export const setCookie = (name: string, value: any, options: ICookieOptions = {}): string =>
  set(name, value, getMergedOptions(options));
export const removeCookie = (name: string, options: ICookieOptions = {}): void =>
  remove(name, getMergedOptions(options));
const setSessionCookie = (name: string, value: any): string => setCookie(name, value, { expires: null });

export const withEnv = {
  get: callWithEnv.bind(null, get),
  set: callWithEnv.bind(null, setCookie),
  remove: callWithEnv.bind(null, removeCookie),
};

export const cookiesProvider = {
  getCookie,
  setCookie,
  removeCookie,
  setSessionCookie,
  withEnv,
};
