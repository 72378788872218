const letterNotations = ['', 'K', 'M', 'B', 'T', 'P', 'E'] as const;
const addComma = (value: number) =>
  value
    .toFixed(2)
    .replace(/\.?0+$/, '')
    .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
const local = 'en-US';

const format = (number: number, options?: Intl.NumberFormatOptions): string => {
  if (options) return new Intl.NumberFormat([], options).format(number);

  if (number === 0) return '0';

  if (number < 0.00001)
    return new Intl.NumberFormat(local, {
      maximumSignificantDigits: 2,
    }).format(number);

  if (number < 100) {
    return new Intl.NumberFormat(local, {
      maximumSignificantDigits: 3,
    }).format(number);
  }

  if (number < 1000) {
    return new Intl.NumberFormat(local, {
      maximumSignificantDigits: 4,
    }).format(number);
  }

  return new Intl.NumberFormat(local, {
    maximumFractionDigits: 0,
  }).format(number);
};

const abbreviateNumber = (value: number, fixedFormatThreshold?: typeof letterNotations[number]): string => {
  if (value < 1000) return value.toString(10);

  const notationIndex = fixedFormatThreshold
    ? letterNotations.indexOf(fixedFormatThreshold)
    : letterNotations.findIndex(notation => Math.abs(value) < 1000 ** (letterNotations.indexOf(notation) + 1));

  const abbreviatedNumber = value / 1000 ** notationIndex;

  return abbreviatedNumber >= 1
    ? addComma(abbreviatedNumber) + letterNotations[notationIndex]
    : addComma(value / 1000 ** (notationIndex - 1)) + letterNotations[notationIndex - 1];
};

const getConvertedBytes = (
  bytes,
  decimals = 2,
): {
  convertedBytes: number;
  index: number;
  size: typeof sizes[number];
} => {
  if (bytes === 0) {
    return { convertedBytes: bytes, index: 0, size: sizes[0] };
  }

  const k = 1024;
  const i = Math.abs(Math.floor(Math.log(bytes) / Math.log(k)));

  return { convertedBytes: parseFloat((bytes / k ** i).toFixed(decimals)), index: i, size: sizes[i] };
};

const formatBytes = (bytes: number, decimals = 2): string => {
  if (bytes === 0) return '0 Bytes';

  const { convertedBytes, size } = getConvertedBytes(bytes, decimals);

  return `${convertedBytes} ${size}`;
};

const compactFormatter = Intl.NumberFormat(undefined, { notation: 'compact' });

const formatCompact = (value: number) => {
  return compactFormatter.format(value);
};

const toFixed = (value: string | number, precision = 3) =>
  Math.round(parseFloat(value.toString()) * 10 ** precision) / 10 ** precision;

const toPlusAbbreviation = ({ num, limit }: { num: number; limit: number }) => (num > limit ? `${limit}+` : num);

export const numberService = {
  sizes,
  formatCompact,
  format,
  abbreviateNumber,
  getConvertedBytes,
  formatBytes,
  toFixed,
  toPlusAbbreviation,
};
