import { RollupRuleModel } from '@logz-pkg/models';
import { ICollectionResponse, ISearchRequestObject } from '@logz-build/typescript';
import { ICrudApiService } from '../utilities/types';
import { CrudApiService } from '../utilities/crud.api.service';
import { populate } from './populate';

export class MetricsRollupApiService implements ICrudApiService<RollupRuleModel> {
  crudApiService = new CrudApiService({ modelDefinition: RollupRuleModel });

  urls = {
    resource: (id: string) => `v1/metrics-management/rollup-rules/${id}`,
    collection: 'v1/metrics-management/rollup-rules',
    search: 'v1/metrics-management/rollup-rules/search',
  };

  get = async (id: RollupRuleModel['id']): Promise<RollupRuleModel> => {
    const response = await this.crudApiService.get(this.urls.resource(id));

    return (await populate.accountNames([response]))[0];
  };

  delete = (id: RollupRuleModel['id']) => {
    return this.crudApiService.delete(this.urls.resource(id));
  };

  create = (model: RollupRuleModel): Promise<RollupRuleModel> => {
    return this.crudApiService.create(this.urls.collection, model);
  };

  update = (model: RollupRuleModel): Promise<RollupRuleModel> => {
    return this.crudApiService.update(this.urls.resource(model.id), model);
  };

  search = async (searchObject: ISearchRequestObject): Promise<ICollectionResponse<RollupRuleModel>> => {
    const response = await this.crudApiService.search(this.urls.search, searchObject);

    response.results = await populate.accountNames(response.results);

    return response;
  };
}

export const metricsRollupApiService = new MetricsRollupApiService();
