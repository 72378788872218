import * as yup from 'yup';
import { TelemetryAgentParamType } from '../enums';
import { oneOfEnum, paramBaseSchema, paramMetaDataSchema } from './shared.schemas';

const multilinePatternSchema = yup.object().shape({
  pattern: yup.string().required(),
  source: yup.string().required(),
});

const multilinePatternParamBaseSchema = paramBaseSchema.shape({
  type: oneOfEnum({ type: TelemetryAgentParamType.MultilinePattern }).required('param type required'),
  value: yup.array().nullable(), // this field's base schema is overridden in its extending schemas
});

export const multilinePatternParamManifestSchema = multilinePatternParamBaseSchema.concat(paramMetaDataSchema).shape({
  value: yup.array().of(multilinePatternSchema).strict(),
});

export const multilinePatternParamSchema = multilinePatternParamBaseSchema.shape({
  value: yup.array().when(['required'], {
    is: req => req,
    then: yup.array().of(multilinePatternSchema).strict().min(1, 'at least one item is required'),
  }),
});
