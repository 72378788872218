import { cacheProvider } from '../../cache/cache.provider';
import { httpService } from '../../http/request.provider';
import { authService } from '../../auth/services/auth.service';
import { sessionApiService } from '../../../dal/session/api.service';

/** @deprecated Use `sessionStateService.session.get()` or `sessionStateService.fetch()` instead. */
const getSummary = async (fresh = false) => {
  const sessionContext = await sessionApiService.get({ fresh });

  // To support backward compatibility.
  return { data: sessionContext.userSummary };
};

/** @deprecated Use `sessionStateService.session.get().loggedInAccount.id` instead. */
const getCurrentAccountId = async () => {
  const {
    data: { accountId },
  } = await getSummary();

  return accountId;
};
const clearCache = () =>
  cacheProvider.clear(httpService.generateHttpResponseCacheKey(cacheProvider.CacheKeys.SESSION_CONTEXT));

const getUsage = async () => {
  const {
    data: { usage },
  } = await httpService.get('/usage');

  return usage || [];
};

const getUsageByType = async () => {
  const {
    data: { usageByType },
  } = await httpService.get('/usage-by-type');

  return usageByType;
};

const updateUser = (username, accountId, suspend) => {
  const postUrl = suspend ? '/__admin__/block-user' : '/__admin__/unblock-user';

  return httpService.post(postUrl, {
    username,
    accountId,
  });
};

/** @deprecated Use `sessionStateService.session.get().userSettings` instead. */
const getUserSettings = async () => {
  if (!authService.isAuthenticated()) return;

  const { userSettings } = await sessionApiService.get();

  return userSettings;
};

const isLogzUser = async (): Promise<boolean> => {
  const {
    userSummary: { username },
  } = await sessionApiService.get();

  const [, emailDomain] = username.split('@');

  return emailDomain === 'logz.io';
};

export const userService = {
  getSummary,
  getCurrentAccountId,
  clearCache,
  getUsage,
  getUsageByType,
  updateUser,
  isLogzUser,
  getUserSettings,
};
