import {
  BillingCycle,
  BillingType,
  LogMeasurementType,
  PaymentMethod,
  PLAN_TYPE,
  ProductPlanResponseStatus,
} from '@logz-pkg/enums';
import { Expose } from 'class-transformer';
import { ProductPlansArray } from './product-plan-array';

export class PlanInfoModel {
  type: PLAN_TYPE;
  logMeasurementType?: LogMeasurementType;
  billingCycle?: BillingCycle;
  paymentMethod?: PaymentMethod;
  taxPercentage?: number;
  totalPrice?: number;
  products: ProductPlansArray;
  billingType: BillingType;
  @Expose({ name: 'isGroup' })
  isGrouped: boolean;

  @Expose({ toClassOnly: true })
  public isCommunityAccount(): boolean {
    return [PLAN_TYPE.FREE, PLAN_TYPE.TRIAL].includes(this.type);
  }

  @Expose({ toClassOnly: true })
  public isPayingAccount(): boolean {
    return [PLAN_TYPE.PRO, PLAN_TYPE.ENTERPRISE].includes(this.type);
  }

  @Expose({ toClassOnly: true })
  public isTrial(): boolean {
    return [PLAN_TYPE.TRIAL].includes(this.type);
  }

  @Expose({ toClassOnly: true })
  public hasErrorsInProductPlans(): boolean {
    return this.products.some(plan => plan.responseStatus !== ProductPlanResponseStatus.SUCCESS);
  }

  @Expose({ toClassOnly: true })
  public isIngested(): boolean {
    return this.logMeasurementType === LogMeasurementType.Ingested;
  }
}
