import { KibanaIsOneOfFilterMetadata } from '../parsers/is-one-of-filter-parser';
import KibanaFilterModel from './osd-filter-model';

export interface IElasticShould {
  match_phrase: {
    [field: string]: string;
  };
}

export default class IsOneOfFilterModel extends KibanaFilterModel {
  public query: {
    bool: {
      should: IElasticShould[];
      minimum_should_match: 1;
    };
  };

  constructor(metadata: KibanaIsOneOfFilterMetadata) {
    super(metadata);

    const should: IElasticShould[] = metadata.params.map(value => ({
      match_phrase: {
        [metadata.key]: value,
      },
    }));

    this.query = {
      bool: {
        should,
        minimum_should_match: 1,
      },
    };
  }
}
