import type { opensearchtypes } from '@opensearch-project/opensearch';
import { isNil } from 'lodash';
import { abortControllerService } from '@logz-pkg/utils';
import { CrudApiService } from '../utilities/crud.api.service';
import { ICrudApiService } from '../utilities/types';
import { handleDalError } from '../utilities/utilities';
import { IBuildSearchPayload, opensearchQueryService } from './opensearch-query.service';

export type QueryMarkers = { n: string; source?: 'explore' };

export class OpensearchApiService implements ICrudApiService<opensearchtypes.SearchResponse> {
  crudApiService = new CrudApiService<opensearchtypes.SearchResponse>();

  urls = {
    search: '/osd-2-0/internal/search/opensearch',
    scrollSearch: '/osd-2-0/api/opensearch-dashboards',
  };

  searchLogs = async <T = any>(
    params: IBuildSearchPayload,
    queryMarkers?: QueryMarkers,
    { abortKey }: { abortKey: string } = { abortKey: undefined },
  ): Promise<opensearchtypes.SearchResponse<T>> => {
    const payload = opensearchQueryService.buildSearchPayload(params);
    const query = queryMarkers
      ? `?${Object.getOwnPropertyNames(queryMarkers)
          .map(p => `${p}=${queryMarkers[p]}`)
          .join('&')}`
      : '';

    let abortController: AbortController | undefined;

    if (!isNil(abortKey)) {
      abortControllerService.abort(abortKey);
      abortController = abortControllerService.create(abortKey);
      abortController.signal.onabort = () => {
        abortControllerService.delete(abortKey);
      };
    }

    try {
      const response = await this.crudApiService.do<{ rawResponse: opensearchtypes.SearchResponse<T> }>(
        queryMarkers ? `${this.urls.search}${query}` : this.urls.search,
        {
          payload,
          abortSignal: abortController?.signal,
        },
      );

      if ((response?.rawResponse as any).status >= 400) {
        throw response?.rawResponse;
      }

      return response?.rawResponse;
    } catch (e) {
      if (e?.config?.signal?.aborted) throw e;

      handleDalError(e);
    }
  };

  searchLogsUsingScrollApi = async <T = any>(
    params: IBuildSearchPayload,
  ): Promise<opensearchtypes.SearchResponse<T>> => {
    const payload = opensearchQueryService.buildSearchPayload(params);

    return this.crudApiService.do<opensearchtypes.SearchResponse<T>>(
      `${this.urls.scrollSearch}/${params.scroll ? 'scroll_continue' : 'scroll_start'}`,
      {
        payload: {
          ...(params.scroll
            ? {
                scroll: params.scroll,
                scrollId: params.scrollId,
              }
            : {
                elasticBody: payload.params.body,
                index: payload.params.index,
                size: payload.params.body.size,
                sort: (Array.isArray(payload.params.body.sort) ? payload.params.body.sort : [payload.params.body.sort])
                  .map(item => `${Object.keys(item)[0]}:${Object.values(item)[0].order}`)
                  .join(','),
              }),
        },
      },
    );
  };
}

export const opensearchApiService = new OpensearchApiService();
