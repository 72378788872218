// DEV-16469: Fix metrics models and api
export class MetricsAccountPlanModel {
  id: number;
  name: string;
  token: string;
  createdAt: number;
  dpm: number;
  planUTS: number;
  active: boolean;
  suspended: boolean;
  dailyUsage: DayUsage[];
  authorizedAccounts: Account[];
  dataStoreType: string;
  maxUniqueMetrics?: number;
}

class DayUsage {
  date: number;
  dpm: number;
  hourlyAverageUTS: number;
}

class Account {
  accountId: number;
  accountName: string;
}
