import { cacheProvider } from './cache.provider';

export const cacheService = [
  'AngularShimService',
  function cacheService({ withDigest }: { withDigest: (Function) => string }): Record<string, any> {
    return {
      ...cacheProvider,
      get: withDigest(cacheProvider.get),
    };
  },
];
