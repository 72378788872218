import moment, { Moment } from 'moment';
import { Expose, Type, Transform } from 'class-transformer';
import { NotificationEndpointType } from '@logz-pkg/enums';

export class NotificationEndpointTemplateModel {
  template: string;
  templateName: NotificationEndpointType;
  templateType: string;

  @Expose({ name: 'notificationType' })
  type: string;

  @Expose({ name: 'createdDate' })
  @Type(() => Date)
  @Transform(value => moment(value * 1000), { toClassOnly: true })
  @Transform(value => value?.valueOf(), { toPlainOnly: true })
  createdAt: Moment;

  @Expose({ name: 'modifiedDate' })
  @Type(() => Date)
  @Transform(value => moment(value * 1000), { toClassOnly: true })
  @Transform(value => value?.valueOf(), { toPlainOnly: true })
  updatedAt: Moment;
}
