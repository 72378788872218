import { ICrudApiService } from '../utilities/types';
import { CrudApiService } from '../utilities/crud.api.service';

class SigmaRulesConvertorModel {
  rule: string;
}

class SigmaRulesConvertorResponseModel {
  lucene: string;
  mitreTags: string[];
  title: string;
  description: string;
}

class SigmaRulesConverterService implements ICrudApiService<SigmaRulesConvertorResponseModel, SigmaRulesConvertorModel> {
  crudApiService = new CrudApiService<SigmaRulesConvertorResponseModel, SigmaRulesConvertorModel>({
    modelDefinition: SigmaRulesConvertorResponseModel,
  });

  urls = {
    convert: '/observability-apps/sigma/convert',
  };

  convert = (model: SigmaRulesConvertorModel) => this.crudApiService.create(this.urls.convert, model);
}

export const sigmaRulesConverterService = new SigmaRulesConverterService();
