import 'reflect-metadata';
import { Expose, Transform, Type } from 'class-transformer';
import moment, { Moment } from 'moment';
import { PLAN_TYPE } from '@logz-pkg/enums';

export class PlanDetailsModel {
  accountId: number;
  archivingSuspensionDailyLimitPctThreshold: number;
  backupEnabled: boolean;
  indexingSuspensionDailyLimitPctThreshold: number;
  maxAlertsDefinition: number;
  maxConcurrentQueriesApiUsers: number;
  maxConcurrentQueriesHumanUsers: number;
  maxKibanaBucketAggregations: number;
  maxKibanaFieldsNum: number;
  maxLiveTailSessions: number;
  maxLiveTailSessionsPerUser: number;
  maxS3BucketsNum: number;
  maxScrollHitsPerDayInMillions: number;
  maxSubAccounts: number;
  maxTimelessIndices: number;
  maxTimelessIndicesVolumeGb: number;
  maxUsersCount: number;
  planNotes: string;
  planType: PLAN_TYPE;
  totalVolumeGb: number;
  warningDailyLimitPctThreshold: number;
  queryApiRetentionDays: number;
  retentionDays: number;
  snapSearchRetentionDays: number | null;
  @Expose({ name: 'apiAccessEnabled' }) isApiAccessEnabled: boolean;
  @Expose({ name: 'insightsFeatureEnabled' }) isInsightsFeatureEnabled: boolean;
  @Expose({ name: 'maxDailyGB' }) maxDailyGb: number;
  // https://github.com/typestack/class-transformer#basic-usage
  @Type(() => Date)
  @Transform(value => moment(value), { toClassOnly: true })
  startDate: Moment;

  @Type(() => Date)
  @Transform(value => moment(value), { toClassOnly: true })
  endDate: Moment;
  isFlexible?: boolean;
  isCapped?: boolean;
  @Expose({ name: 'reservedDailyGB' }) reservedDailyGb?: number;
  @Expose({ name: 'totalTimeBasedDailyGB' }) totalTimeBasedDailyGb?: number;

  @Expose({ toClassOnly: true })
  public isCommunityAccount(): boolean {
    return [PLAN_TYPE.FREE, PLAN_TYPE.TRIAL].includes(this.planType);
  }
}
