import moment, { Moment } from 'moment';
import { Type, Transform, Expose } from 'class-transformer';

export class ApiTokenModel {
  id: number;
  token: string;
  @Expose({ name: 'tokenName', toPlainOnly: true }) name = '';
  @Type(() => Date)
  @Transform(value => moment(value * 1000), { toClassOnly: true })
  createdAt: Moment;
  @Type(() => Date)
  lastUsed?: number;
}
