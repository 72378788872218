import { AccountModel } from './account';

export class TimelessAccountRequestModel {
  id = null;
  name: string;
  volumeGb: number;
  authorizedAccounts: number[] = [];
}

export class TimelessAccountModel {
  id: number;
  ownerAccountId: number;
  name: string;
  volumeGb: number;
  logType: string;
  createdAt: number;

  actualUsage: {
    percentage: string;
    bytes: number;
    humanReadableSize: string;
  };

  dailyUsage: {
    usage: { date: number; bytes: number }[];
  };

  authorizedAccounts: { accountId: number; accountName: string }[];

  suspended: boolean;
}

export class DetailedTimelessAccountsSummaryModel {
  sharingObjectsAccounts: AccountModel[];
  maxAllowed: number;
  totalVolumeGb: number;
  timelessIndices: TimelessAccountModel[];
}

export class TimelessDeleteDataModel {
  timeFrameFrom: number; // unix
  timeFrameTo: number; // unix
  optimizerIds: number[];
}
