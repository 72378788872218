import { AccountStructureModel } from '@logz-pkg/models';
import { ISearchRequestObject, ICollectionResponse } from '@logz-build/typescript';
import { ICrudApiService } from '../utilities/types';
import { CrudApiService } from '../utilities/crud.api.service';

export class LogsStructuresService implements ICrudApiService<AccountStructureModel> {
  crudApiService = new CrudApiService({ modelDefinition: AccountStructureModel });

  urls = {
    search: '/data-optimization/logs/usage/search',
  };

  search = async (requestObject: ISearchRequestObject): Promise<ICollectionResponse<AccountStructureModel>> => {
    return this.crudApiService.search(this.urls.search, requestObject);
  };
}

export const logsStructuresService = new LogsStructuresService();
