import { ICollectionResponse, ISearchRequestObject } from '@logz-build/typescript';
import { SharedTokenModel } from '@logz-pkg/models';
import { CrudApiService } from '../utilities/crud.api.service';
import { ICrudApiService } from '../utilities/types';
import { cacheProvider } from '../../core/cache/cache.provider';
import { populateSharedToken } from './populate';

export class SharedTokensApiService implements ICrudApiService<SharedTokenModel> {
  crudApiService = new CrudApiService({ modelDefinition: SharedTokenModel });

  urls = {
    collection: 'shared-tokens',
    search: 'shared-tokens',
    resource: id => `/shared-tokens/${id}`,
  };

  search = async (requestObject: ISearchRequestObject): Promise<ICollectionResponse<SharedTokenModel>> => {
    // Ensure that populate will fetch fresh data, Token Filters are cached to reduce the amount of requests made on load
    cacheProvider.clear(cacheProvider.CacheKeys.SHARED_TOKENS_FILTERS);

    const searchResult = await this.crudApiService.search(this.urls.search, requestObject, { httpMethod: 'get' });

    searchResult.results = await populateSharedToken(searchResult.results);

    return searchResult;
  };

  create = async (model: SharedTokenModel): Promise<SharedTokenModel> =>
    this.crudApiService.create(this.urls.collection, model);

  update = async (model: SharedTokenModel): Promise<SharedTokenModel> =>
    this.crudApiService.update(this.urls.resource(model.id), model);

  delete = async (id: SharedTokenModel['id']): Promise<SharedTokenModel> =>
    this.crudApiService.delete(this.urls.resource(id));
}

export const sharedTokensApiService = new SharedTokensApiService();
