import { CrudApiService } from '../utilities/crud.api.service';

export class MarketplaceApiService {
  crudApiService = new CrudApiService();

  urls = {
    activateAwsSubscription: '/aws/marketplace/integrate',
    integrateAwsProduct: '/aws/marketplace/product-integrate',
    activateAzureSubscription: '/azure-marketplace-activate-subscription',
  };

  activateAwsSubscription = async (accountId: number, awsMarketplaceToken: string) => {
    return this.crudApiService.do(this.urls.activateAwsSubscription, { payload: { accountId, awsMarketplaceToken } });
  };

  integrateAwsProduct = async (awsMarketplaceToken: string) => {
    return this.crudApiService.do(this.urls.integrateAwsProduct, { payload: { awsMarketplaceToken } });
  };

  activateAzureSubscription = async (accountId: number, cloudMarketplaceToken: string) => {
    return this.crudApiService.do(this.urls.activateAzureSubscription, {
      payload: {
        accountId,
        cloudMarketplaceToken,
      },
    });
  };
}

export const marketplaceApiService = new MarketplaceApiService();
