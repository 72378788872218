import { IElasticFilter } from '../../../osd/filters/filter-interfaces';
import { ElasticsearchFieldModel } from '../../../elasticsearch/elasticsearch-field';
import { ElasticsearchEnhancedFilterModel } from '../../../elasticsearch/elasticsearch-filter';
import KibanaFilterMetadata from '../../../osd/filters/metadatas/osd-filter-metadata';
import LessThanFilterParser from '../../../osd/filters/parsers/less-than-filter-parser';
import { BaseExploreFilterParser } from './base';

/**
 * Elastic to ElasticsearchFilterModel 'LESS THAN' filter parser
 */
export class ExploreFilterLessThanFilterParser extends BaseExploreFilterParser {
  constructor() {
    super(new LessThanFilterParser());
  }

  getFilter(elasticFilter: object, negate: boolean): ElasticsearchEnhancedFilterModel {
    const metadata: KibanaFilterMetadata = this.getMetadata(elasticFilter, negate);

    if (!metadata) return null;

    const filter = new ElasticsearchEnhancedFilterModel();

    filter.type = 'LESS_THAN';
    filter.value = metadata.params;
    filter.negate = metadata.negate;
    filter.field = new ElasticsearchFieldModel();
    filter.field.name = metadata.key;

    return filter;
  }

  getElasticFilter(filter: ElasticsearchEnhancedFilterModel): IElasticFilter {
    if (filter.type !== 'LESS_THAN') return null;

    return { [this.baseFilterParser.elasticKey]: { [filter.field.name]: filter.value } };
  }
}
