import { cloneDeep } from 'lodash';
import { ISearchRequestObject } from '@logz-build/typescript';
import { plainToClass, classToPlain } from 'class-transformer';
import { AlertOutputSamplesResponseModel, ContributingUserModel, RuleModel, RuleExportFields } from '@logz-pkg/models';
import { BulkDeleteActionReq, BulkUpdateActionReq, ExportToCsvService, ICrudApiService } from '../utilities/types';
import { CrudApiService } from '../utilities/crud.api.service';
import { AlertApiService } from '../alert/api.service';
import { PopulateAlert } from '../alert/populate';
import { handleDalError } from '../utilities/utilities';
import { predefinedRuleTags } from './predefined-rule-tags';
import { populateLookups } from './populate';

type RuleActionType = 'createdBy' | 'updatedBy';

class RuleApiService implements ICrudApiService<RuleModel>, ExportToCsvService {
  crudApiService = new CrudApiService({ modelDefinition: RuleModel });
  usersCrudApiService = new CrudApiService({ modelDefinition: ContributingUserModel });
  tagsCrudApiService = new CrudApiService<string>();

  urls = {
    collection: 'v2/security/rules',
    search: 'v2/security/rules/search',
    resource: (id: number) => `v2/security/rules/${id}`,
    protectedResource: (id: number) => `v2/security/rules/protected/${id}`,
    enable: (id: number) => `v2/security/rules/${id}/enable`,
    disable: (id: number) => `v2/security/rules/${id}/disable`,
    generateOutputSamples: `v2/security/rules/generate-samples`,
    users: (ruleActionType: RuleActionType) => `security/rules/contributing-users/${ruleActionType}`,
    searchTags: '/v2/security/rules/tags/search',
    bulkDelete: '/v2/security/rules/bulk/delete',
    bulkUpdate: '/v2/security/rules/bulk/update',
  };

  populate = async (rule: RuleModel): Promise<RuleModel> => {
    let populatedRule = cloneDeep(rule);

    populatedRule = await AlertApiService.populate(populatedRule);
    populatedRule = await populateLookups(populatedRule);

    return populatedRule;
  };

  search = async (requestObject: ISearchRequestObject) => {
    const response: any = await this.crudApiService.search(this.urls.search, requestObject);

    response.results = await PopulateAlert.populateMultiRecipients(response.results);

    return response;
  };

  create = (rule: RuleModel) => this.crudApiService.create(this.urls.collection, rule);

  update = (rule: RuleModel) => this.crudApiService.update(this.urls.resource(rule.id), rule);

  delete = (id: RuleModel['id']) => this.crudApiService.delete(this.urls.resource(id));

  // replace with simple getAll after DEV-37467
  getAll = async () => {
    const allRulesSearch = await this.search({
      pagination: {
        pageNumber: 1,
        pageSize: 1e9,
      },
    });

    return allRulesSearch.results;
  };

  get = async (id: RuleModel['id']) => {
    const rule = await this.crudApiService.get(this.urls.resource(id));

    return this.populate(rule);
  };

  enableState = async (id: RuleModel['id']) => {
    const response = await this.crudApiService.do<RuleModel>(this.urls.enable(id));

    return plainToClass(RuleModel, response);
  };

  disableState = async (id: RuleModel['id']) => {
    const response = await this.crudApiService.do<RuleModel>(this.urls.disable(id));

    return plainToClass(RuleModel, response);
  };

  createdByUsers = () => this.usersCrudApiService.get(this.urls.users('createdBy'));

  updatedByUsers = () => this.usersCrudApiService.get(this.urls.users('updatedBy'));

  generateSamples = async (alert: RuleModel) => {
    const response = await this.crudApiService.do<AlertOutputSamplesResponseModel>(this.urls.generateOutputSamples, {
      payload: { alert: classToPlain(alert) },
    });

    try {
      return plainToClass(AlertOutputSamplesResponseModel, response);
    } catch (error) {
      handleDalError(error);
    }
  };

  searchTags = async (requestObject: ISearchRequestObject) => {
    const privateTags = await this.tagsCrudApiService.search(this.urls.searchTags, requestObject);
    const searchString = requestObject.filter?.searchTerm?.toLowerCase();
    const allTags = Array.from(new Set([...privateTags.results, ...predefinedRuleTags]));

    privateTags.results = searchString ? allTags.filter(tag => tag.includes(searchString)) : allTags;

    return privateTags;
  };

  bulkDelete = (requestObject: BulkDeleteActionReq) =>
    this.crudApiService.bulkDelete(this.urls.bulkDelete, requestObject);

  bulkUpdate = (requestObject: BulkUpdateActionReq) =>
    this.crudApiService.bulkUpdate(this.urls.bulkUpdate, requestObject);

  csvExportFields = RuleExportFields;
}

export const ruleApiService = new RuleApiService();
