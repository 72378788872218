import { ElasticsearchEnhancedFilterModel } from '../elasticsearch/elasticsearch-filter';

export class SavedObjectRequestModel {
  constructor(values: SavedObjectRequestModel) {
    Object.assign(this, values);
  }

  name: string;
  description: string;
  url: string;
  isFavorite?: boolean = false;
  type?: 'search' | 'vis' | 'dash' = 'search';
  id?: string;
  osdData: SavedObjectOsdData;
}

export class SavedObjectOsdData {
  hits: number;
  columns: string[];
  sort: [string, string][];
  exploreFilterState: ElasticsearchEnhancedFilterModel[];
  excludedAccounts: string[];
}

export class SavedObjectModel {
  id: string;
  userId: number;
  createdAt: string;
  updatedAt: string;
  createdBy: string;
  updatedBy: string;
  name: string;
  description: string;
  isFavorite: boolean = false;
  url: string;
  type: 'search' | 'vis' | 'dash';
}
