import React from 'react';
import ReactDOM from 'react-dom';

export const elementReady = ({ targetWindow = window, selector }) => {
  return new Promise(resolve => {
    const elm = targetWindow.document.querySelector(selector);

    if (elm) resolve(elm);

    new MutationObserver((non, observer) => {
      const elm = targetWindow.document.querySelector(selector);

      if (elm) {
        observer.disconnect();
        resolve(elm);
      }
    }).observe(targetWindow.document.documentElement, {
      childList: true,
      subtree: true,
    });
  });
};

export const appendReactComponentToElement = ({
  component,
  domElement,
  place = null,
  props = { scope: null },
  logzId = null,
  wrapper = null,
}) => {
  if (document.getElementById(logzId)) return;

  let renderTo = domElement;

  const newDiv = wrapper || document.createElement('div');

  if (logzId) newDiv.id = logzId;

  if (place === 'first') {
    renderTo.prepend(newDiv);
    renderTo = newDiv;
  } else if (place === 'last') {
    renderTo.append(newDiv);
    renderTo = newDiv;
  } else if (logzId) renderTo.id = logzId;

  if (props.scope) {
    props.scope.$on('$destroy', () => {
      ReactDOM.unmountComponentAtNode(renderTo); // DEV-41495 refactor to root.unmount();
    });
  }

  ReactDOM.render(React.createElement(component, props), renderTo);
};

export const reactInjectorService = {
  elementReady,
  appendReactComponentToElement,
};
