import { DetailedTimelessAccountsSummaryModel } from '@logz-pkg/models';
import { CrudApiService } from '../../utilities/crud.api.service';
import { ICrudApiService } from '../../utilities/types';

class TimelessAccountManagementCollectionApiService implements ICrudApiService<DetailedTimelessAccountsSummaryModel> {
  apiService = new CrudApiService<DetailedTimelessAccountsSummaryModel>({
    modelDefinition: DetailedTimelessAccountsSummaryModel,
  });

  urls = {
    getAll: `/timeless-indices`,
  };

  get = (): Promise<DetailedTimelessAccountsSummaryModel> => this.apiService.get(this.urls.getAll);
}

export const timelessAccountManagementCollectionApiService = new TimelessAccountManagementCollectionApiService();
