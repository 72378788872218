import { BookmarkModel, GrafanaSearchResultModel, SearchSource } from '@logz-pkg/models';
import { ICrudApiService } from '../utilities/types';
import { CrudApiService } from '../utilities/crud.api.service';
import { metricsSearchApiService } from '../global-search/metrics-search-api.service';

export class FavoriteApiService implements ICrudApiService<BookmarkModel> {
  crudApiService = new CrudApiService({ modelDefinition: BookmarkModel });
  urls = {
    search: '/grafana-app/api/search',
    getStarredUrl: dashboardId => `/grafana-app/api/user/stars/dashboard/${dashboardId}`,
  };
  async unStarGrafanaDashboard(dashboardId: number | string): Promise<void> {
    const id =
      typeof dashboardId === 'number' ? dashboardId : (await metricsSearchApiService.getGrafanaObject(dashboardId))?.id;

    if (id) await this.crudApiService.delete(this.urls.getStarredUrl(id));
  }
  async starGrafanaDashboard(dashboardUid: string): Promise<void> {
    const dashboard = await metricsSearchApiService.getGrafanaObject(dashboardUid);

    if (!dashboard) throw new Error(`Favorites - Dashboard "${dashboard}" (${dashboardUid}) not found`);

    const { isStarred, id: dashboardId } = dashboard;

    if (isStarred) return;

    await this.crudApiService.do(this.urls.getStarredUrl(dashboardId));
  }
  async getStarredGrafanaObjects(): Promise<BookmarkModel[]> {
    const data = (await this.crudApiService.getAll(
      `${this.urls.search}?starred=true`,
    )) as unknown as GrafanaSearchResultModel[];

    return data.map(
      seachResult =>
        ({
          source: SearchSource.GrafanaDashboard,
          id: seachResult.uid,
          title: seachResult.title,
          state: {
            name: 'metrics.dashboard',
            params: seachResult,
          },
        } as BookmarkModel),
    );
  }
}
export const favoriteApiService = new FavoriteApiService();
