import { Type, Transform } from 'class-transformer';

class KibanaObjectAttributes {
  type: string;
  title: string;
  description: string;
  _createdBy: {
    username: string;
  };
  @Type(() => Date)
  @Transform(value => value * 1000, { toClassOnly: true })
  createdAt: Date;
  _updatedBy: {
    username: string;
  };
  @Type(() => Date)
  @Transform(value => value * 1000, { toClassOnly: true })
  _updatedAt: Date;
}

export class KibanaSearchResultModel {
  id: number;
  attributes: KibanaObjectAttributes;
  meta: { link: string; icon: string };
}
