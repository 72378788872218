import { ICollectionResponse, ISearchRequestObject } from '@logz-build/typescript';
import { DropFiltersConfigModel, LogsDropFilterModel } from '@logz-pkg/models';
import { ICrudApiService } from '../utilities/types';
import { CrudApiService } from '../utilities/crud.api.service';

export class DropFiltersApiService implements ICrudApiService<LogsDropFilterModel> {
  crudApiService = new CrudApiService({ modelDefinition: LogsDropFilterModel });
  configCrudService = new CrudApiService<DropFiltersConfigModel>();

  urls = {
    collection: 'logs/drop-filters',
    search: 'logs/drop-filters/search',
    configuration: 'logs/drop-filters/configuration',
    resource: (id: LogsDropFilterModel['id']) => `logs/drop-filters/${id}`,
    activate: (id: LogsDropFilterModel['id']) => `logs/drop-filters/${id}/activate`,
    deactivate: (id: LogsDropFilterModel['id']) => `logs/drop-filters/${id}/deactivate`,
  };

  search = async (requestObject: ISearchRequestObject): Promise<ICollectionResponse<LogsDropFilterModel>> =>
    this.crudApiService.search(this.urls.search, requestObject);

  get = async (id: LogsDropFilterModel['id']): Promise<LogsDropFilterModel> =>
    this.crudApiService.get(this.urls.resource(id));

  create = async (model: Omit<LogsDropFilterModel, 'state'>): Promise<LogsDropFilterModel> =>
    this.crudApiService.create(this.urls.collection, model);

  update = async (model: Omit<LogsDropFilterModel, 'state'>): Promise<LogsDropFilterModel> =>
    this.crudApiService.update(this.urls.resource(model.id), model);

  delete = async (id: LogsDropFilterModel['id']): Promise<LogsDropFilterModel> =>
    this.crudApiService.delete(this.urls.resource(id));

  activate = async (id: LogsDropFilterModel['id']): Promise<LogsDropFilterModel> =>
    this.crudApiService.do(this.urls.activate(id));

  deactivate = async (id: LogsDropFilterModel['id']): Promise<LogsDropFilterModel> =>
    this.crudApiService.do(this.urls.deactivate(id));

  getAccountConfig = () => this.configCrudService.get(this.urls.configuration);
}

export const dropFiltersApiService = new DropFiltersApiService();
