export const SESSION_ID_KEY = '__logz.sessionId__';

const getExistingSessionId = () => {
  if (!window.name) return null;

  return window.sessionStorage.getItem(SESSION_ID_KEY);
};

const setSessionId = sessionId => {
  window.name = sessionId;
  window.sessionStorage.setItem(SESSION_ID_KEY, sessionId);
};

const generateNewSessionId = () => {
  // generate random string
  const options = 'abcdefghijklmnopqrstuvwxyz0987654321';
  const newSessionId = '..........'
    .split('')
    .map(() => options.charAt(Math.floor(Math.random() * options.length)))
    .join('');

  setSessionId(newSessionId);

  return newSessionId;
};

const getSessionId = () => {
  const currentSessionId = getExistingSessionId();

  if (currentSessionId !== null) {
    return currentSessionId;
  }

  return generateNewSessionId();
};

export const sessionIdService = {
  getExistingSessionId,
  setSessionId,
  getSessionId,
};
