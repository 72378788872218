import { SeverityLevel } from '@logz-pkg/enums';
import { AlertModel } from './alert';

import { alertFilterModelManipulator } from './filter/model.manipulator';
import { AlertSeverityThresholdModel } from './alert-severity-threshold';
import { SubComponentModel } from './sub-component';

class AlertModelManipulator {
  private getElasticFilters = (elasticFilters: Record<any, any>): SubComponentModel['queryDefinition']['filters'] => {
    if (!(elasticFilters.bool && elasticFilters.bool.filter)) return [];

    return elasticFilters.bool.filter
      .map(filter => alertFilterModelManipulator.convertElasticToFilter(filter))
      .filter(filter => Boolean(filter));
  };

  private getMustElasticFilters = (
    elasticFilters: Record<any, any>,
  ): SubComponentModel['queryDefinition']['filters'] => {
    if (!(elasticFilters.bool && elasticFilters.bool.must)) return [];

    return elasticFilters.bool.must
      .map(filter => alertFilterModelManipulator.convertElasticToFilter(filter))
      .filter(filter => Boolean(filter));
  };

  private getMustNotElasticFilters = (
    elasticFilters: Record<any, any>,
  ): SubComponentModel['queryDefinition']['filters'] => {
    if (!elasticFilters.bool || !(elasticFilters.bool && elasticFilters.bool.must_not)) return [];

    return elasticFilters.bool.must_not
      .map(filter => alertFilterModelManipulator.convertElasticToFilter(filter, true))
      .filter(filter => Boolean(filter));
  };

  convertElasticToFilters(elasticFilters: Record<any, any>): SubComponentModel['queryDefinition']['filters'] {
    if (!elasticFilters) return [];

    return [
      ...this.getElasticFilters(elasticFilters),
      ...this.getMustElasticFilters(elasticFilters),
      ...this.getMustNotElasticFilters(elasticFilters),
    ];
  }

  convertFiltersToElastic(alertQueryDefinition: SubComponentModel['queryDefinition']): object {
    if (alertQueryDefinition.filters.length === 0) return { bool: {} };

    return {
      bool: {
        must: alertQueryDefinition.filters
          .filter(filter => !filter.negate)
          .map(filter => alertFilterModelManipulator.convertToElastic(filter)),
        must_not: alertQueryDefinition.filters
          .filter(filter => filter.negate)
          .map(filter => alertFilterModelManipulator.convertToElastic(filter)),
      },
    };
  }

  convertSeverityThresholdTiersToRecord(
    severityThresholdTiers: SubComponentModel['trigger']['severityThresholdTiers'],
  ): Partial<Record<SeverityLevel, number>> {
    const severitiesRecord: Partial<Record<SeverityLevel, number>> = {};

    severityThresholdTiers.forEach(({ severityLevel, threshold }) => (severitiesRecord[severityLevel] = threshold));

    return severitiesRecord;
  }

  convertSeverityThresholdTiersToArray(
    severityThresholdTiers: Record<SeverityLevel, number>,
  ): SubComponentModel['trigger']['severityThresholdTiers'] {
    return Object.entries(severityThresholdTiers).map(([severityLevel, threshold]) => {
      const severityThreshold = new AlertSeverityThresholdModel();

      severityThreshold.severityLevel = severityLevel as SeverityLevel;
      severityThreshold.threshold = threshold;

      return severityThreshold;
    });
  }

  applyDuplicateProps(alert: AlertModel): Pick<AlertModel, 'title' | 'id' | 'createdBy' | 'updatedBy'> {
    return {
      title: `Copy of ${alert.title}`,
      id: null,
      createdBy: null,
      updatedBy: null,
    };
  }

  getGroupByFieldName({
    alert,
    subComponentIndex,
    groupByIndex,
  }: {
    alert: AlertModel;
    subComponentIndex: number;
    groupByIndex: number;
  }) {
    return alert.subComponents[subComponentIndex].queryDefinition.groupBy[groupByIndex].name;
  }

  getMaxJoinsAmount(alert: AlertModel) {
    return Math.min(...alert.subComponents.map(({ queryDefinition: { groupBy } }) => groupBy.length));
  }
}

export const alertModelManipulator = new AlertModelManipulator();
