import { ElasticFilterType, IElasticFilter } from '../../../osd/filters/filter-interfaces';
import KibanaFilterMetadata from '../../../osd/filters/metadatas/osd-filter-metadata';
import IsOneOfFilterParser from '../../../osd/filters/parsers/is-one-of-filter-parser';
import { ElasticsearchFieldModel } from '../../../elasticsearch/elasticsearch-field';
import {
  ElasticsearchFilterModel,
  ElasticsearchFilterOperator,
  IElasticsearchFilterValue,
} from '../../../elasticsearch/elasticsearch-filter';
import { BaseAlertFilterParser } from './base';

/**
 * Elastic to ElasticsearchFilterModel 'IS ONE OF' filter parser
 */
export class AlertFilterIsOneOfFilterParser extends BaseAlertFilterParser {
  constructor() {
    super(new IsOneOfFilterParser());
  }

  getFilter(elasticFilter: object, negate: boolean): ElasticsearchFilterModel {
    const metadata: KibanaFilterMetadata = this.getMetadata(elasticFilter, negate);

    if (!metadata) return null;

    const filter = new ElasticsearchFilterModel();

    filter.type = ElasticsearchFilterOperator.IsOneOf;
    filter.value = metadata.params;
    filter.negate = metadata.negate;
    filter.field = new ElasticsearchFieldModel();
    filter.field.name = metadata.key;

    return filter;
  }

  getElasticFilter(filter: ElasticsearchFilterModel): IElasticFilter {
    if (filter.type !== ElasticsearchFilterOperator.IsOneOf) return null;

    return {
      [this.baseFilterParser.elasticKey]: {
        minimum_should_match: 1,
        should: (filter.value as IElasticsearchFilterValue[ElasticsearchFilterOperator.IsOneOf]).map(value => ({
          [ElasticFilterType.MatchPhrase]: { [filter.field.name]: value },
        })),
      },
    };
  }
}
