import { format } from 'url';
import { HubIntegrationsManifest } from '@logz-pkg/models';
import { IntegrationType } from '@logz-pkg/enums';
import { ICrudApiService } from '../utilities/types';
import { CrudApiService } from '../utilities/crud.api.service';

class IntegrationsHubService implements ICrudApiService<HubIntegrationsManifest> {
  cacheOptions = { cache: true, secondsToLive: 60 * 60 };
  manifestApiService = new CrudApiService({ modelDefinition: HubIntegrationsManifest });
  urls = {
    manifest: () =>
      format({
        pathname: '/integrations/manifest',
      }),
  };

  getManifest = (isFeatureFlagEnabled: boolean): Promise<HubIntegrationsManifest> =>
    this.manifestApiService.get(this.urls.manifest(), this.cacheOptions).then(d => {
      if (!isFeatureFlagEnabled) {
        return {
          integrations: d.integrations.filter(i => i.integrationType === IntegrationType.COLLECTOR),
          tags: d.tags,
        };
      }

      return d;
    });
}

export const integrationsHubService = new IntegrationsHubService();
