import { AppStateEntities, UserRole } from '@logz-pkg/enums';

export enum GuidedWalkthroughTaskStatus {
  Todo = 'todo',
  Done = 'done',
  Locked = 'locked',
}

export enum GuidedWalkthroughTaskId {
  SendData = 'send-data',
  InviteTeamMember = 'invite-team-member',
  CreateAlert = 'create-alert',
  ManageData = 'manage-data',
  SetLandingPage = 'set-landing-page',
  SearchInExplore = 'search-in-explore',
  SaveSearchInExplore = `save-search-in-explore`,
  GroupByInExplore = 'group-by-in-explore',
}

export class GuidedWalkthroughActionSection {
  callback: () => void;
  text: string;
  learnMoreUrl?: string;
}

export class GuidedWalkthroughTask {
  id: GuidedWalkthroughTaskId;
  userRoles: UserRole[];
  entityLevel: AppStateEntities;
  title: string;
  completeEventName?: string;
  description: string;
  actionSection: GuidedWalkthroughActionSection;
  hint?: string;
  imageUrl?: string;
}
