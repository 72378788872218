import { TokenFilterModel } from '@logz-pkg/models';
import { ICollectionResponse, ISearchRequestObject } from '@logz-build/typescript';
import { ICrudApiService } from '../utilities/types';
import { CrudApiService } from '../utilities/crud.api.service';
import { cacheProvider } from '../../core/cache/cache.provider';

export class TokenFiltersApiService implements ICrudApiService<TokenFilterModel> {
  apiService = new CrudApiService<TokenFilterModel>();

  urls = {
    getAll: 'shared-tokens/filters',
    entity: 'shared-tokens/filters',
    resource: (id: TokenFilterModel['id']) => `shared-tokens/filters/${id}`,
  };

  getAll = async (): Promise<TokenFilterModel[]> => {
    return this.apiService.getAll(this.urls.getAll);
  };

  search = async (requestObject: ISearchRequestObject): Promise<ICollectionResponse<TokenFilterModel>> => {
    return this.apiService.search(this.urls.getAll, requestObject, { httpMethod: 'get' });
  };

  create = async (model: TokenFilterModel) => {
    cacheProvider.clear(cacheProvider.CacheKeys.SHARED_TOKENS_FILTERS);

    return this.apiService.create(this.urls.entity, model);
  };

  delete = async (id: TokenFilterModel['id']): Promise<TokenFilterModel> => {
    cacheProvider.clear(cacheProvider.CacheKeys.SHARED_TOKENS_FILTERS);

    return this.apiService.delete(this.urls.resource(id));
  };
}

export const tokenFiltersApiService = new TokenFiltersApiService();
