import { PlainNotificationEndpointModel } from './plain-notification-endpoint';

export class NotificationEndpointModel extends PlainNotificationEndpointModel {
  description: string;
  url: string;
  apiToken: string;
  appKey: string;
  apiKey: string;
  serviceKey: string;
  messageType: string;
  routingKey: string;
  serviceApiKey: string;
  headers: string;
  method: string;
}
