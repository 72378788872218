// https://github.com/logzio/gaia-full/blob/master/log-analytics/structure-analyzer/client/src/main/kotlin/io/logz/structure/analyzer/responses/AccountStructureResponse.kt
export class AccountStructureModel {
  signature: number;
  logType: string;
  logSizeInBytes: number;
  previousLogSize: number;
  planUsagePercentage: number;
  docCount: number;
  sampleLog: string;
}
