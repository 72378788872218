import { ServerStreamDataChunk } from '@logz-pkg/models';
import { ICrudApiService } from '../utilities/types';
import { CrudApiService } from '../utilities/crud.api.service';

type PollingParams = {
  streamUrl: string;
  payload: Record<string, any> | string;
};

type SseParams = {
  streamUrl: string;
  payload: Record<string, any>;
  onData?: (chunk: ServerStreamDataChunk) => void;
  onDone?: () => void;
  abortSignal?: AbortSignal;
};

class ServerStreamApiService implements ICrudApiService<ServerStreamDataChunk> {
  crudApiService = new CrudApiService<ServerStreamDataChunk>();

  doPolling = ({ streamUrl, payload }: PollingParams): Promise<ServerStreamDataChunk> =>
    this.crudApiService.do(streamUrl, { payload });

  doSse = ({ streamUrl, payload, onData, onDone, abortSignal }: SseParams) =>
    new Promise<void>((resolve, reject) => {
      this.crudApiService.serverSentEvent({
        abortSignal,
        url: streamUrl,
        payload,
        onData: chunk => onData(chunk),
        onError: error => {
          reject(error);
        },
        onEnd: () => {
          onDone?.();
          resolve();
        },
      });
    });
}

export const serverStreamApiService = new ServerStreamApiService();
