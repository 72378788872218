import { Type } from 'class-transformer';
import { BillingCycle } from '@logz-pkg/enums';
import { ProductPlanModel } from '../plan/product-plan';
import { PlanInfoModel } from '../plan/plan-info';

export class AccountPlanModel {
  @Type(() => PlanInfoModel)
  current: PlanInfoModel;
  @Type(() => PlanInfoModel)
  pending?: PlanInfoModel;
}

export class CalculatePricesRequestModel {
  billingCycle: BillingCycle;
  productPlans: Array<{
    name: ProductPlanModel['name'];
    type: ProductPlanModel['type'];
    params: ProductPlanModel['params'];
  }>;
}
