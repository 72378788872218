import KibanaFilterMetadata from '../metadatas/osd-filter-metadata';
import { KibanaFilterType, ElasticFilterType, IElasticFilter } from '../filter-interfaces';
import KibanaFilterModel from '../models/osd-filter-model';

export default class BaseFilterParser {
  constructor(public type: KibanaFilterType, public elasticKey: ElasticFilterType) {}

  assertType(metadata) {
    return metadata && metadata.type === this.type;
  }

  getFieldName(subject: object) {
    return Object.keys(subject)[0];
  }

  /**
   * Parse elastic filter to Kibana filter metadata
   */
  getMetadata(filter: IElasticFilter, negate = false, indexPattern): KibanaFilterMetadata {
    throw new Error('MUST OVERRIDE');
  }

  /**
   * Parse elastic filter to Kibana filter
   */
  getFilter(metadata: KibanaFilterMetadata): KibanaFilterModel {
    throw new Error('MUST OVERRIDE');
  }
}
