import { Type } from 'class-transformer';

class TracingDailyUsageModel {
  usage: number;
  @Type(() => Date)
  date: Date;
}

export class TracingUsageResponseModel {
  numberOfSpans: number;
  startTime: number;
  endTime: number;
  dailyUsage: TracingDailyUsageModel[];
}
