import { cloneDeep } from 'lodash';
import { ISearchRequestObject } from '@logz-build/typescript';
import { EventModel } from '@logz-pkg/models';
import { ruleApiService } from '../rule/api.service';

export const populate = {
  withMitreTags: async (events: EventModel[]): Promise<EventModel[]> => {
    const eventsCopy = cloneDeep(events);
    const ruleIds = events.map(event => event.alertDefinitionId);
    const searchRequestObject: ISearchRequestObject = {
      filter: { ruleIds },
      pagination: { pageSize: 1000, pageNumber: 1 },
    };
    const rulesTags = (await ruleApiService.search(searchRequestObject)).results.reduce(
      (acc, cur) => ({ ...acc, [cur.id]: cur.mitreTags }),
      {},
    );

    eventsCopy.forEach(event => {
      event.mitreTags = rulesTags[event.alertDefinitionId];
    });

    return eventsCopy;
  },
};
