import { httpService } from '../../http/request.provider';

const _getUrl = (feature, version = 0) => `/state/v${version}/${feature}`;

const get = (feature, version = 0) =>
  httpService.get(_getUrl(feature, version), {}, { dontShowProgressBar: true }).then(({ data }) => data);

const set = (feature, value, version = 0) =>
  httpService.post(_getUrl(feature, version), value, { dontShowProgressBar: true }).then(({ data }) => data);

export const appStateService = {
  get,
  set,
};
