import KibanaFilterMetadata from '../metadatas/osd-filter-metadata';
import KibanaFilterModel from './osd-filter-model';

export default class LessThanFilterModel extends KibanaFilterModel {
  public range: {
    [key: string]: {
      gte: number;
      lt: number;
    };
  };

  constructor(metadata: KibanaFilterMetadata) {
    super(metadata);

    const [from, to] = metadata.value.split('to').map(item => Number(item));

    this.range = {
      [metadata.key]: {
        gte: null,
        lt: isNaN(to) ? null : to,
      },
    };
  }
}
