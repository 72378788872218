import { KibanaFilterType } from '../filter-interfaces';
import KibanaFilterMetadata from './osd-filter-metadata';

export default class KibanaLookupFilterMetadata extends KibanaFilterMetadata {
  constructor(
    public id: string,
    public name: string,
    key: string,
    negate = false,
    public alertEventId: string = null,
    indexPattern: string,
    alias: string = null,
    disabled = false,
  ) {
    super(key, `lookup: ${name}`, KibanaFilterType.Lookup, negate, indexPattern, alias, disabled);
  }
}
