/* eslint-disable max-lines */
import 'reflect-metadata';
import { serializeError } from 'serialize-error';
import { cloneDeep } from 'lodash';
import { LogLevels, UxType, Origin } from '@logz-pkg/enums';
import { urlParser } from '@logz-pkg/utils';
import { httpService } from '../http/request.provider';
import { pageConfigReader } from '../environment/config-reader';
import { sessionIdService } from '../session-id/session-id.service';
import { authTokens } from '../auth/tokens';
import { Env } from '../environment';
import { sessionStateService } from '../../state/session.state.service';
import { IExtractXhrData, ITheLog } from './logger.interface';

// we want to filter out errors coming from chrome extensions as they are clutter our logs and not relevant as real erros
// https://logzio.atlassian.net/browse/DEV-42400
const CHROME_EXTENSION_ERROR_REGEX = /chrome-extension:\/\/\w{32}/;

class Log {
  public theLog: ITheLog = {};
  constructor({ logLevel, origin, error, extra, message, uxType, pageReload, manualErrorRef, category }: ITheLog) {
    this.theLog = { logLevel, origin, error, message, uxType, pageReload, manualErrorRef, category };
    this.theLog.type = 'app-ui';
    this.theLog.clientVersion = this.getVersion();
    this.addExtra(extra);
    this.addLocationHash();
    this.addMemoryFootprint();
    this.addUsage();

    if (manualErrorRef && manualErrorRef.stack) {
      manualErrorRef.stack = Log.removeLoggerServiceFileFromCallstack(manualErrorRef.stack);

      if (!manualErrorRef.name) manualErrorRef.name = error.name || 'Error';
    }

    if (!this.theLog.userAgent && window.navigator) this.theLog.userAgent = window.navigator.userAgent;
  }
  static stringify(field) {
    return field && typeof field !== 'string' ? JSON.stringify(field) : field;
  }
  static removeLoggerServiceFileFromCallstack(stack) {
    let splitStack = stack.split('\n');

    splitStack.splice(1, 1);
    splitStack = splitStack.join('\n');

    return splitStack;
  }

  static async logIt(log) {
    // Prevent bursts of logs
    const MAX_CONCURRENT_LOGS = 5;
    let concurrentLoggerCount = 0;

    try {
      if (Env.configs.logToConsole) log.logToConsoleFallback();

      const flattenLog = await log.enrichAndFlatten();

      if (CHROME_EXTENSION_ERROR_REGEX.test(flattenLog?.error?.stack)) return;

      if (concurrentLoggerCount >= MAX_CONCURRENT_LOGS)
        console.log('Reached Max Concurrent Messages - Logging to console', flattenLog);

      concurrentLoggerCount += 1;

      return await httpService.post('/logger/log', flattenLog, { dontShowProgressBar: true }).finally(() => {
        concurrentLoggerCount -= 1;
      });
    } catch (ex) {
      const flattenLog = await log.enrichAndFlatten();

      return console.log('Failed to send log: ', flattenLog, ex);
    }
  }

  async logIt() {
    return Log.logIt(this);
  }

  addMemoryFootprint() {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    // A non-standard extension added in Chrome, this property provides an object with basic memory usage information. You should not use this non-standard API.
    const memory = performance && performance.memory;

    if (!memory) return;

    this.theLog.performance = {
      memory: {
        totalJSHeapSize: memory.totalJSHeapSize,
        usedJSHeapSize: memory.usedJSHeapSize,
      },
    };
  }

  flatten() {
    const flatten = cloneDeep(this.theLog);

    delete flatten.manualErrorRef;

    flatten.message = Log.stringify(this.theLog.message);

    this.serializeError(flatten);

    return flatten;
  }

  getVersion() {
    return pageConfigReader.getValue('VERSION');
  }

  serializeError(flatten) {
    let { error } = this.theLog;

    if (!error || ['string', 'number'].includes(typeof error)) {
      error = {
        message: error || this.theLog.message || 'unknown error',
      };
    }

    if (this.theLog.manualErrorRef && this.theLog.manualErrorRef.stack) {
      if (!error.stack) error.stack = this.theLog.manualErrorRef.stack;

      if (!error.name) error.name = this.theLog.manualErrorRef.name;
    }

    if (error.requestId) flatten.requestId = error.requestId;

    if (error.message && error.stack) {
      try {
        flatten.error = serializeError(error);
        flatten._logzio_js_stacktrace = !error.isAxiosError;
      } catch (e) {
        console.error('failed to serializeError', e, error);
      }
    }
  }

  async enrichAndFlatten() {
    await this.enrich();

    return this.flatten();
  }

  async enrich() {
    try {
      this.theLog.sessionId = sessionIdService.getSessionId();

      if (this.theLog.userSummary) return this.theLog;

      const { loggedInUser, userSummary, userSession } = sessionStateService.session.get();

      if (loggedInUser) {
        this.theLog.userSummary = cloneDeep(userSummary);
      } else if (authTokens.isInShareMode()) {
        const sessionAsSummary = cloneDeep(userSession);

        delete sessionAsSummary.userToken;

        this.theLog.userSummary = sessionAsSummary;
      }
    } catch (e) {}

    return this.theLog;
  }

  addExtra(extra) {
    if (typeof extra !== 'object') return;

    Object.assign(this.theLog, extra);
  }

  addUsage() {
    Object.assign(this.theLog, { inIframe: window.self !== window.top });
  }

  addLocationHash() {
    const { hashPathname } = urlParser(window.location.href);

    this.theLog.locationHash = hashPathname;
    this.theLog.url = window.location.href;
  }

  toString({ includeError = true } = {}) {
    const flatten = this.flatten();

    try {
      flatten.error = JSON.stringify(flatten.error);
    } catch (e) {}

    const logToString = {
      Level: flatten.logLevel,
      Origin: flatten.origin,
      Message: flatten.message,
      uxType: flatten.uxType,
      Reload: flatten.pageReload,
      Error: flatten.error,
    };

    if (includeError && flatten.error) logToString.Error = flatten.error;

    return Object.entries(logToString)
      .filter(([, value]) => value)
      .map(([key, value]) => `${key.padEnd(7)}: ${value}`)
      .join('\n');
  }

  async logToConsoleFallback() {
    await this.enrich();

    if (this.theLog.logLevel === LogLevels.ERROR) {
      if (Env.configs.logObjectToConsole) {
        console.error(`Logging to console: \n${this.toString({ includeError: false })}\n`, this.theLog);

        // Logging to console separately so chrome will parse the stacktrace
        return console.error(this.theLog.error);
      }

      return console.error(`Logging to console: \n${this.toString()}\n`, this.theLog);
    }

    return console.log(`Logging to console: \n${this.toString()}\n`, this.theLog);
  }
}

class XhrLog extends Log {
  constructor({ logLevel, origin, error, message, uxType, pageReload, manualErrorRef, category }: ITheLog) {
    super({
      logLevel,
      origin,
      uxType,
      pageReload,
      manualErrorRef,
      category,
    });

    let extractedXhr = XhrLog.extractXhrData(error);

    extractedXhr = {
      ...extractedXhr,
      source: message,
      data: (extractedXhr.data =
        typeof extractedXhr.data === 'string' ? { message: extractedXhr.data } : extractedXhr.data),
      requestId: error.config.headers['X-REQUEST-ID'],
    };
    this.addExtra(extractedXhr);

    this.theLog.message = `Request Failed (${message}) - ${extractedXhr.method.toUpperCase()} path: ${extractedXhr.url}`;

    // manually add error name to stack since no real error is thrown here
    if (manualErrorRef && manualErrorRef.stack) this.theLog.manualErrorRef.name = 'RequestError';
  }

  static extractXhrData(error): IExtractXhrData {
    const {
      data,
      config: { url, method },
      status,
    } = error;

    return { data, url, statusCode: status, method };
  }
}

export const LoggerService = {
  UxType,
  Origin,
  logInfo: ({ origin = Origin.APP, message, extra, uxType = UxType.NONE, pageReload = false }: ITheLog) =>
    new Log({ logLevel: LogLevels.INFO, origin, extra, message, uxType, pageReload }).logIt(),
  logWarn: ({ origin = Origin.APP, message, extra, uxType = UxType.NONE, pageReload = false }: ITheLog) =>
    new Log({ logLevel: LogLevels.WARN, origin, extra, message, uxType, pageReload }).logIt(),
  logError: ({
    origin = Origin.APP,
    message,
    error,
    uxType = UxType.NONE,
    pageReload = false,
    extra,
    isFailedRequest = false,
    category,
  }: ITheLog & { isFailedRequest?: boolean }) => {
    const manualErrorRef = new Error(`${message || error.message || 'Manually generated stacktrace'}`);
    const logObj = {
      logLevel: LogLevels.ERROR,
      origin,
      error,
      message,
      extra,
      uxType,
      pageReload,
      manualErrorRef,
      category,
    };

    return new (isFailedRequest ? XhrLog : Log)(logObj).logIt();
  },
};

export default function loggerService() {
  return LoggerService;
}
