import { IntegrationType, WelcomePageUserRole } from '@logz-pkg/enums';
import { TelemetryAgentSubtypeModel } from '../telemetry-agent/configuration';
import { TelemetryAgentIntegrationType } from '../telemetry-agent/enums';
import { ManifestIntegrationItemModel } from './manifest-item';

export class IntegrationsManifestModel {
  collectors: ManifestIntegrationItemModel[] = [];
  tags: string[] = [];
}

export type HubIntegration = IntegrationCollector | TelemetryAgentCollector;

export class HubIntegrationsManifest {
  integrations: HubIntegration[];
  tags: string[];
}

export class GroupedTagCollectors {
  collectors: HubIntegrationsManifest['integrations'] = [];
  title: string;
}

export class CollectorModel extends ManifestIntegrationItemModel {
  markdown: string;
}

export class IntegrationCollector extends ManifestIntegrationItemModel {
  integrationType: IntegrationType = IntegrationType.COLLECTOR;
}

export class TelemetryAgentCollector extends TelemetryAgentSubtypeModel {
  integrationType: IntegrationType = IntegrationType.AGENT;
  type?: TelemetryAgentIntegrationType;
  categoryName: string;
  title: string;
  id: string;
  recommendedFor?: WelcomePageUserRole[];
  osTags: string[];
  bundle?: string[];
}
