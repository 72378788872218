import { Type } from 'class-transformer';
import { GrafanaAlertState } from './grafana-rules';

export class GrafanaAnnotationModel<P = any> {
  alertId: number;
  alertName: string;
  avatarUrl: string;
  created: number;
  dashboardId: number;
  data: P;
  email: string;
  id: number;
  login: string;
  newState: GrafanaAlertState;
  panelId: number;
  prevState: GrafanaAlertState;
  tags: string[];
  text: string;
  @Type(() => Date)
  time: Date;
  @Type(() => Date)
  timeEnd: Date;
  @Type(() => Date)
  updated: Date;
  userId: number;
}
