import { ICollectionResponse, ISearchRequestObject } from '@logz-build/typescript';
import { EventDetailsModel, EventModel } from '@logz-pkg/models';
import { ICrudApiService } from '../utilities/types';
import { CrudApiService } from '../utilities/crud.api.service';
import { populate } from './populate';

export class EventDetailsApiService implements ICrudApiService<EventDetailsModel> {
  constructor(public eventId: EventModel['id']) {}

  crudApiService = new CrudApiService<EventDetailsModel>({ modelDefinition: EventDetailsModel });

  get urls() {
    return {
      search: `/event-manager/events/${this.eventId}/details`,
    };
  }

  search = (requestObject: ISearchRequestObject): Promise<ICollectionResponse<EventDetailsModel>> =>
    this.crudApiService.search(this.urls.search, requestObject);
}

export class CorrelatedEventDetailsApiService {
  constructor(public eventId: EventModel['id']) {}

  crudApiService = new CrudApiService<EventDetailsModel>({ modelDefinition: EventDetailsModel });

  get urls() {
    return {
      search: `/v2/event-manager/siem/events/${this.eventId}/details`,
    };
  }

  search = async (requestObject: ISearchRequestObject): Promise<ICollectionResponse<EventDetailsModel>> => {
    const response = await this.crudApiService.search(this.urls.search, requestObject);

    response.results = await populate.withMitreTags(response.results as EventModel[]);

    return response;
  };
}
