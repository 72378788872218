import { AuthenticationGroupModel, AuthenticationPermissionModel } from '@logz-pkg/models';
import { ICrudApiService } from '../utilities/types';
import { CrudApiService } from '../utilities/crud.api.service';
import { handleDalError } from '../utilities/utilities';

export class AuthenticationGroupsApiService implements ICrudApiService<AuthenticationGroupModel> {
  crudApiService = new CrudApiService({ modelDefinition: AuthenticationGroupModel });
  isAuthGroupsEnabledCrudApiService = new CrudApiService({ modelDefinition: AuthenticationPermissionModel });

  urls = {
    collection: '/authentication/groups',
    permissions: '/app/permissions/authentication',
  };

  getAll = async () => this.crudApiService.getAll(this.urls.collection);

  create = async (authenticationGroup: AuthenticationGroupModel): Promise<AuthenticationGroupModel> => {
    const authGroups = await this.getAll();

    return this.crudApiService.do(this.urls.collection, { payload: [...authGroups, authenticationGroup] });
  };

  delete = async (_, authenticationGroup: AuthenticationGroupModel): Promise<AuthenticationGroupModel> => {
    const authGroups = await this.getAll();
    const isExist = authGroups.some(groupElement => groupElement.id === authenticationGroup.id);

    if (!isExist) {
      handleDalError(new Error('Authentication group not found'));
    }

    const filteredState = authGroups.filter(({ group }) => group !== authenticationGroup.group);

    return this.crudApiService.do(this.urls.collection, { payload: filteredState });
  };
  update = async (authenticationGroup: AuthenticationGroupModel): Promise<AuthenticationGroupModel> => {
    const authGroups = await this.getAll();
    const groupIndex = authGroups.findIndex(groupElement => groupElement.id === authenticationGroup.id);

    if (groupIndex < 0) {
      handleDalError(new Error('Authentication group not found'));
    }

    authGroups[groupIndex] = authenticationGroup;

    return this.crudApiService.do(this.urls.collection, { payload: authGroups });
  };

  isAuthenticationGroupsEnabled = async (): Promise<boolean> => {
    const response = await this.isAuthGroupsEnabledCrudApiService.get(this.urls.permissions, { cache: true });

    // Having enterprise connection in this case means that there is SSO configured to the current account
    return response.hasEnterpriseConnection;
  };
}
export const authenticationGroupsApiService = new AuthenticationGroupsApiService();
