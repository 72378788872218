import { Expose } from 'class-transformer';
import { ProductPlanModel } from './product-plan';

export class ProductPlansArray extends Array<ProductPlanModel> {
  constructor(productPlans: Array<ProductPlanModel>) {
    super(...(Array.isArray(productPlans) ? productPlans : []));

    Object.setPrototypeOf(this, Object.create(ProductPlansArray.prototype));
  }

  @Expose({ toClassOnly: true })
  getProduct?(product: ProductPlanModel['name']): ProductPlanModel {
    return this.find(({ name }) => name === product);
  }
  @Expose({ toClassOnly: true })
  getRequestParams?(): ProductPlansArray {
    return this.map(({ name, type, params }) => ({ name, type, params }));
  }

  @Expose({ toClassOnly: true })
  clone?(): ProductPlansArray {
    return new ProductPlansArray([...this]);
  }

  @Expose({ toClassOnly: true })
  getTotalPrice?(taxFees = 0): number {
    return (
      this.map(product => product?.price).reduce((totalPrice, productPrice) => totalPrice + productPrice, 0) +
      (taxFees ?? 0)
    );
  }
}
