import { Rationale, InvocationInput } from '@aws-sdk/client-bedrock-agent-runtime';
import { HomeDashboardEventType, AIDataSource } from '@logz-pkg/enums';
import { opensearchtypes } from '@opensearch-project/opensearch';
import { ServerStreamDataChunk } from '../server-stream/server-stream';
import { Insight } from '../insight/insight';
import { HomeDashboardEvent } from '../home-dashboard/home-dashboard';

export class AiRecord {
  content: string;
  role: AiChatRoles;
}

export enum AiChatRoles {
  User = 'user',
  AI = 'ai',
  Assistant = 'assistant',
}

export type ChatResponse = ServerStreamDataChunk & Partial<Rationale> & Partial<InvocationInput>;

export class ChatChoice {
  message?: {
    role: AiChatRoles;
    content: string | null;
  };
}

export type ChatRole = 'User' | 'Ai' | 'System' | 'Asisstant';
export type RecordType =
  | 'Error'
  | 'Loading'
  | 'User'
  | 'Conclusion'
  | 'Collapse'
  | 'Assistant'
  | 'Log'
  | 'SystemInsights'
  | `${HomeDashboardEventType}`;

export type SearchChatRecord = {
  content: string;
  type: RecordType;
  role: ChatRole;
  outOfContext?: boolean;
  isDoneContent?: boolean;
  createdAt?: string;
};
export type InvocationAgentChatRecord = {
  functionName?: ChatResponse['actionGroupInvocationInput']['function'];
  parameters?: ChatResponse['actionGroupInvocationInput']['parameters'];
};

export type AgentChatRecord = SearchChatRecord & {
  title?: string;
  subTitle?: string;
  information?: Insight | opensearchtypes.SearchHit['_source'] | HomeDashboardEvent;
  isLastBeforeConclusion?: boolean;
  invocationFunctions?: InvocationAgentChatRecord[];
};

export type AiHistoryItemModel = AgentChatRecord & {
  id: string;
  index: number;
  createdAt: Date;
  convId: string;
};

export interface IAiConversationModel {
  id: string;
  createdAt: Date;
  updatedAt: Date;
  title: string;
  accountId: number;
  userId?: number;
  dataSource: AIDataSource;
  sessionId?: string;
  rcaId?: string;
  triggerRole?: 'Manual' | 'Proactive';
}

export type InsightsTableRow = Insight & { conv: IAiConversationModel };

export type ConversationsList = IAiConversationModel[];

export type GroupedByDateConversations = {
  today?: ConversationsList;
  ['Previous 7 days']?: ConversationsList;
  ['Previous 30 days']?: ConversationsList;
  older?: ConversationsList;
};
