import { PLAN_TYPE, DateFormat } from '@logz-pkg/enums';
import moment from 'moment';
import { cloneDeep, isNil } from 'lodash';
import { PlanCapacityModel } from './plan-capacity';
import { PlanDetailsModel } from './plan-detail';

export class PlanDetailsModelManipulator {
  isTrial = (planDetails: PlanDetailsModel): boolean => planDetails?.planType === PLAN_TYPE.TRIAL;
  isFree = (planDetails: PlanDetailsModel): boolean => planDetails?.planType === PLAN_TYPE.FREE;
  isEnterprise = (planDetails: PlanDetailsModel): boolean => planDetails?.planType === PLAN_TYPE.ENTERPRISE;
  isPro = (planDetails: PlanDetailsModel): boolean => planDetails?.planType === PLAN_TYPE.PRO;

  trialDaysLeft = ({ planType, endDate }: PlanDetailsModel): number =>
    this.isTrial({ planType } as PlanDetailsModel) ? -1 * moment({ hours: 0 }).diff(endDate.valueOf(), 'days') : null;

  mergePlanCapacity = (planCapacity: PlanCapacityModel, planDetails: PlanDetailsModel): PlanDetailsModel => {
    const planDetailsClone = cloneDeep(planDetails);

    planDetailsClone.maxDailyGb = planCapacity.volumeGB;
    planDetailsClone.retentionDays = planCapacity.retentionDays;

    return planDetailsClone;
  };

  mergePlanType = (planType: PLAN_TYPE, planDetails: PlanDetailsModel): PlanDetailsModel => {
    const planDetailsClone = cloneDeep(planDetails);

    planDetailsClone.planType = planType;

    return planDetailsClone;
  };

  getFormattedStartDate = (planDetails: PlanDetailsModel): string =>
    planDetails.startDate.format(DateFormat.Date).toString();

  getMaxDailyGb = (planDetails: PlanDetailsModel): number =>
    planDetails.isFlexible ? planDetails.totalTimeBasedDailyGb : planDetails.maxDailyGb;

  getPlanVolumeGb = (planDetails: PlanDetailsModel): number => {
    if (planDetails.isFlexible && isNil(planDetails.totalTimeBasedDailyGb)) {
      // Flexible sub-accounts
      return planDetails.reservedDailyGb || 0;
    }

    return this.getMaxDailyGb(planDetails);
  };
}

export const planDetailsModelManipulator = new PlanDetailsModelManipulator();
