import { LookupListsAccountSettingsModel as LookupSettingsModel } from '@logz-pkg/models';
import { ICrudApiService } from '../utilities/types';
import { CrudApiService } from '../utilities/crud.api.service';

class LookupListsAccountSettingsApiService implements ICrudApiService<LookupSettingsModel> {
  crudApiService = new CrudApiService<LookupSettingsModel>({
    modelDefinition: LookupSettingsModel,
  });

  urls = {
    resource: () => `/lookup-lists/settings`,
  };

  get = () => this.crudApiService.get(this.urls.resource());
}

export const lookupListsAccountSettingsApiService = new LookupListsAccountSettingsApiService();
