import { Product, WelcomePageUserRole } from '@logz-pkg/enums';

export class ManifestIntegrationItemModel {
  id: string;
  title: string;
  recommendedFor?: WelcomePageUserRole[];
  logo: string;
  dataLink: string;
  productTags: Array<Product>;
  filterTags: Array<string>;
  description: string;
  osTags: string[];
  bundle: BundleModel[];
}

export class BundleModel {
  type: string;
  id: string;
}
