import * as yup from 'yup';
import { Product } from '@logz-pkg/enums';
import { TelemetryAgentParamType } from '../enums';

export const oneOfEnum = <T>(enumObject: T) => yup.mixed<T>().oneOf(Object.values(enumObject));

export const paramBaseSchema = yup.object().shape({
  type: oneOfEnum(TelemetryAgentParamType).required('param type required'), // this field's base schema is overridden in its extending schemas
  name: yup.string().required('param name required'),
  value: yup.mixed(), // this field's base schema is overridden in its extending schemas
});

export const paramMetaDataSchema = yup.object().shape({
  label: yup.string().required('param label required'),
  logo: yup.string().nullable(),
  description: yup.string().nullable(),
  hint: yup.string().nullable(),
  required: yup.boolean().nullable(),
});

export const selectOptionsSchema = yup
  .array()
  .of(
    yup.object({
      label: yup.string().required(),
      value: yup.mixed().required(),
    }),
  )
  .strict();

export const telemetryBaseSchema = yup.object().shape({
  type: yup.string().oneOf([Product.LogAnalytics, Product.Metrics, Product.Tracing]).required(),
  description: yup.string().nullable(),
  dashboards: yup.array().of(yup.string()).nullable(),
  params: yup.array().nullable(), // this field's base schema is overridden in its extending schemas
  otel: yup
    .object({
      processors: yup.array().of(yup.string()).nullable(),
      receivers: yup.array().of(yup.string()).nullable(),
    })
    .nullable(),
});
