import { classToPlain, plainToClass } from 'class-transformer';
import { cloneDeep } from 'lodash';
import { ArchiveAndRestoreStorageType, S3AuthenticationTypes } from '@logz-pkg/enums';
import { ArchiveSettingsModel } from '@logz-pkg/models';
import { CrudApiService } from '../utilities/crud.api.service';

interface IArchiveSettingsResponseData {
  id?: number;
  settings: ArchiveSettingsModel;
}

export const prepareModelForApiRequest = (model: ArchiveSettingsModel) => {
  const nextModel = cloneDeep(model);

  if (nextModel.storageType === ArchiveAndRestoreStorageType.AWS) {
    delete nextModel.blob;

    if (nextModel.s3.credentialsType === S3AuthenticationTypes.IAM) {
      delete nextModel.s3.secret;
    } else {
      delete nextModel.s3.iam;
    }

    if (nextModel.s3.secret?.secretKey === '') {
      nextModel.s3.secret.secretKey = null;
    }
  } else {
    delete nextModel.s3;

    if (nextModel.blob.clientSecret === '') {
      nextModel.blob.clientSecret = null;
    }
  }

  return nextModel;
};

class ArchiveSettingsApiService {
  transformationOptions = { version: 2 };
  crudApiService = new CrudApiService({
    transformationOptions: this.transformationOptions,
  });
  urls = {
    collection: '/v2/archive/settings',
    resource: (id: number) => `/v2/archive/settings/${id}`,
    test: '/v2/archive/settings/test',
  };

  private createModelFromResponseData(data: IArchiveSettingsResponseData) {
    const archiveSettings = plainToClass(ArchiveSettingsModel, data.settings, this.transformationOptions);

    archiveSettings.id = data.id;

    return archiveSettings;
  }

  get = async (): Promise<ArchiveSettingsModel> => {
    const archiveSettingsList = (await this.crudApiService.getAll(
      this.urls.collection,
    )) as IArchiveSettingsResponseData[];

    if (archiveSettingsList.length) {
      return this.createModelFromResponseData(archiveSettingsList[0]);
    }

    throw { errorCode: 'ENTITY_NOT_FOUND', message: 'Settings Not Found' };
  };

  update = async (model: ArchiveSettingsModel): Promise<ArchiveSettingsModel> => {
    const data = (await this.crudApiService.update(
      this.urls.resource(model.id),
      classToPlain(prepareModelForApiRequest(model), this.transformationOptions),
    )) as IArchiveSettingsResponseData;

    return this.createModelFromResponseData(data);
  };

  create = async (model: ArchiveSettingsModel): Promise<ArchiveSettingsModel> => {
    const data = (await this.crudApiService.create(
      this.urls.collection,
      classToPlain(prepareModelForApiRequest(model), this.transformationOptions),
    )) as IArchiveSettingsResponseData;

    return this.createModelFromResponseData(data);
  };

  test = async (model: ArchiveSettingsModel): Promise<void> => {
    const payload = {
      archiveSettings: classToPlain(prepareModelForApiRequest(model), this.transformationOptions),
      id: model.id,
    };

    return this.crudApiService.do(this.urls.test, { payload });
  };

  delete = (id: ArchiveSettingsModel['id']) => this.crudApiService.delete(this.urls.resource(id));
}

export const archiveSettingsApiService = new ArchiveSettingsApiService();
