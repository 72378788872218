import * as yup from 'yup';
import { TelemetryAgentParamType } from '../enums';
import { oneOfEnum, paramBaseSchema, paramMetaDataSchema } from './shared.schemas';

const keyValueSchema = yup.object().shape({
  key: yup.string().required('key is required'),
  value: yup.string().required('value is required'),
});

const keyValueParamBaseSchema = paramBaseSchema.shape({
  type: oneOfEnum({ type: TelemetryAgentParamType.KeyValue }).required('param type required'),
  value: yup.array().of(keyValueSchema.strict()).strict().nullable(), // this field's base schema is overridden in its extending schemas
});

export const keyValueParamManifestSchema = keyValueParamBaseSchema.concat(paramMetaDataSchema).shape({
  value: yup.array().of(keyValueSchema.strict()).strict().nullable(),
});

export const keyValueParamSchema = keyValueParamBaseSchema.shape({
  value: yup.array().when(['required'], {
    is: req => req,
    then: yup.array().of(keyValueSchema).strict().min(1, 'at least one item is required').required('required'),
    otherwise: yup.array().of(keyValueSchema.strict()).strict().nullable(),
  }),
});
