import { SiemAccountModel } from '@logz-pkg/models';
import { ICrudApiService } from '../utilities/types';
import { CrudApiService } from '../utilities/crud.api.service';

class SiemAccountsApiService implements ICrudApiService<SiemAccountModel> {
  apiService = new CrudApiService({ modelDefinition: SiemAccountModel });
  urls = {
    trial: '/siem/accounts/trial',
    search: '/siem/accounts/search',
  };

  createTrialAccount = () => this.apiService.do<SiemAccountModel>(this.urls.trial);
  search = (searchObject = {}) => this.apiService.search(this.urls.search, searchObject);
}

export const siemAccountsApiService = new SiemAccountsApiService();
