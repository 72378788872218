import { AIDataSource, AgentType } from '@logz-pkg/enums';
import { dateToAbsolute, isRelativeDate } from '@logz-pkg/utils';
import moment from 'moment';
import {
  AgentChatRecord,
  AiChatRoles,
  ChatResponse,
  InvocationAgentChatRecord,
  RecordType,
  SearchChatRecord,
} from './ai-record';

export type BedrockMessage = {
  role: AiChatRoles;
  content:
    | string
    | {
        [value: string]: any;
      };
  userInput?: string;
  type?: 'summary' | 'proSummary' | 'conclusionSummary' | 'proConclusionSummary' | 'dynamicQuestions';
};

export type ReturnBedrockMessage = {
  message: {
    content: string;
    type: string;
  };
}[];

export type AgentRequestBody = {
  message: BedrockMessage;
  sessionId: string;
  tabSessionId: string;
  shouldStart: boolean;
  reqIndex: number;
  agentType?: AIDataSource | AgentType; // AgentType - backwards compatibility;
};

type KnowledgeBaseDataSource = 'shipping' | 'userGuide';

export type KnowledgeBaseQuestion = {
  dataSource: KnowledgeBaseDataSource;
  sessionId?: string;
  text: string;
};
export type InvocationFunctionName =
  | 'logzio-find-deployments'
  | 'group-by-with-date-histogram'
  | 'search-with-date-histogram'
  | 'get_grouped_by_buckets'
  | 'get_logs_histogram'
  | 'get_logs_samples'
  | 'logzio-search-logs-using-lambda'
  | 'get-workload-list'
  | 'get-workload-metrics';

const buildAgentRecordByIndex = ({
  index,
  chatResponse,
}: {
  index: number;
  chatResponse: ChatResponse;
}): AgentChatRecord => {
  const { message } = chatResponse;
  const title = message.slice(0, 140);
  const messageLength = index;
  const stepNumberToSubTitle = messageLength >= 10 ? messageLength : `0${messageLength}`;
  const subTitle = `Step ${stepNumberToSubTitle}`;

  return { type: 'Collapse', role: 'Ai', content: message, subTitle, title };
};

export const addAgentInvocation = (chatResponse: ChatResponse, historyItems: AgentChatRecord[]) => {
  const data = chatResponse;
  const { actionGroupInvocationInput } = data;
  const { function: functionName, parameters } = actionGroupInvocationInput;
  const invocation = { functionName, parameters } as InvocationAgentChatRecord;

  ['start_time', 'end_time'].forEach(key => {
    const parameter = parameters.find(param => param.name === key);

    if (parameter && isRelativeDate(parameter.value)) {
      parameter.value = `${dateToAbsolute(parameter.value) * 1000}`;
    }
  });

  const last = historyItems.at(-1) as AgentChatRecord;

  last.invocationFunctions = last?.invocationFunctions ? [...last.invocationFunctions, invocation] : [invocation];
};

const getLoadingComponents = ({
  prompt,
  componentType,
  context,
  dataSource,
}: {
  prompt: string;
  context: unknown;
  componentType: RecordType;
  dataSource: AIDataSource;
}): (AgentChatRecord | SearchChatRecord)[] => {
  const addToState: AgentChatRecord = {
    content: prompt,
    role: 'User',
    type: componentType,
    createdAt: moment().format(),
  };

  if (context) {
    addToState.information = context;
  }

  const loadingPrompt: SearchChatRecord = {
    content: dataSource === AIDataSource.RCA ? 'Root cause analysis' : 'Answer',
    role: 'System',
    type: 'Loading',
  };

  return [addToState, loadingPrompt];
};

const generateRandomString = (length: number): string =>
  Array.from({ length }, () => Math.random().toString(36)[2]).join('');

const getNewSessionId = (): string => `logz-observability-ai-${generateRandomString(16)}`;

export const bedrockUtils = {
  getNewSessionId,
  generateRandomString,
  buildAgentRecordByIndex,
  addAgentInvocation,
  getLoadingComponents,
};
