import { Expose, Transform } from 'class-transformer';

class QueryStringModel {
  query: string;
  fields: string[];
  type: string;
  default_operator: string;
  max_determinized_states: number;
  enable_position_increments: boolean;
  fuzziness: string;
  fuzzy_prefix_length: number;
  fuzzy_max_expansions: number;
  phrase_slop: number;
  escape: boolean;
  auto_generate_synonyms_phrase_query: boolean;
  fuzzy_transpositions: boolean;
  boost: number;
}

class FilterBoolModel {
  query_string?: QueryStringModel;
  term?: Record<string, any>;
  terms?: Record<string, any>;
  bool?: Record<string, any>;
  match_phrase?: Record<string, any>;
  exists?: Record<string, any>;
  lookup?: Record<string, any>;
}

class BoolModel {
  must: FilterBoolModel[];
  should: unknown[];
  filter: unknown[];
  must_not: FilterBoolModel[];
}

class QueryFiltersModel {
  bool: BoolModel;
}

class QueryAggregationModel {
  aggregationType: string;
  fieldToAggregateOn: string;
  valueToAggregateOn: string;
}

class QueryModel {
  query: string;
  filters: QueryFiltersModel;
  groupBy: string[];
  aggregation: QueryAggregationModel;
  shouldQueryOnAllAccounts: boolean;
  accountIdsToQueryOn: number[];
}

export class AlertEventDetailsModel {
  @Expose({ name: 'alertEventId' })
  id: string;
  @Transform((value: string | number): number => parseInt(value.toString(), 10) * 1000, { toClassOnly: true })
  fromDate: number;
  @Transform((value: string | number): number => parseInt(value.toString(), 10) * 1000, { toClassOnly: true })
  toDate: number;
  query: QueryModel;
}
