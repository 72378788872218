import { SiemAccountV2Model } from '@logz-pkg/models';
import { ICrudApiService } from '../utilities/types';
import { CrudApiService } from '../utilities/crud.api.service';

class SiemAccountV2ApiService implements ICrudApiService<SiemAccountV2Model> {
  crudApiService = new CrudApiService({ modelDefinition: SiemAccountV2Model });
  urls = {
    collection: '/siem/v2/accounts',
    resource: (id: number): string => `/siem/v2/accounts/${id}`,
  };

  get = async (id: number): Promise<SiemAccountV2Model> => this.crudApiService.get(this.urls.resource(id));
  getAll = async (): Promise<SiemAccountV2Model[]> => {
    return this.crudApiService.getAll(this.urls.collection);
  };
  update = (model: SiemAccountV2Model): Promise<SiemAccountV2Model> => {
    return this.crudApiService.update(this.urls.resource(model.id), model);
  };
  create = (model: SiemAccountV2Model): Promise<SiemAccountV2Model> => {
    return this.crudApiService.create(this.urls.collection, model);
  };

  delete = (accountId: number): Promise<SiemAccountV2Model> => {
    return this.crudApiService.delete(this.urls.resource(accountId));
  };
}

export const siemAccountsApiServiceV2 = new SiemAccountV2ApiService();
