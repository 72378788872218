import { Exclude } from 'class-transformer';

class MetricUsageInfoResource {
  name: string; // dashboard name
  id: string; // dashboard identifier (ID or UID TBD)
}

export type MetricUsageType = 'DASHBOARD' | 'ALERT' | 'QUERY' | 'NOT_USED';

export class MetricUsageInfo {
  usageType: MetricUsageType;
  resources: Array<MetricUsageInfoResource>;
}

export class MetricMetaData {
  approximateCardinality: boolean; // if false - metric cardinality is accurate; otherwise - it displays just the limit of returned TS from m3db and actual value is bigger
  active: boolean; // is metric active
  usage: Array<MetricUsageInfo>;
}

export class MetricLabelCardinality {
  labelName: string;
  cardinality: number;
}

export class MetricsAccountData {
  accountId: number;
  accountName: string;
}

export class MetricInfoModel {
  @Exclude()
  id: string;

  metricName: string;
  cardinality: number;
  prevCardinality: number | null;
  percentageOfTotal: number;
  metadata: MetricMetaData;
  labelCardinality: Array<MetricLabelCardinality>;
  accounts: Array<MetricsAccountData>;

  @Exclude()
  isDroppingInProgress: boolean;
}
