import { configs } from './configs';
import { pageConfigReader } from './config-reader';

const INJECTED_ENV_FROM_NODE = '<${INJECTED_ENV_FROM_NODE}>';
const windowInstance: any = window;

export enum Environments {
  PROD = 'PROD',
  STAGING = 'STAGING',
  ITEST = 'ITEST',
  LOCAL = 'LOCAL',
  UNIT_TEST = 'UNIT_TEST',
  REMOTE_STAGING = 'REMOTE_STAGING',
  REMOTE_PROD = 'REMOTE_PROD',
}

export interface IFrontendConfigs {
  cookieSuffix?: string;
  mockAuth0?: boolean;
  errorOnUnhandledRejections?: boolean;
  angularDebugMode?: boolean;
  redirectToMarketingRegistration?: boolean;
  reportUserAnalytics?: boolean;
  fullstory?: boolean;
  overrideSystemRegions?: boolean;
  logToConsole?: boolean;
  logObjectToConsole?: boolean;
  overrideConsole?: boolean;
  storeDevTools?: boolean;
  marketo?: boolean;
  intercom?: boolean;
  tours?: boolean;
  disableUrlValidation?: boolean;
  uiRouteTracing?: boolean;
  debugProductIframe?: boolean;
  debugHttpInterceptors?: boolean;
  mockTours?: boolean;
  adminPanel?: boolean;
  captureAnalytics?: boolean;
}

class Environment {
  public configs: IFrontendConfigs;
  private env: Environments;

  constructor() {
    this.initEnv();
    this.initConfig();
    this.mergeEnvOverride();
  }

  initConfig(): void {
    this.configs = configs[this.env];
    this.configs.adminPanel = pageConfigReader.getValue('ADMIN_PANEL') === 'true';
  }
  private initialEnv(): string {
    const DOCKER_FRONTEND_ENV_PLACEHOLDER = pageConfigReader.getValue('DOCKER_FRONTEND_ENV_PLACEHOLDER');
    const BUNDLER_TARGET = pageConfigReader.getValue('BUNDLER_TARGET');

    if (DOCKER_FRONTEND_ENV_PLACEHOLDER && DOCKER_FRONTEND_ENV_PLACEHOLDER !== INJECTED_ENV_FROM_NODE)
      return DOCKER_FRONTEND_ENV_PLACEHOLDER;

    if (BUNDLER_TARGET) return BUNDLER_TARGET;

    if (windowInstance.isUnit) return Environments.UNIT_TEST;

    return Environments.LOCAL;
  }

  private initEnv(): void {
    const env = this.initialEnv();
    const envUppercase = env.toUpperCase() as Environments;

    if (Object.values(Environments).includes(envUppercase)) {
      this.env = envUppercase;

      console.debug(`Environment is ${this.env}`);

      return;
    }

    console.error(`Environment configuration seems to be wrongly named: ${envUppercase}`);
    this.env = Environments.LOCAL;
  }

  mergeEnvOverride(): void {
    const REMOTE = pageConfigReader.getValue('BUNDLER_TARGET_OVERRIDE');

    let overrideConfig = {};

    if (REMOTE?.toUpperCase() === Environments.STAGING) {
      overrideConfig = configs.REMOTE_STAGING;
    }

    if (REMOTE?.toUpperCase() === Environments.PROD) {
      overrideConfig = configs.REMOTE_PROD;
    }

    this.configs = {
      ...this.configs,
      ...overrideConfig,
    };
  }
}

export const Env = new Environment();
