import { IElasticFilter } from '../../../osd/filters/filter-interfaces';
import IsBetweenFilterParser from '../../../osd/filters/parsers/is-between-filter-parser';
import { ElasticsearchFieldModel } from '../../../elasticsearch/elasticsearch-field';
import { ElasticsearchFilterModel, ElasticsearchFilterOperator } from '../../../elasticsearch/elasticsearch-filter';
import KibanaFilterMetadata from '../../../osd/filters/metadatas/osd-filter-metadata';
import { BaseAlertFilterParser } from './base';

/**
 * Elastic to ElasticsearchFilterModel 'IS BETWEEN' filter parser
 */
export class AlertFilterIsBetweenFilterParser extends BaseAlertFilterParser {
  constructor() {
    super(new IsBetweenFilterParser());
  }

  getFilter(elasticFilter: object, negate: boolean): ElasticsearchFilterModel {
    const metadata: KibanaFilterMetadata = this.getMetadata(elasticFilter, negate);

    if (!metadata) return null;

    const filter = new ElasticsearchFilterModel();

    filter.type = ElasticsearchFilterOperator.IsBetween;
    filter.value = metadata.params;
    filter.negate = metadata.negate;
    filter.field = new ElasticsearchFieldModel();
    filter.field.name = metadata.key;

    return filter;
  }

  getElasticFilter(filter: ElasticsearchFilterModel): IElasticFilter {
    if (filter.type !== ElasticsearchFilterOperator.IsBetween) return null;

    return { [this.baseFilterParser.elasticKey]: { [filter.field.name]: filter.value } };
  }
}
