import { ICollectionResponse, ISearchRequestObject } from '@logz-build/typescript';
import { KibanaDashboardModel } from '@logz-pkg/models';
import { ICrudApiService } from '../../utilities/types';
import { CrudApiService } from '../../utilities/crud.api.service';

class KibanaDashboardApiService implements ICrudApiService<KibanaDashboardModel> {
  crudApiService = new CrudApiService({ modelDefinition: KibanaDashboardModel });

  urls = {
    search: '/api/kibana/dashboards/summary/search',
  };

  search = (requestObject: ISearchRequestObject) => this.crudApiService.search(this.urls.search, requestObject);

  searchByIds(ids: KibanaDashboardModel['id'][]): Promise<ICollectionResponse<KibanaDashboardModel>> {
    return this.search({
      filter: { dashboardIds: ids },
      pagination: { pageSize: ids.length || 1, pageNumber: 1 },
    });
  }
}

export const kibanaDashboardApiService = new KibanaDashboardApiService();
