import { MetricsSearchResponseModel } from '@logz-pkg/models';
import moment from 'moment';
import { ICrudApiService } from '../utilities/types';
import { CrudApiService } from '../utilities/crud.api.service';

const round = (date, rounding = 60 * 1000) => moment(Math.ceil(+date / rounding) * rounding);
const getTimeInSeconds = (hoursAgo = 0) => round(moment()).subtract(hoursAgo, 'hours').unix();
const getTimeRange = hoursAgo => `start=${getTimeInSeconds(hoursAgo)}&end=${getTimeInSeconds()}`;

interface IGetMetricLabels {
  accountId: number;
  metricName: string;
  fromHoursAgo?: number;
}

export class M3dbPrometheusApiService implements ICrudApiService<MetricsSearchResponseModel> {
  crudApiService = new CrudApiService<MetricsSearchResponseModel>();

  urls = {
    metrics: (accountId: number, timeRange: string) =>
      `m3db/prometheus/${accountId}/api/v1/label/__name__/values?${timeRange}`,
    labels: (accountId, metricName, timeRange) =>
      `m3db/prometheus/${accountId}/api/v1/labels?${timeRange}&match[]={__name__="${metricName}"}`,
    labelsByServiceName: (accountId, timeRange, serviceName, environments: string[]) =>
      `m3db/prometheus/${accountId}/api/v1/labels?${timeRange}&match[]={__name__="calls_total",service_name="${serviceName}"${
        environments ? `,env_id=~"${environments?.join('|')}"` : ''
      }}`,
  };

  getMetricsEnvironmentLabels = async ({ accountId, fromHoursAgo, serviceName, environments }): Promise<string[]> => {
    return this.crudApiService
      .get(this.urls.labelsByServiceName(accountId, getTimeRange(fromHoursAgo), serviceName, environments))
      .then(({ data }) => data);
  };
  getMetricsNames = async ({ accountId, fromHoursAgo = 14 * 24 }: Omit<IGetMetricLabels, 'metricName'>) =>
    this.crudApiService.get(this.urls.metrics(accountId, getTimeRange(fromHoursAgo))).then(({ data }) => data);

  getMetricLabels = async ({ accountId, metricName, fromHoursAgo = 14 * 24 }: IGetMetricLabels) =>
    this.crudApiService
      .get(this.urls.labels(accountId, metricName, getTimeRange(fromHoursAgo)), {
        cache: true,
        secondsToLive: 5 * 60,
      })
      .then(({ data }) => data);
}

export const m3dbPrometheusApiService = new M3dbPrometheusApiService();
