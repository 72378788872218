import { TimelessAccountModel, TimelessAccountRequestModel, TimelessDeleteDataModel } from '@logz-pkg/models';
import { CrudApiService } from '../../utilities/crud.api.service';
import { ICrudApiService } from '../../utilities/types';

class TimelessAccountManagementCrudApiService
  implements ICrudApiService<TimelessAccountModel, TimelessAccountRequestModel>
{
  apiService = new CrudApiService<TimelessAccountModel, TimelessAccountRequestModel>({
    modelDefinition: TimelessAccountModel,
  });

  urls = {
    collection: `/timeless-indices`,
    resource: (id: number) => `/timeless-indices/${id}`,
    deleteData: (id: number) => `/timeless-indices/${id}/delete-data`,
  };

  create = (model: TimelessAccountRequestModel) => this.apiService.create(this.urls.collection, model);

  update = (model: TimelessAccountRequestModel) => this.apiService.update(this.urls.resource(model.id), model);

  delete = (id: number) => this.apiService.delete(this.urls.resource(id));

  deleteData = (id: number, deleteParams: TimelessDeleteDataModel) =>
    this.apiService.do(this.urls.deleteData(id), { payload: deleteParams });
}

export const timelessAccountManagementCrudApiService = new TimelessAccountManagementCrudApiService();
