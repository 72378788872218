import { SeverityLevel } from '@logz-pkg/enums';
import { Expose, Transform } from 'class-transformer';

export class TriggeredAlertModel {
  @Expose({ name: 'logzio-alert-definition-id' }) definitionId: number;
  @Expose({ name: 'logzio-alert' }) condition: string;
  @Expose({ name: 'logzio-alert-description' }) description: string;
  @Expose({ name: 'logzio-alert-event-id' }) eventId: string;
  @Expose({ name: 'logzio-alert-group-ids' }) @Transform(object => object ?? {}) groupByIds: object;
  @Expose({ name: 'logzio-alert-timerange-start' }) fromDate: string;
  @Expose({ name: 'logzio-alert-timerange-end' }) toDate: string;
  @Expose({ name: 'logzio-alert-title' }) title: string;
  @Expose({ name: 'logzio-alert-triggered-severity' }) severity: SeverityLevel;
  @Expose({ name: 'logzio-event-muted' }) isMuted: boolean;
  @Expose({ name: 'logzio-hits' }) hits: number;
  @Expose({ name: 'logzio-investigate' }) investigateUrlParams: string;
  @Expose({ name: 'logzio-sub-component-index' }) subComponentIndex: number;
  @Expose({ name: 'lozgio-time-range-in-minutes' }) timeRangeInMinutes: number;
}
