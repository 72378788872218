import { Product, ProductPlanResponseStatus, ProductType } from '@logz-pkg/enums';

export class ProductPlanModel {
  name: Product;
  type: ProductType;
  params: Record<string, number>;
  priceBeforeDiscount?: number;
  discountPercentage?: number;
  price?: number;
  onDemandPrice?: number;
  hardCap?: number;
  responseStatus?: ProductPlanResponseStatus;

  constructor({ name, type, params, discountPercentage, price, priceBeforeDiscount, responseStatus }: ProductPlanModel) {
    this.name = name;
    this.type = type;
    this.params = params;
    this.priceBeforeDiscount = priceBeforeDiscount;
    this.discountPercentage = discountPercentage;
    this.price = price;
    this.responseStatus = responseStatus;
  }
}
