import { PlanUsageBucketModel, PlanDetailsModel } from '@logz-pkg/models';
import { ICrudApiService } from '../../utilities/types';
import { CrudApiService } from '../../utilities/crud.api.service';
import { sessionApiService } from '@logz-pkg/frontend-services';

class UsageResponse {
  usage: PlanUsageBucketModel[];
}

class PlanDetailsApiService implements ICrudApiService<PlanDetailsModel> {
  crudApiService = new CrudApiService({ modelDefinition: UsageResponse });
  urls = {
    usageBuckets: '/usage',
  };

  async get({ fresh } = { fresh: false }): Promise<PlanDetailsModel> {
    const { plan } = await sessionApiService.get({ fresh });

    return plan;
  }

  async getUsageBuckets(): Promise<PlanUsageBucketModel[]> {
    const { usage } = await this.crudApiService.get(this.urls.usageBuckets);

    return usage;
  }
}

export const planDetailsApiService = new PlanDetailsApiService();
