import { AccountType } from '@logz-pkg/enums';
import { RollupRuleModel } from '@logz-pkg/models';
import { cloneDeep } from 'lodash';
import { searchableAccountsApiService } from '../account/searchable/api.service';

const accountNames = async (records: RollupRuleModel[]): Promise<RollupRuleModel[]> => {
  const recordsClone = cloneDeep(records);

  const accounts = await searchableAccountsApiService.getAccountsByType(AccountType.Metrics);

  recordsClone.forEach(record => {
    record.accountName = accounts.find(account => account.id === record.accountId).name;
  });

  return recordsClone;
};

export const populate = { accountNames };
