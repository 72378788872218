import _ from 'lodash';
import { eventEmitter, EVENTS } from '../eventEmitter.service';
import { sharedObjects } from '../../core/identity/shared-objects.service';
import { cacheProvider } from '../../core/cache/cache.provider';
import { httpService } from '../../core/http/request.provider';

const NOTIFY_INDEXES_CHANGE = 'notifyIndexesChange';
const endpoint = 'searchable-accounts';

let searchableIndexes = [];

const getCurrentAccountSearchableIndex = () => _.find(searchableIndexes, { requestingAccount: true });

// +accountId turns from string to number (i.e +'7' === 7)
const getSearchableAccountByAccountId = accountId => _.find(searchableIndexes, { accountId: +accountId });

const getSearchableAccounts = () => [...searchableIndexes];
const clearCachedSearchableIndexes = () => cacheProvider.clear(cacheProvider.CacheKeys.SEARCHABLE_ACCOUNTS);
const clearSearchableCacheAndNotify = (res = null) => {
  clearCachedSearchableIndexes();
  eventEmitter.emit(EVENTS.NOTIFY_INDEXES_CHANGE);

  return res;
};

const getFreshSearchableIndexes = () => httpService.get(endpoint).then(({ data }) => data);

const getCachedSearchableIndexes = () =>
  cacheProvider
    .get(endpoint, getFreshSearchableIndexes, 300) // cache for 5 min
    .then(data => {
      searchableIndexes = data;

      return data;
    });

const getSearchableIndexByDocId = async docId => {
  const accountId = sharedObjects.parseAccountId(docId);

  if (!accountId) {
    return null;
  }

  return getCachedSearchableIndexes().then(res => {
    const filterAction = searchableIndex =>
      !searchableIndex.requestingAccount && searchableIndex.accountId === accountId; // filter tags to not be shown on main account

    const matchedAccounts = res.filter(filterAction);

    if (matchedAccounts.length) {
      return matchedAccounts[0];
    }

    return null;
  });
};

export const searchableIndexesService = {
  getCurrentAccountSearchableIndex,
  getSearchableAccountByAccountId,
  getSearchableAccounts,
  NOTIFY_INDEXES_CHANGE,
  clearSearchableCacheAndNotify,
  clearCachedSearchableIndexes,
  getCachedSearchableIndexes,
  getSearchableIndexByDocId,
};
