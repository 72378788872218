import { ICollectionResponse, ISearchRequestObject } from '@logz-build/typescript';
import { ThreatsIocModel as IocModel } from '@logz-pkg/models';
import { ICrudApiService } from '../../utilities/types';
import { CrudApiService } from '../../utilities/crud.api.service';

export class ThreatsIocApiService implements ICrudApiService<IocModel> {
  iocApiService = new CrudApiService({ modelDefinition: IocModel });
  urls = {
    search: '/compromise-indications/search',
  };

  search = async (requestObject: ISearchRequestObject): Promise<ICollectionResponse<IocModel>> =>
    this.iocApiService.search(this.urls.search, requestObject);
}

export const threatsIocApiService = new ThreatsIocApiService();
