import { CrudApiService } from '../utilities/crud.api.service';
import { ICrudApiService } from '../utilities/types';

class AnalyticsApiService implements ICrudApiService<unknown> {
  crudApiService = new CrudApiService({ modelDefinition: null });

  urls = {
    capture: '/analytics/capture',
    accountIdentify: 'analytics/identify-account',
    userIdentify: 'analytics/identify-user',
  };

  capture = (featureName: unknown, eventName: unknown, eventProps: Record<string, unknown>) =>
    this.crudApiService.do(this.urls.capture, {
      payload: { featureName, eventName, eventProps },
      meta: { dontShowProgressBar: true },
    });

  identifyAccount = (accountProps: Record<string, unknown>) =>
    this.crudApiService.do(this.urls.accountIdentify, {
      payload: { accountProps },
      meta: { dontShowProgressBar: true },
    });

  identifyUser = (userProps: Record<string, unknown>) =>
    this.crudApiService.do(this.urls.userIdentify, { payload: { userProps }, meta: { dontShowProgressBar: true } });
}

export const analyticsApiService = new AnalyticsApiService();
