import { AccountType } from '@logz-pkg/enums';

export class AccountRelationModel {
  ownerAccountId: number;
  subAccountId: number;
  searchable: boolean;
  accessible: boolean;
  createdDate: number;
  lastUpdatedDate: number;
  lastUpdaterUserId: number;
  type: AccountType;
}

export class AccountModel {
  accountId: number;
  accountName: string;
  active: boolean;
  esIndexPrefix: string;
  maxDailyGB?: number;
  reservedDailyGB: number;
  isFlexible: boolean;
  retentionDays: number;
  accountToken: string;
}

export class DetailedAccountModel {
  account: AccountModel;

  subAccountRelation?: AccountRelationModel;
  isCapped?: boolean;

  sharingObjectsAccounts: AccountModel[] = [];

  utilizationSettings: {
    frequencyMinutes?: number;
    utilizationEnabled: boolean;
  };

  dailyUsagesList: {
    usage: { date: number; bytes: number }[];
  };

  docSizeSetting: boolean;
  softLimitGB?: number;
  snapSearchRetentionDays?: number;
}

export class AccountRetentionDetailsModel {
  accountId: number;
  retentionDays: number;
  snapSearchRetentionDays?: number;
}
