import { isNil } from 'lodash';
import { getFilterValueFormat } from '../../elasticsearch/utils';
import {
  ElasticsearchEnhancedFilterModel,
  ElasticsearchEnhancedFilterOperator,
  IElasticsearchFilterValue,
} from '../../elasticsearch/elasticsearch-filter';
import { IElasticFilter } from '../../osd/filters/filter-interfaces';
import { ExploreExploreIsFilterParser } from './parsers/is';
import { BaseExploreFilterParser } from './parsers/base';
import { ExploreFilterExistsFilterParser } from './parsers/exists';
import { ExploreFilterIsOneOfFilterParser } from './parsers/is-one-of';
import { ExploreFilterLookupFilterParser } from './parsers/lookup';
import { ExploreFilterIsBetweenFilterParser } from './parsers/is-between';
import { ExploreFilterLessThanFilterParser } from './parsers/less-than';
import { ExploreFilterGreaterThanFilterParser } from './parsers/greater-than';
import { ExploreFilterLuceneFilterParser } from './parsers/lucene';

const isRangeFilter = (filterType: ElasticsearchEnhancedFilterOperator) =>
  filterType === 'IS_BETWEEN' || filterType === 'GREATER_THAN' || filterType === 'LESS_THAN';

class EnhancedFilterModelManipulator {
  private filterToElasticFilterParsers: BaseExploreFilterParser[];

  constructor() {
    this.filterToElasticFilterParsers = [
      new ExploreExploreIsFilterParser(),
      new ExploreFilterIsBetweenFilterParser(),
      new ExploreFilterExistsFilterParser(),
      new ExploreFilterIsOneOfFilterParser(),
      new ExploreFilterLookupFilterParser(),
      new ExploreFilterLessThanFilterParser(),
      new ExploreFilterGreaterThanFilterParser(),
      new ExploreFilterLuceneFilterParser(),
    ];
  }

  convertElasticToFilter({
    elasticFilter,
    negate = false,
  }: {
    elasticFilter: object;
    negate?: boolean;
  }): ElasticsearchEnhancedFilterModel | undefined {
    const filters = this.filterToElasticFilterParsers.map(parser => parser.getFilter(elasticFilter, negate));

    // First non-null filter
    const filter = filters.find(filter => Boolean(filter));

    if (isRangeFilter(filter?.type)) {
      const { gte, lt } = filter.value as IElasticsearchFilterValue['IS_BETWEEN'];

      if (!isNil(gte) && !isNil(lt)) return filters.find(o => o?.type === 'IS_BETWEEN');

      if (isNil(gte)) return filters.find(o => o?.type === 'LESS_THAN');

      if (isNil(lt)) return filters.find(o => o?.type === 'GREATER_THAN');
    }

    return filter;
  }

  convertToElastic({ filter }: { filter: ElasticsearchEnhancedFilterModel }): IElasticFilter | undefined {
    const matchFilter = this.filterToElasticFilterParsers
      .map(parser => parser.getElasticFilter(filter))
      .find(
        // Return the first non-null filter
        filter => Boolean(filter),
      );

    return matchFilter;
  }

  getHumanReadableValue(filter: ElasticsearchEnhancedFilterModel): string {
    return String(getFilterValueFormat(filter));
  }
}

export const enhancedFilterModelManipulator = new EnhancedFilterModelManipulator();
