export type DropMetricPerAccountValue = {
  accountId: number;
  accountName: string;
  metricNames: string[];
};

type RecommendationAction = {
  name: string;
  type: string;
  value: DropMetricPerAccountValue[];
};

export class MetricsDataOptimizationModel {
  name: string;
  action: string;
  actionArgs: RecommendationAction[];
}
