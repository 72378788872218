import { RollupFunction } from '@logz-pkg/enums';
import { Expose } from 'class-transformer';
import { LabelsEliminationMethod, MetricType } from './types';

export class RollupRuleModel {
  @Expose({ toClassOnly: true })
  id: string;

  accountId: number;
  metricName: string;
  metricType: MetricType = 'COUNTER';
  rollupFunction: RollupFunction = RollupFunction.SUM;
  labelsEliminationMethod: LabelsEliminationMethod = 'EXCLUDE_BY';
  labels: string[];
  dropOriginalMetric = true;

  @Expose({ toClassOnly: true })
  accountName: string;
}
