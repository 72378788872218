import { ConsumptionAccountInfo } from '@logz-pkg/models';
import { ISearchRequestObject } from '@logz-build/typescript';
import { ICrudApiService } from '../../utilities/types';
import { CrudApiService } from '../../utilities/crud.api.service';

class ConsumptionAccountApiService implements ICrudApiService<ConsumptionAccountInfo> {
  apiService = new CrudApiService<ConsumptionAccountInfo>();

  urls = {
    search: `/accounts/consumption/search`,
  };

  search = (requestObject: ISearchRequestObject) => {
    return this.apiService.search(this.urls.search, requestObject);
  };
}

export const consumptionAccountApiService = new ConsumptionAccountApiService();
