import { Expose } from 'class-transformer';

export class AddressModel {
  country: string;
  state: string;
  city: string;
  @Expose({ name: 'address_line1' })
  addressLine: string;
  zip: number;
  @Expose({ toPlainOnly: true })
  email: string;
}
