import * as yup from 'yup';
import { TelemetryAgentParamType } from '../enums';
import { oneOfEnum, paramBaseSchema, paramMetaDataSchema, selectOptionsSchema } from './shared.schemas';

const singleSelectParamBaseSchema = paramBaseSchema.shape({
  type: oneOfEnum({ type: TelemetryAgentParamType.SingleSelect }).required('param type required'),
  value: yup.mixed(),
  options: selectOptionsSchema.min(1),
});

export const singleSelectParamSchema = singleSelectParamBaseSchema.shape({
  value: yup.mixed().when(['required'], {
    is: required => required,
    then: yup.mixed().required(),
    otherwise: yup.mixed().nullable(),
  }),
});

export const singleSelectParamManifestSchema = singleSelectParamBaseSchema.concat(paramMetaDataSchema).shape({
  value: yup.mixed().nullable(),
});
