import { UserModel } from '@logz-pkg/models';
import { ICollectionResponse, ISearchRequestObject } from '@logz-build/typescript';
import { ICrudApiService } from '../utilities/types';
import { CrudApiService } from '../utilities/crud.api.service';
import { handleDalError } from '../utilities/utilities';

export class UserApiService implements ICrudApiService<UserModel> {
  crudApiService = new CrudApiService<UserModel>({ modelDefinition: UserModel });
  urls = {
    collection: '/user-management',
    search: '/user-management/search',
    resource: (id: UserModel['id']) => `/user-management/${id}`,
    suspend: (id: UserModel['id']) => `/user-management/suspend/${id}`,
    unsuspend: (id: UserModel['id']) => `/user-management/unsuspend/${id}`,
    deleteInvitation: '/invitations/delete',
    resendInvitation: '/invitations/resend',
  };

  getAll = (): Promise<UserModel[]> => this.crudApiService.getAll(this.urls.collection);
  get = (id: UserModel['id']) => this.crudApiService.get(this.urls.resource(id));
  delete = (id: UserModel['id']) => this.crudApiService.delete(this.urls.resource(id));
  search = async (requestObject?: ISearchRequestObject): Promise<ICollectionResponse<UserModel>> =>
    this.crudApiService.search(this.urls.search, requestObject, {
      appSideSortFn: (a, b) => b.id - a.id,
    });

  async searchByIds(byIds: number[]) {
    const users = await this.getAll();

    return users.filter(user => byIds.includes(user.id));
  }

  update = async (model: UserModel): Promise<UserModel> => {
    await this.crudApiService.update(this.urls.resource(model.id), model);

    // Since the API does not return the conventional response
    return model;
  };

  create = async (model: UserModel): Promise<UserModel> => {
    await this.crudApiService.create(this.urls.collection, model);

    // Since the API does not return the conventional response
    return model;
  };

  suspend = async (model: UserModel): Promise<UserModel> => {
    try {
      await this.crudApiService.do(this.urls.suspend(model.id), { payload: model });

      // Since the API does not return the conventional response
      return model;
    } catch (error) {
      handleDalError(error);
    }
  };

  unsuspend = async (model: UserModel): Promise<UserModel> => {
    try {
      await this.crudApiService.do(this.urls.unsuspend(model.id), { payload: model });

      // Since the API does not return the conventional response
      return model;
    } catch (error) {
      handleDalError(error);
    }
  };

  deleteInvitation = async (payload: { accountId: number; email: string }) =>
    this.crudApiService.do(this.urls.deleteInvitation, { payload });

  resendInvitation = async (payload: { accountId: number; email: string }) =>
    this.crudApiService.do(this.urls.resendInvitation, { payload });
}

export const userApiService = new UserApiService();
