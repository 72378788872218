import { SiemAccountSettingsModel } from '@logz-pkg/models';
import { SiemOwnerAccountState } from '@logz-pkg/enums';
import { ICrudApiService } from '../utilities/types';
import { CrudApiService } from '../utilities/crud.api.service';

type OwnerSiemAccountState = {
  ownerState: SiemOwnerAccountState;
  isOwner: boolean;
};

export class SiemAccountSettingsApiService implements ICrudApiService<SiemAccountSettingsModel> {
  siemAccountSettingsApiService = new CrudApiService({ modelDefinition: SiemAccountSettingsModel });
  siemOwnerAccountApiService = new CrudApiService<OwnerSiemAccountState>();
  urls = {
    collection: `/siem/settings`,
    ownerSiemAccountStatus: '/siem/accounts/owner-siem-state',
  };

  getLoggedInAccountSettings = async () => this.siemAccountSettingsApiService.get(this.urls.collection);
  getOwnerAccountStatus = () => this.siemOwnerAccountApiService.get(this.urls.ownerSiemAccountStatus);
}
export const siemAccountSettingsApiService = new SiemAccountSettingsApiService();
