class SendYourDataOpenSourceModel {
  title: string;
  githubRepo: string;
}

export class SendYourDataContentModel {
  id: string;
  collectionId: string;
  title: string;
  dataSource: string;
  openSource: SendYourDataOpenSourceModel[];
  logo: string; // svg or png
  filepath: string; // markdown file
  tags: string[];
  markdown?: string;
  shortDescription: string;
}
