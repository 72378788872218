import { Type } from 'class-transformer';
import { BillingCycle, BillingType } from '@logz-pkg/enums';
import { AddressModel } from './address';
import { ProductPlansArray } from './product-plan-array';
import { ProductPlanModel } from './product-plan';

export class CalculatePricesResponseModel {
  productPlans: ProductPlansArray;
}

export class PurchaseRequestModel {
  companyName: string;
  companyEmail: string;
  paymentToken: string;
  @Type(() => AddressModel)
  billingAddress: AddressModel;
  @Type(() => AddressModel)
  shippingAddress: AddressModel;
  billingCycle: BillingCycle;
  productPlans: ProductPlansArray;
}

export class UpgradeRequestModel {
  billingCycle: BillingCycle;
  productPlans: ProductPlansArray;
}

export class UpdateRequestModel {
  productPlans: ProductPlansArray;
}

export class DowngradeRequestModel {
  billingCycle: BillingCycle;
  productPlans: ProductPlansArray;
}

export class CalculateTaxRequestModel {
  shippingAddress: AddressModel;
}

export class CalculateTaxResponseModel {
  taxPercentage: number;
}

export class CustomRequestModel {
  billingCycle?: BillingCycle;
  productPlans: Array<{
    name: ProductPlanModel['name'];
    type: ProductPlanModel['type'];
    params: ProductPlanModel['params'];
  }>;
  billingType?: BillingType;
}
