import { ICollectionResponse, ISearchRequestObject } from '@logz-build/typescript';
import { ApiTokenModel } from '@logz-pkg/models';
import { ICrudApiService } from '../utilities/types';
import { CrudApiService } from '../utilities/crud.api.service';
import { cacheProvider } from '../../core/cache/cache.provider';
import { sortByLastUsed } from '../utilities/utilities';

const searchManipulator = (
  apiTokens: ApiTokenModel[],
  requestObject: ISearchRequestObject,
): ICollectionResponse<ApiTokenModel> => {
  const startPage = (requestObject.pagination.pageNumber - 1) * requestObject.pagination.pageSize;
  const endPage = requestObject.pagination.pageNumber * requestObject.pagination.pageSize;

  return {
    total: apiTokens.length,
    results: sortByLastUsed<ApiTokenModel>(apiTokens).slice(startPage, endPage),
    pagination: {
      pageNumber: requestObject.pagination.pageNumber,
      pageSize: requestObject.pagination.pageSize,
    },
  };
};

class ApiTokenApiService implements ICrudApiService<ApiTokenModel> {
  crudApiService = new CrudApiService({ modelDefinition: ApiTokenModel });

  urls = {
    collection: 'api-tokens',
    search: 'api-tokens',
    resource: id => `/api-tokens/${id}`,
  };

  search = async (requestObject: ISearchRequestObject): Promise<ICollectionResponse<ApiTokenModel>> => {
    // Ensure that populate will fetch fresh data, Token Filters are cached to reduce the amount of requests made on load
    cacheProvider.clear(cacheProvider.CacheKeys.SHARED_TOKENS_FILTERS);

    const searchResult = await this.crudApiService.search(this.urls.search, requestObject, { httpMethod: 'get' });

    return searchManipulator(searchResult.results, requestObject);
  };

  create = async (model: ApiTokenModel): Promise<ApiTokenModel> =>
    this.crudApiService.create(this.urls.collection, model);

  delete = async (id: ApiTokenModel['id']): Promise<ApiTokenModel> => this.crudApiService.delete(this.urls.resource(id));
}

export const apiTokenApiService = new ApiTokenApiService();
