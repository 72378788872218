import { ICollectionResponse, ISearchRequestObject } from '@logz-build/typescript';
import {
  ActiveState,
  ContributingUserModel,
  OptimizerDefinitionModel,
  OptimizerSearchRequestModel,
  OptimizerSearchResponseModel,
} from '@logz-pkg/models';
import { plainToClass } from 'class-transformer';
import { SortDirection } from '@logz-pkg/enums';
import { isNil } from 'lodash';
import { CrudApiService } from '../utilities/crud.api.service';
import { ICrudApiService } from '../utilities/types';

export type OptimizerFilters = { activeState: ActiveState; createdBy: string[]; updatedBy: string[] };

const DEFAULT_PAGE_SIZE = 10;

class OptimizerApiService implements ICrudApiService<OptimizerDefinitionModel, Partial<OptimizerDefinitionModel>> {
  crudApiService = new CrudApiService<OptimizerDefinitionModel, Partial<OptimizerDefinitionModel>>({
    modelDefinition: OptimizerDefinitionModel,
  });
  contributorsService = new CrudApiService({ modelDefinition: ContributingUserModel });

  urls = {
    create: '/triggers',
    search: '/triggers/filtered-list',
    resource: (id: OptimizerDefinitionModel['id']) => `triggers/${id}`,
    createdBy: '/triggers/contributing-users/createdBy',
    updatedBy: '/triggers/contributing-users/updatedBy',
    targetOptimizers: (timelessAccountId: number) => `/triggers/target/${timelessAccountId}`,
  };

  buildPayload = (requestObject: ISearchRequestObject<OptimizerFilters>): OptimizerSearchRequestModel => {
    const { filter, pagination, sort } = requestObject;
    const searchPayload: OptimizerSearchRequestModel = {
      ...(filter?.searchTerm ? { search: filter.searchTerm } : {}),
      ...(!isNil(filter?.activeState) ? { activeState: [filter.activeState] } : {}),
      ...(filter?.createdBy?.length > 0 ? { createdBy: filter.createdBy } : {}),
      ...(filter?.updatedBy?.length > 0 ? { updatedBy: filter.updatedBy } : {}),
      sortBy: sort?.[0]?.field ?? 'updatedAt',
      sortOrder: sort?.[0]?.descending ? SortDirection.Descending : SortDirection.Ascending,
      from: pagination ? (pagination.pageNumber - 1) * pagination.pageSize : 0,
      size: pagination ? pagination.pageSize : DEFAULT_PAGE_SIZE,
    };

    return searchPayload;
  };

  search = async (
    requestObject: ISearchRequestObject<OptimizerFilters>,
  ): Promise<ICollectionResponse<OptimizerDefinitionModel>> => {
    const searchPayload = this.buildPayload(requestObject);

    const response = await this.crudApiService.do<OptimizerSearchResponseModel>(this.urls.search, {
      payload: searchPayload,
    });

    const pagination = {
      pageNumber: requestObject?.pagination?.pageNumber ?? 1,
      pageSize: requestObject?.pagination?.pageSize ?? DEFAULT_PAGE_SIZE,
    };

    const collectionResponse: ICollectionResponse<OptimizerDefinitionModel> = {
      results: plainToClass(OptimizerDefinitionModel, response.results),
      total: response.total,
      pagination,
    };

    return collectionResponse;
  };

  get = async (id: number): Promise<OptimizerDefinitionModel> => {
    const retrivedModel = await this.crudApiService.get(this.urls.resource(id));

    return retrivedModel;
  };

  enable = async (model: OptimizerDefinitionModel): Promise<OptimizerDefinitionModel> => {
    const updatedModel = await this.crudApiService.update(this.urls.resource(model.id), {
      ...model,
      enabled: true,
    } as OptimizerDefinitionModel);

    return updatedModel;
  };

  disable = async (model: OptimizerDefinitionModel): Promise<OptimizerDefinitionModel> => {
    const updatedModel = await this.crudApiService.update(this.urls.resource(model.id), {
      ...model,
      enabled: false,
    });

    return updatedModel;
  };

  delete = async (id: OptimizerDefinitionModel['id']) => this.crudApiService.delete(this.urls.resource(id));

  createdByUsers = () => this.contributorsService.getAll(this.urls.createdBy);

  updatedByUsers = () => this.contributorsService.getAll(this.urls.updatedBy);

  getConnectedOptimizers = (accountId: number) => this.crudApiService.getAll(this.urls.targetOptimizers(accountId));

  create = async (model: Partial<OptimizerDefinitionModel>): Promise<OptimizerDefinitionModel> => {
    const createdModel = await this.crudApiService.create(this.urls.create, model);

    return createdModel;
  };

  update = async (model: OptimizerDefinitionModel): Promise<OptimizerDefinitionModel> => {
    const updatedModel = await this.crudApiService.update(this.urls.resource(model.id), model);

    return updatedModel;
  };
}

export const optimizerApiService = new OptimizerApiService();
