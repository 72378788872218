import KibanaLookupFilterMetadata from '../metadatas/osd-lookup-filter-metadata';
import KibanaFilterModel from './osd-filter-model';

export default class LookupFilterModel extends KibanaFilterModel {
  public lookup: {
    id: string;
    name: string;
    field: string;
    negate: boolean;
    alertEventId: string;
  };

  constructor(public meta: KibanaLookupFilterMetadata) {
    super(meta);

    this.lookup = {
      id: meta.id,
      name: meta.name,
      field: meta.key,
      negate: meta.negate,
      alertEventId: meta.alertEventId,
    };
  }
}
