import { AIDataSource } from '@logz-pkg/enums';
import {
  ChatChoice,
  BedrockMessage,
  KnowledgeBaseQuestion,
  AgentChatRecord,
  AiHistoryItemModel,
  IAiConversationModel,
} from '@logz-pkg/models';
import { ICollectionResponse } from '@logz-build/typescript';
import { CrudApiService } from '../utilities/crud.api.service';
import { ICrudApiService } from '../utilities/types';

class AiApiService implements ICrudApiService<IAiConversationModel> {
  cacheOptions = { cache: true, secondsToLive: 60 * 60 * 6 };
  crudApiService = new CrudApiService<IAiConversationModel>();

  private singleConversationUrl = (id: IAiConversationModel['id']) => `/app-ai/services/bedrock-ai/conversation/${id}`;
  private singleConversationHistoryUrl = (id: IAiConversationModel['id']) => `${this.singleConversationUrl(id)}/history`;
  urls = {
    aiSearch: '/app-ai/services/bedrock-ai',
    aiDocumentation: '/app-ai/services/bedrock-documentation-knowledge-base',
    aiConversation: '/app-ai/services/bedrock-ai/conversation',
    aiCategorizeText: '/app-ai/services/bedrock-ai/utilities/categorize',
    aiConversations: '/app-ai/services/bedrock-ai/conversations',
  };

  aiCategorizeText = async (userInput: string) => {
    return this.crudApiService.do<{ topic: string }>(this.urls.aiCategorizeText, { payload: { userInput } });
  };
  aiSearch = async (payload: BedrockMessage[]): Promise<ChatChoice[]> =>
    this.crudApiService.do(this.urls.aiSearch, { payload });

  integrationsAi = async (payload: KnowledgeBaseQuestion, abortSignal: AbortSignal) => {
    return this.crudApiService.do<{ text: string; sessionId: string }>(this.urls.aiDocumentation, {
      payload,
      abortSignal,
    });
  };

  aiSaveConversation = async (payload: {
    title: string;
    dataSource: AIDataSource;
    sessionId: string;
    historyItems: AgentChatRecord[];
    rcaId?: string;
  }): Promise<{ id: string }> => {
    return this.crudApiService.do(this.urls.aiConversation, {
      payload,
    });
  };

  aiAddConversationHistoryItems = async (
    payload: {
      historyItems?: AgentChatRecord[];
    },
    convId: string,
  ): Promise<{ id: string }> =>
    this.crudApiService.do(this.singleConversationHistoryUrl(convId), {
      payload,
    });

  aiGetConversation = async (convId: string): Promise<IAiConversationModel & { historyItems: AiHistoryItemModel[] }> =>
    this.crudApiService.get(this.singleConversationUrl(convId));

  aiGetConversations = async (): Promise<ICollectionResponse<IAiConversationModel>> =>
    this.crudApiService.search(this.urls.aiConversations);

  aiDeleteConversation = async (id: string) => this.crudApiService.delete(this.singleConversationUrl(id));

  aiUpdateConversation = async (conversation: IAiConversationModel) =>
    this.crudApiService.update(this.singleConversationUrl(conversation.id), conversation);
}

export const aiApiService = new AiApiService();
