import { MultiFilterBaseModel } from './osd-multi-filter-base';

export class KibanaMultiFilterModel extends MultiFilterBaseModel {
  livetailFiltersService: any;
  existTypeFilters: any;

  constructor(options, LivetailFiltersService) {
    super(options);

    this.livetailFiltersService = LivetailFiltersService;
    this.existTypeFilters = LivetailFiltersService.ExistTypeFilters;
  }

  isEmpty(filter) {
    return this.livetailFiltersService.isKibanaFilterEmpty(filter);
  }

  _isExistTypeFilter(filterData) {
    return Object.keys(this.existTypeFilters).includes(filterData.filterType);
  }

  _getDisplayedFilterParams(filterData) {
    if (this._isExistTypeFilter(filterData)) {
      return this.existTypeFilters[filterData.filterType];
    }

    if (Array.isArray(filterData.filterParams)) {
      return filterData.filterParams.join(', ');
    }

    return filterData.filterParams;
  }

  getDisplayedName() {
    return `${this.data.fieldName.value}: ${this._getDisplayedFilterParams(this.data)}`;
  }
}
