import { LogShippingTokenLimitsModel } from './log-shipping-token-limits';

class LogShippingTokenLimitsModelManipulator {
  isLastEnabledToken(numOfEnabledTokens: LogShippingTokenLimitsModel['numOfEnabledTokens']): boolean {
    return numOfEnabledTokens === 1;
  }

  getRemainingTokens(limits: LogShippingTokenLimitsModel): number {
    return limits?.maxAllowedTokens - limits?.numOfEnabledTokens;
  }
}

export const logShippingTokenLimitsModelManipulator = new LogShippingTokenLimitsModelManipulator();
