import { ScheduledReportModel } from '@logz-pkg/models';
import { classToPlain } from 'class-transformer';
import { ICrudApiService, IErrorResponse } from '../utilities/types';
import { CrudApiService } from '../utilities/crud.api.service';
import { populateScheduledReports } from './populate';

class ScheduledReportApiService implements ICrudApiService<ScheduledReportModel> {
  crudApiService = new CrudApiService({ modelDefinition: ScheduledReportModel });
  validateCronApiService = new CrudApiService();

  urls = {
    collection: 'scheduled-reports',
    search: 'scheduled-reports/search',
    resource: (id: number) => `scheduled-reports/${id}`,
    activate: (id: number) => `scheduled-reports/${id}/activate`,
    deactivate: (id: number) => `scheduled-reports/${id}/deactivate`,
    validateCron: `scheduled-reports/validate-cron`,
    test: `scheduled-reports/test`,
  };

  search = requestObject =>
    this.crudApiService.search(this.urls.search, requestObject).then(populateScheduledReports.collection);

  get = id => this.crudApiService.get(this.urls.resource(id)).then(populateScheduledReports.entity);

  create = model => this.crudApiService.create(this.urls.collection, model);

  update = model => this.crudApiService.update(this.urls.resource(model.id), model);

  delete = async id => this.crudApiService.delete(this.urls.resource(id));

  validateCron = async (cronExpression: string): Promise<any | IErrorResponse> =>
    this.validateCronApiService.get(this.urls.validateCron, undefined, { cronExpression });

  test = (scheduledReport: ScheduledReportModel) =>
    this.crudApiService.do(this.urls.test, { payload: classToPlain(scheduledReport) });

  activate = (scheduledReport: ScheduledReportModel): Promise<ScheduledReportModel> =>
    this.crudApiService.do(this.urls.activate(scheduledReport.id));

  deactivate = (scheduledReport: ScheduledReportModel): Promise<ScheduledReportModel> =>
    this.crudApiService.do(this.urls.deactivate(scheduledReport.id));
}

export const scheduledReportApiService = new ScheduledReportApiService();
