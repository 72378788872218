import { MathOperator } from '@logz-pkg/enums';
import { Transform, Type } from 'class-transformer';
import { AlertOutputFormatColumnModel } from './alert-output-format-column';
import { AlertQueryModel } from './alert-query';
import { AlertSeverityThresholdModel } from './alert-severity-threshold';
import { alertModelManipulator } from './alert.manipulator';

class AlertTrigger {
  operator: MathOperator = MathOperator.GreaterThan;

  // No type is added due to: https://github.com/typestack/class-transformer/issues/308
  @Transform(value => alertModelManipulator.convertSeverityThresholdTiersToRecord(value), { toPlainOnly: true })
  @Transform(value => alertModelManipulator.convertSeverityThresholdTiersToArray(value), { toClassOnly: true })
  severityThresholdTiers: AlertSeverityThresholdModel[] = [new AlertSeverityThresholdModel()];
}

class SubComponentOutput {
  shouldUseAllFields = true;
  @Type(() => AlertOutputFormatColumnModel)
  columns: AlertOutputFormatColumnModel[] = [];
}

export class SubComponentModel {
  @Type(() => AlertQueryModel) queryDefinition: AlertQueryModel = new AlertQueryModel();
  @Type(() => AlertTrigger) trigger: AlertTrigger = new AlertTrigger();
  @Type(() => SubComponentOutput) output: SubComponentOutput = new SubComponentOutput();
}
