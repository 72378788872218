export interface IElasticFilter {
  ids?: object;
  exists?: {
    field: string;
  };
  range?: object;
  match_phrase?: object;
  bool?: {
    should: object;
  };
  query_string?: {
    query: string;
  };

  // Custom filters models
  lookup?: {
    id: string;
    field: string;
    name: string;
    negate: boolean;
    alertEventId: string;
  };
}

export enum ElasticFilterType {
  Bool = 'bool',
  Range = 'range',
  Exists = 'exists',
  MatchPhrase = 'match_phrase',
  Match = 'match',
  QueryString = 'query_string',

  // Custom filters
  Lookup = 'lookup',
}

export enum KibanaFilterType {
  Phrase = 'phrase',
  Phrases = 'phrases',
  Range = 'range',
  Exists = 'exists',
  Match = 'match',
  Query = 'query',

  // Custom filters
  Lookup = 'lookup',
}

export type IOpensearchDsl = {
  bool: {
    must?: IElasticFilter[];
    must_not?: IElasticFilter[];
    filter?: Record<string, unknown>[];
    should?: Record<string, unknown>[];
  };
};
