import {
  MetricsCompactAccountsSummaryModel,
  MetricsAccountBudgetModel,
  MetricsAccountPlanModel,
} from '@logz-pkg/models';
import { isNil } from 'lodash';
import { ICrudApiService } from '../../utilities/types';
import { CrudApiService } from '../../utilities/crud.api.service';

class MetricsApiBudgetService implements ICrudApiService<MetricsAccountBudgetModel> {
  crudApiService = new CrudApiService({ modelDefinition: MetricsAccountBudgetModel });

  urls = {
    create: `/account-management/metrics-accounts`,
    get: id => `/account-management/metrics-accounts/${id}`,
    getDetailed: '/account-management/metrics-accounts/detailed',
  };

  async createFreeTrial(): Promise<any> {
    const data = await this.crudApiService.create(this.urls.create);

    return { id: data };
  }

  async getDetailed(): Promise<MetricsAccountBudgetModel> {
    const data = await this.crudApiService.get(this.urls.getDetailed);

    return data;
  }
}

class MetricsApiPlanService implements ICrudApiService<MetricsAccountPlanModel> {
  crudApiService = new CrudApiService<MetricsAccountPlanModel, Partial<MetricsAccountPlanModel>>({
    modelDefinition: MetricsAccountPlanModel,
  });
  compactMetricsAccountApi = new CrudApiService({ modelDefinition: MetricsCompactAccountsSummaryModel });

  urls = {
    create: '/account-management/metrics-accounts',
    resource: id => `/account-management/metrics-accounts/${id}`,
    detailed: id => `account-management/metrics-accounts/detailed/${id}`,
    compactMetricsAccountsSummary: '/account-management/metrics-accounts/is-metrics-product-ready',
  };

  get = (id: number) => {
    return this.crudApiService.get(this.urls.detailed(id));
  };

  async createAccount(model: MetricsAccountPlanModel): Promise<{ id: number }> {
    type CreateAccountPayload = { accountName: string; accessible: boolean; dpm: number; maxUniqueMetrics?: number };

    const payload: CreateAccountPayload = {
      accountName: model.name,
      accessible: false,
      dpm: model.dpm,
    };

    if (!isNil(model.maxUniqueMetrics)) {
      payload.maxUniqueMetrics = model.maxUniqueMetrics;
    }

    const data = await this.crudApiService.do<number>(this.urls.create, {
      payload,
    });

    return { id: data };
  }

  async update(model: MetricsAccountPlanModel, { id }): Promise<any> {
    await this.crudApiService.update(this.urls.resource(id), model);

    return { id };
  }

  async delete(id: number): Promise<any> {
    return this.crudApiService.delete(this.urls.resource(id));
  }

  async getCompactMetricsAccountsSummary(): Promise<MetricsCompactAccountsSummaryModel> {
    const data = await this.compactMetricsAccountApi.get(this.urls.compactMetricsAccountsSummary);

    return data;
  }
}

export const metricsApiBudgetService = new MetricsApiBudgetService();
export const metricsApiPlanService = new MetricsApiPlanService();
