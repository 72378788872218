import { KibanaFilterType } from '../filter-interfaces';

export default class KibanaFilterMetadata {
  public index: string;
  public params: any;
  public name: any;

  constructor(
    public key: string,
    public value: string,
    public type: KibanaFilterType,
    public negate = false,
    indexPattern: string,
    public alias: string = null,
    public disabled = false,
  ) {
    this.index = indexPattern;
  }
}
