import KibanaFilterMetadata from '../metadatas/osd-filter-metadata';
import { KibanaFilterType } from '../filter-interfaces';
import KibanaFilterModel from './osd-filter-model';

export default class IsFilterModel extends KibanaFilterModel {
  public query: {
    match: {
      [key: string]: {
        query: any;
        type: KibanaFilterType;
      };
    };
  };

  constructor(metadata: KibanaFilterMetadata) {
    super(metadata);
    this.query = {
      match: {
        [metadata.key]: {
          query: metadata.value,
          type: metadata.type,
        },
      },
    };
  }
}
