import { AuthorizedAccountModel } from '@logz-pkg/models';
import { ICrudApiService } from '../utilities/types';
import { CrudApiService } from '../utilities/crud.api.service';

class AuthorizedAccountsApiService implements ICrudApiService<AuthorizedAccountModel> {
  crudApiService = new CrudApiService({ modelDefinition: AuthorizedAccountModel });
  urls = {
    get: '/app/authorized-accounts',
  };

  getAll = ({ fresh } = { fresh: false }): Promise<AuthorizedAccountModel[]> =>
    this.crudApiService.getAll(this.urls.get, {
      cache: true,
      fresh,
      secondsToLive: 60 * 10,
    });
}

export const authorizedAccountsApiService = new AuthorizedAccountsApiService();
