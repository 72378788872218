export class AccountLogTypeModel {
  logType: string;
  hasSampleLogs: boolean;
  pipelineEditor: string;
  supportedLogType: boolean;
  get value() {
    return this.logType;
  }
  get name() {
    return this.logType;
  }
}
