import { Expose, Type } from 'class-transformer';
import { SendYourDataManifestCollectionModel } from './send-your-data-manifest-collection';
import { SendYourDataManifestTagModel } from './send-your-data-manifest-tag';

export class SendYourDataManifestModel {
  updatedAt: string;
  @Type(() => SendYourDataManifestCollectionModel)
  collections: SendYourDataManifestCollectionModel[] = [];
  @Expose({ name: 'tagMap' })
  @Type(() => SendYourDataManifestTagModel)
  tags: SendYourDataManifestTagModel[] = [];
}
