import * as yup from 'yup';
import { TelemetryAgentParamType } from '../enums';
import { oneOfEnum, paramBaseSchema, paramMetaDataSchema } from './shared.schemas';

const textParamBaseSchema = paramBaseSchema.shape({
  type: oneOfEnum({ type: TelemetryAgentParamType.Text }).required('param type required'),
  pattern: yup.string().nullable(),
  patternError: yup.string().nullable(),
  value: yup.string(),
});

export const textParamSchema = yup.lazy(param => {
  let schema = textParamBaseSchema;
  let valueSchema = yup.string();

  if (param.required) {
    valueSchema = valueSchema
      .transform(value => value ?? '')
      .trim()
      .required('required');
  } else {
    valueSchema = valueSchema.nullable();
  }

  if (param.pattern) {
    valueSchema = valueSchema.matches(
      new RegExp(param.pattern, 'gi'),
      param.patternError ?? `value does not match pattern: ${param.pattern}`,
    );
  }

  schema = schema.shape({
    value: valueSchema,
  });

  return schema;
});

export const textParamManifestSchema = textParamBaseSchema.concat(paramMetaDataSchema).shape({
  value: yup.string().nullable(),
});
