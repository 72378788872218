import { SiemPlanModel } from '@logz-pkg/models';
import { ICrudApiService } from '../utilities/types';
import { CrudApiService } from '../utilities/crud.api.service';

class SiemPlanApiService implements ICrudApiService<SiemPlanModel> {
  siemPlanApiService = new CrudApiService({ modelDefinition: SiemPlanModel });
  urls = {
    resource: '/siem/main-account/plan',
  };

  get = () => this.siemPlanApiService.get(this.urls.resource);
}

export const siemPlanApiService = new SiemPlanApiService();
