import { Type, Transform, Expose } from 'class-transformer';
import { EventHistoryField, EventStatus } from '@logz-pkg/enums';
import { UserModel } from '../../user/user';

export class EventHistoryModel {
  @Type(() => Date)
  @Transform(value => value * 1000, { toClassOnly: true })
  createdAt: Date;
  createdBy: UserModel['id'];
  @Expose({ name: 'field' })
  @Transform(status => status as typeof EventHistoryField, { toClassOnly: true })
  changeType: EventHistoryField;
  oldValue: string | EventStatus | UserModel['id'];
  newValue: string | EventStatus | UserModel['id'];
}
