import { GrafanaUserPreferencesModel } from '@logz-pkg/models';
import { CrudApiService } from '../../utilities/crud.api.service';

class GrafanaApiService {
  crudApiService = new CrudApiService({ modelDefinition: GrafanaUserPreferencesModel });

  urls = {
    userPreferences: '/grafana-app/api/user/preferences',
  };

  getUserPreferences = () => this.crudApiService.get(this.urls.userPreferences);
}

export const grafanaApiService = new GrafanaApiService();
