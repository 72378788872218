import { InsightModel } from '@logz-pkg/models';
import { CrudApiService } from '../utilities/crud.api.service';
import { ICrudApiService } from '../utilities/types';

export class InsightsApiService implements ICrudApiService<InsightModel> {
  crudApiService = new CrudApiService<InsightModel>();

  urls = {
    search: '/insights',
  };

  getByIds = async (ids: string[]): Promise<InsightModel[]> => {
    return this.crudApiService.do(this.urls.search, {
      payload: {
        ids,
      },
    });
  };
}

export const insightsApiService = new InsightsApiService();
