import { TracingSamplingRuleActionTypes } from '@logz-pkg/enums';
import {
  OtelRule,
  ServicesRule,
  KeepFailedRule,
  KeepLatencyRule,
  KeepPercentageRule,
  TracingConfigRule,
  RuleWithSubPolicy,
} from './tracing-sampling-config';

// OTEL rules => Configuration
const isRuleAllServices = rule => typeof (rule as RuleWithSubPolicy).and === 'undefined';
const isServiceRule = rule => (rule as ServicesRule).string_attribute?.key === 'service.name';

const getActionTypeAndValue = (rule: OtelRule): Exclude<TracingConfigRule['action'], 'name'> => {
  if (!!(rule as KeepLatencyRule).latency) {
    return {
      type: TracingSamplingRuleActionTypes.KeepLatencyThreshold,
      value: (rule as KeepLatencyRule).latency.threshold_ms,
    };
  } else if (!!(rule as KeepPercentageRule).probabilistic) {
    return {
      type: TracingSamplingRuleActionTypes.KeepPercentageOfTraces,
      value: (rule as KeepPercentageRule).probabilistic.sampling_percentage,
    };
  } else if (!!(rule as KeepFailedRule).status_code) {
    return {
      type: TracingSamplingRuleActionTypes.KeepFailed,
    };
  }

  throw new Error(`Unsupported rule format${JSON.stringify(rule)}`);
};

export const transformOtelRulesToConfigRules = (rules: OtelRule[], disabledRules: number[]): TracingConfigRule[] =>
  rules.map((rule: OtelRule, index: number) => {
    if (isRuleAllServices(rule)) {
      return {
        isDisabled: disabledRules.includes(index),
        index,
        action: getActionTypeAndValue(rule),
        services: [],
      };
    }

    const {
      and: { and_sub_policy: subPolicies },
    } = rule as RuleWithSubPolicy;

    const { action, services } = subPolicies.reduce(
      (acc, cur) => {
        if (isServiceRule(cur)) {
          return { ...acc, services: (cur as ServicesRule).string_attribute.values };
        }

        return { ...acc, action: getActionTypeAndValue(cur as OtelRule) };
      },
      { action: null, services: null },
    );

    return {
      isDisabled: disabledRules.includes(index),
      services,
      action,
      index,
    };
  });

// Configuration => OTEL Rules

const getActionRule = (baseRuleName: string, action: TracingConfigRule['action']) =>
  ({
    [TracingSamplingRuleActionTypes.KeepLatencyThreshold]: {
      name: `${baseRuleName}-latency`,
      type: 'latency',
      latency: {
        threshold_ms: action.value,
      },
    },
    [TracingSamplingRuleActionTypes.KeepPercentageOfTraces]: {
      name: `${baseRuleName}-probabilistic`,
      type: 'probabilistic',
      probabilistic: {
        hash_salt: 'custom-salt',
        sampling_percentage: action.value,
      },
    },
    [TracingSamplingRuleActionTypes.KeepFailed]: {
      name: `${baseRuleName}-status_code`,
      type: 'status_code',
      status_code: {
        status_codes: ['ERROR'],
      },
    },
  }[action.type]);

const getServiceNameRule = (baseRuleName: string, services: TracingConfigRule['services']) => ({
  name: `${baseRuleName}-string_attribute`,
  type: 'string_attribute',
  string_attribute: {
    key: 'service.name',
    values: services,
  },
});

const generateSubRules = (baseRuleName: string, { action, services }: TracingConfigRule) => [
  getServiceNameRule(baseRuleName, services),
  getActionRule(baseRuleName, action),
];

export const transformConfigRulesToOtelRules = (configRules: TracingConfigRule[]): OtelRule[] =>
  configRules.map((configRule, index) => {
    const baseRuleName = `${configRule.action.type}-${configRule.action.value ?? ''}-${index}`;

    if (configRule.services?.length === 0) {
      return getActionRule(baseRuleName, configRule.action) as OtelRule;
    }

    return {
      name: baseRuleName,
      type: 'and',
      and: {
        and_sub_policy: generateSubRules(baseRuleName, configRule),
      },
    } as OtelRule;
  });

export const getDisabledRulesIndexes = (samplingRules: TracingConfigRule[]): number[] =>
  samplingRules.reduce((acc, rule, index) => {
    if (rule.isDisabled) {
      acc.push(index);
    }

    return acc;
  }, []);
