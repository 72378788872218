import { Type } from 'class-transformer';

class FieldCondition {
  fieldName: string;
  value: string | number;
  groupBy?: boolean;
}

export enum DropFilterState {
  DROPPING = 'DROPPING',
  NOT_DROPPING = 'NOT_DROPPING',
}

export class LogsDropFilterModel {
  id: string;
  logType: string;

  @Type(() => FieldCondition)
  fieldConditions: FieldCondition[];

  description: string;
  thresholdInGB: number;
  active: boolean;
  state?: DropFilterState = DropFilterState.NOT_DROPPING;
}
