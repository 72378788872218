import { ICollectionResponse, ISearchRequestObject } from '@logz-build/typescript';
import { MetricsDropFilterModel } from '@logz-pkg/models';
import { BulkActionsRes, ICrudApiService } from '../utilities/types';
import { CrudApiService } from '../utilities/crud.api.service';

export class MetricsDropFiltersApiService implements ICrudApiService<MetricsDropFilterModel> {
  crudApiService = new CrudApiService({ modelDefinition: MetricsDropFilterModel });

  urls = {
    collection: 'metrics-management/drop-filters',
    search: 'metrics-management/drop-filters/search',
    resource: (id: MetricsDropFilterModel['id']) => `metrics-management/drop-filters/${id}`,
    activate: (id: MetricsDropFilterModel['id']) => `metrics-management/drop-filters/${id}/enable`,
    deactivate: (id: MetricsDropFilterModel['id']) => `metrics-management/drop-filters/${id}/disable`,
    bulkCreate: '/metrics-management/drop-filters/bulk/create',
    bulkDelete: '/metrics-management/drop-filters/bulk/delete',
    deleteBySearchFilter: '/metrics-management/drop-filters/delete',
  };

  search = async (requestObject: ISearchRequestObject): Promise<ICollectionResponse<MetricsDropFilterModel>> =>
    this.crudApiService.search(this.urls.search, requestObject);

  get = async ({ id }): Promise<MetricsDropFilterModel> => this.crudApiService.get(this.urls.resource(id));

  create = async (model: MetricsDropFilterModel): Promise<MetricsDropFilterModel> =>
    this.crudApiService.create(this.urls.collection, model);

  update = async (model: MetricsDropFilterModel, { id }): Promise<MetricsDropFilterModel> =>
    this.crudApiService.update(this.urls.resource(id), model);

  delete = async ({ id }): Promise<MetricsDropFilterModel> => this.crudApiService.delete(this.urls.resource(id));

  activate = async (id: MetricsDropFilterModel['id']): Promise<MetricsDropFilterModel> =>
    this.crudApiService.do(this.urls.activate(id));

  deactivate = async (id: MetricsDropFilterModel['id']): Promise<MetricsDropFilterModel> =>
    this.crudApiService.do(this.urls.deactivate(id));

  bulkCreate = async (payload: MetricsDropFilterModel[]) => this.crudApiService.do(this.urls.bulkCreate, { payload });

  bulkDelete = requestObject => this.crudApiService.bulkDelete(this.urls.bulkDelete, requestObject.filters.ids);

  deleteBySearchFilter = (searchRequestObject: ISearchRequestObject): Promise<BulkActionsRes> =>
    this.crudApiService.do(this.urls.deleteBySearchFilter, { payload: searchRequestObject.filter });
}

export const metricsDropFiltersApiService = new MetricsDropFiltersApiService();
