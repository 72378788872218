import { IDBNames, UxType } from '@logz-pkg/enums';
import { get, set, del, UseStore, getMany, setMany, delMany, createStore } from 'idb-keyval';
import { LoggerService } from '../logger/logger.service';

export class IndexDbService {
  get = async <T>(key: IDBValidKey, customStore: UseStore): Promise<T | void> => {
    return get<T>(key, customStore).catch(e => {
      LoggerService.logError({
        message: 'Failed to Delete value from persist client',
        error: e,
        extra: { key },
        uxType: UxType.IN_PAGE,
      });
    });
  };

  getMany = async <T>(keys: IDBValidKey[], customStore: UseStore): Promise<T[] | void> => {
    return getMany<T>(keys, customStore).catch(e => {
      LoggerService.logError({
        message: 'Failed to Get many values from persist client',
        error: e,
        extra: { keys },
        uxType: UxType.IN_PAGE,
      });
    });
  };

  set = async (key: IDBValidKey, value: any, customStore: UseStore): Promise<void> => {
    return set(key, value, customStore).catch(e => {
      LoggerService.logError({
        message: 'Failed to Set value in persist client',
        error: e,
        extra: { key, value },
        uxType: UxType.IN_PAGE,
      });
    });
  };

  setMany = async (entries: [IDBValidKey, any][], customStore: UseStore): Promise<void> => {
    return setMany(entries, customStore).catch(e => {
      LoggerService.logError({
        message: 'Failed to Set many values in persist client',
        error: e,
        extra: { entries },
        uxType: UxType.IN_PAGE,
      });
    });
  };

  del = async (key: IDBValidKey, customStore: UseStore): Promise<void> => {
    return del(key, customStore).catch(e => {
      LoggerService.logError({
        message: 'Failed to Delete value from persist client',
        error: e,
        extra: { key },
        uxType: UxType.IN_PAGE,
      });
    });
  };

  delMany = async (keys: IDBValidKey[], customStore: UseStore): Promise<void> => {
    return delMany(keys, customStore).catch(e => {
      LoggerService.logError({
        message: 'Failed to Delete many values from persist client',
        error: e,
        extra: { keys },
        uxType: UxType.IN_PAGE,
      });
    });
  };

  createStore = (DBName: `${IDBNames}`, storeName: string): UseStore => createStore(DBName, storeName);
}

export const indexDbService = new IndexDbService();
