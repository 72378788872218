import moment from 'moment';
import { ICrudApiService } from '../../dal/utilities/types';
import { CrudApiService } from '../../dal/utilities/crud.api.service';

export interface IMetricsResponse<T> {
  data: {
    result: T[];
    resultType: string;
  };
  status: string;
}

export interface IQueryMetric<T> {
  metric: T;
  value: [number, string];
}

export interface IMetricsLabelValues {
  data: string[];
  status: string;
}

export interface IQueryRangeMetric<T> {
  metric: T;
  step_size_ms: number;
  values: [number, string][];
}

const getRequestConfig = (queryId = null) => {
  return {
    headers: { 'content-type': 'application/x-www-form-urlencoded', ...(queryId ? { 'Query-Id': queryId } : {}) },
  };
};

interface IQueryRangeParams {
  rawQuery: string;
  accountId: string | number;
  start: number;
  end: number;
  step?: number | string;
  queryId?: string;
}

class M3DBApiService implements ICrudApiService<IMetricsResponse<any>> {
  urls = {
    values: ({ accountId, label }) => `/m3db/prometheus/${accountId}/api/v1/label/${label}/values`,
    query: ({ accountId }) => `/m3db/prometheus/${accountId}/api/v1/query`,
    queryRange: ({ accountId }) => `/m3db/prometheus/${accountId}/api/v1/query_range`,
  };

  crudApiService = new CrudApiService();

  async query<T>({
    rawQuery,
    accountId,
    queryId = null,
    time = moment().unix(),
  }): Promise<IMetricsResponse<IQueryMetric<T>>> {
    const { data } = await this.crudApiService.httpService.post<IMetricsResponse<IQueryMetric<T>>>(
      this.urls.query({ accountId }),
      {
        query: rawQuery,
        time,
      },
      {},
      {},
      getRequestConfig(queryId),
    );

    return data;
  }

  values = async ({
    accountId,
    label,
    match,
    start = Math.trunc(new Date().getTime() / 1000) - 1,
    end = Math.trunc(new Date().getTime() / 1000),
  }) => {
    const { data } = await this.crudApiService.httpService.get<IMetricsLabelValues>(
      this.urls.values({ accountId, label }),
      {
        'match[]': match,
        start,
        end,
      },
      {},
      {},
      getRequestConfig(),
    );

    return data;
  };

  async queryRange<T = any>({
    rawQuery,
    accountId,
    start,
    end = Math.trunc(new Date().getTime() / 1000), // right now
    step = 60 * 60, // one hour
    queryId = null,
  }: IQueryRangeParams): Promise<IMetricsResponse<IQueryRangeMetric<T>>> {
    const { data } = await this.crudApiService.httpService.post<IMetricsResponse<IQueryRangeMetric<T>>>(
      this.urls.queryRange({ accountId }),
      {
        query: rawQuery,
        start,
        end,
        step,
      },
      {},
      {},
      getRequestConfig(queryId),
    );

    return data;
  }
}

export const m3dbApiService = new M3DBApiService();
