import { AccountType, type IAppModeValue, LogzioTheme, PLAN_TYPE } from '@logz-pkg/enums';
import { SessionModel } from '@logz-pkg/models';
import { Observable } from '@logz-pkg/observable';
import { merge } from 'lodash';
import { authService } from '../core/auth/services/auth.service';
import { sessionApiService } from '../dal/session/api.service';

export interface ISessionState extends Omit<SessionModel, 'user' | 'account'> {
  loggedInUser: SessionModel['user'];
  loggedInAccount: SessionModel['account'];
  appMode?: IAppModeValue;
  isSysAdmin: boolean;
}

export class SessionStateService {
  readonly session = new Observable<ISessionState>({} as ISessionState);
  appModeMap: Partial<Record<AccountType, IAppModeValue>>;

  fetch = async ({ fresh } = { fresh: false }) => {
    const { account, user, ...session } = await sessionApiService.get({ fresh });
    const isSysAdmin = authService.isAdmin();

    this.session.set({
      ...session,
      loggedInAccount: account,
      loggedInUser: user,
      isSysAdmin,
      appMode: this.appModeMap[account.type],
    });
  };

  // Clear is an empty object so destructuring won't throw an error.
  clear = () => this.session.set({} as ISessionState);

  initAppModes = (modes: Partial<Record<AccountType, IAppModeValue>>) => {
    this.appModeMap = modes;
  };

  isLoggedIn = () => {
    const session = this.session.get();

    return Boolean(session?.loggedInUser);
  };

  isTrial = async () => {
    const {
      userSummary: { planType },
    } = await sessionApiService.get();

    return planType === PLAN_TYPE.TRIAL || planType === PLAN_TYPE.FREE;
  };

  setThemeWithoutSave = async (logzioTheme: LogzioTheme) => {
    const newSession = merge({}, this.session.get(), { userSettings: { logzioTheme, kibanaTheme: logzioTheme } });

    this.session.set(newSession);
  };
}

export const sessionStateService = new SessionStateService();
