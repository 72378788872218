export class AuthSettingsModel {
  authSettings: {
    allowed_ip_prefixes?: string[];
    multifactor?: {
      provider: 'google-authenticator';
    };
  };
  connection: string | null;
  emailDomain: string | null;
}
