import { IElasticFilter } from '../../../osd/filters/filter-interfaces';
import KibanaFilterMetadata from '../../../osd/filters/metadatas/osd-filter-metadata';
import IsFilterParser from '../../../osd/filters/parsers/is-filter-parser';
import { ElasticsearchFieldModel } from '../../../elasticsearch/elasticsearch-field';
import { ElasticsearchEnhancedFilterModel } from '../../../elasticsearch/elasticsearch-filter';
import { BaseExploreFilterParser } from './base';

/**
 * Elastic to ElasticsearchFilterModel 'IS' filter parser
 */
export class ExploreExploreIsFilterParser extends BaseExploreFilterParser {
  constructor() {
    super(new IsFilterParser());
  }

  getFilter(elasticFilter: object, negate: boolean): ElasticsearchEnhancedFilterModel {
    const metadata: KibanaFilterMetadata = this.getMetadata(elasticFilter, negate);

    if (!metadata) return null;

    const filter = new ElasticsearchEnhancedFilterModel();

    filter.type = 'IS';
    filter.value = metadata.value;
    filter.negate = metadata.negate;
    filter.field = new ElasticsearchFieldModel();
    filter.field.name = metadata.key;

    return filter;
  }

  getElasticFilter(filter: ElasticsearchEnhancedFilterModel): IElasticFilter {
    if (filter.type !== 'IS') return null;

    return { [this.baseFilterParser.elasticKey]: { [filter.field.name]: { query: filter.value } } };
  }
}
