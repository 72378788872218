import { PlainNotificationEndpointModel } from '@logz-pkg/models';
import { ICrudApiService } from '../utilities/types';
import { CrudApiService } from '../utilities/crud.api.service';

class RcaNotificationSettingsStateService implements ICrudApiService<PlainNotificationEndpointModel> {
  crudApiService = new CrudApiService<PlainNotificationEndpointModel>();
  settingsCrudApiService = new CrudApiService<{ contactPoint: number; accountId: number }>();
  private readonly urls = {
    settings: '/app-ai/notification/settings',
  };
  getNotificationEndpoint = async () => this.settingsCrudApiService.get(this.urls.settings);
  updateNotificationEndpoint = async (value: PlainNotificationEndpointModel) => {
    await this.crudApiService.update(this.urls.settings, value);
  };
}

export const rcaNotificationSettingsService = new RcaNotificationSettingsStateService();
