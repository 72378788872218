import { LookupListModel } from '../../../lookup/lookup-list';
import { ElasticsearchFieldModel } from '../../../elasticsearch/elasticsearch-field';
import lookupFilterParser from '../../../osd/filters/parsers/lookup-filter-parser';
import KibanaFilterMetadata from '../../../osd/filters/metadatas/osd-filter-metadata';
import KibanaLookupFilterMetadata from '../../../osd/filters/metadatas/osd-lookup-filter-metadata';
import { IElasticFilter } from '../../../osd/filters/filter-interfaces';
import { ElasticsearchEnhancedFilterModel } from '../../../elasticsearch/elasticsearch-filter';
import { BaseExploreFilterParser } from './base';

/**
 * Elastic to ElasticsearchFilterModel 'LOOKUP' filter parser
 */
export class ExploreFilterLookupFilterParser extends BaseExploreFilterParser {
  constructor() {
    super(lookupFilterParser);
  }

  getFilter(elasticFilter: object, negate: boolean): ElasticsearchEnhancedFilterModel {
    const metadata: KibanaFilterMetadata = this.getMetadata(elasticFilter, negate);

    if (!metadata) return null;

    const filter = new ElasticsearchEnhancedFilterModel();
    const lookupTable = new LookupListModel();

    lookupTable.id = (metadata as KibanaLookupFilterMetadata).id;
    lookupTable.name = metadata.name;

    filter.type = 'LOOKUP';
    filter.value = lookupTable;
    filter.negate = metadata.negate;
    filter.field = new ElasticsearchFieldModel();
    filter.field.name = metadata.key;

    return filter;
  }

  getElasticFilter(filter: ElasticsearchEnhancedFilterModel): IElasticFilter {
    if (filter.type !== 'LOOKUP') return null;

    return {
      [this.baseFilterParser.elasticKey]: {
        id: (filter.value as LookupListModel).id,
        name: (filter.value as LookupListModel).name,
        field: filter.field.name,
      },
    };
  }
}
