import type { DashboardResource } from '@perses-dev/core';

export class UnifiedDashboardModel {
  createdAt: string;
  createdBy: string;
  deletedAt?: string;
  deletedBy?: number;
  id: string;
  uid: string;
  projectId: string;
  updatedAt: string;
  updatedBy: number;
  version: number;
  doc: DashboardResource;
}
