import { AnnouncementModel, WhatsNewMetadataModel } from '@logz-pkg/models';
import { ISearchRequestObject } from '@logz-build/typescript';
import { ICrudApiService } from '../utilities/types';
import { CrudApiService } from '../utilities/crud.api.service';

export class AnnouncementApiService implements ICrudApiService<AnnouncementModel> {
  crudApiService = new CrudApiService({ modelDefinition: AnnouncementModel });
  whatsNewCrudApiService = new CrudApiService({ modelDefinition: WhatsNewMetadataModel });
  urls = {
    search: 'whats-new/search',
    markRead: 'whats-new/mark-read',
    metadata: 'whats-new/metadata',
  };

  search = async (requestObject: ISearchRequestObject) => this.crudApiService.search(this.urls.search, requestObject);
  markRead = async () => this.crudApiService.do(this.urls.markRead);
  metadata = async () => this.whatsNewCrudApiService.get(this.urls.metadata);
}

export const announcementApiService = new AnnouncementApiService();
