import { Environments, IFrontendConfigs } from './index';

export const configs: Record<Environments, IFrontendConfigs> = {
  LOCAL: {
    cookieSuffix: 'local',
    mockAuth0: true,
    tours: true,
    errorOnUnhandledRejections: true,
    angularDebugMode: true,
    redirectToMarketingRegistration: false,
    reportUserAnalytics: true,
    fullstory: false,
    storeDevTools: true,
    logToConsole: true,
    logObjectToConsole: true,
    uiRouteTracing: false,
    debugProductIframe: false,
    mockTours: false,
    overrideSystemRegions: true,
    debugHttpInterceptors: false,
    captureAnalytics: false,
  },
  ITEST: {
    mockAuth0: true,
    errorOnUnhandledRejections: true,
    angularDebugMode: true,
    logToConsole: true,
    logObjectToConsole: false,
    debugProductIframe: false,
    disableUrlValidation: true,
    debugHttpInterceptors: false,
    mockTours: true,
    captureAnalytics: false,
  },
  STAGING: {
    cookieSuffix: 'staging',
    errorOnUnhandledRejections: false,
    angularDebugMode: false,
    reportUserAnalytics: false,
    debugProductIframe: false,
    fullstory: false,
    overrideConsole: true,
    logToConsole: false,
    intercom: false,
    tours: true,
    captureAnalytics: false,
  },
  PROD: {
    errorOnUnhandledRejections: false,
    angularDebugMode: false,
    redirectToMarketingRegistration: true,
    reportUserAnalytics: true,
    fullstory: true,
    logToConsole: false,
    overrideConsole: true,
    marketo: true,
    intercom: true,
    tours: true,
    captureAnalytics: true,
  },
  UNIT_TEST: {
    mockAuth0: true,
    errorOnUnhandledRejections: true,
    angularDebugMode: true,
    reportUserAnalytics: false,
    fullstory: false,
    storeDevTools: true,
    logToConsole: true,
    logObjectToConsole: false,
    captureAnalytics: false,
  },
  REMOTE_STAGING: {
    mockAuth0: false,
    cookieSuffix: 'staging',
    reportUserAnalytics: false,
    debugProductIframe: false,
    tours: true,
    errorOnUnhandledRejections: true,
    angularDebugMode: true,
    storeDevTools: true,
    overrideConsole: false,
    logToConsole: true,
    logObjectToConsole: true,
    fullstory: false,
    captureAnalytics: false,
  },
  REMOTE_PROD: {
    cookieSuffix: null,
    mockAuth0: false,
    reportUserAnalytics: false,
    debugProductIframe: false,
    tours: true,
    errorOnUnhandledRejections: true,
    angularDebugMode: true,
    storeDevTools: true,
    overrideConsole: false,
    logToConsole: true,
    logObjectToConsole: true,
    fullstory: false,
    captureAnalytics: false,
  },
};
