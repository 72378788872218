import { FieldFilterOperator, LogsToMetricsAggregationMethod } from '@logz-pkg/enums';
import { Transform } from 'class-transformer';
import { BasicFieldFilter } from '../multi-field-filter/basic';

export class LogsToMetricsAggregationModel {
  methods: LogsToMetricsAggregationMethod[];
  histogramBuckets?: number[];
  targetField: string;
}

export class LogsToMetricsLabelsModel {
  key: string;
  value: string;
}

const shouldConvert = operator => operator === FieldFilterOperator.Is || operator === FieldFilterOperator.IsNot;

const mapIsOperatorValueToArray = filter => ({
  ...filter,
  value: shouldConvert(filter.operator) ? [filter.value] : filter.value,
});

const mapIsOperatorValueFromArray = filter => ({
  ...filter,
  value: shouldConvert(filter.operator) ? filter.value?.[0] : filter.value,
});

export class LogsToMetricsRuleModel {
  id: string;
  name: string;
  description?: string;
  metricsAccountId: number;
  @Transform(filters => filters.map(mapIsOperatorValueFromArray), { toClassOnly: true })
  @Transform(filters => filters.map(mapIsOperatorValueToArray), { toPlainOnly: true })
  filters: BasicFieldFilter[];
  dimensionFields: string[];
  countLogs?: boolean;
  aggregations?: LogsToMetricsAggregationModel[];
  labels?: LogsToMetricsLabelsModel[];
  @Transform(value => value * 1000, { toClassOnly: true })
  createdAt: Date;
  createdBy: number;
  @Transform(value => value * 1000, { toClassOnly: true })
  updatedAt: Date;
  updatedBy: number;
  enabled: boolean;
}

export class LogsToMetricsRuleSearchResponseModel extends LogsToMetricsRuleModel {
  createdByName: string;
  updatedByName: string;
  metricsAccountName?: string;
}

export class LogsToMetricsCreateRuleRequestModel {
  name: string;
  description?: string;
  metricsAccountId?: number;
  @Transform(filters => filters.map(mapIsOperatorValueToArray), { toPlainOnly: true })
  filters: BasicFieldFilter[];
  dimensionFields: string[];
  countLogs?: boolean;
  aggregations?: LogsToMetricsAggregationModel[];
  labels?: LogsToMetricsLabelsModel[];
}
