import { UserRole } from '@logz-pkg/enums';
import { UserSession } from '@logz-pkg/models';
import { sessionApiService } from '../../../dal/session/api.service';
import { httpService } from '../../http/request.provider';
import { cacheProvider } from '../../cache/cache.provider';
import { authTokenService } from './auth-token.service';

// We shouldn't do any authentication required actions if we are in shareToken mode
const isAuthenticated = (): boolean => authTokenService.hasCurrentAccountId() && !authTokenService.hasShareToken();
const isIdentified = (): boolean => authTokenService.hasCurrentAccountId() || authTokenService.hasShareToken();

const getUser = async (): Promise<{ data?: UserSession }> => {
  if (!isIdentified()) return {};

  const { userSession } = await sessionApiService.get();

  const response = { data: userSession };

  if (response?.data?.admin === true) {
    authTokenService.setAdminAccountId(response.data.accountId);
  }

  return response;
};

const herokuSsoValidation = (id: number, token: string, timestamp: string): void => {
  httpService
    .post('/heroku/sso-validate', {
      accountId: id,
      token,
      timestamp,
    })
    .then(res => {
      cacheProvider.set(cacheProvider.CacheKeys.HEROKU, true);
      window.location.reload();
    });
};

const getUserSession = async (): Promise<UserSession> => {
  const user = await getUser();

  return user && user.data;
};

const isAdminRole = (): Promise<boolean> =>
  getUserSession().then(response => response && response.role === UserRole.Admin);

export const authService = {
  isIdentified,
  isAuthenticated,
  isAdmin: authTokenService.hasAdminAccountId,
  getUser,
  herokuSsoValidation,
  getUserSession,
  isAdminRole,
  hasShareToken: authTokenService.hasShareToken,
};
