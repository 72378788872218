import { DiscoverInsightModel } from '@logz-pkg/models';
import { ICollectionResponse, ISearchRequestObject } from '@logz-build/typescript';
import { CrudApiService } from '../utilities/crud.api.service';
import { ICrudApiService } from '../utilities/types';
import { IDiscoverInsightSearchFilter } from './types';

export class DiscoverInsightsApiService implements ICrudApiService<DiscoverInsightModel> {
  crudApiService = new CrudApiService<DiscoverInsightModel>();

  urls = {
    search: '/proxy/discover-insights/search',
  };

  search = async (
    payload: ISearchRequestObject | IDiscoverInsightSearchFilter,
  ): Promise<ICollectionResponse<DiscoverInsightModel>> => {
    return this.crudApiService.do(this.urls.search, {
      payload,
    });
  };
}

export const discoverInsightsApiService = new DiscoverInsightsApiService();
