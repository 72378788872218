import { Expose } from 'class-transformer';
import { UserRole, UserStatus } from '@logz-pkg/enums';

export const humanReadableRoles = {
  [UserRole.Siem]: 'Siem',
  [UserRole.ReadOnly]: 'Read Only',
  [UserRole.User]: 'User',
  [UserRole.Admin]: 'Admin',
  [UserRole.Expert]: 'Expert User',
  null: 'Configured per user',
};

export class BaseUserModel {
  username = '';
  fullName = '';
  role: UserRole = UserRole.User;
  accountID: number;
}

export class UserModel extends BaseUserModel {
  id: number;
  username = '';
  fullName = '';
  role: UserRole = UserRole.User;
  status?: UserStatus = UserStatus.Pending;
  @Expose({ name: 'accountID' }) accountId: number;
  @Expose({ name: 'active' }) isActive: boolean;
  @Expose({ toClassOnly: true }) getHumanReadableRole() {
    return humanReadableRoles[this.role];
  }
  @Expose({ toClassOnly: true }) getDomain() {
    this.username.substr(this.username.indexOf('@') + 1).toLowerCase();
  }
}
