import { Type } from 'class-transformer';

export class LogShippingTokenModel {
  id: number;
  token: string;
  name: string;
  enabled: boolean;
  updatedBy: string;
  createdBy: string;
  @Type(() => Date)
  updatedAt: number;
  @Type(() => Date)
  createdAt: number;
  @Type(() => Date)
  lastUsed?: number;
}
