import EventEmitter from 'events';

export const EVENTS = {
  NOTIFY_SEARCHED_ACCOUNTS_CHANGE: 'NOTIFY_SEARCHED_ACCOUNTS_CHANGE',
  NOTIFY_INDEXES_CHANGE: 'NOTIFY_INDEXES_CHANGE',
  AUTH_LOGOUT: 'AUTH_LOGOUT',
  RELOAD_PRODUCTS: 'RELOAD_PRODUCTS',
  PLAN_CHANGE: 'PLAN_CHANGE',
  PRE_KILL_PRODUCT_IFRAMES: 'PRE_KILL_PRODUCT_IFRAMES',
  SUB_ACCOUNTS_UPDATED: 'SUB_ACCOUNTS_UPDATED',
  ACCOUNT_NAME_UPDATED: 'ACCOUNT_NAME_UPDATED',
  ACCOUNT_SWITCH_MODE: 'ACCOUNT_SWITCH_MODE',
  DISCOVER_TAB_CHANGED: 'DISCOVER_TAB_CHANGED',
  UPDATE_HEADER_TITLE: 'UPDATE_HEADER_TITLE',
  EXPORT_STATE: 'EXPORT_STATE',
  STOP_LAST_REQUEST: 'STOP_LAST_REQUEST',
};

export const eventEmitter = new EventEmitter();
