import {
  RestoreLimitsModel,
  RestoreRequestModel,
  EstimationCostRequestModel,
  RestoreEstimationResponseModel,
  QueryValidationResponseModel,
} from '@logz-pkg/models';
import { ICrudApiService } from '../utilities/types';
import { CrudApiService } from '../utilities/crud.api.service';

class RestoreRequestApiService implements ICrudApiService<RestoreRequestModel> {
  crudApiService = new CrudApiService({ modelDefinition: RestoreRequestModel });
  limitsCrudApiService = new CrudApiService({ modelDefinition: RestoreLimitsModel });
  restoreEstimationCrudApiService = new CrudApiService({ modelDefinition: EstimationCostRequestModel });

  urls = {
    collection: 'restore-request',
    delete: (id: RestoreRequestModel['id']) => `restore-request/${id}`,
    limits: 'restore-request/limits',
    estimatedCost: 'restore-request/estimate',
    queryValidation: 'restore-request/query/validate',
  };

  create = async (model: RestoreRequestModel): Promise<RestoreRequestModel> =>
    this.crudApiService.create(this.urls.collection, model);

  getAll = async (): Promise<RestoreRequestModel[]> => this.crudApiService.getAll(this.urls.collection);

  delete = async (id: RestoreRequestModel['id']): Promise<RestoreRequestModel> =>
    this.crudApiService.delete(this.urls.delete(id));

  getLimits = async (): Promise<RestoreLimitsModel> => this.limitsCrudApiService.get(this.urls.limits);

  getEstimatedCost = async (estimationCostData: EstimationCostRequestModel): Promise<RestoreEstimationResponseModel> =>
    this.restoreEstimationCrudApiService.do(this.urls.estimatedCost, { payload: estimationCostData });

  getQueryValidation = async (query: string): Promise<QueryValidationResponseModel> =>
    this.restoreEstimationCrudApiService.do(this.urls.queryValidation, { payload: { query } });
}

export const restoreRequestApiService = new RestoreRequestApiService();
