import _ from 'lodash';
import { KibanaFilterType, ElasticFilterType, IElasticFilter } from '../filter-interfaces';
import IsOneOfFilterModel, { IElasticShould } from '../models/is-one-of-filter-model';
import KibanaFilterMetadata from '../metadatas/osd-filter-metadata';
import BaseFilterParser from './base-filter-parser';

export class KibanaIsOneOfFilterMetadata extends KibanaFilterMetadata {
  constructor(
    public params: string[],
    key: string,
    value: string,
    negate = false,
    indexPattern: string,
    alias: string = null,
    disabled = false,
  ) {
    super(key, value, KibanaFilterType.Phrases, negate, indexPattern, alias, disabled);
  }
}

/**
 * Elastic to Kibana 'IS-ONE-OF' filter parser
 */
export default class IsOneOfFilterParser extends BaseFilterParser {
  constructor() {
    super(KibanaFilterType.Phrases, ElasticFilterType.Bool);
  }

  getMetadata(filter: IElasticFilter, negate = false, indexPattern): KibanaIsOneOfFilterMetadata {
    let retVal = null;

    if (filter[this.elasticKey] && this.isShouldFilterExist(filter)) {
      const key = this.getFieldName(filter.bool.should[0].match_phrase);
      const values = _.map(filter.bool.should, (value: IElasticShould) => {
        if (value.match_phrase) return value.match_phrase[key];
      });

      retVal = new KibanaIsOneOfFilterMetadata(values, key, values.join(', '), negate, indexPattern);
    }

    return retVal;
  }

  getFilter(metadata: KibanaIsOneOfFilterMetadata): IsOneOfFilterModel {
    if (!this.assertType(metadata)) return null;

    return new IsOneOfFilterModel(metadata);
  }

  private isShouldFilterExist(filter: IElasticFilter): boolean {
    return Array.isArray(filter.bool.should) && filter.bool.should[0]?.match_phrase !== undefined;
  }
}
