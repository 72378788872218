import { Transform } from 'class-transformer';
import { ContentObjectStatus, ContentObjectType } from '@logz-pkg/enums';

export class ContentObjectModel {
  id: string;
  elkAppId: number;
  name: string;
  description: string;
  type: ContentObjectType;
  importedObjectId: string;
  creatorAccountId: number;
  @Transform(definition => JSON.stringify(JSON.parse(definition), null, 2), { toClassOnly: true })
  contentData: string;
  status: ContentObjectStatus;
  createDate: number;
  updateDate: number;
  updateDataDate: number;
  tags: string[];
}
