import { Exclude, Expose, Type, Transform } from 'class-transformer';
import { SeverityLevel, EventStatus, SiemEventType, EventModelGroupingType } from '@logz-pkg/enums';
import { UserModel } from '../../user/user';

@Exclude()
export class EventModel {
  @Expose()
  id: string;
  @Expose({ toClassOnly: true })
  alertEventId: string;
  @Expose({ toClassOnly: true })
  alertDefinitionId: number;
  @Expose()
  @Transform(status => (status || EventStatus.New) as typeof EventStatus, { toClassOnly: true })
  status: EventStatus;
  @Expose()
  assignee?: UserModel['id'];
  @Expose()
  comment?: string;

  @Expose({ toClassOnly: true })
  commentedBy?: UserModel['id'];

  @Expose({ toClassOnly: true })
  title: string;

  @Expose({ toClassOnly: true })
  description: string;

  @Expose({ toClassOnly: true })
  @Transform(severity => severity as typeof SeverityLevel, { toClassOnly: true })
  severity: SeverityLevel;

  @Expose({ toClassOnly: true })
  @Type(() => Date)
  @Transform(value => value * 1000, { toClassOnly: true })
  triggeredAt: Date;

  @Expose({ toClassOnly: true })
  @Type(() => Date)
  @Transform(value => (value ? value * 1000 : null), { toClassOnly: true })
  lastTriggeredAt: Date;

  @Expose({ toClassOnly: true })
  @Type(() => Date)
  @Transform(value => (value ? value * 1000 : null), { toClassOnly: true })
  updatedAt?: Date;

  @Expose({ toClassOnly: true })
  updatedBy?: UserModel['id'];

  @Expose({ toClassOnly: true })
  count?: number;

  @Exclude()
  mitreTags: string[] = [];

  @Exclude({ toPlainOnly: true })
  type?: SiemEventType;

  @Expose({ toClassOnly: true })
  @Transform(value => value ?? EventModelGroupingType.AlertBased)
  groupingType: EventModelGroupingType;
}
