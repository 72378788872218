/* eslint-disable max-lines */

import { isEmpty, sortBy } from 'lodash';
import { ACCOUNT_CREATION_STATES, ACCOUNT_STATES, AccountType } from '@logz-pkg/enums';
import { LogsAccountRequestModel, SessionModel, AccountUserModel } from '@logz-pkg/models';
import { searchableIndexesService } from '../../common/searchable/searchable-indexes.service';
import { cacheProvider } from '../cache/cache.provider';
import { sessionApiService } from '../../dal/session/api.service';
import { httpService, IRequestCacheOptions } from '../http/request.provider';
import { authService } from '../auth/services/auth.service';
import { userService } from './user/user.service';

const TIME_BASED_ACCOUNT_BASE = '/account-management/time-based-accounts';
const _accountLogTypesEndpoint = '/account/log-types';
const _accountFieldsEndpoint = '/account/fields';
const _getAccountUsersEndpoint = '/account/users';

export interface IAccountExtraDetails {
  id: number;
  type: string;
  esIndexPrefix: string;
  kafkaTopic: string;
}

export interface IAccountRecord extends IAccountExtraDetails {
  active: boolean;
  name: string;
  region: string;
}

export interface IAccountsTable {
  total: number;
  results: IAccountRecord[];
  pagination: {
    pageNumber: number;
    pageSize: number;
  };
}

const deleteSubAccount = async function (accountId) {
  return httpService
    .delete(`${TIME_BASED_ACCOUNT_BASE}/${accountId}`)
    .then(searchableIndexesService.clearSearchableCacheAndNotify);
};

const updateSubAccount = async function (accountId, postData: LogsAccountRequestModel) {
  return httpService
    .put(`${TIME_BASED_ACCOUNT_BASE}/${accountId}`, postData)
    .then(searchableIndexesService.clearSearchableCacheAndNotify);
};

const updateMainAccount = async function (
  accountName,
  sharingAccountIds,
  isLogSizeSaved,
  utilizationSettings,
  softLimitGB,
  snapSearchRetentionDays,
) {
  const params = {
    accountName,
    sharingAccountIds,
    docSizeSetting: isLogSizeSaved,
    utilizationSettings,
  };

  if (softLimitGB) {
    (params as any).softLimitGB = softLimitGB;
  }

  if (snapSearchRetentionDays) {
    (params as any).snapSearchRetentionDays = snapSearchRetentionDays;
  }

  return httpService.put('/account/owner-account', params).then(searchableIndexesService.clearSearchableCacheAndNotify);
};

const createSubAccount = async function (subAccountData) {
  return authService
    .getUser()
    .then(({ data: userSummary }) =>
      httpService.post(TIME_BASED_ACCOUNT_BASE, {
        ...subAccountData,
        email: userSummary.username, // add the email for the sub account
      }),
    )
    .then(({ data: createdSubAccount }) => createdSubAccount)
    .then(searchableIndexesService.clearSearchableCacheAndNotify);
};

const getSubAccount = function (accountId) {
  return httpService.get(`${TIME_BASED_ACCOUNT_BASE}/detailed/${accountId}`).then(resp => resp.data);
};

const getSubAccounts = function () {
  return httpService.get(`${TIME_BASED_ACCOUNT_BASE}/detailed`).then(resp => resp.data);
};

const clearAccountLogTypesCache = function () {
  cacheProvider.clear(_accountLogTypesEndpoint);
};

const getAccountLogTypes = function (): Promise<any> {
  return cacheProvider.get(
    _accountLogTypesEndpoint,
    () => authService.getUser().then(() => httpService.get(_accountLogTypesEndpoint).then(res => res.data)),
    5 * 60,
  ); // cache for 5 min
};

const clearAccountFieldsCache = function () {
  cacheProvider.getAllItems().forEach(item => {
    if (item.indexOf([`${cacheProvider.LOGZ_CACHE_PREFIX}.`, _accountFieldsEndpoint].join('')) !== 0) {
      return;
    }

    cacheProvider.clear(item.replace(`${cacheProvider.LOGZ_CACHE_PREFIX}.`, ''));
  });
};

const getAccountFieldsByType = function (logType, size, aggregatableOnly) {
  size = size || 3000;

  const logTypeQuery = logType ? `&logType=${logType}` : '';
  const aggregatableQuery = aggregatableOnly ? `&aggregatableOnly=${aggregatableOnly}` : '';

  const endpointName = `${_accountFieldsEndpoint}?size=${size}${aggregatableQuery}${logTypeQuery}`;

  return cacheProvider.get(endpointName, () => httpService.get(endpointName), 5 * 60); // cache for 5 min
};

const getAccountSettings = async function (): Promise<SessionModel['accountSettings']> {
  if (!authService.isAuthenticated()) {
    return {} as SessionModel['accountSettings'];
  }

  return (await sessionApiService.get()).accountSettings;
};

const clearAccountListCache = () => {
  cacheProvider.getAllItems().forEach(item => {
    if (item.indexOf(`${cacheProvider.LOGZ_CACHE_PREFIX}.${cacheProvider.CacheKeys.ACCOUNTS_LIST}`) === 0) {
      cacheProvider.clear(item.replace(`${cacheProvider.LOGZ_CACHE_PREFIX}.`, ''));
    }
  });
};

const searchAccounts = async (pageNumber = 1, pageSize = 60, query): Promise<IAccountsTable> => {
  const searchObject = {
    pagination: {
      pageNumber,
      pageSize,
    },
  };

  if (!isEmpty(query)) {
    const filterObject = {
      filter: {
        searchCriteria: query,
        field: parseInt(query, 10).toString() === query.toString() ? 'ID' : 'NAME',
      },
    };

    Object.assign(searchObject, filterObject);
  }

  return httpService.post('/__admin__/federated-accounts/search', searchObject).then(resp => resp.data);
};

const getAccountsDetailsByRegion = async (region, accountIds): Promise<IAccountExtraDetails[]> => {
  const accountsDetailsResponse = await httpService.post('/v1/__admin__/accounts', accountIds, { region });

  return accountsDetailsResponse.data.map(account => ({
    id: account.accountId,
    type: account.type,
    esIndexPrefix: account.accountSettings.esIndexPrefix,
    kafkaTopic: account.accountSettings.kafkaTopic,
  }));
};

const searchAccount = function (accountId): Promise<IAccountsTable> {
  return searchAccounts(1, 1, accountId);
};

const updateAccount = function (accountId, newState) {
  return httpService.post('/__admin__/account', {
    accountId,
    block: newState === ACCOUNT_STATES.BLOCKED,
  });
};

const deleteAccount = function (accountId) {
  return httpService.delete(`/__admin__/account/${accountId}`);
};

const getAccount = function getAccount(accountId: string | number, cacheOptions: IRequestCacheOptions = {}) {
  return httpService.get(`/__admin__/account/${accountId}`, undefined, undefined, cacheOptions).then(res => res.data);
};

const getUsers = function getUsers(): Promise<AccountUserModel[]> {
  return httpService.get(_getAccountUsersEndpoint).then(resp => resp.data.users);
};

const getCachedAccountUsers = function () {
  return cacheProvider.get(_getAccountUsersEndpoint, getUsers, 5 * 60); // cache for 5 min
};

const getAccountUsers = function (accountId) {
  return httpService.get(`/__admin__/account/${accountId}/users`).then(resp => resp.data.users);
};

const getAccountUsersSettings = function (accountId) {
  return httpService.get(`/__admin__/accounts/${accountId}/users/settings`).then(resp => resp.data);
};

const getUserAccounts = function () {
  return httpService.get('/user/accounts').then(accounts => accounts.data);
};

// DEV-29699: Refactor API file to crudApiService
const getAccountLoggerToken = async function () {
  const {
    data: { token },
  } = await httpService.get('/v1/log-shipping/tokens/default', undefined, undefined, { cache: true });

  return token;
};

const isAccountStateReady = async ({ tryFresh = true } = {}) => {
  if (authService.hasShareToken()) return true;

  // When a user comes back from long wait and the auth token is invalid/expired
  // the get summary is running and causing the system to redirect to the login page multiple times
  if (!authService.isAuthenticated()) {
    throw new Error('Not Authenticated');
  }

  // first naively check cache if the account is ready
  return userService.getSummary().then(cacheRes => {
    if (cacheRes.data.accountState === ACCOUNT_CREATION_STATES.READY) {
      return true;
    }

    if (!tryFresh) return false;

    // then, if not, try get fresh results every 2s to see if there's a status change
    return userService.getSummary(true).then(res => res.data.accountState === ACCOUNT_CREATION_STATES.READY);
  });
};

const getUsersFilterList = () => {
  let accountUsers = [];

  return Promise.all([getCachedAccountUsers(), authService.getUser()]).then(([usersRes, userRes]) => {
    usersRes.forEach(userData => {
      // Will be added at the top of the list after sorting it
      if (userData.user.fullName === userRes.data.fullName) {
        return;
      }

      accountUsers.push({
        fullName: userData.user.fullName,
        username: userData.user.username,
        userId: userData.user.userId,
      });
    });

    accountUsers = sortBy(accountUsers, 'fullName');
    accountUsers.splice(0, 0, {
      fullName: userRes.data.fullName,
      username: userRes.data.username,
      userId: userRes.data.userId,
      myUser: true,
    });

    return accountUsers;
  });
};

const getAccountType = (): Promise<AccountType | void> =>
  userService
    .getSummary()
    .then(({ data }) => data.accountType)
    .catch(() => {});

const activateFlexiblePlan = () => httpService.post(`${TIME_BASED_ACCOUNT_BASE}/enable-flexibility`);

const deactivateFlexiblePlan = () => httpService.post(`${TIME_BASED_ACCOUNT_BASE}/disable-flexibility`);

export const accountService = {
  deleteSubAccount,
  updateSubAccount,
  updateMainAccount,
  createSubAccount,
  getSubAccount,
  getSubAccounts,
  clearAccountLogTypesCache,
  getAccountLogTypes,
  clearAccountFieldsCache,
  getAccountFieldsByType,
  getAccountSettings,
  clearAccountListCache,
  searchAccount,
  updateAccount,
  deleteAccount,
  getAccount,
  getUsers,
  getCachedAccountUsers,
  getAccountUsers,
  getUserAccounts,
  isAccountStateReady,
  getUsersFilterList,
  getAccountType,
  activateFlexiblePlan,
  deactivateFlexiblePlan,
  getAccountLoggerToken,
  searchAccounts,
  getAccountsDetailsByRegion,
  getAccountUsersSettings,
};
