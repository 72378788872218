import { objectDeepCompare } from 'deep-compare-any-order';
import _ from 'lodash';
import { authService } from '../../core/auth/services/auth.service';
import { sessionStateService } from '../../state/session.state.service';
import { appStateService } from '../../core/identity/app-state/app-state.service';
import { cacheProvider } from '../../core/cache/cache.provider';
import { eventEmitter, EVENTS } from '../eventEmitter.service';
import { searchableIndexesService } from './searchable-indexes.service';

const QUERY_SOURCE_PREFIX = 'logzioAccountId-';
const extractSourceRegex = /^logzioAccountId-(\d+)$/;
const searchedAccountsTemplate = { accountIds: [], allSelected: false, showAccountNames: false };
const KIBANA_SEARCHED_ACCOUNTS_STATE_SCHEMA = cacheProvider.CacheKeys.KIBANA_SEARCHED_ACCOUNTS_STATE;

const getSearchedCache = () => {
  return cacheProvider.getCached(KIBANA_SEARCHED_ACCOUNTS_STATE_SCHEMA) || {};
};

const setSearchedCache = newState => {
  const state = { ...searchedAccountsTemplate, ...newState };

  cacheProvider.set(KIBANA_SEARCHED_ACCOUNTS_STATE_SCHEMA, state, 3600);
};

const getSharedState = () => {
  const actualSearchableIds = searchableIndexesService.getSearchableAccounts().map(({ accountId }) => accountId);
  const sharedState = getSearchedCache();

  if (!Array.isArray(sharedState?.accountIds)) return;

  const validStateIds = sharedState.accountIds.filter(accountId => actualSearchableIds.includes(accountId));

  return {
    ...sharedState,
    accountIds: validStateIds,
  };
};

const setSharedState = (newState, service = false) => {
  let cachedState = getSearchedCache();

  cachedState = { ...cachedState, ...newState };
  setSearchedCache(cachedState);

  if (service && authService.isAuthenticated()) {
    appStateService.set(KIBANA_SEARCHED_ACCOUNTS_STATE_SCHEMA, cachedState);
  }

  return cachedState;
};

const serializeValues = searchedState => {
  let serializedState = _.cloneDeep(searchedState);

  if (_.size(serializedState) === 0) {
    serializedState = _.cloneDeep(searchedAccountsTemplate);
  }

  const isStateInvalid =
    !serializedState || (_.size(serializedState.accountIds) === 0 && serializedState.allSelected === false);

  if (isStateInvalid) {
    // DEV-26147: The session is not fetched and stored in share mode
    const session = sessionStateService.session.get();

    if (session?.appMode?.value === 'SECURITY') {
      serializedState = { accountIds: [], allSelected: true };
    } else {
      const currentAccountId = searchableIndexesService.getCurrentAccountSearchableIndex().accountId;

      serializedState = { accountIds: [currentAccountId], allSelected: false };
    }
  }

  return serializedState;
};

const setSearchedAccountState = async ({
  accountIds,
  allSelected,
  notify = false,
  performSearchOnChange = false,
  forceNotify = false,
  locationReplace = false,
}) => {
  const setAndNotify = async ({ hasChanged }) => {
    const searchedState = setSharedState({ accountIds, allSelected });

    if (forceNotify || (hasChanged && notify)) {
      // Temp fix for transition problem - remove with new product iframe
      eventEmitter.emit(EVENTS.NOTIFY_SEARCHED_ACCOUNTS_CHANGE, { locationReplace });
    }

    if (hasChanged) {
      if (performSearchOnChange) eventEmitter.emit(EVENTS.NOTIFY_INDEXES_CHANGE);

      if (authService.isAuthenticated()) {
        return appStateService.set(KIBANA_SEARCHED_ACCOUNTS_STATE_SCHEMA, searchedState);
      }
    }

    return searchedState;
  };
  const state = await getSearchedState();

  if (!state) {
    return setAndNotify({ hasChanged: true });
  }

  let hasChanged = !objectDeepCompare(
    { accountIds, allSelected },
    { accountIds: state.accountIds, allSelected: state.allSelected },
  );

  const stayedAllSelected = allSelected && state.allSelected;

  if (stayedAllSelected) {
    hasChanged = false;
  }

  return setAndNotify({ hasChanged });
};

const getSearchedState = async () => {
  const sharedState = getSharedState();

  if (sharedState) return sharedState;

  return appStateService.get(KIBANA_SEARCHED_ACCOUNTS_STATE_SCHEMA).then(searched => {
    setSearchedCache(serializeValues(searched));

    return getSharedState();
  });
};

const getSearchedAccountIds = async () => {
  const { accountIds } = await getSearchedState();

  return accountIds;
};

const extractAccountsFromSource = _source => {
  const excludes = _.get(_source, 'excludes');

  if (!_.isArray(excludes) || excludes.length === 0) return [];

  return (
    _.chain(excludes)
      .map(accountPlaceholder => {
        const [, accountId] = extractSourceRegex.exec(accountPlaceholder);

        return searchableIndexesService.getSearchableAccountByAccountId(accountId);
      })
      // get rid of unavailable searchable accounts
      .filter(item => !_.isNil(item))
      .map('accountId')
      .value()
  );
};

const getAccountsWithPrefix = (accountIds: (string | number)[]) => {
  return accountIds.map(accountId => `${QUERY_SOURCE_PREFIX}${accountId}`);
};

const getSearchedAccountsWithPrefix = () => {
  const sharedState = getSharedState();

  if (!sharedState) return [];

  const cachedSearchedAccounts = sharedState.accountIds;

  return getAccountsWithPrefix(cachedSearchedAccounts);
};

const getCachedSearchedIndexes = () => getSharedState()?.accountIds;

const getSearchedAccounts = () => {
  const searchedState = getSharedState();

  // handle uninitialized account selector state
  if (!searchedState?.accountIds?.length) return [];

  return searchedState.allSelected ? searchedState.allSelected : searchedState.accountIds;
};

const getSearchedAccountsStateParamObject = () => {
  const searchedState = getSharedState();

  if (!searchedState) return null;

  if (searchedState.allSelected === true) return 'true';

  if (searchedState.accountIds.length === 0) return null;

  return searchedState.accountIds.map(String);
};

const setKibanaRouteSearchedAccounts = async ({
  accounts,
  locationReplace = false,
}: { accounts?: string[] | boolean | string; locationReplace?: boolean } = {}) => {
  const performSearchOnChange = true;

  if (_.isArray(accounts)) {
    const accountIds = accounts.map(id => +id);

    await setSearchedAccountState({ performSearchOnChange, accountIds, allSelected: false, locationReplace });
  } else if (accounts === true || accounts === 'true') {
    // when no accounts are specified, search on all accounts
    // this will give us backward compatibility for the share behaviour
    await setSearchedAccountState({ performSearchOnChange, accountIds: [], allSelected: true, locationReplace });
  } else if (_.isString(accounts) || _.isNumber(accounts)) {
    await setSearchedAccountState({
      performSearchOnChange,
      accountIds: [+accounts],
      allSelected: false,
      locationReplace,
    });
  }
};

const accountUrlParams = {
  ALL_ACCOUNTS: '&accountIds=true',
  buildSearchedAccounts: accountIds => `&${accountIds.map(accountId => `accountIds=${accountId}`).join('&')}`,
};

const getSearchedAccountsQueryParams = () => {
  const searchedState = getSharedState();

  // handle uninitialized account selector state
  if (!searchedState) return '';

  // this will mark accountIds in state as true
  if (searchedState.allSelected === true) return accountUrlParams.ALL_ACCOUNTS;

  if (searchedState.accountIds.length === 0) return '';

  return accountUrlParams.buildSearchedAccounts(searchedState.accountIds);
};

export const searchedIndexesService = {
  QUERY_SOURCE_PREFIX,
  getSearchedAccountsQueryParams,
  accountUrlParams,
  getSharedState,
  getSearchedState,
  getSearchedAccountIds,
  extractAccountsFromSource,
  getAccountsWithPrefix,
  getSearchedAccountsWithPrefix,
  getCachedSearchedIndexes,
  getSearchedAccounts,
  getSearchedAccountsStateParamObject,
  setSharedState,
  setSearchedAccountState,
  setKibanaRouteSearchedAccounts,
};
