import { MetricsDataOptimizationModel } from '@logz-pkg/models';
import { ICrudApiService } from '../utilities/types';
import { CrudApiService } from '../utilities/crud.api.service';

export class MetricsDataOptimizationApiService implements ICrudApiService<MetricsDataOptimizationModel> {
  crudApiService = new CrudApiService({ modelDefinition: MetricsDataOptimizationModel });
  urls = {
    resource: `data-optimization/metrics/recommendations`,
  };

  getAll = async (): Promise<MetricsDataOptimizationModel[]> => {
    return this.crudApiService.getAll(this.urls.resource);
  };
}

export const metricsDataOptimizationApiService = new MetricsDataOptimizationApiService();
