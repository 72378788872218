type LogPatternHighlight = {
  start: number;
  end: number;
};

export class LogPatternModel {
  text: string;
  id: number;
  highlights: LogPatternHighlight[];
}
