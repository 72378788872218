import { cloneDeep } from 'lodash';
import { SharedTokenModel, TokenFilterModel } from '@logz-pkg/models';
import { tokenFiltersApiService } from '@logz-pkg/frontend-services';

const populateFilters = async (
  sharedToken: SharedTokenModel,
  filters: TokenFilterModel[],
): Promise<SharedTokenModel> => {
  const sharedTokenCopy = cloneDeep(sharedToken);
  const filterIds = sharedTokenCopy.filters ? (sharedTokenCopy.filters as unknown as TokenFilterModel['id'][]) : [];

  sharedTokenCopy.filters = filterIds.map(filterId => filters.find(filter => filter.id === filterId));

  return sharedTokenCopy;
};

export const populateSharedToken = async (sharedTokens: SharedTokenModel[]): Promise<SharedTokenModel[]> => {
  const filters = await tokenFiltersApiService.getAll();

  return Promise.all(
    sharedTokens.map(async sharedToken => {
      let populatedSharedToken = cloneDeep(sharedToken);

      populatedSharedToken = await populateFilters(sharedToken, filters);

      return populatedSharedToken;
    }),
  );
};
