import { ICollectionResponse, ISearchRequestObject } from '@logz-build/typescript';
import { Metadata, ProjectResource, ProjectSpec } from '@perses-dev/core';
import { UnifiedDashboardModel } from '@logz-pkg/models';
import { CrudApiService } from '../utilities/crud.api.service';
import { ICrudApiService } from '../utilities/types';

export class PersesProject implements ProjectResource {
  kind: 'Project';
  metadata: Metadata;
  spec: ProjectSpec;
}

class ProjectModel {
  id: string;
  name: string;
  doc: ProjectResource;
  createdBy: string;
  updatedAt: string;
}

export class ProjectsModel {
  project: ProjectModel;
  dashboards: UnifiedDashboardModel[];
}

export class ProjectApiService implements ICrudApiService<PersesProject> {
  crudApiService = new CrudApiService({ modelDefinition: PersesProject });

  urls = {
    resource: `/perses/api/v1/projects`,
    search: `/perses/api/v1/projects/search`,
  };

  getAllDetailed = async (params?: { withDashboards: boolean }): Promise<ProjectsModel[]> => {
    return this.crudApiService.get(this.urls.resource, undefined, params);
  };

  getOne(name: string): Promise<ProjectModel> {
    return this.crudApiService.get(`${this.urls.resource}/${name}`);
  }

  createProject = async (name: string): Promise<ProjectModel> => {
    const persesProjectObject: ProjectResource = {
      kind: 'Project',
      spec: {
        display: {
          name,
        },
      },
      metadata: {
        name,
      },
    };

    return this.crudApiService.create(this.urls.resource, persesProjectObject) as unknown as ProjectModel;
  };

  update = async (model: ProjectResource, params: { name: string }): Promise<ProjectResource> => {
    return this.crudApiService.update(`${this.urls.resource}/${params.name}`, model);
  };

  searchForProjects = async (requestObject: ISearchRequestObject): Promise<ICollectionResponse<ProjectsModel>> => {
    return this.crudApiService.search(this.urls.search, requestObject) as unknown as ICollectionResponse<ProjectsModel>;
  };

  renameFolder(folderId: string, newFolderName: string): Promise<PersesProject> {
    return this.crudApiService.update(`${this.urls.resource}/${folderId}/rename`, {
      newProjectName: newFolderName,
    } as any);
  }

  deleteFolder(folderId: string): Promise<PersesProject> {
    return this.crudApiService.delete(`${this.urls.resource}/${folderId}`);
  }
}

export const projectsApiService = new ProjectApiService();
