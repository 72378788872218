import { TextToLuceneModel } from '@logz-pkg/models';
import { CrudApiService } from '@logz-pkg/frontend-services/src/dal/utilities/crud.api.service';
import { ICrudApiService } from '@logz-pkg/frontend-services/src/dal/utilities/types';
import { ITextToLuceneSearch } from './types';

export class TextToLuceneApiService implements ICrudApiService<TextToLuceneModel> {
  crudApiService = new CrudApiService<TextToLuceneModel>();

  urls = {
    search: '/text-to-lucene/generate',
  };

  fetchTextToLucene = async (payload: ITextToLuceneSearch): Promise<TextToLuceneModel> =>
    this.crudApiService.do(this.urls.search, { payload });
}

export const textToLuceneApiService = new TextToLuceneApiService();
