export enum TelemetryAgentParamType {
  Boolean = 'boolean',
  MultilinePattern = 'multiline-pattern',
  PathList = 'path-list',
  StringList = 'string-list',
  SingleSelect = 'single-select',
  MultiSelect = 'multi-select',
  Text = 'text',
  Number = 'number',
  KeyValue = 'key-value',
}

export enum TelemetryAgentInformationElementType {
  Info = 'info',
  Announcement = 'announcement',
}

export enum TelemetryAgentStatus {
  Enabled = 'ENABLED',
  Disabled = 'DISABLED',
  Failed = 'FAILED',
  Suspended = 'SUSPENDED',
}

export enum TelemetryAgentIntegrationCapability {
  SPM = 'SPM',
}

export enum TelemetryAgentIntegrationType {
  Fetcher = 'FETCHER',
  Agent = 'AGENT',
}
