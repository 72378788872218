import { AccountType } from '@logz-pkg/enums';
import { AccountModel } from './account';

const readableAccountType = {
  [AccountType.Owner]: 'Owner Account',
  [AccountType.Sub]: 'Sub Account',
  [AccountType.Security]: 'Security Account',
  [AccountType.Timeless]: 'Timeless Account',
  [AccountType.Metrics]: 'Metrics Account',
  [AccountType.Restore]: 'Restore Account',
  [AccountType.Tracing]: 'Tracing Account',
};

class AccountModelManipulator {
  getReadableType = (key: AccountType) => readableAccountType[key];

  isOwner = ({ type }: AccountModel): boolean => type === AccountType.Owner;
  isSub = ({ type }: AccountModel): boolean => type === AccountType.Sub;
  isSecurity = ({ type }: AccountModel): boolean => type === AccountType.Security;
  isTimeless = ({ type }: AccountModel): boolean => type === AccountType.Timeless;
  isMetrics = ({ type }: AccountModel): boolean => type === AccountType.Metrics;
  isRestore = ({ type }: AccountModel): boolean => type === AccountType.Restore;
  isTracing = ({ type }: AccountModel): boolean => type === AccountType.Tracing;

  /**
   * @deprecated
   */
  deprecatedIsOwner = (accountType: AccountType) => accountType === AccountType.Owner;
  /**
   * @deprecated
   */
  deprecatedIsTimeless = (accountType: AccountType) => accountType === AccountType.Timeless;
  /**
   * @deprecated
   */
  deprecatedIsMetrics = (accountType: AccountType) => accountType === AccountType.Metrics;
}

export const accountModelManipulator = new AccountModelManipulator();
