import { plainToClass } from 'class-transformer';
import { convertBytesToGigaBytes } from '@logz-pkg/utils';
import { DashboardLinksModel, ProductUsageModel } from '@logz-pkg/models';
import { ICrudApiService } from '../../utilities/types';
import { CrudApiService } from '../../utilities/crud.api.service';
import { usageMock } from './usage.mock';

export class UsageResponse {
  usage: ProductUsageModel[];
}

class PlanUsageApiService implements ICrudApiService<UsageResponse> {
  crudApiService = new CrudApiService({ modelDefinition: UsageResponse });
  dashboardApiService = new CrudApiService({ modelDefinition: DashboardLinksModel });

  urls = {
    usage: '/v2/plan/usage',
    quickSight: 'usage/quicksight-dashboard-link',
  };

  getUsage = async (isUsageV2: boolean): Promise<UsageResponse> => {
    if (!isUsageV2) {
      return usageMock.getUsage();
    }

    const response = await this.crudApiService.get(this.urls.usage);

    const usageResponse = plainToClass(UsageResponse, response);

    usageResponse.usage = plainToClass(
      ProductUsageModel,
      usageResponse.usage.map(product => ({
        ...product,
        usages: product.usages.map(usg => ({
          date: new Date(+usg.date),
          value: convertBytesToGigaBytes(usg.value),
        })),
      })),
    );

    return usageResponse;
  };

  getQuicksightDashboardUrl = async (dashboardType?: keyof DashboardLinksModel): Promise<DashboardLinksModel> => {
    return this.dashboardApiService.get(this.urls.quickSight, { fresh: true }, { dashboardType });
  };
}

export const planUsageApiService = new PlanUsageApiService();
