import { Type, Expose, Transform } from 'class-transformer';
import moment, { Moment } from 'moment';
import { ElasticsearchFilterOperator, IElasticsearchFilterValue } from '../elasticsearch/elasticsearch-filter';

export interface IRestoreFiltersRequest {
  operatorType: string;
  fieldName: string;
  fieldValues?: (
    | IElasticsearchFilterValue[ElasticsearchFilterOperator.Exists]
    | IElasticsearchFilterValue[ElasticsearchFilterOperator.Is]
    | IElasticsearchFilterValue[ElasticsearchFilterOperator.IsOneOf]
  )[];
}

export class RestoreRequestModel {
  id: number;
  accountName: string;
  volume: number;
  volumeInBytes: number;
  status: string;
  createdBy: string;
  query?: string;
  filters: IRestoreFiltersRequest[];
  restoreAccountId?: number;
  @Expose({ toPlainOnly: true }) archiveSettingsId: number;

  @Type(() => Date)
  @Transform(value => moment(value * 1000), { toClassOnly: true })
  @Transform(value => value.seconds(0).valueOf(), { toPlainOnly: true })
  startTime: Moment;

  @Type(() => Date)
  @Transform(value => moment(value * 1000), { toClassOnly: true })
  @Transform(value => value.seconds(0).valueOf(), { toPlainOnly: true })
  endTime: Moment;

  @Type(() => Date)
  @Transform(value => moment(value * 1000), { toClassOnly: true })
  @Transform(value => value?.valueOf(), { toPlainOnly: true })
  createdAt: Moment;

  @Type(() => Date)
  @Transform(value => moment(value * 1000), { toClassOnly: true })
  @Transform(value => value?.valueOf(), { toPlainOnly: true })
  finishedAt: Moment;

  @Type(() => Date)
  @Transform(value => moment(value * 1000), { toClassOnly: true })
  @Transform(value => value?.valueOf(), { toPlainOnly: true })
  expiresAt: Moment;
}

export class EstimationCostRequestModel {
  query: string;
  startTime: Moment;
  endTime: Moment;
  constructor({ query, startTime, endTime }) {
    this.endTime = endTime;
    this.startTime = startTime;
    this.query = query;
  }
}

export class RestoreEstimationResponseModel {
  estimatedScanSizeInGB: number;
  costInUSD: number;
}

export class QueryValidationResponseModel {
  errorMsg: string;
  success: boolean;
}
