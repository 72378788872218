import { LogShippingTokenModel, LogShippingTokenLimitsModel } from '@logz-pkg/models';
import { ICollectionResponse, ISearchRequestObject } from '@logz-build/typescript';
import { ICrudApiService } from '../utilities/types';
import { CrudApiService } from '../utilities/crud.api.service';

export class LogShippingTokenApiService implements ICrudApiService<LogShippingTokenModel> {
  limitApiService = new CrudApiService({ modelDefinition: LogShippingTokenLimitsModel });
  crudApiService = new CrudApiService({ modelDefinition: LogShippingTokenModel });
  urls = {
    create: '/v1/log-shipping/tokens',
    limits: '/v1/log-shipping/tokens/limits',
    default: '/v1/log-shipping/tokens/default',
    search: '/v1/log-shipping/tokens/search',
    resource: id => `/v1/log-shipping/tokens/${id}`,
    all: '/v1/log-shipping/tokens',
  };
  async getLimits(): Promise<LogShippingTokenLimitsModel> {
    const data = await this.limitApiService.get(this.urls.limits);

    return data;
  }

  search = async (requestObject: ISearchRequestObject): Promise<ICollectionResponse<LogShippingTokenModel>> => {
    const results = await this.crudApiService.search(this.urls.search, requestObject);

    return results;
  };

  get = async id => {
    return this.crudApiService.get(this.urls.resource(id));
  };

  create = async token => {
    return this.crudApiService.create(this.urls.create, token);
  };

  delete = id => {
    return this.crudApiService.delete(this.urls.resource(id));
  };

  update = async (token: LogShippingTokenModel) => {
    return this.crudApiService.update(this.urls.resource(token.id), token);
  };

  async getDefaultToken(): Promise<LogShippingTokenModel> {
    return this.crudApiService.get(this.urls.default);
  }
}

export const logShippingTokenApiService = new LogShippingTokenApiService();
