import moment, { Moment } from 'moment';
import { Exclude, Transform } from 'class-transformer';
import { QueryOperator } from '@logz-pkg/enums';
import { ApiTokenModel } from './api-token';
import { TokenFilterModel } from './token-filter';

export class SharedTokenModel extends ApiTokenModel {
  @Transform((filters: TokenFilterModel[]) => filters.map(filter => filter.id), { toPlainOnly: true })
  filters: TokenFilterModel[] = [];

  @Exclude()
  declare createdAt: Moment;

  @Transform((value: Moment) => (value ? value.valueOf() / 1_000 : null), { toPlainOnly: true })
  @Transform((value: number) => (value ? moment(value * 1_000) : null), { toClassOnly: true })
  expirationDate: Moment;

  filtersOperator?: QueryOperator.AND | QueryOperator.OR = QueryOperator.OR;
}
