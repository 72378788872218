import { ICrudApiService } from '../utilities/types';
import { CrudApiService } from '../utilities/crud.api.service';

export class InvitationApiService implements ICrudApiService<{}> {
  crudApiService = new CrudApiService();

  urls = {
    verify: '/invitations/verify',
  };

  verify = async (verificationToken: string) =>
    this.crudApiService.do(this.urls.verify, { payload: { verificationToken } });
}

export const invitationApiService = new InvitationApiService();
