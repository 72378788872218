import { Permissions } from '@logz-pkg/enums';
import { sessionApiService } from '../session/api.service';

const get = async (): Promise<Permissions[]> => {
  const { userSession } = await sessionApiService.get();

  return userSession?.permissions ?? [];
};

export const userPermissionsApiService = { get };
