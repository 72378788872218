import { Expose } from 'class-transformer';
import { MetricsAccountPlanModel } from './metrics-account-plan';

const sumAllDailyDpm = (total, { hourlyAverageUTS = 0 }) => total + hourlyAverageUTS;

const accountDailyUsageAvg = ({ dailyUsage }) =>
  !dailyUsage.length ? 0 : dailyUsage.reduce(sumAllDailyDpm, 0) / dailyUsage.length;

export class MetricsAccountBudgetModel {
  dpm: number;
  hasMetrics: boolean;
  maxMetricsSubAccounts: number;
  metricsAccounts: MetricsAccountPlanModel[];
  budgetUTS: number;

  @Expose({ toClassOnly: true })
  get hasActiveMetricsAccounts() {
    return this.metricsAccounts?.length > 0;
  }

  @Expose({ toClassOnly: true })
  get uniqueMetrics() {
    return this.budgetUTS;
  }

  @Expose({ toClassOnly: true })
  get totalUsage() {
    return this.metricsAccounts.reduce((usage, account) => usage + accountDailyUsageAvg(account), 0);
  }
}
