import { PciModel } from '@logz-pkg/models';
import { plainToClass } from 'class-transformer';
import { ICrudApiService } from '../utilities/types';
import { CrudApiService } from '../utilities/crud.api.service';

export class PciApiService implements ICrudApiService<PciModel> {
  crudApiService = new CrudApiService<PciModel>({
    modelDefinition: PciModel,
  });

  urls = {
    resource: () => `self-service/pci`,
  };

  get = () => this.crudApiService.get(this.urls.resource());

  activate = () => {
    const data = this.crudApiService.do(this.urls.resource());

    return plainToClass(PciModel, data);
  };

  updateStatus = (pciModel: PciModel) => {
    return this.crudApiService.update(this.urls.resource(), {
      accountPciStatus: pciModel.status,
    } as unknown as PciModel);
  };
}

export const pciApiService = new PciApiService();
