import { UserRole, AccountType } from '@logz-pkg/enums';
import { Expose, Transform, Type } from 'class-transformer';
import { humanReadableRoles } from '../user/user';
import { AccountModel } from '../account/account';

export class AuthenticationGroupModel {
  group = '';
  userRole: UserRole = null;

  @Expose({ toClassOnly: true })
  @Transform((_, column) => column.group, { toClassOnly: true })
  id: string;

  @Expose({ toClassOnly: true }) getHumanReadableRole() {
    return humanReadableRoles[this.userRole];
  }
}

export class AuthGroupModel {
  groupName = '';
  userRole: UserRole = null;

  @Type(() => AccountModel)
  accounts: AccountModel[] = [];

  @Expose({ toClassOnly: true })
  @Transform((_, column) => column.groupName, { toClassOnly: true })
  id: string;

  @Expose({ toClassOnly: true }) getHumanReadableRole() {
    return humanReadableRoles[this.userRole];
  }
}

export class AuthGroupUpdateRequestModel extends AuthGroupModel {
  originalGroupName: string;
}

export class AuthGroupValidationRequestModel {
  groupName: string;
}

export class AuthGroupBulkDeleteRequestModel {
  accountType: AccountType;
  groupName: string;
}

export class AuthGroupValidationResponseModel {
  valid: boolean;
  reason?: string;
}
