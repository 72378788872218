import { ISearchRequestObject } from '@logz-build/typescript';
import { UnifiedDashboardModel } from '@logz-pkg/models';
import { GlobalDatasourceResource, DatasourceSpec, ProjectMetadata } from '@perses-dev/core';
import { CrudApiService } from '../utilities/crud.api.service';
import { ICrudApiService } from '../utilities/types';

const buildParams = (params: Record<string, string>) => {
  if (!params || !Object.entries(params).length) return '';

  return `?${new URLSearchParams(params)}`;
};

class DatasourceResponse implements GlobalDatasourceResource {
  kind: 'GlobalDatasource';
  metadata: ProjectMetadata;
  spec: DatasourceSpec;
}

export class DashboardApiService implements ICrudApiService<UnifiedDashboardModel> {
  crudApiService = new CrudApiService({ modelDefinition: UnifiedDashboardModel });
  datasourceApiService = new CrudApiService({ modelDefinition: DatasourceResponse });

  urls = {
    project: `/perses/api/v1/dashboards`,
    resource: folderId => `/perses/api/v1/projects/${encodeURIComponent(folderId)}/dashboards`,
    instance: (uid: string, folderId: string, params?: Record<string, string>) =>
      `/perses/api/v1/projects/${encodeURIComponent(folderId)}/dashboards/${encodeURIComponent(uid)}${buildParams(
        params,
      )}`,
    globalDatasources: `/perses/api/v1/globaldatasources`,
    search: `/perses/api/v1/dashboards/search`,
    getDashboardsOwnerUsers: `/perses/api/v1/dashboards/users`,
    move: `/perses/api/v1/dashboards/move`,
  };

  getAll = async (): Promise<UnifiedDashboardModel[]> => {
    return this.crudApiService.getAll(this.urls.project);
  };

  getOne(name: string, folderId: string, source?: 'grafana'): Promise<UnifiedDashboardModel> {
    return this.crudApiService.get(this.urls.instance(name, folderId, { source }));
  }

  createDashboard = async (folderId: string, model: Partial<UnifiedDashboardModel>): Promise<UnifiedDashboardModel> => {
    return this.crudApiService.create(this.urls.resource(folderId), model as UnifiedDashboardModel);
  };

  update = async (model: Pick<UnifiedDashboardModel, 'uid' | 'doc'>, folderId): Promise<UnifiedDashboardModel> => {
    return this.crudApiService.update(this.urls.instance(model.uid, folderId), {
      doc: model.doc,
    } as UnifiedDashboardModel);
  };

  getGlobalDatasources = async () => {
    return this.datasourceApiService.getAll(this.urls.globalDatasources);
  };

  deleteDashboard = async (dashboardId: string, folderId: string) => {
    return this.crudApiService.delete(this.urls.instance(dashboardId, folderId));
  };

  search = async (requestObject: ISearchRequestObject) => {
    return this.crudApiService.search(this.urls.search, requestObject);
  };

  move = async (dashboardId: string, oldFolderId: string, newFolderId: string) => {
    return this.crudApiService.update(this.urls.move, {
      dashboardId,
      oldProjectId: oldFolderId,
      newProjectId: newFolderId,
    } as any);
  };

  getDashboardsOwnerUsers = async () => {
    return this.crudApiService.get<{ id: number; fullName: string }[]>(this.urls.getDashboardsOwnerUsers);
  };
}

export const dashboardsApiService = new DashboardApiService();
