import { GlobalSearchResultModel, GrafanaResultType, GrafanaSearchResultModel, SearchSource } from '@logz-pkg/models';
import { httpService } from '../../core/http/request.provider';

class MetricsSearchApiService {
  private grafanaTypeToSource = {
    [GrafanaResultType.Dashboard]: SearchSource.GrafanaDashboard,
    [GrafanaResultType.Folder]: SearchSource.GrafanaFolder,
  };

  async getGrafanaObjects(query: string): Promise<GlobalSearchResultModel[]> {
    const url = query ? `/grafana-app/api/search?query=${query}` : '/grafana-app/api/search';
    const { data } = await httpService.get(url);

    const results: GlobalSearchResultModel[] = data.map((result: GrafanaSearchResultModel) => ({
      source: this.grafanaTypeToSource[result.type],
      title: result.title,
      link: result.url.replace('/grafana-app', '#/dashboard/metrics'),
      state: {
        params: result,
      },
    }));

    return results;
  }
  async getGrafanaObject(id: string | number): Promise<GrafanaSearchResultModel> {
    const url =
      typeof id === 'number' ? `/grafana-app/api/dashboards/id/${id}` : `/grafana-app/api/dashboards/uid/${id}`;

    try {
      const { data } = await httpService.get(url);

      return { ...data.dashboard, isStarred: data.meta.isStarred };
    } catch (err) {}
  }
}

export const metricsSearchApiService = new MetricsSearchApiService();
