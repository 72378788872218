import { MetricsOperator } from '@logz-pkg/enums';
import { Expose } from 'class-transformer';
import { MetricsFilterModel } from './metrics-filter';

export interface IMetricsDropFilterModel {
  id?: number;
  accountId: number;
  filter: {
    operator: MetricsOperator;
    expression: MetricsFilterModel[];
  };

  active: boolean;
  createdAt?: string;
  createdBy?: string;
  modifiedAt?: string;
  modifiedBy?: string;
}

export class MetricsDropFilterModel implements IMetricsDropFilterModel {
  id?: number;
  accountId: number;
  filter: { operator: MetricsOperator; expression: MetricsFilterModel[] };
  active: boolean;
  createdAt?: string;
  createdBy?: string;
  modifiedAt?: string;
  modifiedBy?: string;

  @Expose({ toClassOnly: true })
  get metricName() {
    return this.filter.expression.find(ex => ex.name === '__name__').value;
  }
}
