import { ICrudApiService } from '../utilities/types';
import { CrudApiService } from '../utilities/crud.api.service';

export class EmailVerificationApiService implements ICrudApiService<{}> {
  crudApiService = new CrudApiService();

  urls = {
    verify: '/verify-email',
  };

  verify = async (verificationToken: string): Promise<{ email: string }> =>
    this.crudApiService.do(this.urls.verify, { payload: { verificationToken } });
}

export const emailVerificationApiService = new EmailVerificationApiService();
