import * as yup from 'yup';
import { TelemetryAgentParamType } from '../enums';
import { oneOfEnum, paramBaseSchema, paramMetaDataSchema } from './shared.schemas';

const stringListParamBaseSchema = paramBaseSchema.shape({
  type: oneOfEnum({ type: TelemetryAgentParamType.StringList }).required('param type required'),
  placeholder: yup.string().nullable(),
  actionText: yup.string().nullable(),
  pattern: yup.string().nullable(),
  patternError: yup.string().nullable(),
  value: yup.array().nullable(), // this field's base schema is overridden in its extending schemas
});

export const stringListParamManifestSchema = stringListParamBaseSchema.concat(paramMetaDataSchema).shape({
  value: yup.array().of(yup.string().nullable()).strict(),
});

export const stringListParamSchema = yup.lazy(param => {
  let schema = stringListParamBaseSchema;

  let valueSchema = yup.string();

  if (param.value?.length > 1) {
    valueSchema = valueSchema.required();
  }

  if (param.pattern) {
    valueSchema = valueSchema.matches(
      new RegExp(param.pattern, 'gi'),
      param.patternError ?? `value does not match pattern: ${param.pattern}`,
    );
  }

  if (param.required) {
    valueSchema = yup.array().of(valueSchema).strict().min(1, 'at least one item is required');
  } else {
    valueSchema = yup.array().of(valueSchema).strict();
  }

  schema = schema.shape({
    value: valueSchema,
  });

  return schema;
});
