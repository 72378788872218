import { GlobalNotificationsResponseModel } from '@logz-pkg/models';
import { ICrudApiService } from '../utilities/types';
import { CrudApiService } from '../utilities/crud.api.service';

class GlobalNotificationsApiService implements ICrudApiService<GlobalNotificationsResponseModel> {
  crudApiService = new CrudApiService({ modelDefinition: GlobalNotificationsResponseModel });

  urls = {
    collection: '/global-notifications',
    dismiss: id => `/global-notifications/dismiss/${id}`,
  };

  get = () => this.crudApiService.get(this.urls.collection);
  dismiss = (id: string) => this.crudApiService.do(this.urls.dismiss(id));
}

export const globalNotificationsApiService = new GlobalNotificationsApiService();
