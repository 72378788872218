import moment from 'moment';
import { LogPatternModel } from './log-pattern';

export class LogPatternRecordModel {
  firstOccurrenceInTimeWindow: moment.Moment;
  pattern: LogPatternModel;
  count: number;
  percentage: number;
  storageSize?: number;
  storageSizeRatioPercentage?: number;
}
