import { Expose } from 'class-transformer';
import { AccountType } from '@logz-pkg/enums';

export class AccountModel {
  esIndexPrefix: string;
  @Expose({ name: 'accountId' }) id: number;
  @Expose({ name: 'accountName' }) name: string;
  @Expose({ name: 'active' }) isActive: boolean;
  @Expose({ name: 'accountType' }) type: AccountType;
}
