import { withEnv, setCookie, getCookie, removeCookie } from '../cookies.provider';

export function cookiesService(): Record<string, any> {
  return {
    getCookieValue: (name: string, suppressEncSuffix: string): string => {
      if (suppressEncSuffix) {
        return getCookie(name);
      }

      return withEnv.get(name);
    },
    setCookie: (name: string, value, suppressEncSuffix: string): string => {
      if (suppressEncSuffix) {
        return setCookie(name, value);
      }

      return withEnv.set(name, value);
    },
    removeCookie: (name: string, suppressEncSuffix: string): void => {
      if (suppressEncSuffix) {
        return removeCookie(name);
      }

      return withEnv.remove(name);
    },
  };
}
