import { Transform } from 'class-transformer';
import moment from 'moment';
import { PLAN_TYPE } from '@logz-pkg/enums';

export class TracingBudgetModel {
  monthlySpansNum: number;
  retention: number;
  maxTracingAccountsNum: number;
  @Transform(value => moment(value * 1000), { toClassOnly: true })
  @Transform(value => value?.valueOf(), { toPlainOnly: true })
  createdAt: number;
  @Transform(value => (value === null ? null : moment(value * 1000)), { toClassOnly: true })
  @Transform(value => (value === null ? null : value.valueOf()), { toPlainOnly: true })
  expiresAt: number;
  type: PLAN_TYPE.TRIAL | PLAN_TYPE.PRO | PLAN_TYPE.FREE;
  dailyGB: number;
  maxDailyGBHardCap: number;
}
