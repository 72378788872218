import { Type, Expose, Transform, Exclude } from 'class-transformer';
import { KibanaFieldModel } from './osd-field';
import { KibanaDashboardModel } from './osd-dashboard';

export class KibanaDrilldownModel {
  fieldName: KibanaFieldModel['name'] | undefined = undefined;
  @Expose({ name: 'protected', toClassOnly: true })
  isProtected: boolean;
  // When sending to the server, send the dashboard ID taken from the dashboard object attached to the object.
  @Transform((value, obj) => obj.dashboard && obj.dashboard.id, { toPlainOnly: true })
  dashboardId: KibanaDashboardModel['id'] | null = null;
  // This data isn't coming from the API. It's added with another request, but can come with a JSON dashboard from a
  // form like KibanaDashboardSelect.
  @Exclude({ toPlainOnly: true })
  @Type(() => KibanaDashboardModel)
  dashboard: KibanaDashboardModel | undefined = undefined;
  // This is helping us workaround the fact that we don't really get an ID, and we return the value of fieldName
  // as the ID.
  @Expose()
  get id(): KibanaFieldModel['name'] {
    return this.fieldName;
  }
}
