import { Type } from 'class-transformer';

export class AnnouncementModel {
  id: string;
  isRead = false;
  title: string;
  content = '';
  externalLink: string;
  @Type(() => Date)
  createdOn: Date;
}
