import { parse, format } from 'url';
import qs, { stringify } from 'query-string';
import axios, { AxiosRequestConfig, Method, AxiosResponse } from 'axios';
import uuid from 'uuid-browser';
import { merge, cloneDeep, throttle } from 'lodash';
import { SystemRegion } from '@logz-pkg/models';
import { urlParser } from '@logz-pkg/utils';
import { cookiesProvider } from '../auth/cookies.provider';
import { authTokens } from '../auth/tokens';
import { authTokenService } from '../auth/services/auth-token.service';
import { cacheProvider } from '../cache/cache.provider';
import { TimeConstants } from '@logz-pkg/enums';

let currentAccountRegion = null;
let systemRegions: SystemRegion[] = [];

export const setCurrentAccountRegion = (region: string): void => {
  currentAccountRegion = region;
};

export const setSystemRegions = (regions: SystemRegion[]): void => {
  systemRegions = cloneDeep(regions);
};

const LOGZIO_XSRF_TOKEN_NAME = 'X-Logz-CSRF-Token-V2';
const LOGZIO_XSRF_COOKIE_NAME = 'Logzio-Csrf-V2';
const REQUEST_ID_HEADER = 'X-REQUEST-ID';
const SOURCE_PAGE_URL = 'X-SOURCE-PAGE-URL';
const getInterceptors = (): Record<string, any> => axios.interceptors;

// `xsrfCookieName` is the name of the cookie to use as a value for xsrf token
// in places using http (local) we want to add -insecure suffix so we can have two kinds of this cookie and they won't collide (chrome)
// https://stackoverflow.com/questions/52763345/browsers-ignore-set-cookie-response-header-if-we-try-to-set-a-cookie-which-was-s
axios.defaults.xsrfCookieName = LOGZIO_XSRF_COOKIE_NAME;
// `xsrfHeaderName` is the name of the http header that carries the xsrf token value
axios.defaults.xsrfHeaderName = LOGZIO_XSRF_TOKEN_NAME;

const isAccountImpersonated = accountId => accountId.includes('i-');

// this cookie is used to save time when open a new tab
// so instead of requesting authorized accounts we can get last used account from the cookie
const updateLastUsedAccountIdCookie = throttle(accountId => {
  // if an admin is impersonating, we don't want to override the last_used_account with the impersonated user.
  // we want to stick with the admin account on new tabs.
  if (isAccountImpersonated(accountId)) return;

  if (authTokenService.hasCurrentAccountId()) {
    const expirationDate = new Date();

    expirationDate.setHours(expirationDate.getHours() + 1);

    cookiesProvider.withEnv.set(authTokens.LAST_USED_ACCOUNT_ID_COOKIE, accountId, {
      expires: expirationDate,
    });
  }
}, TimeConstants.MillisecondsInMinute);

export const getDefaultHeaders = (method: Method = 'get'): Record<string, string> => {
  const xsrfCookie = cookiesProvider.getCookie(LOGZIO_XSRF_COOKIE_NAME);
  const defaultContentType = method === 'post' ? { 'content-type': 'application/json' } : {};
  const shareToken = authTokens.getShareToken();
  const { hashPathname } = urlParser(window.location.href);
  const headers = { [REQUEST_ID_HEADER]: uuid.v1(), [SOURCE_PAGE_URL]: hashPathname, ...defaultContentType };

  if (xsrfCookie) {
    headers[LOGZIO_XSRF_TOKEN_NAME] = xsrfCookie;
  }

  if (shareToken) {
    return {
      ...headers,
      [authTokens.SHARE_TOKEN_HEADER]: shareToken,
      [authTokens.USER_TOKEN_HEADER]: shareToken,
    };
  }

  const currentAccountId = authTokenService.getCurrentAccountId();

  if (currentAccountId) {
    headers[authTokens.CURRENT_ACCOUNT_ID_HEADER_NAME] = currentAccountId;

    updateLastUsedAccountIdCookie(currentAccountId);
  }

  const adminAccountId = authTokenService.getAdminAccountId();

  if (adminAccountId) {
    headers[authTokens.ADMIN_ACCOUNT_ID_HEADER_NAME] = adminAccountId;
  }

  return headers;
};

const generateHttpResponseCacheKey = (url: string, data?: any): string => {
  // Remove the prefix `/` from the url
  const urlKeySegment = url ? `.${url.replace(/^\//g, '')}` : '';
  const dataKeySegment = data ? `.${JSON.stringify(data)}` : '';

  return `${cacheProvider.CacheKeys.HTTP_RESPONSE}${urlKeySegment}${dataKeySegment}`;
};

const getFreshAxiosCall = async (req: Record<string, any>): Promise<Record<string, any>> => {
  // Remove the `config` since they contain sensitive data.
  const { config, ...response } = await axios.request(req);

  return response;
};

export interface IRequestMeta {
  region?: string;
  dontShowProgressBar?: boolean;
}

export type RequestParams = Record<string, unknown>;

export interface IRequestCacheOptions {
  cache?: boolean;
  fresh?: boolean;
  secondsToLive?: number;
}

async function createAxiosCall<Response>(
  method: Method,
  url: string,
  options: AxiosRequestConfig = {},
  meta: null | IRequestMeta = {},
  { cache = false, fresh = false, secondsToLive }: IRequestCacheOptions = {},
): Promise<AxiosResponse<Response>> {
  const req = merge(
    {
      method,
      url,
      meta,
      headers: getDefaultHeaders(method),
      paramsSerializer: qs.stringify,
    } as AxiosRequestConfig,
    options,
  );

  if (req.headers['content-type'] === 'application/x-www-form-urlencoded') {
    req.data = stringify(req.data);
  }

  if (options.data instanceof FormData && options.data.has('file')) {
    req.headers['content-type'] = 'multipart/form-data';
  }

  const accountRegionName = meta?.region || currentAccountRegion;

  if (accountRegionName && url.includes('/__admin__')) {
    const accountRegion = systemRegions.find(({ regionName }) => accountRegionName === regionName);

    if (accountRegion) {
      req.baseURL = format({ ...parse(accountRegion.adminConsoleUrl), protocol: window.location.protocol });
      req.withCredentials = true;
    }
  }

  if (cache) {
    const cacheKey = generateHttpResponseCacheKey(url, options?.data ?? options?.params);

    if (fresh) {
      cacheProvider.clear(cacheKey);
    }

    try {
      const cacheResponse = cacheProvider.get(cacheKey, () => getFreshAxiosCall(req), secondsToLive);

      return cacheResponse;
    } catch (e) {
      throw new Error('Failed to get cache response');
    }
  }

  return axios.request<Response>(req);
}

// Clear all cache starting with this URL
const clearCache = (url: string): void => {
  cacheProvider.clearByPrefix(generateHttpResponseCacheKey(url, null));
};

async function get<Response = any>(
  url: string,
  params: RequestParams = undefined,
  meta: IRequestMeta = undefined,
  cacheOptions: IRequestCacheOptions = {},
  config: AxiosRequestConfig = {},
): Promise<AxiosResponse<Response>> {
  return createAxiosCall('get', url, { params, ...config }, meta, cacheOptions);
}

async function post<Response = any>(
  url: string,
  data: any = undefined,
  meta: IRequestMeta = undefined,
  cacheOptions: IRequestCacheOptions = {},
  config: AxiosRequestConfig = {},
): Promise<AxiosResponse<Response>> {
  return createAxiosCall('post', url, { data, ...config }, meta, cacheOptions);
}

async function put<Response = any>(
  url: string,
  data: any = undefined,
  meta: IRequestMeta = undefined,
): Promise<AxiosResponse<Response>> {
  return createAxiosCall('put', url, { data }, meta);
}

async function patch<Response = any>(
  url: string,
  data: any = undefined,
  meta: IRequestMeta = undefined,
): Promise<AxiosResponse<Response>> {
  return createAxiosCall('patch', url, { data }, meta);
}

async function del<Response = any>(
  url: string,
  params: RequestParams = undefined,
  meta: IRequestMeta = undefined,
): Promise<AxiosResponse<Response>> {
  return createAxiosCall('delete', url, { params }, meta);
}

export const httpService = {
  get,
  post,
  put,
  patch,
  del,
  delete: del,
  getInterceptors,
  adapter: axios,
  clearCache,
  getDefaultHeaders,
  generateHttpResponseCacheKey,
  setCurrentAccountRegion,
  setSystemRegions,
};
