import * as yup from 'yup';
import { TelemetryAgentParamType } from '../enums';
import { oneOfEnum, paramBaseSchema, paramMetaDataSchema, selectOptionsSchema } from './shared.schemas';

const multiSelectParamBaseSchema = paramBaseSchema.shape({
  type: oneOfEnum({ type: TelemetryAgentParamType.MultiSelect }).required('param type required'),
  value: yup.array().nullable(),
  options: selectOptionsSchema.min(1),
});

export const multiSelectParamSchema = multiSelectParamBaseSchema.shape({
  value: yup.array().when(['required'], {
    is: required => required,
    then: yup.array().required(),
    otherwise: yup.array().nullable(),
  }),
});

export const multiSelectParamManifestSchema = multiSelectParamBaseSchema.concat(paramMetaDataSchema).shape({
  value: yup.array().nullable(),
});
