import { isNil } from 'lodash';
import moment from 'moment';
import { LookupElementModel } from './lookup-element';

const roundDays = number => Math.max(1, Math.ceil(number.toFixed(1)));

const pluralizeDay = (dayValue: number): string => {
  if (dayValue === 1) return `${dayValue} day`;

  return `${dayValue} days`;
};

const toReadableValue = (timeEpoch: number, base: number = moment().unix()): string => {
  if (isNil(timeEpoch)) {
    return 'No expiration';
  }

  if (timeEpoch < base) {
    return 'Expired';
  }

  const parsedTime = moment.unix(timeEpoch);
  const duration = moment.duration(parsedTime.diff(moment.unix(base)));
  const relativeDays = pluralizeDay(roundDays(duration.asDays()));

  return relativeDays;
};

const toEpochValue = (relativeValue: string, base: number = moment().unix()): number => {
  if (relativeValue === 'No expiration') return null;

  const days = parseInt(relativeValue, 10);

  return moment.unix(base).add(days, 'days').unix();
};

const isExpired = (record: LookupElementModel): boolean =>
  record.expirationTime === null || !(record.expirationTime < Date.now() / 1000);

class LookupElementModelManipulator {
  toRelativeTime = toReadableValue;
  toEpochTime = toEpochValue;
  isExpired = isExpired;
  pluralizeDay = pluralizeDay;
}

export const lookupElementModelManipulator = new LookupElementModelManipulator();
