import _ from 'lodash';
import KibanaFilterMetadata from '../metadatas/osd-filter-metadata';
import { KibanaFilterType, ElasticFilterType, IElasticFilter } from '../filter-interfaces';
import GreaterThanFilterModel from '../models/greater-than-filter-model';
import BaseFilterParser from './base-filter-parser';

/**
 * Elastic to Kibana 'GREATER THAN' filter parser
 */
export default class GreaterThanFilterParser extends BaseFilterParser {
  constructor() {
    super(KibanaFilterType.Range, ElasticFilterType.Range);
  }

  getMetadata(filter: IElasticFilter, negate = false, indexPattern): KibanaFilterMetadata {
    let retVal = null;

    if (filter[this.elasticKey]) {
      const key = this.getFieldName(filter.range);
      const range = filter.range[key];
      const from = _.has(range, 'gte') ? _.get(range, 'gte') : _.get(range, 'gt');
      const to = _.has(range, 'lte') ? _.get(range, 'lte') : _.get(range, 'lt');
      const value = [from || '-∞', to || '+∞'].join(' to ');

      retVal = new KibanaFilterMetadata(key, value, this.type, negate, indexPattern);
      retVal.params = { gte: from, lt: to };
    }

    return retVal;
  }

  getFilter(metadata: KibanaFilterMetadata): GreaterThanFilterModel {
    if (!this.assertType(metadata)) return null;

    return new GreaterThanFilterModel(metadata);
  }
}
