import { ICollectionResponse, ISearchRequestObject } from '@logz-build/typescript';

import { EventHistoryModel, EventModel } from '@logz-pkg/models';
import { ICrudApiService } from '../utilities/types';
import { CrudApiService } from '../utilities/crud.api.service';

export class EventHistoryApiService implements ICrudApiService<EventHistoryModel> {
  constructor(public eventId: EventModel['id']) {}

  crudApiService = new CrudApiService<EventHistoryModel>({ modelDefinition: EventHistoryModel });

  get urls() {
    return {
      search: `/event-manager/events/${this.eventId}/history`,
    };
  }

  search = (requestObject: ISearchRequestObject): Promise<ICollectionResponse<EventHistoryModel>> =>
    this.crudApiService.search(this.urls.search, requestObject);
}

export class CorrelatedEventHistoryApiService extends EventHistoryApiService {
  constructor(public eventId: EventModel['id']) {
    super(eventId);
    this.urls.search = `/v2/event-manager/siem/events/${this.eventId}/history`;
  }
}
