import { TimeConstants, LookupListTypes } from '@logz-pkg/enums';
import { Exclude, Expose, Transform, Type } from 'class-transformer';
import { AccountModel } from '../account/account';
import { alertModelManipulator } from '../alert/alert.manipulator';
import { ElasticsearchFilterModel } from '../elasticsearch/elasticsearch-filter';
import { StaticLookupListModel } from './static-lookup-list';

class ElasticQuery {
  query = '*';
  @Exclude({ toPlainOnly: true })
  groupBy?: string[] = [];
  shouldQueryOnAllAccounts = true;

  @Type(() => AccountModel)
  @Expose({ name: 'accountIdsToQueryOn' })
  @Transform((accounts: AccountModel[]) => accounts.map(account => account.id), { toPlainOnly: true })
  accountsToQueryOn: AccountModel[] = [];

  @Type(() => ElasticsearchFilterModel)
  @Transform((_, query) => alertModelManipulator.convertFiltersToElastic(query), { toPlainOnly: true })
  @Transform((value: object) => alertModelManipulator.convertElasticToFilters(value), { toClassOnly: true })
  filters: ElasticsearchFilterModel[] = [];
}

export class LookupListModel extends StaticLookupListModel {
  type?: LookupListTypes = LookupListTypes.STATIC;
  @Type(() => ElasticQuery)
  searchQuery?: ElasticQuery;
  timeframeToRunQueryOn?: number = 12 * TimeConstants.SecondsInHour;
  resultsFieldToPickFrom?: string;
  updateInterval?: string = '12 0 * * * * ?';
  constructor(type: LookupListTypes = LookupListTypes.STATIC) {
    super();

    if (type === LookupListTypes.DYNAMIC) {
      this.searchQuery = new ElasticQuery();
      this.updateInterval = `0 ${Math.floor(Math.random() * 24)} * * * ?`;
      this.type = LookupListTypes.DYNAMIC;
    }
  }
}
