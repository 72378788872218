import { Type } from 'class-transformer';
import { AccountModel } from './account';

export class AccountLogoModel {
  id: number;
  accountId: AccountModel['id'];
  logoUrl: string;
  logoHash: string;

  @Type(() => Date)
  createdAt: Date;

  @Type(() => Date)
  updatedAt: Date;
}
