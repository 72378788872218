import { ISearchRequestObject } from '@logz-build/typescript/src/types';
import { AccountLogTypeModel } from '@logz-pkg/models';
import { ICrudApiService } from '../utilities/types';
import { CrudApiService } from '../utilities/crud.api.service';

export class DataParsingApiService implements ICrudApiService<AccountLogTypeModel> {
  crudApiService = new CrudApiService({ modelDefinition: AccountLogTypeModel });

  urls = {
    search: '/account/dataparsing/log-types/search',
  };

  search = async (requestObject?: ISearchRequestObject) => this.crudApiService.search(this.urls.search, requestObject);
}

export const dataParsingApiService = new DataParsingApiService();
