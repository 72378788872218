import { ICollectionResponse, ISearchRequestObject } from '@logz-build/typescript';
import {
  AuthGroupModel,
  AuthGroupBulkDeleteRequestModel,
  AuthGroupValidationRequestModel,
  AuthGroupValidationResponseModel,
} from '@logz-pkg/models';
import { AccountType } from '@logz-pkg/enums';
import { ICrudApiService } from '../utilities/types';
import { CrudApiService } from '../utilities/crud.api.service';

interface IAuthGroupSearchRequestObject extends ISearchRequestObject {
  filter?: {
    searchTerm?: string;
    accountType?: AccountType;
  };
}

export class AuthGroupsApiService implements ICrudApiService<AuthGroupModel> {
  crudApiService = new CrudApiService<AuthGroupModel>({
    modelDefinition: AuthGroupModel,
  });

  urls = {
    collection: '/v2/authentication/groups/',
    search: '/v2/authentication/groups/search',
    bulkDelete: '/v2/authentication/groups/bulk/delete',
    validate: '/v2/authentication/groups/validate',
  };

  search = async (requestObject?: IAuthGroupSearchRequestObject): Promise<ICollectionResponse<AuthGroupModel>> => {
    return this.crudApiService.search(this.urls.search, requestObject);
  };

  create = async (model: AuthGroupModel) => {
    return this.crudApiService.create(this.urls.collection, model);
  };

  update = async (model: AuthGroupModel) => {
    return this.crudApiService.update(this.urls.collection, model);
  };

  deleteByAccountType = async (deleteItem: AuthGroupBulkDeleteRequestModel): Promise<AuthGroupModel> => {
    return this.crudApiService.do(this.urls.bulkDelete, { payload: deleteItem });
  };

  validate = async (groupName: string): Promise<AuthGroupValidationResponseModel> => {
    const payload: AuthGroupValidationRequestModel = {
      groupName,
    };
    const meta = {
      dontShowProgressBar: true,
    };

    return this.crudApiService.do(this.urls.validate, { payload, meta });
  };
}

export const authGroupsApiService = new AuthGroupsApiService();
