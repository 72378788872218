/* eslint-disable max-lines */
/* eslint-disable max-params */
/* eslint-disable consistent-return */
import { ICollectionResponse, ISearchRequestObject } from '@logz-build/typescript';
import { plainToClass, classToPlain } from 'class-transformer';
import { ServerStreamDataChunk } from '@logz-pkg/models';
import {
  httpService as _httpService,
  IRequestCacheOptions,
  IRequestMeta,
  RequestParams,
  getDefaultHeaders,
} from '../../core/http/request.provider';
import { EVENTS, eventEmitter } from '../../common/eventEmitter.service';
import { fromList, handleDalError, parseSSEData } from './utilities';
import {
  BulkActionsRes,
  BulkDeleteActionReq,
  BulkUpdateActionReq,
  DoOptions,
  ICacheInvalidationOptions,
  ICrudApiConstructorProps,
  ITransformationOptions,
  InvalidData,
  SearchOptions,
} from './types';
import { UniqueEntityModel } from './models/unique-entity.model';
import { CollectionEntityModel } from './models/collection-entity.model';

export class CrudApiService<T extends CollectionEntityModel | UniqueEntityModel, ICreateInput = T> {
  private modelDefinition;
  public httpService: typeof _httpService;
  private transformationOptions: ITransformationOptions;
  private xhr = new XMLHttpRequest();
  constructor({ modelDefinition, httpService = _httpService, transformationOptions }: ICrudApiConstructorProps<T> = {}) {
    this.modelDefinition = modelDefinition;
    this.httpService = httpService;
    this.transformationOptions = transformationOptions;
  }

  search = async (
    url: string,
    requestObject: ISearchRequestObject = {},
    options?: Partial<SearchOptions<T>>,
  ): Promise<ICollectionResponse<T>> => {
    try {
      requestObject.pagination = requestObject.pagination ?? { pageNumber: 1, pageSize: 25 };

      const { data } = await this.httpService[options?.httpMethod ?? 'post'](
        url,
        requestObject,
        null,
        options?.cacheOptions,
      );

      const response: ICollectionResponse<T> = Array.isArray(data) ? fromList(data) : data;

      if (this.modelDefinition) {
        if (Array.isArray(response.results)) {
          const transformedResults = [];
          const invalidData: InvalidData<T> = [];

          response.results.forEach(plainItem => {
            let transformedItem = null;

            try {
              transformedItem = plainToClass(this.modelDefinition, plainItem, this.transformationOptions);
            } catch (error) {
              if (options?.invalidDataHandler === undefined) {
                throw error;
              }

              invalidData.push({
                item: plainItem,
                error,
              });
            }

            if (transformedItem !== null) {
              transformedResults.push(transformedItem);
            }
          });

          if (options?.invalidDataHandler && invalidData.length > 0) {
            options.invalidDataHandler(invalidData);
          }

          response.results = transformedResults;
        } else {
          response.results = plainToClass(this.modelDefinition, response.results, this.transformationOptions);
        }
      }

      // Add app-side filter if requested
      if (options?.appSideFilterFn) {
        response.results = response.results.filter(options.appSideFilterFn);
        response.total = response.results.length;
      }

      // Add app-side sort if requested
      response.results = options?.appSideSortFn ? response.results.sort(options?.appSideSortFn) : response.results;

      if (!options?.useAppSidePagination) return response;

      // Add app-side pagination if requested
      response.results = response.results.slice(
        (requestObject.pagination.pageNumber - 1) * requestObject.pagination.pageSize,
        requestObject.pagination.pageNumber * requestObject.pagination.pageSize,
      );
      response.pagination = requestObject.pagination;

      return response;
    } catch (error) {
      handleDalError(error);
    }
  };

  getAll = async (url: string, cacheOptions?: IRequestCacheOptions, params?: RequestParams): Promise<T[]> => {
    try {
      const { data } = await this.httpService.get(url, params, null, cacheOptions);

      if (this.modelDefinition) {
        return plainToClass(this.modelDefinition, data, this.transformationOptions);
      }

      return data;
    } catch (error) {
      handleDalError(error);
    }
  };

  get = async <P = T>(
    url: string,
    cacheOptions?: IRequestCacheOptions,
    params?: RequestParams,
    meta?: IRequestMeta,
  ): Promise<P> => {
    try {
      const { data }: { data: P } = await this.httpService.get(url, params, meta, cacheOptions);

      if (this.modelDefinition) {
        return plainToClass(this.modelDefinition, data, this.transformationOptions);
      }

      return data;
    } catch (error) {
      handleDalError(error);
    }
  };

  delete = async (url: string, cacheInvalidationOptions?: ICacheInvalidationOptions): Promise<T> => {
    try {
      const { data }: { data: T } = await this.httpService.del(url);

      if (cacheInvalidationOptions?.urlsToInvalidateCache?.length > 0) {
        cacheInvalidationOptions.urlsToInvalidateCache.forEach(urlToInvalidate =>
          this.httpService.clearCache(urlToInvalidate),
        );
      }

      if (this.modelDefinition) {
        return plainToClass(this.modelDefinition, data, this.transformationOptions);
      }

      return data;
    } catch (error) {
      handleDalError(error);
    }
  };

  update = async (
    url: string,
    model: ICreateInput,
    cacheInvalidationOptions?: ICacheInvalidationOptions,
  ): Promise<T> => {
    try {
      const { data }: { data: T } = await this.httpService.put(url, classToPlain(model, this.transformationOptions));

      if (cacheInvalidationOptions?.urlsToInvalidateCache?.length > 0) {
        cacheInvalidationOptions.urlsToInvalidateCache.forEach(urlToInvalidate =>
          this.httpService.clearCache(urlToInvalidate),
        );
      }

      if (this.modelDefinition) {
        return plainToClass(this.modelDefinition, data, this.transformationOptions);
      }

      return data;
    } catch (error) {
      handleDalError(error);
    }
  };

  create = async (
    url: string,
    model?: ICreateInput,
    cacheInvalidationOptions?: ICacheInvalidationOptions,
  ): Promise<T> => {
    try {
      const { data }: { data: T } = await this.httpService.post(url, classToPlain(model, this.transformationOptions));

      if (cacheInvalidationOptions?.urlsToInvalidateCache?.length > 0) {
        cacheInvalidationOptions.urlsToInvalidateCache.forEach(urlToInvalidate =>
          this.httpService.clearCache(urlToInvalidate),
        );
      }

      if (this.modelDefinition) {
        return plainToClass(this.modelDefinition, data, this.transformationOptions);
      }

      return data;
    } catch (error) {
      handleDalError(error);
    }
  };
  bulkDelete = async (url: string, requestObject: BulkDeleteActionReq): Promise<BulkActionsRes> => {
    try {
      const { data } = await this.httpService.post(url, requestObject);

      return data;
    } catch (error) {
      handleDalError(error);
    }
  };
  bulkUpdate = async (url: string, requestObject: BulkUpdateActionReq): Promise<BulkActionsRes> => {
    try {
      const { data } = await this.httpService.post(url, requestObject);

      return data;
    } catch (error) {
      handleDalError(error);
    }
  };
  do = async <P = unknown>(
    url: string,
    { payload, cacheInvalidationOptions, meta, abortSignal }: DoOptions = {},
    cacheOptions?: IRequestCacheOptions,
  ): Promise<P> => {
    try {
      const config = abortSignal ? { signal: abortSignal } : undefined;
      const { data } = await this.httpService.post(url, payload, meta, cacheOptions, config);

      if (cacheInvalidationOptions?.urlsToInvalidateCache?.length > 0) {
        cacheInvalidationOptions.urlsToInvalidateCache.forEach(urlToInvalidate =>
          this.httpService.clearCache(urlToInvalidate),
        );
      }

      return data;
    } catch (error) {
      if (error?.config?.signal?.aborted) throw error;

      handleDalError(error);
    }
  };

  serverSentEvent = async <P = unknown>({
    url,
    payload,
    onData,
    onError,
    onEnd,
    abortSignal,
  }: {
    url: string;
    payload: P;
    onData: (res: ServerStreamDataChunk) => void;
    onError: (error: Error) => void;
    onEnd: () => void;
    abortSignal?: AbortSignal;
  }): Promise<void> => {
    let forceStop;
    const stopSearchSse = () => {
      forceStop = true;
    };

    abortSignal?.addEventListener('abort', () => {
      stopSearchSse();
      this.xhr.abort();
    });

    try {
      eventEmitter.on(EVENTS.STOP_LAST_REQUEST, stopSearchSse);

      let ongoing = false,
        start = 0;

      this.xhr.abort();

      const eventTarget = new EventTarget();

      this.xhr.open('post', url, true);

      const headers = getDefaultHeaders('post');

      for (const k in headers) {
        if (headers[k]) this.xhr.setRequestHeader(k, headers[k]);
      }

      this.xhr.onprogress = () => {
        if (forceStop) return;

        if (!ongoing) {
          ongoing = true;
          eventTarget.dispatchEvent(new Event('open'));
        }

        let i, chunk, parsedSSEData;

        while ((i = this.xhr.responseText.indexOf('\n\n', start)) >= 0 && !forceStop) {
          chunk = this.xhr.responseText.slice(start, i);
          start = i + 2;

          if (chunk.length) {
            eventTarget.dispatchEvent(new MessageEvent('message', { data: chunk }));
            parsedSSEData = parseSSEData(chunk);

            if (parsedSSEData === ' end\nend\n') {
              eventEmitter.on(EVENTS.STOP_LAST_REQUEST, stopSearchSse);
              onEnd();
            } else {
              onData({ message: parsedSSEData, status: 'other' });
            }
          }
        }
      };

      this.xhr.onload = () => {
        if (forceStop) return;

        if (this.xhr.status !== 0 && !(this.xhr.status >= 200 && this.xhr.status < 300)) {
          eventTarget.dispatchEvent(
            new CloseEvent('error', { reason: `HTTP Error: ${this.xhr.status} - ${this.xhr.statusText}` }),
          );
          eventEmitter.on(EVENTS.STOP_LAST_REQUEST, stopSearchSse);
          onError(new Error(`HTTP Error: ${this.xhr.status} - ${this.xhr.statusText}`));
        }
      };

      this.xhr.onreadystatechange = () => {
        if (forceStop) return;

        if (this.xhr.readyState === XMLHttpRequest.DONE) {
          if (this.xhr.status !== 0 && !(this.xhr.status >= 200 && this.xhr.status < 300)) {
            eventTarget.dispatchEvent(
              new CloseEvent('error', { reason: `HTTP Error: ${this.xhr.status} - ${this.xhr.statusText}` }),
            );
            eventEmitter.on(EVENTS.STOP_LAST_REQUEST, stopSearchSse);
            onError(new Error(`HTTP Error: ${this.xhr.status} - ${this.xhr.statusText}`));
          }
        }
      };

      this.xhr.ontimeout = () => {
        if (forceStop) return;

        eventEmitter.on(EVENTS.STOP_LAST_REQUEST, stopSearchSse);
        onError(new Error(`Error occured`));

        eventTarget.dispatchEvent(new CloseEvent('error', { reason: 'Network request timed out' }));
      };

      this.xhr.onerror = () => {
        if (forceStop) return;

        eventEmitter.on(EVENTS.STOP_LAST_REQUEST, stopSearchSse);
        onError(new Error('Error occured'));

        eventTarget.dispatchEvent(
          new CloseEvent('error', { reason: this.xhr.responseText || 'Network request failed' }),
        );
      };

      this.xhr.send(JSON.stringify(payload));
    } catch (error) {
      if (forceStop) return;

      handleDalError(error);
      eventEmitter.on(EVENTS.STOP_LAST_REQUEST, stopSearchSse);
      onError(error as Error);
    }
  };
}
