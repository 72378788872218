import { Expose } from 'class-transformer';

export class SiemAccountV2Model {
  @Expose()
  get id(): number {
    return this.accountId;
  }
  accountId: number;
  accountName: string;
  maxScanGB: number;
  maxDailyGB?: number;
  accountsToScan?: number[];
  sharingObjectsAccounts?: number[];
  created: number;
  isActive: boolean;
  isUsingRepositoryAccount: boolean;
}
