import { isNil } from 'lodash';
import { LogzioCacheKeys } from '@logz-pkg/enums';
import { authTokens } from '../auth/tokens';

const currentlyFetching = {};
const PERSIST_KEY = '_persistent_';
const DEFAULT_EXPIRE = 60 * 60 * 12; // default to 12 hours
const LOGZ_CACHE_PREFIX = 'logzCache';

const getCacheKeyPrefix = (): string => {
  if (!isNil(authTokens.getShareToken())) return '.share';

  return '';
};

const getFullCacheKey = (key: string): string => `${LOGZ_CACHE_PREFIX}${getCacheKeyPrefix()}.${key}`;

type CacheValue = boolean | Record<string, unknown> | string;

const set = <T = CacheValue>(key: string, value: T, secondsToLive = DEFAULT_EXPIRE): void => {
  if (!window.sessionStorage) return;

  const cacheObject = {
    value,
    exp: Date.now() + secondsToLive * 1000,
  };

  window.sessionStorage.setItem(getFullCacheKey(key), JSON.stringify(cacheObject));
};

const storeInCache = (value: Record<string, any>, key: string, secondsToLive: number): Record<string, any> => {
  set(key, value, secondsToLive);
  delete currentlyFetching[getFullCacheKey(key)];

  return value;
};

function isPromise(p) {
  if (typeof p === 'object' && typeof p.then === 'function') {
    return true;
  }

  return false;
}

const callGetterFunc = async <T>(getterFunc: () => Promise<T> | T, key: string, secondsToLive: number) => {
  const cacheValue =
    (typeof getterFunc === 'function' && (await getterFunc())) || (isPromise(getterFunc) && (await getterFunc));

  return storeInCache(cacheValue, key, secondsToLive);
};

const getCached = (key: string): any => {
  const value = window.sessionStorage.getItem(getFullCacheKey(key));

  if (typeof value === 'string') {
    const valObj = JSON.parse(value);

    if (valObj && typeof valObj === 'object' && valObj.exp && valObj.exp >= Date.now()) {
      return valObj.value;
    }
  }

  return null;
};

const hasCached = (key: string): boolean => {
  return Boolean(getCached(key));
};

const setCurrentlyFetching = <T>(
  key: string,
  getterFunc: () => Promise<T> | T,
  secondsToLive: number,
): Promise<Record<string, any>> => {
  const cacheKey = getFullCacheKey(key);

  if (!currentlyFetching.hasOwnProperty(cacheKey)) {
    currentlyFetching[cacheKey] = callGetterFunc(getterFunc, key, secondsToLive).catch(error => {
      // In case of failure, remove the promise from the holder, to not hand it every time.
      delete currentlyFetching[getFullCacheKey(key)];

      return Promise.reject(error);
    });
  }

  return currentlyFetching[cacheKey];
};

const get = async <T>(key: string, getterFunc?: () => Promise<T> | T, secondsToLive = DEFAULT_EXPIRE): Promise<any> => {
  // If Cached return the cached value;
  if (hasCached(key)) return getCached(key);

  return setCurrentlyFetching(key, getterFunc, secondsToLive);
};

const clear = (key: string): void => window.sessionStorage.removeItem(getFullCacheKey(key));

const getAllItems = (): string[] => Object.keys(window.sessionStorage);

const getPersistent = <T>(key: string): T => {
  const persistKey = `${PERSIST_KEY}.${key}`;

  if (localStorage.hasOwnProperty(persistKey)) {
    const rawValue = localStorage.getItem(persistKey);

    try {
      return JSON.parse(rawValue);
    } catch (err) {
      localStorage.removeItem(persistKey);
    }
  }

  return null;
};

const setPersistent = <T>(key: string, value: T): void =>
  localStorage.setItem(`${PERSIST_KEY}.${key}`, JSON.stringify(value));

const getPersistentTtl = <T>(key: string): T => {
  const item = getPersistent<T & { ttl?: number }>(key);

  if (!item) return null;

  const now = new Date().getTime();

  if (item.ttl && now > item.ttl) {
    localStorage.removeItem(key);

    return null;
  }

  return item;
};

const setPersistentTtl = <T>(key: string, value: T, ttl: number): void => {
  const now = new Date().getTime();

  setPersistent(key, { ...value, ttl: now + ttl });
};

const clearImpersistentLocalStorage = (): void =>
  Object.keys(localStorage)
    .filter(key => !key.includes(PERSIST_KEY))
    .forEach(key => localStorage.removeItem(key));

const clearPersistentLocalStorage = (): void =>
  Object.keys(localStorage)
    .filter(key => key.includes(PERSIST_KEY))
    .forEach(key => localStorage.removeItem(key));

const clearByPrefix = (prefix: string): void => {
  getAllItems().forEach(item => {
    if (item.startsWith(`${LOGZ_CACHE_PREFIX}.${prefix}`)) {
      clear(item.replace(`${LOGZ_CACHE_PREFIX}.`, ''));
    }
  });
};

export const cacheProvider = {
  get,
  set,
  clear,
  clearByPrefix,
  hasCached,
  getCached,
  getAllItems,
  getPersistent,
  setPersistent,
  getPersistentTtl,
  setPersistentTtl,
  clearPersistentLocalStorage,
  clearImpersistentLocalStorage,
  CacheKeys: LogzioCacheKeys,
  LOGZ_CACHE_PREFIX,
};
