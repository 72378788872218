import { ICollectionResponse, ISearchRequestObject } from '@logz-build/typescript';
import { EventModel } from '@logz-pkg/models';
import { BulkUpdateActionReq, ICrudApiService } from '../utilities/types';
import { CrudApiService } from '../utilities/crud.api.service';
import { populate } from './populate';

export class EventManagementApiService implements ICrudApiService<EventModel> {
  crudApiService = new CrudApiService<EventModel>({ modelDefinition: EventModel });

  urls = {
    search: '/event-manager/events/search',
    resource: (eventId: EventModel['id']): string => `/event-manager/events/${eventId}`,
    bulkUpdate: '/event-manager/events/bulk/update',
  };

  search = async (requestObject: ISearchRequestObject): Promise<ICollectionResponse<EventModel>> => {
    const response = await this.crudApiService.search(this.urls.search, requestObject);

    response.results = await populate.withMitreTags(response.results);

    return response;
  };

  get = (id: EventModel['id']): Promise<EventModel> => this.crudApiService.get(this.urls.resource(id));

  update = (event: EventModel): Promise<EventModel> => this.crudApiService.update(this.urls.resource(event.id), event);

  bulkUpdate = (requestObject: BulkUpdateActionReq) =>
    this.crudApiService.bulkUpdate(this.urls.bulkUpdate, requestObject);
}

export const eventManagementApiService = new EventManagementApiService();
