import * as yup from 'yup';
import { FieldFilterOperator, LogsToMetricsAggregationMethod } from '@logz-pkg/enums';

const oneOfEnum = <T>(enumObject: T) => yup.mixed<T>().oneOf(Object.values(enumObject));

const logsToMetricsFilterSchema = yup.object({
  field: yup.string().required(),
  operator: oneOfEnum(FieldFilterOperator).required(),
  value: yup.mixed().when(['operator'], {
    is: operator => [FieldFilterOperator.Exists, FieldFilterOperator.NotExists].includes(operator),
    then: yup.mixed().nullable(),
    otherwise: yup.mixed().required(),
  }),
});

const labelsObjectSchema = yup.object({
  key: yup.string().required(),
  value: yup.string().required(),
});

const logsToMetricsLabelSchema = yup
  .object({
    key: yup.string(),
    value: yup.string(),
  })
  .test('labels-filled-properly', function (values) {
    if (!values.key && !values.value) {
      return true;
    }

    try {
      return labelsObjectSchema.validateSync(values);
    } catch (e) {
      return this.createError({ path: `${this.path}.${e.path}`, message: e.message });
    }
  });

const logsToMetricsAggregationSchema = yup
  .object({
    methods: yup.array().of(oneOfEnum(LogsToMetricsAggregationMethod)).strict().nullable(),
    histogramBuckets: yup
      .array()
      .of(yup.number())
      .strict()
      .nullable()
      .min(1, 'Must have at least one bucket')
      .max(15, 'Maximum of 15 buckets'),
    targetField: yup.string().required('Aggregation target field is missing'),
  })
  .test('at-least-one-aggregation-action', function (values) {
    const isValid = values.methods?.length || values.histogramBuckets?.length;

    if (isValid) {
      return true;
    }

    return this.createError({ path: 'anyAggregationAction', message: 'Must select at least one aggregation action' });
  });

export const logsToMetricsRuleSchema = yup
  .object({
    name: yup
      .string()
      .matches(/^[a-zA-Z0-9_]+$/, 'You can use Letters, numbers and underscore')
      .max(128, 'Metric name must be at most 128 characters')
      .test(`does not start with a digit`, function (value) {
        const { path, createError } = this;
        const startsWithNumber = /^[0-9]/;

        if (startsWithNumber.test(value)) {
          return createError({ path, message: 'Metric name can not start with a digit' });
        }

        return true;
      })
      .required('Metric name is required'),
    description: yup.string().nullable(),
    metricsAccountId: yup.number().nullable(),
    filters: yup.array().of(logsToMetricsFilterSchema).strict().required().min(1, 'At least one filter is required'),
    dimensionFields: yup.array().of(yup.string().required()).strict().max(6, 'Please select up to 6 dimensions'),
    countLogs: yup.boolean().nullable(),
    aggregations: yup.array().of(logsToMetricsAggregationSchema).strict().nullable().max(1),
    labels: yup.array().of(logsToMetricsLabelSchema).strict().nullable(),
  })
  .test('at-least-one-agg-method', function (values) {
    const isValid = values.countLogs || values.aggregations?.length > 0;

    if (isValid) {
      return true;
    }

    return this.createError({ path: 'anyAggregation', message: 'Must select at least one aggregation' });
  });
