import { ElasticFilterType, IElasticFilter } from '../../../osd/filters/filter-interfaces';
import KibanaFilterMetadata from '../../../osd/filters/metadatas/osd-filter-metadata';
import IsOneOfFilterParser from '../../../osd/filters/parsers/is-one-of-filter-parser';
import { ElasticsearchFieldModel } from '../../../elasticsearch/elasticsearch-field';
import {
  ElasticsearchEnhancedFilterModel,
  IElasticsearchFilterValue,
} from '../../../elasticsearch/elasticsearch-filter';
import { BaseExploreFilterParser } from './base';

/**
 * Elastic to ElasticsearchFilterModel 'IS ONE OF' filter parser
 */
export class ExploreFilterIsOneOfFilterParser extends BaseExploreFilterParser {
  constructor() {
    super(new IsOneOfFilterParser());
  }

  getFilter(elasticFilter: object, negate: boolean): ElasticsearchEnhancedFilterModel {
    const metadata: KibanaFilterMetadata = this.getMetadata(elasticFilter, negate);

    if (!metadata) return null;

    const filter = new ElasticsearchEnhancedFilterModel();

    filter.type = 'IS_ONE_OF';
    filter.value = metadata.params;
    filter.negate = metadata.negate;
    filter.field = new ElasticsearchFieldModel();
    filter.field.name = metadata.key;

    return filter;
  }

  getElasticFilter(filter: ElasticsearchEnhancedFilterModel): IElasticFilter {
    if (filter.type !== 'IS_ONE_OF') return null;

    return {
      [this.baseFilterParser.elasticKey]: {
        minimum_should_match: 1,
        should: (filter.value as IElasticsearchFilterValue['IS_ONE_OF']).map(value => ({
          [ElasticFilterType.MatchPhrase]: { [filter.field.name]: value },
        })),
      },
    };
  }
}
