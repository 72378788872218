const cache: Record<string, string> = {};

const getMetaValue = (name: string): string => {
  const metaTag = document.querySelector(`meta[itemprop="${name}"]`);

  if (metaTag) {
    return metaTag.getAttribute('content');
  }

  return '';
};

export const pageConfigReader = {
  getValue: (name: string): string => {
    if (!cache[name]) {
      cache[name] = getMetaValue(`logz:${name}`);
    }

    return cache[name];
  },
};
