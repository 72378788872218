import KibanaFilterMetadata from '../metadatas/osd-filter-metadata';
import KibanaFilterModel from './osd-filter-model';

export default class LuceneFilterModel extends KibanaFilterModel {
  public query_string: { query: string };

  constructor(public meta: KibanaFilterMetadata) {
    super(meta);
    this.query_string = { query: meta.key };
  }
}
