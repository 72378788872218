import { Expose, Transform, Type } from 'class-transformer';
import { AlertOutputType, AlertCorrelationOperator, SeverityLevel } from '@logz-pkg/enums';
import { PlainNotificationEndpointModel } from '../notification-endpoint/plain-notification-endpoint';
import { AlertScheduleModel } from './alert-schedule';
import { SubComponentModel } from './sub-component';

class SubComponentCorrelations {
  correlationOperators: AlertCorrelationOperator[] = [AlertCorrelationOperator.And];
  joins: Record<number, string>[] = [];
}

export class AlertOutputRecipients {
  emails: string[] = [];
  @Expose({ name: 'notificationEndpointIds' })
  @Transform(
    (notificationEndpoints: PlainNotificationEndpointModel[]) =>
      notificationEndpoints.map(notificationEndpoint => notificationEndpoint.id),
    { toPlainOnly: true },
  )
  @Transform(value => (Array.isArray(value) ? value.map(endpoint => endpoint.id || endpoint) : []), {
    toClassOnly: true,
  })
  notificationEndpoints: PlainNotificationEndpointModel[] = [];
}

class AlertOutput {
  suppressNotificationsMinutes = 60;
  type: AlertOutputType = AlertOutputType.Json;

  @Type(() => AlertOutputRecipients) recipients: AlertOutputRecipients = new AlertOutputRecipients();
}

export class AlertModel {
  id: number | null;
  title = '';
  createdBy: string | null;
  createdAt: string | null;
  updatedBy: string | null;
  updatedAt: string | null;
  description: string;
  searchTimeFrameMinutes = 15;
  sendToAll = false;
  rca = false;

  @Transform(value => value.join(','), { groups: ['csv'] })
  tags: string[] = [];

  @Transform(JSON.stringify, { groups: ['csv'] })
  @Type(() => AlertOutput)
  output: AlertOutput = new AlertOutput();

  @Expose({ name: 'enabled' }) isEnabled: boolean;
  isProtected: boolean;

  @Transform(JSON.stringify, { groups: ['csv'] })
  correlations: SubComponentCorrelations = new SubComponentCorrelations();

  @Transform(JSON.stringify, { groups: ['csv'] })
  @Type(() => SubComponentModel)
  subComponents: SubComponentModel[] = [new SubComponentModel()];

  @Transform(JSON.stringify, { groups: ['csv'] })
  schedule: AlertScheduleModel = new AlertScheduleModel();
}

export const AlertExportFields: Array<keyof AlertModel> = [
  'id',
  'title',
  'createdBy',
  'createdAt',
  'updatedBy',
  'updatedAt',
  'description',
  'tags',
  'searchTimeFrameMinutes',
  'sendToAll',
  'output',
  'correlations',
  'subComponents',
  'schedule',
];

export type AlertsSearchFilters = {
  search: string;
  severities: SeverityLevel[];
  updatedBy: string[];
  createdBy: string[];
  enabledState: true[];
  emailNotifications: string[];
  notificationsEndpointIds: number[];
  tags: string[];
  alertIds: number[];
};

export type AlertsSearchSort = 'NAME' | 'SEVERITY' | 'CREATED_AT' | 'UPDATED_AT';
