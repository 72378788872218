const eventNames = {
  clickExportButton: 'export:click-export-button',
  insightFilter: 'insight:filtering',
  logsTabClicked: 'logs-tab-clicked',
  logsPatternsTabClicked: 'log-patterns-tab-clicked',
  exceptionsTabClicked: 'exceptions-tab-clicked',
  cognitiveInsightsTabClicked: 'cognitive-insights-tab-clicked',
};

const categories = {
  exceptions: 'exceptions',
  logPatterns: 'log-patterns',
  cognitiveInsights: 'cognitive-insights',
  logs: 'logs',
  export: 'export',
};

const analyticsDataTestSubj = {
  navbarInspect: 'openInspectorButton',
  navbarNew: 'discoverNewButton',
  navbarOpen: 'discoverOpenButton',
  navbarSave: 'discoverSaveButton',
  navHeaderTab: 'navDrawerAppsMenuLink',
  saveFilter: 'saveFilter',
};

const getDataTestSubSelector = (type: string): string => `[data-test-subj=${type}]`;

export const kibanaAnalyticsService = {
  eventNames,
  categories,
  analyticsDataTestSubj,
  getDataTestSubSelector,
};
