import { Expose, Type } from 'class-transformer';
import { isNil } from 'lodash';
import { LookupListModel } from '../lookup/lookup-list';
import { ElasticsearchFieldModel } from './elasticsearch-field';

export enum ElasticsearchFilterOperator {
  Exists = 'EXIST',
  Is = 'IS',
  IsOneOf = 'IS_ONE_OF',
  IsBetween = 'IS_BETWEEN',
  Lookup = 'LOOKUP',
  LessThan = 'LESS_THAN',
  GreaterThan = 'GREATER_THAN',
  Lucene = 'LUCENE',
}

export type ElasticsearchEnhancedFilterOperator =
  | 'EXIST'
  | 'IS'
  | 'IS_ONE_OF'
  | 'IS_BETWEEN'
  | 'LOOKUP'
  | 'LESS_THAN'
  | 'GREATER_THAN'
  | 'LUCENE';

export interface IElasticsearchFilterValue {
  [ElasticsearchFilterOperator.IsOneOf]: (string | number)[];
  [ElasticsearchFilterOperator.Is]: string | number | boolean;
  [ElasticsearchFilterOperator.Exists]: null;
  [ElasticsearchFilterOperator.IsBetween]: { gte: number | string; lt: number | string };
  [ElasticsearchFilterOperator.Lookup]: LookupListModel;
  [ElasticsearchFilterOperator.LessThan]: { gte: null; lt: number | string };
  [ElasticsearchFilterOperator.GreaterThan]: { gte: number | string; lt: null };
  [ElasticsearchFilterOperator.Lucene]: string | null;
}

export class ElasticsearchFilterModel {
  type: ElasticsearchFilterOperator = ElasticsearchFilterOperator.Is;
  negate = false;
  isDisabled?: boolean;
  value:
    | IElasticsearchFilterValue[ElasticsearchFilterOperator.IsBetween]
    | IElasticsearchFilterValue[ElasticsearchFilterOperator.Exists]
    | IElasticsearchFilterValue[ElasticsearchFilterOperator.Lookup]
    | IElasticsearchFilterValue[ElasticsearchFilterOperator.Is]
    | IElasticsearchFilterValue[ElasticsearchFilterOperator.IsOneOf];

  @Type(() => ElasticsearchFieldModel)
  field: ElasticsearchFieldModel = null;
  @Expose({ toClassOnly: true })
  invalid = false;

  getFilterValueFormat() {
    return {
      [ElasticsearchFilterOperator.Exists]: (_?: any) => 'exists',
      [ElasticsearchFilterOperator.Is]: (value: IElasticsearchFilterValue[ElasticsearchFilterOperator.Is]) => value,
      [ElasticsearchFilterOperator.Lookup]: (value: IElasticsearchFilterValue[ElasticsearchFilterOperator.Lookup]) =>
        `Lookup: ${value?.name}`,
      [ElasticsearchFilterOperator.IsOneOf]: (value: IElasticsearchFilterValue[ElasticsearchFilterOperator.IsOneOf]) =>
        value.join(', '),
      [ElasticsearchFilterOperator.IsBetween]: (
        value: IElasticsearchFilterValue[ElasticsearchFilterOperator.IsBetween],
      ) => `${!isNil(value.gte) ? value.gte : '-∞'} to ${!isNil(value.lt) ? value.lt : '+∞'}`,
    }
      [this.type](this.value)
      .toString();
  }
}

export class ElasticsearchEnhancedFilterModel {
  type: ElasticsearchEnhancedFilterOperator = 'IS';
  negate = false;
  isDisabled?: boolean;
  value:
    | IElasticsearchFilterValue['IS_BETWEEN']
    | IElasticsearchFilterValue['EXIST']
    | IElasticsearchFilterValue['LOOKUP']
    | IElasticsearchFilterValue['IS']
    | IElasticsearchFilterValue['IS_ONE_OF']
    | IElasticsearchFilterValue['LESS_THAN']
    | IElasticsearchFilterValue['GREATER_THAN']
    | IElasticsearchFilterValue['LUCENE'];

  @Type(() => ElasticsearchFieldModel)
  field: ElasticsearchFieldModel = null;
  @Expose({ toClassOnly: true })
  invalid = false;

  @Expose({ toClassOnly: true })
  get id() {
    return `${this.field?.id ?? 'field'}-${this.negate ? 'NOT_' : ''}${this.type}-${JSON.stringify(this.value) ?? ''}`;
  }
}
