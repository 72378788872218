import { Transform, Type } from 'class-transformer';
import { ACCOUNT_CREATION_STATES, AccountType, LogzioTheme, Permissions, UserRole } from '@logz-pkg/enums';
import { PlanDetailsModel } from '../plan-details/plan-detail';
import { AccountModel } from '../account/account';
import { UserModel } from '../user/user';

export class UserSettings {
  userId: number;
  hideKibanaSearchTooltip: boolean;
  kibanaTheme: number;
  allowSupport: boolean;
  externalLogin: boolean;
  insightsDisplayed: boolean;
  logzioTheme: LogzioTheme;
}

class AccountSettings {
  insightsEnabled: boolean;
  labsEnabled: boolean;
  welcomeFlowCurrentStep: number;
}

export class UserSession {
  accountId: number;
  accountType: AccountType;
  sessionId: number;
  userId: number;
  username: string;
  fullName: string;
  userToken: string;
  accountState: ACCOUNT_CREATION_STATES;
  indexPrefix: string;
  kibanaTheme: 0 | 1;
  role: UserRole;
  permissions: Permissions[];
  created: Date;
  userRegistered: Date;
  lastActivityDate: Date;
  expirationDateInSeconds: null;
  inSupportAccess: boolean;
  supportUserId: number;
  supportUsername: string;
  supportFullName: string;
  admin: boolean;
}

export class UserSummary {
  accountState: string;
  planType: string;
  listenerAddress: string;
  accountEsIndexPrefix: string;
  accountRegion: string;
  canManuallyAddUsers: boolean;
  /** @deprecated Use session.account.id instead */
  accountId: AccountModel['id'];
  /** @deprecated Use session.account.type instead */
  accountType: AccountType;
  systemModes: string[];
  frequencyMinutes: number;
  utilizationEnabled: boolean;
  docSizeSetting: boolean;
  cloudProvider: string;
  subAccount: boolean;
  accountLogsSuspensionState: string;
  @Type(() => Date) accountCreatedDate: Date;
  @Type(() => Date) planStartDate: Date;
  username: string;
  fullName: string;
  accountName: string;
  liftrAccount: boolean;
  utilizationSettings: {
    frequencyMinutes: number;
    utilizationEnabled: boolean;
  };
}

class IntercomIntegration {
  isDisabled = false;
}

class AmplitudeIntegration {
  isDisabled = false;
}

class FullstoryIntegration {
  isDisabled = false;
}

class AskNicelyIntegration {
  isDisabled = false;
  emailHash: string;
  forceOpenConversation = false;
}

class PendoIntegration {
  isDisabled: false;
}

class Integrations {
  @Type(() => IntercomIntegration) intercom: IntercomIntegration;
  @Type(() => AmplitudeIntegration) amplitude: AmplitudeIntegration;
  @Type(() => FullstoryIntegration) fullstory: FullstoryIntegration;
  @Type(() => AskNicelyIntegration) askNicely: AskNicelyIntegration;
  @Type(() => PendoIntegration) pendo: PendoIntegration;
}

export class SessionModel {
  featureFlags: string[];
  appAdminPanelUrl: string;
  @Type(() => UserSettings) userSettings: UserSettings;
  @Type(() => AccountSettings) accountSettings: AccountSettings;
  @Type(() => PlanDetailsModel) plan: PlanDetailsModel;
  @Type(() => UserModel) user = new UserModel();
  @Type(() => AccountModel)
  @Transform((account, session) => ({ ...account, type: session.userSummary.accountType }))
  account = new AccountModel();
  @Type(() => Integrations) integrations: Integrations;
  @Type(() => UserSummary) userSummary = new UserSummary();
  @Type(() => UserSession) userSession = new UserSession();
}
