import ExistsFilterParser from '../../../osd/filters/parsers/exists-filter-parser';
import { ElasticsearchFieldModel } from '../../../elasticsearch/elasticsearch-field';
import { ElasticsearchFilterModel, ElasticsearchFilterOperator } from '../../../elasticsearch/elasticsearch-filter';
import KibanaFilterMetadata from '../../../osd/filters/metadatas/osd-filter-metadata';
import { IElasticFilter } from '../../../osd/filters/filter-interfaces';
import { BaseAlertFilterParser } from './base';

/**
 * Elastic to ElasticsearchFilterModel 'EXISTS' filter parser
 */
export class AlertFilterExistsFilterParser extends BaseAlertFilterParser {
  constructor() {
    super(new ExistsFilterParser());
  }

  getFilter(elasticFilter: object, negate: boolean): ElasticsearchFilterModel {
    const metadata: KibanaFilterMetadata = this.getMetadata(elasticFilter, negate);

    if (!metadata) return null;

    const filter = new ElasticsearchFilterModel();

    filter.type = ElasticsearchFilterOperator.Exists;
    filter.negate = metadata.negate;
    filter.field = new ElasticsearchFieldModel();
    filter.field.name = metadata.key;

    return filter;
  }

  getElasticFilter(filter: ElasticsearchFilterModel): IElasticFilter {
    if (filter.type !== ElasticsearchFilterOperator.Exists) return null;

    return { [this.baseFilterParser.elasticKey]: { field: filter.field.name } };
  }
}
