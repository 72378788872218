import BaseFilterParser from './parsers/base-filter-parser';
import ExistsFilterParser from './parsers/exists-filter-parser';
import IsBetweenFilterParser from './parsers/is-between-filter-parser';
import IsFilterParser from './parsers/is-filter-parser';
import IsOneOfFilterParser from './parsers/is-one-of-filter-parser';
import lookupFilterParser from './parsers/lookup-filter-parser';
import MatchFilterParser from './parsers/match-filter-parser';

export const KibanaFilterParsers = [
  new IsFilterParser(),
  new IsBetweenFilterParser(),
  new IsOneOfFilterParser(),
  new ExistsFilterParser(),
  new MatchFilterParser(),
];

export const CustomFilterParsers: BaseFilterParser[] = [lookupFilterParser];

export const SupportedElasticKeys: string[] = KibanaFilterParsers.map((parser: BaseFilterParser) => parser.elasticKey);

export const CustomElasticKeys: string[] = CustomFilterParsers.map((parser: BaseFilterParser) => parser.elasticKey);
